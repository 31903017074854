import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Security from '../../commons/security/Security.js';
import Loading from '../ui/Loading.js';
import 'moment/min/locales';
import { FormattedMessage, injectIntl } from 'react-intl';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import BasicCardMultipleValuesInfo from '../ui/BasicCardMultipleValuesInfo.js';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import LlantaAsignacionesDetails from './LlantaAsignacionesDetails';
import PreventivoTicketRojo from '../../assets/images/tareas/preventivostickets-06-rojo.svg';
import PreventivoRojo from '../../assets/images/tareas/preventivo_rojo.svg';
import PreventivoAmarillo from '../../assets/images/tareas/preventivo_amarillo.svg';
import PreventivoVerde from '../../assets/images/tareas/preventivo_verde.svg';
import Calendario from '../../assets/images/tareas/calendario.svg';
import Autopista from '../../assets/images/tareas/autopista.svg';
import $ from 'jquery';
import VencimientoTicketRojo from '../../assets/images/tareas/vencimientostickets-07-rojo.svg';
import VencimientoVerde from '../../assets/images/tareas/vencimiento_verde.svg';
import VencimientoAmarillo from '../../assets/images/tareas/vencimiento_amarillo.svg';
import VencimientoRojo from '../../assets/images/tareas/vencimiento_rojo.svg';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Sort from '../../assets/images/llantas/table/sort.svg';
import SortAsc from '../../assets/images/llantas/table/sort-asc.svg';
import SortDesc from '../../assets/images/llantas/table/sort-desc.svg';
import Input from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Timezone from '../../commons/timezone/Timezone.js';
import * as Utils from '../../commons/utils/Utils';
import Switch from "react-switch";

const useStylesPersonal = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {},
}));

function Row(props) {
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const { movil } = props;
  const { index } = props;
  const { handleImageError } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [llantaDominio, setLlantaDominio] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [isHidden, setIsHidden] = useState('hidden-personal');
  const [marcasImages, setMarcasImages] = useState(null);

  useLayoutEffect(() => {
    $('#preventivoDias_' + movil.id).tooltip();
    $('#preventivoTicket_' + movil.id).tooltip();
    $('#preventivoServicio_' + movil.id).tooltip();
    $('#vencimientoServicio_' + movil.id).tooltip();
    $('#vencimientoTicket_' + movil.id).tooltip();
    $('#estadoMovil_' + movil.id).tooltip();
    return () => {
      $('.preventivoDias_' + movil.id).tooltip('dispose');
      $('.preventivoTicket_' + movil.id).tooltip('dispose');
      $('.preventivoServicio_' + movil.id).tooltip('dispose');
      $('.vencimientoServicio_' + movil.id).tooltip();
      $('.vencimientoTicket_' + movil.id).tooltip();
      $('.estadoMovil_' + movil.id).tooltip();
    };
  }, []);

  const openCollapse = () => {
    setOpen(!open);
    if (isHidden === 'hidden-personal') {
      setIsHidden('block');
    } else {
      setIsHidden('hidden-personal');
    }
  };

  const tareaPreventivo = () => {
    let preventivo = null;
    if (props.estadosActivos.indexOf(movil.estado) === -1) {
      return '';
    } else {
      let promedio = movil.promedio_kms_recorridos ? movil.promedio_kms_recorridos : 0;
      let mensajeP = '';
      let diasPreventivo = null;
      if (movil.prev_id != null) {
        if (movil.fecha_prev == null) {
          if (promedio != 0) {
            diasPreventivo = Math.ceil(movil.resta_prev / promedio);
            mensajeP =
              diasPreventivo == 0
                ? 'Aprox. Hoy'
                : diasPreventivo < 0
                ? 'Hace ' + Math.abs(diasPreventivo) + ' días'
                : 'En aprox. ' + Math.abs(diasPreventivo) + ' días';
          } else diasPreventivo = null;
        } else {
          diasPreventivo = -movil.fecha_prev;
          mensajeP = 'Ticket Abierto hace ' + Math.abs(diasPreventivo) + ' días';
        }
        if (diasPreventivo) if (diasPreventivo < 0) diasPreventivo = diasPreventivo * -1;
        preventivo = {
          dias: diasPreventivo,
          kms: Math.ceil(movil.resta_prev),
          servicio: movil.nombre_prev,
          tiene_ticket: Boolean(movil.tiene_ticket_prev),
          ticket_id: movil.ticket_id_prev,
          mensaje: mensajeP,
        };
      } else {
        preventivo = null;
      }
      let devolver = '';
      if (preventivo !== null) {
        if (preventivo.servicio !== null) {
          devolver +=
            '<div class="text-center success dt-info-icon row"><div data-html="true" id="preventivoServicio_' +
            movil.id +
            '" data-placement="top" data-toggle="tooltip" title="' +
            props.intl.formatMessage({
              id: 'movilesGrid.preventivos.column_prox_tareas.content_servicio',
              defaultMessage: 'Servicio: ',
            }) +
            preventivo.servicio +
            '">';
          if (preventivo.tiene_ticket) {
            devolver +=
              '<a href="' +
              process.env.PUBLIC_URL +
              '/tickets/' +
              preventivo.ticket_id +
              '/edit/"><img style="height: 30px" src="';
          } else {
            devolver += '<img style="height: 30px" src="';
          }
          if (preventivo.tiene_ticket) {
            devolver += PreventivoTicketRojo;
          } else {
            if (preventivo.dias === null) {
              if (preventivo.kms <= 100) devolver += PreventivoRojo;
              else if (preventivo.kms <= 1000) {
                devolver += PreventivoAmarillo;
              } else {
                devolver += PreventivoVerde;
              }
            } else {
              if (preventivo.dias <= 7) {
                devolver += PreventivoRojo;
              } else if (preventivo.dias <= 30) {
                if (preventivo.kms <= 100) {
                  devolver += PreventivoRojo;
                } else {
                  devolver += PreventivoAmarillo;
                }
              } else {
                if (preventivo.kms <= 100) devolver += PreventivoRojo;
                else if (preventivo.kms <= 1000) {
                  devolver += PreventivoAmarillo;
                } else {
                  devolver += PreventivoVerde;
                }
              }
            }
          }
          if (preventivo.tiene_ticket) {
            devolver += '"/></a></div>';
          } else {
            devolver += '"/></div>';
          }
          if (preventivo.dias !== null) {
            devolver +=
              '<div data-html="true" id="preventivoDias_' +
              movil.id +
              '" data-placement="top" data-toggle="tooltip" title="' +
              preventivo.mensaje +
              '"><img style="height: 30px" src="' +
              Calendario +
              '"></div>';
          }
          if (!preventivo.tiene_ticket)
            //en el caso en que tenga ticket no se muestra la ruta porque es confuso el tooltip del calendario con respecto al tooltip de la ruta
            devolver +=
              '<div data-html="true" id="preventivoTicket_' +
              movil.id +
              '" data-placement="top" data-toggle="tooltip" title="' +
              (preventivo.kms < 0 ? 'Hace ' : 'En ') +
              Math.abs(preventivo.kms) +
              ' kms"><img style="height: 30px" src="' +
              Autopista +
              '"></div>';
          devolver += '</div>';
        }
      }
      return devolver;
    }
  };

  const tareaVencimiento = () => {
    let vencimiento = null;
    if (props.estadosActivos.indexOf(movil.estado) === -1) {
      return '';
    } else {
      let mensaje = '';
      if (movil.ven_id != null) {
        if (movil.fecha_ven == null) {
          if (movil.resta_ven <= 0) {
            mensaje =
              'Venció ' +
              (movil.resta_ven == 0
                ? 'hoy'
                : 'hace ' + Math.abs(Math.ceil(movil.resta_ven)) + ' días');
          } else {
            mensaje = 'Vence en ' + Math.ceil(movil.resta_ven) + ' días';
          }
        } else {
          if (movil.fecha_ven <= movil.resta_ven) {
            mensaje =
              'Ticket Abierto ' +
              (movil.fecha_ven == 0
                ? 'hoy'
                : 'hace ' + Math.abs(Math.ceil(movil.fecha_ven)) + ' días');
          } else {
            //venció y luego se creó el ticket
            mensaje =
              'Venció ' +
              (movil.resta_ven == 0
                ? 'hoy'
                : 'hace ' + Math.abs(Math.ceil(movil.resta_ven)) + ' días');
          }
        }
        vencimiento = {
          dias: movil.resta_ven,
          servicio: movil.nombre_ven,
          tiene_ticket: Boolean(movil.tiene_ticket_ven),
          ticket_id: movil.ticket_id_ven,
          mensaje: mensaje,
        };
      } else {
        vencimiento = null;
      }
      let devolver = '';
      if (vencimiento !== null) {
        devolver += '<div class="text-center success dt-info-icon row">';
        if (vencimiento.servicio !== null) {
          devolver +=
            '<div data-html="true" id="vencimientoServicio_' +
            movil.id +
            '" data-placement="top" data-toggle="tooltip" title="' +
            props.intl.formatMessage({
              id: 'movilesGrid.vencimientos.column_prox_tareas.content_servicio',
              defaultMessage: 'Servicio: ',
            }) +
            vencimiento.servicio +
            '">';
          if (vencimiento.tiene_ticket) {
            devolver +=
              '<a href="' +
              process.env.PUBLIC_URL +
              '/tickets/' +
              vencimiento.ticket_id +
              '/edit/"><img style="height: 30px" src="';
          } else {
            devolver += '<img style="height: 30px" src="';
          }
          if (vencimiento.tiene_ticket) {
            devolver += VencimientoTicketRojo;
          } else {
            if (vencimiento.dias > 30) {
              devolver += VencimientoVerde;
            } else if (vencimiento.dias > 7 && vencimiento.dias <= 30) {
              devolver += VencimientoAmarillo;
            } else {
              devolver += VencimientoRojo;
            }
          }
          if (vencimiento.tiene_ticket) {
            devolver += '"/></a></div>';
          } else {
            devolver += '"/></div>';
          }
          devolver +=
            '<div data-html="true" id="vencimientoTicket_' +
            movil.id +
            '" data-placement="top" data-toggle="tooltip" title="' +
            vencimiento.mensaje +
            '"><img style="height: 30px" src="' +
            Calendario +
            '"/></div></div>';
        }
      }
      return devolver;
    }
  };

  const getLlantaEstado = () => {
    if (props.estadosResaltados && props.estadosResaltados.indexOf(movil.estado) >= 0) {
      return 'status-orange';
    } else {
      if (props.estadosActivos.indexOf(movil.estado) >= 0) {
        return 'status-green';
      } else {
        return 'status-red';
      }
    }
  };

  $('#preventivoDias_' + movil.id).tooltip();
  $('#preventivoTicket_' + movil.id).tooltip();
  $('#preventivoServicio_' + movil.id).tooltip();
  $('#vencimientoServicio_' + movil.id).tooltip();
  $('#vencimientoTicket_' + movil.id).tooltip();
  $('#estadoMovil_' + movil.id).tooltip();

  return (
    <React.Fragment>
      <tr key={movil.id}>
        <td
          component="th"
          scope="row"
          align="center"
          size="small"
          className="custom-lineHeight"
          style={{ width: '70px' }}
        >
          <div className="d-flex items-center">
            <div
              className="personal-no-focus avatar-plus-detail d-flex items-center"
              aria-label="expand row"
              size="small"
              onClick={() => openCollapse()}
            >
              {open ? (
                <i className="fa fa-2x fa-angle-double-down personal-no-focus avatar-plus-detail"></i>
              ) : (
                <i className="fa fa-angle-double-right fa-2x personal-no-focus avatar-plus-detail"></i>
              )}
            </div>
            {movil.movil_codigo ? (
              <img
                className="dt-user-avatar-image"
                src={process.env.PUBLIC_URL + '/images/marcas/' + movil.movil_codigo + '.png'}
                onError={(e) => {
                  handleImageError(e, movil, index);
                }}
              />
            ) : (
              <div key={'logo_' + movil.id} className="dt-user-avatar">
                {movil.movil_marca_label.substr(0, 2).toUpperCase()}
              </div>
            )}
          </div>
        </td>
        <td
          className={getLlantaEstado(movil) + ' estadoMovil_' + movil.id + ' all custom-lineHeight'}
          data-toggle="tooltip"
          data-placement="top"
          title={movil.estado}
          style={{ maxWidth: '3px' }}
        ></td>
        <td className="all custom-lineHeight">{movil.dominio}</td>
        <td className="all custom-lineHeight">{movil.movil_modelo_label}</td>
        <td className="all custom-lineHeight">
          <div dangerouslySetInnerHTML={{ __html: tareaPreventivo() + tareaVencimiento() }} />
        </td>
        <td className="all custom-lineHeight">{movil.movil_base_label}</td>
        <td className="all custom-lineHeight">
          {movil.ultimaInspeccion
            ? Timezone.getDateForClient(
                movil.ultimaInspeccion.created_at,
                null,
                'YYYY-MM-DD HH:mm:ss',
              )
            : ''}
        </td>
      </tr>
      <TableRow className={isHidden}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <LlantaAsignacionesDetails
              intl={props.intl}
              redirectToCorrectivo={props.redirectToCorrectivo}
              redirectToInspeccion={props.redirectToInspeccion}
              movil={movil}
              modelo_id={movil.movil_modelo_id}
            /> */}
            <LlantaAsignacionesDetails
              redirectToCorrectivo={props.redirectToCorrectivo}
              redirectToInspeccion={props.redirectToInspeccion}
              movil={movil}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const LlantaAsignacionesGrid = (props) => {
  const classes = useStylesPersonal();
  const intl = props.intl;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [dataService, setDataService] = useState('/llantaasignaciones');
  const [moviles, setMoviles] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [bases, setBases] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    count: 2,
    perPage: 10,
  });
  const [query, setQuery] = useState({
    page: 0,
    activo: 1,
    dominio: '',
    dominioSort: '',
    marca: '',
    marcaSort: '',
    base: '',
    pais: '',
    baseSort: '',
    modelo: '',
    modeloSort: '',
    estado: '',
    movil_id: '',
    filtroKpi: '',
  });
  const [accordionExpanded, setAccordionExpanded] = React.useState(false);
  const [summaryAlerts, setSummaryAlerts] = useState([]);
  const [estados, setEstados] = useState([]);
  const [paises, setPaises] = useState([]);
  const [estadosActivos, setEstadosActivos] = useState([]);
  const [estadosInactivos, setEstadosInactivos] = useState([]);
  const [estadosResaltados, setEstadosResaltados] = useState(null);
  const [marca, setMarca] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [base, setBase] = useState(null);
  const [pais, setPais] = useState(null);
  const [modeloSort, setModeloSort] = useState(null);
  const [estado, setEstado] = useState('');
  const [dominio, setDominio] = useState('');
  const [kpis, setKpis] = useState([]);
  const [filtersActive, setFiltersActive] = useState(null);
  const [queryFilterBase, setQueryFilterBase] = useState(null);
  const [filterLoading, setFilterLoading] = useState({
    marcas: false,
    modelos: false,
  });
  const [checked, setChecked] = React.useState(false);
  const [sortConfig, setSortConfig] = React.useState({
    modelo: null,
  });
  const [showInactivos, setShowInactivos] = useState(false);
  useEffect(() => {
    if (Security.hasPermission('LLANTAS_ASIGNACIONES_LISTAR')) {
      ajaxHandler.subscribe(this);
      initGrid();
    } else {
      setRedirectTo('/error');
    }
    Promise.all([
      ConfigBusiness.get('moviles.estados'),
      ConfigBusiness.get('moviles.estadosActivos'),
      ConfigBusiness.get('moviles.estadosInactivos'),
      ConfigBusiness.get('moviles.estadosResaltados'),
    ]).then((data) => {
      let estados = data[0] ? data[0].split(',') : null;
      let menuEstado = [];
      estados.forEach((estado) => {
        menuEstado.push({ value: estado, label: estado });
      });
      let estadosActivos = data[1] ? data[1].split(',') : null;
      let estadosInactivos = data[2] ? data[2].split(',') : null;
      let estadosResaltados = data[3] ? data[3].split(',') : null;
      setSummaryAlerts(data[4]);
      setEstados(estados);
      setEstadosActivos(estadosActivos);
      setEstadosInactivos(estadosInactivos);
      setEstadosResaltados(estadosResaltados);
    });
    return () => {};
  }, []);

  const initGrid = () => {
    setLoading(true);
    window.scrollTo(0, 0);
    Promise.all([
      getData('/marcas/select'),
      getData('/modelos/select'),
      getData('/bases/select-filtered-by-user/base'),
      getData('/paises'),
    ]).then((data) => {
      setMarcas(data[0]);
      setModelos(data[1]);
      setBases(data[2]);
      setPaises(data[3]);
    });

    getData(dataService + dataTableQuery())
      .then((data) => {
        setLoading(false);
        setMoviles(data.moviles);
        setPagination(data.pagination);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const dataTableQuery = () => {
    let myQuery = '?page=' + pagination.page;
    myQuery += '&perPage=' + pagination.perPage;
    myQuery += '&perPage=' + pagination.perPage;
    if (query.dominio.value) {
      myQuery += '&dominio=' + query.dominio.value;
      myQuery += '&dominioSort=' + query.dominioSort;
    } else {
      myQuery += '&dominio=' + query.dominio;
      myQuery += '&dominioSort=' + query.dominioSort;
    }
    if (query.activo !== null) {
      myQuery += "&activo=" + query.activo;
    }
    myQuery += '&marca=' + query.marca;
    myQuery += '&marcaSort=' + query.marcaSort;
    myQuery += '&modelo=' + query.modelo;
    myQuery += '&modeloSort=' + query.modeloSort;
    myQuery += '&estado=' + query.estado;
    myQuery += '&base=' + query.base;
    myQuery += '&baseSort=' + query.baseSort;
    myQuery += '&movil_id=' + query.movil_id;
    myQuery += '&filtroKpi=' + query.filtroKpi;
    myQuery += '&pais=' + query.pais;
    return myQuery;
  };

  const dataTableUpdate = () => {
    setFiltersActive(true);
    //let photoUrl = Config.get('apiUrlBase') + dataService + dataTableQuery();

    Promise.all([
        Utils.getData(dataService + dataTableQuery())
      ]).then((data) => {
        console.log(data);
        let moviles = data[0].moviles;
        setMoviles(moviles);
        let pagination = data[0].pagination;
        setPagination(pagination);
        
    });
    /*fetch(photoUrl, {
      method: 'GET',
      headers: {
        'Authorization-Token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let moviles = data.moviles;
        setMoviles(moviles);
        let pagination = data.pagination;
        setPagination(pagination);
      });*/
  };
  const handleImageError = (event, movil, index) => {
    let movilCopy = JSON.parse(JSON.stringify(movil));
    let movilesCopy = JSON.parse(JSON.stringify(moviles));
    movilCopy.movil_codigo = null;
    movilesCopy[index] = movilCopy;
    setMoviles(movilesCopy);
  };
  const handleChangePage = (event, newPage) => {
    pagination.page = newPage;
    dataTableUpdate();
  };

  const handleChangeRowsPerPage = (event) => {
    pagination.perPage = parseInt(event.target.value, 10);
    pagination.page = 0;
    dataTableUpdate();
  };

  const getData = (service) => {
    let serviceURL = Config.get('apiUrlBase') + service;
    return fetch(serviceURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  };
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleFilterReset = () => {
    query.page = 0;
    query.dominio = '';
    query.dominioSort = '';
    query.marca = '';
    query.marcaSort = '';
    query.modelo = '';
    query.modeloSort = '';
    query.estado = '';
    query.base = '';
    query.baseSort = '';
    query.filtroKpi = '';
    query.movil_id = '';
    query.pais = '';
    setMarca(null);
    setModelo(null);
    setModeloSort(null);
    setBase(null);
    setDominio('');
    setEstado(null);
    setPais(null);
    setQuery(query);
    dataTableUpdate();
    setFiltersActive(null);
  };

  const handleMaterialChange = (name, values) => {
    if (name === 'marca') {
      query['marca'] = values ? values.value : '';
      query['modelo'] = values ? '' : query['modelo'];
      filterLoading['modelos'] = true;
      setMarca(values ? values : null);
      setFilterLoading(filterLoading);
      actualizarSelectModelosDeMarca(values);
    }
    if (name === 'modelo') {
      query['modelo'] = values ? values.id : '';
      filterLoading['marcas'] = true;
      setModelo(values ? values : null);
      setFilterLoading(filterLoading);
    }
    if (name === 'dominio') {
      let event = values;
      setDominio(event.target.value);
      query[name] = event.target.value;
    }
    if (name === 'estado') {
      query['estado'] = values ? values : '';
    }
    if (name === 'base') {
      if(values){
        query['base'] = values ? values.value : '';
        setBase(values ? values : null);
      }else{
        query['base'] = '';
        setBase(null);
        setQueryFilterBase(null);
        searchBaseString([]);
      }
    }
    if (name === 'pais') {
      setPais(values ? values : null);
      query['pais'] = values ? values.id : '';
    }
    pagination.page = 0;
    setQuery(query);
    dataTableUpdate();
    if (values === null) {
      setFiltersActive(null);
    }
  };

  const handleDominioChange = (e) => {
    setDominio(e.target.value);
    query['dominio'] = e.target.value;
    setQuery(query);
  };

  const handleActivoSwitch = (value) => {
    setShowInactivos(value)
    query['activo'] = value === true ? 0 : 1;
    setQuery(query)
    dataTableUpdate(false);
  }

  const handleOnEnterDominioChange = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleMaterialChange("dominio", {target: {value: dominio}})
    }
  }

  const actualizarSelectModelosDeMarca = (marca) => {
    if (marca) {
      setModelo(null);
      Promise.all([getData('/modelos/marca/' + marca.value + '/select')]).then((data) => {
        setModelos(data[0].map(el => ({...el, nombre: el.label, id: el.value})));
        setFilterLoading({...filterLoading, modelos: false});
      });
    } else {
      Promise.all([getData('/marcas/select'), getData('/modelos/select')]).then((data) => {
        setMarcas(data[0]);
        setModelos(data[1]);
        setFilterLoading({modelos: false, marcas: false});
      });
    }
  };

  const handleAlertClick = (filter) => {
    query.filtroKpi = filter;
    dataTableUpdate();
  };

  const setLoadingAndUpdate = (state) => {
    setLoading(state);
    dataTableUpdate();
  };

  const redirectToCorrectivo = (id) => {
    setRedirectTo('/correctivos/add/llanta' + '/' + id);
  };

  const redirectToInspeccion = (id) => {
    setRedirectTo('/llantainspecciones/add?movil_id=' + id);
  };

  const requestSort = (key) => {
    pagination['page'] = 0;
    if (key === 'modeloSort') {
      if (!query[key]) query[key] = 'asc';
      else if (query[key] === 'desc') query[key] = 'asc';
      else query[key] = 'desc';
      query['dominioSort'] = '';
      query['marcaSort'] = '';
      query['baseSort'] = '';
    } else if (key === 'dominioSort') {
      if (!query[key]) query[key] = 'asc';
      else if (query[key] === 'desc') query[key] = 'asc';
      else query[key] = 'desc';
      query['modeloSort'] = '';
      query['marcaSort'] = '';
      query['baseSort'] = '';
    } else if (key === 'marcaSort') {
      if (!query[key]) query[key] = 'asc';
      else if (query[key] === 'desc') query[key] = 'asc';
      else query[key] = 'desc';
      query['modeloSort'] = '';
      query['dominioSort'] = '';
      query['baseSort'] = '';
    } else if (key === 'baseSort') {
      if (!query[key]) query[key] = 'asc';
      else if (query[key] === 'desc') query[key] = 'asc';
      else query[key] = 'desc';
      query['modeloSort'] = '';
      query['dominioSort'] = '';
      query['marcaSort'] = '';
    }
    setQuery(query);
    setPagination(pagination);
    dataTableUpdate();
  };

  const searchBase = (e) => {
    setQueryFilterBase(e.target.value);
    const value = e.target.value;
    Utils.getData('/bases/simple-search?search=' + value).then((res) =>
      setBases(res.map((b) => ({ value: b.id, label: b.descripcion }))),
    );
  };

  const searchBaseString = (value) => {
    setQueryFilterBase(value);
    Utils.getData("/bases/simple-search?search=" + value).then((res) =>
      setBases(res.map((b) => ({ value: b.id, label: b.descripcion })))
    );
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
      {summaryAlerts && (
        <div className="row">
          {summaryAlerts.map((alert, index) => {
            return (
              <div
                key={index}
                className={'col-lg-2 col-md-4 col-12'}
                style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
              >
                <BasicCardMultipleValuesInfo
                  onClickAlert={handleAlertClick}
                  id={index}
                  values={alert}
                ></BasicCardMultipleValuesInfo>
              </div>
            );
          })}
        </div>
      )}
      <div className="row mt-2">
        <div className="col-12">
          <div className="card">
            <div className="card-content collpase show">
              <div className="card-body card-dashboard">
                <div className="container-fluid">
                  <div className="row dt-icons">
                    <div className="col-10">
                      <div className="row">
                        <div
                          style={{ maxHeight: '52px' }}
                          className="btn btn-grey-blue btn-round box-shadow-2 btn-dt-main round-icon"
                          onClick={handleChange}
                          data-toggle="tooltip"
                          data-placement="right"
                          title={props.intl.formatMessage({
                            id: 'llantaAsignacionesGrid.render.button_add.title_filtros',
                            defaultMessage: 'Filtros',
                          })}
                        >
                          {checked ? <UpIcon /> : <ExpandMoreIcon />}
                        </div>
                        {filtersActive ? (
                          <div className="btn-dt-main round-icon">
                            <div
                              className="text-center"
                              style={{
                                height: '25px',
                                borderRadius: '3px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingLeft: '8px',
                                backgroundColor: '#5AAC43',
                                color: 'white',
                              }}
                            >
                              <div
                                className="text-center"
                                style={{
                                  width: '100%',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                }}
                              >
                                {moviles.length} de {pagination.count} resultados de busqueda
                              </div>
                              <div className="resultados-busqueda-notif">
                                <i
                                  className="las la-times la-xs cursor-pointer"
                                  onClick={handleFilterReset}
                                ></i>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse in={checked}>
                  <div className="row mb-1">
                    <div className="col-md-10">
                      <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="marca"
                            loading={true}
                            options={marcas}
                            value={marca ? marca : null}
                            getOptionLabel={(option) => option.label}
                            onChange={(name, values) => handleMaterialChange('marca', values)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_marca.label',
                                  defaultMessage: 'Marca',
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_marca.placeholder',
                                  defaultMessage: 'Marca',
                                })}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="modelo"
                            loading={filterLoading.modelos}
                            options={modelos}
                            value={modelo ? modelo : null}
                            getOptionLabel={(option) => option.nombre}
                            onChange={(event, value) => handleMaterialChange('modelo', value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_modelo.label',
                                  defaultMessage: 'Modelo',
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_modelo.placeholder',
                                  defaultMessage: 'Modelo',
                                })}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {filterLoading.modelos ? (
                                        <CircularProgress color="inherit" size={21} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="estado"
                            options={estados}
                            value={query.estado}
                            getOptionLabel={(option) => option}
                            onChange={(event, value) => handleMaterialChange('estado', value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_estado.label',
                                  defaultMessage: 'Estado',
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_estados.placeholder',
                                  defaultMessage: 'Estados',
                                })}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-4">
                          <form className={classes.root} noValidate autoComplete="off">
                            <Input
                              id="standard-basic"
                              label={props.intl.formatMessage({
                                id: 'llantaAsignacionesGrid.render.filtros_dominio.label',
                                defaultMessage: 'Dominio',
                              })}
                              name="serial"
                              value={query.dominio}
                              onChange={(e) => handleDominioChange(e)}
                              onBlur={(e) => handleMaterialChange('dominio', e)}
                              onKeyDown={(e) => handleOnEnterDominioChange(e)}
                            />
                          </form>
                        </div>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="base"
                            options={bases}
                            value={base ? base : null}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => handleMaterialChange('base', value)}
                            
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_base.label',
                                  defaultMessage: 'Base',
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: 'llantaAsignacionesGrid.render.filtros_base.placeholder',
                                  defaultMessage: 'Base',
                                })}
                                onChange={searchBase}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center">
                            <div className="mr-2">Ver Inactivos</div>
                            <Switch
                              onChange={(value) => handleActivoSwitch(value)}
                              checked={showInactivos}
                              disabled={loading}
                              offColor="#FF4961"
                              onColor="#28D094"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="pais"
                            options={paises}
                            value={pais ? pais : ''}
                            getOptionLabel={(option) => option.nombre}
                            onChange={(event, value) => handleMaterialChange('pais', value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: 'llantasGrid.render.filtros_pais.label',
                                  defaultMessage: 'Pais',
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: 'llantasGrid.render.filtros_paises.placeholder',
                                  defaultMessage: 'Paises',
                                })}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/*NEW FILTER*/}
                    </div>
                  </div>
                </Collapse>

                <div className="table-responsive">
                  <table
                    id="dataTable"
                    className="dataTable table nowrap server-side table-hover dataTableMovil"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        {/* <th scope="col"></th> */}
                        <th
                          scope="col"
                          style={{ cursor: 'pointer' }}
                          onClick={() => requestSort('marcaSort')}
                        >
                          <div className="bg-transparent text-white border-0">
                            <FormattedMessage
                              id="llantaAsignacionesGrid.rennder.column_marca.label"
                              defaultMessage="Marca"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.marcaSort === ''
                                  ? Sort
                                  : query.marcaSort === 'asc'
                                  ? SortAsc
                                  : SortDesc
                              }
                              alt={'/'}
                              style={{ height: '16px', width: '26px' }}
                            />
                          </div>
                        </th>
                        <th scope="col" style={{ maxWidth: '3px' }}></th>
                        <th
                          scope="col"
                          style={{ cursor: 'pointer' }}
                          onClick={() => requestSort('dominioSort')}
                        >
                          <div className="bg-transparent text-white border-0">
                            <FormattedMessage
                              id="llantaAsignacionesGrid.rennder.column_dominio.label"
                              defaultMessage="Dominio"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.dominioSort === ''
                                  ? Sort
                                  : query.dominioSort === 'asc'
                                  ? SortAsc
                                  : SortDesc
                              }
                              alt={'/'}
                              style={{ height: '16px', width: '26px' }}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          style={{ cursor: 'pointer' }}
                          onClick={() => requestSort('modeloSort')}
                        >
                          <div className="bg-transparent text-white border-0">
                            <FormattedMessage
                              id="llantaAsignacionesGrid.rennder.column_modelo.label"
                              defaultMessage="Modelo"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.modeloSort === ''
                                  ? Sort
                                  : query.modeloSort === 'asc'
                                  ? SortAsc
                                  : SortDesc
                              }
                              alt={'/'}
                              style={{ height: '16px', width: '26px' }}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <FormattedMessage
                            id="llantaAsignacionesGrid.rennder.column_prox_tareas.label"
                            defaultMessage="Prox. Tareas"
                          />
                        </th>
                        <th
                          scope="col"
                          style={{ cursor: 'pointer' }}
                          onClick={() => requestSort('baseSort')}
                        >
                          <div className="bg-transparent text-white border-0">
                            <FormattedMessage
                              id="llantaAsignacionesGrid.rennder.column_medidas.label"
                              defaultMessage="Base"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.baseSort === ''
                                  ? Sort
                                  : query.baseSort === 'asc'
                                  ? SortAsc
                                  : SortDesc
                              }
                              alt={'/'}
                              style={{ height: '16px', width: '26px' }}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <FormattedMessage
                            id="llantaAsignacionesGrid.rennder.column_recapados.label"
                            defaultMessage="Última inspección"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {moviles.length > 0 ? (
                        moviles.map((movil, index) => (
                          <Row
                            intl={props.intl}
                            movil={movil}
                            index={index}
                            handleImageError={handleImageError}
                            loading={(state) => setLoadingAndUpdate(state)}
                            key={movil.id}
                            dataTableUpdate={dataTableUpdate}
                            redirectToCorrectivo={redirectToCorrectivo}
                            redirectToInspeccion={redirectToInspeccion}
                            estadosResaltados={estadosResaltados}
                            estadosActivos={estadosActivos}
                          />
                        ))
                      ) : (
                        <tr>
                          <td align={'center'} colSpan={14}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              props.intl.formatMessage({
                                id: 'llantaAsignacionesGrid.render.sin_datos',
                                defaultMessage: 'No hay datos disponibles.',
                              })
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={pagination.count}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="Mostrar"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} a ${to} de ${count}`
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(LlantaAsignacionesGrid);
