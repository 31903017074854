import React, { Component } from 'react';
import Config from '../../commons/config/Config.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Security from '../../commons/security/Security.js'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import {FormattedMessage, injectIntl} from 'react-intl';
import Timezone from '../../commons/timezone/Timezone.js';

class MovilesTicketsGrid extends Component{
    constructor(props) {
        super(props);

        this.ajaxHandler = new AjaxHandler();

        this.state = {
            entidad: this.props.entidad,
            urlId: this.props.match.params.id,
            ticketGrid: {
                id: null,
                fechaHoraAlta: null,
                ticketTipo: null,
                servicio: null,
                estado: null,
                manoDeObra: null,
                repuestos: null,
                total: null
            },
            vencimientoGrid: {
                id: null,
                ticket: null,
                fechaHoraAlta: null,
                fechaHoraVenc: null,
                servicio: null
            },
            preventivoGrid: {
                id: null,
                servicio: null,
                km: null,
                realizar: null,
                repetir: null,
                alertar: null,
                ticket: null
            },
            redirectTo: null,
        }
        this._loaded =  false;
        this.clickViewEvent = this.clickViewEvent.bind(this);
    }

    componentDidMount(){
        this._loaded = true;
        if( (this.state.entidad === "PERSONA" && Security.hasAnyPermission(['TICKETS_PERSONA_LISTAR', 'VENCIMIENTO_PERSONA_LISTAR'])) ||
            (this.state.entidad === "MOVIL" && Security.hasAnyPermission(['TICKETS_MOVIL_LISTAR', 'VENCIMIENTOS_MOVIL_LISTAR', 'PREVENTIVOS_MOVIL_LISTAR']))){
                this.ajaxHandler.subscribe(this);
                this.initForm();
            }else{
                this.setState({
                    redirectTo: '/error'
                });
            }
    }

    componentWillUnmount() {
        this.ajaxHandler.unsubscribe();
        this._loaded = false;
    }

    clickViewEvent(ticketId){
        const miState = {...this.state};
        miState.redirectTo = "/tickets/"+ticketId;
        this.setState(miState);
    }

    initForm(){
        const that = this;
        const miState = {...this.state};
        let promises = [];

        if(this.state.entidad == "MOVIL"){
            if(Security.hasPermission('TICKETS_MOVIL_LISTAR')){
                const p0 = this.getData('/tickets/movil/'+this.state.urlId);
                promises.push(p0);
            }
            if(Security.hasPermission('VENCIMIENTOS_MOVIL_LISTAR')){
                const p1 = this.getData('/vencimientos/movil/'+this.state.urlId);
                promises.push(p1);
            }
            if(Security.hasPermission('PREVENTIVOS_MOVIL_LISTAR')){
                const p2 = this.getData('/preventivos/movil/'+this.state.urlId);
                promises.push(p2);
            }

        }else{
            if(Security.hasPermission('TICKETS_PERSONA_LISTAR')){
                const p0 = this.getData('/tickets/persona/'+this.state.urlId);
                promises.push(p0);
            }
            if(Security.hasPermission('VENCIMIENTOS_PERSONA_LISTAR')){
                const p1 = this.getData('/vencimientos/persona/'+this.state.urlId);
                promises.push(p1);
            }
        }

        Promise.all(promises)
        .then((data) => {
            let contData = 0;
            let tickets = [];
            let vencimientos = [];
            let preventivos = [];
            if(this.state.entidad == "MOVIL"){
                if(Security.hasPermission('TICKETS_MOVIL_LISTAR')){
                    if(data[contData]) data[contData].map((row, index) => {
                        let ticketGrid = {
                            id: null,
                            ticket: null,
                            fechaHoraAlta: null,
                            ticketTipo: null,
                            servicio: null,
                            estado: null,
                            manoDeObra: null,
                            repuestos: null,
                            total: null
                        }
                        ticketGrid.id = row.id;
                        ticketGrid.fechaHoraAlta = row.fechaHoraAlta;
                        ticketGrid.ticketTipo = row.ticketTipo;
                        ticketGrid.servicio = row.servicio.nombre;
                        ticketGrid.estado = row.estado;
                        ticketGrid.manoDeObra = row.manoDeObra ? parseFloat(row.manoDeObra).toFixed(2) : '0.00';
                        ticketGrid.repuestos = row.repuestos ? parseFloat(row.repuestos).toFixed(2) : '0.00';
                        ticketGrid.total = row.manoDeObra && row.repuestos ? (parseFloat(row.manoDeObra) + parseFloat(row.repuestos)).toFixed(2) : '0.00';

                        tickets.push(ticketGrid);
                    });
                    miState.ticketGrid = tickets;

                    contData++;
                }
                if(Security.hasPermission('VENCIMIENTOS_MOVIL_LISTAR')){
                    if(data[contData][0]) data[contData][0].map((row, index) => {
                        let vencimientoGrid = {
                            id: null,
                            ticket: null,
                            fechaHoraAlta: null,
                            fechaHoraVenc: null,
                            servicio: null
                        }
                        vencimientoGrid.id = row.id;
                        vencimientoGrid.ticket = row.ticket;
                        vencimientoGrid.fechaHoraAlta = row.fechaAlta;
                        vencimientoGrid.fechaHoraVenc = row.fechaVencimiento;
                        vencimientoGrid.servicio = row.servicio.nombre;

                        vencimientos.push(vencimientoGrid);

                    });
                    miState.vencimientoGrid = vencimientos;

                    contData++;
                }
                if(Security.hasPermission('PREVENTIVOS_MOVIL_LISTAR')){
                    if(data[contData]) data[contData].map((row, index) => {
                        let preventivoGrid = {
                            id: null,
                            servicio: null,
                            km: null,
                            realizar: null,
                            repetir: null,
                            alertar: null,
                            ticket: null
                        }

                        preventivoGrid.id = row.id;
                        preventivoGrid.servicio = row.servicio.nombre;
                        preventivoGrid.km = row.kmsAlAlta;
                        preventivoGrid.realizar = row.realizarALos;
                        preventivoGrid.repetir = row.servicio.preventivoCadaKms;
                        preventivoGrid.alertar = row.servicio.preventivoAlertarKmsPrevios;
                        preventivoGrid.ticket = row.ticket;

                        preventivos.push(preventivoGrid);
                    });
                    miState.preventivoGrid = preventivos;

                    contData++;
                }
            }else{
                if(Security.hasPermission('TICKETS_PERSONA_LISTAR')){
                    data[contData].map((row, index) => {
                        let ticketGrid = {
                            id: null,
                            ticket: null,
                            fechaHoraAlta: null,
                            ticketTipo: null,
                            servicio: null,
                            estado: null
                        }
                        ticketGrid.id = row.id;
                        ticketGrid.fechaHoraAlta = row.fechaHoraAlta;
                        ticketGrid.ticketTipo = row.ticketTipo;
                        ticketGrid.servicio = row.servicio.nombre;
                        ticketGrid.estado = row.estado;

                        tickets.push(ticketGrid);
                    });
                    miState.ticketGrid = tickets;

                    contData++;
                }

                if(Security.hasPermission('VENCIMIENTOS_PERSONA_LISTAR')){

                    data[contData].map((row, index) => {
                        let vencimientoGrid = {
                            id: null,
                            ticket: null,
                            fechaHoraAlta: null,
                            fechaHoraVenc: null,
                            servicio: null
                        }

                        vencimientoGrid.id = row.id;
                        vencimientoGrid.ticket = row.ticket;
                        vencimientoGrid.fechaHoraAlta = row.fechaAlta;
                        vencimientoGrid.fechaHoraVenc = row.fechaVencimiento;
                        vencimientoGrid.servicio = row.servicio.nombre;

                        vencimientos.push(vencimientoGrid);

                    });
                    miState.vencimientoGrid = vencimientos;

                    contData++;
                }
            }
            if(this._loaded){
                that.setState(miState);
            }
        })
    }

    render(){
        if(!this._loaded){
            return null;
        }

        let movilGrid = null;

        if((this.state.entidad == "MOVIL" && Security.hasPermission('TICKETS_MOVIL_LISTAR')) ||
           (this.state.entidad == "PERSONA" && Security.hasPermission('TICKETS_PERSONA_LISTAR'))){
            if(this.state.ticketGrid.length > 0){
                movilGrid = (

                    <div className="card pull-up">
                        <div className="card-content">
                            <div className="card-body">
                                <h4 className="form-section">
                                    <FormattedMessage id="ticketPersonaMovil.render.tickets.header_tickets" defaultMessage=" Tickets"/>
                                </h4>
                                <table className="table">
                                    <thead className="thead-fleet">
                                        <tr>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_ticket" defaultMessage="Ticket"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_fecha_hora_de_alta" defaultMessage="Fecha - Hora de Alta"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_tipo" defaultMessage="Tipo"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_servicio" defaultMessage="Servicio"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_estado" defaultMessage="Estado"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_mano_de_obra" defaultMessage="Mano De Obra"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_repuestos" defaultMessage="Repuestos"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.tickets.table_column_total" defaultMessage="Total"/></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ticketGrid.map((ticket, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{ticket.id}</td>
                                                    <td>{Timezone.getDateForClient(ticket.fechaHoraAlta, null,'DD/MM/YYYY') }</td>
                                                    <td>{ticket.ticketTipo}</td>
                                                    <td>{ticket.servicio}</td>
                                                    <td>{ticket.estado}</td>
                                                    <td className="text-right">{ticket.manoDeObra}</td>
                                                    <td className="text-right">{ticket.repuestos}</td>
                                                    <td className="text-right">{ticket.total}</td>
                                                    <td>
                                                        <button className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                                                                onClick={()=>this.clickViewEvent(ticket.id)}>
									                        <i className="fa fa-search fa-xs"></i>
								                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }else{
                movilGrid = (
                    <div className="card pull-up">
                        <div className="card-header"><h5><FormattedMessage id="ticketPersonaMovil.render.tickets.informatio_no_posee_tickets" defaultMessage="No posee tickets."/></h5></div>
                    </div>
                )
            }
        }


        let vencimientoGrid = null;

        if((this.state.entidad == "MOVIL" && Security.hasPermission('VENCIMIENTOS_MOVIL_LISTAR')) ||
           (this.state.entidad == "PERSONA" && Security.hasPermission('VENCIMIENTOS_PERSONA_LISTAR'))){
            if(this.state.vencimientoGrid.length > 0){
                vencimientoGrid = (
                    <div className="card pull-up">
                        <div className="card-content">
                            <div className="card-body">
                                <h4 className="form-section">
                                    <FormattedMessage id="ticketPersonaMovil.render.expiration.header_vencimientos" defaultMessage=" Vencimientos"/>
                                </h4>
                                <table className="table">
                                    <thead className="thead-fleet">
                                        <tr>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.expiration.table_column_fecha_hora_de_alta" defaultMessage="Fecha - Hora de Alta"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.expiration.table_column_fecha_hora_de_vencimiento" defaultMessage="Fecha - Hora de Vencimiento"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.expiration.table_column_servicio" defaultMessage="Servicio"/></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.vencimientoGrid.map((vencimiento, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{ Timezone.getDateForClient(vencimiento.fechaHoraAlta, null, 'DD/MM/YYYY')}</td>
                                                    <td>{ Timezone.getDateForClient(vencimiento.fechaHoraVenc, null, 'DD/MM/YYYY')}</td>
                                                    <td>{vencimiento.servicio}</td>
                                                    <td>
                                                        {   vencimiento.ticket !== null ?
                                                                <button className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                                                                    onClick={()=>this.clickViewEvent(vencimiento.ticket)}>
                                                                    <i className="fa fa-search fa-xs"></i>
                                                                </button>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }else{
                vencimientoGrid = (
                    <div className="card pull-up">
                        <div className="card-header"><h5><FormattedMessage id="ticketPersonaMovil.render.expiration.informatio_no_posee_vencimientos" defaultMessage="No posee vencimientos."/></h5></div>
                    </div>
                )
            }
        }


        let preventivoGrid = null;

        if(Security.hasPermission('PREVENTIVOS_MOVIL_LISTAR')){
            if(this.state.preventivoGrid.length > 0){
                preventivoGrid = (
                    <div className="card pull-up">
                        <div className="card-content">
                            <div className="card-body">
                                <h4 className="form-section">
                                    <FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.header_controles_de_mantenimiento_preventivo" defaultMessage=" Controles de Mantenimiento Preventivo"/>
                                </h4>
                                <table className="table">
                                    <thead className="thead-fleet">
                                        <tr>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.table_column_servicio" defaultMessage="Servicio"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.table_column_km_al_alta" defaultMessage="Medidor al Alta"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.table_column_realizar" defaultMessage="Realizar"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.table_column_repetir" defaultMessage="Repetir"/></th>
                                            <th scope="col"><FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.table_column_alertar" defaultMessage="Alertar"/></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.preventivoGrid.map((preventivo, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{preventivo.servicio}</td>
                                                    <td>{preventivo.km}</td>
                                                    <td>{preventivo.realizar}</td>
                                                    <td>{preventivo.repetir}</td>
                                                    <td>{preventivo.alertar}</td>
                                                    <td>
                                                        {   preventivo.ticket !== null ?
                                                                <button className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                                                                    onClick={()=>this.clickViewEvent(preventivo.ticket)}>
                                                                    <i className="fa fa-search fa-xs"></i>
                                                                </button>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }else{
                if(this.state.entidad == "MOVIL"){
                    preventivoGrid = (
                        <div className="card pull-up">
                            <div className="card-header"><h5><FormattedMessage id="ticketPersonaMovil.render.preventive_maintenance_controls.information_no_posee_controles_de_mantenimiento_preventivo" defaultMessage="No posee controles de mantenimiento preventivo."/></h5></div>
                        </div>
                    )
                }
            }
        }

        let botones = null;
        botones = (
            <div className="card-footer text-right">
                <button className="btn btn-danger"
                        onClick={() => this.props.history.goBack()}>
                    <i className="fa fa-times-circle"></i>&nbsp;Volver
                </button>
            </div>
        )
        return(
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                <div className="form form-horizontal">
                <div className="form-body">
                    {movilGrid}
                    {vencimientoGrid}
                    {preventivoGrid}
                    {botones}
                </div>
                </div>

            </React.Fragment>
        )
    }

    getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      // aparentemente el handleResponseErrorsIsValid está leyendo la response y genera un error
      // al intentar leerlo por segunda vez
      let response2 = response.clone();
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response2.json() : null;
		});
	}
}

export default injectIntl(MovilesTicketsGrid);
