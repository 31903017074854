import InstanciaSelect from './instancias/InstanciaSelect.js'
import InstanciaText from './instancias/InstanciaText.js'
import InstanciaNumber from './instancias/InstanciaNumber.js'
import InstanciaNumberRange from './instancias/InstanciaNumberRange.js'
import InstanciaSwitch from './instancias/InstanciaSwitch.js'
import InstanciaImagen from './instancias/InstanciaImagen.js'
import InstanciaAdjunto from './instancias/InstanciaAdjunto.js'
import InstanciaFirma from './instancias/InstanciaFirma.js'
import InstanciaSiNo from './instancias/InstanciaSiNo.js'
import InstanciaMedidor from './instancias/InstanciaMedidor.js'
import ExtraBasic from './extras/ExtraBasic.js'
import ExtraSelectSimple from './extras/ExtraSelectSimple.js'
import ExtraSelectCompuesto from './extras/ExtraSelectCompuesto.js'
import ExtraSiNo from './extras/ExtraSiNo.js'
import ExtraNumber from './extras/ExtraNumber.js'
import InstanciaDate from './instancias/InstanciaDate.js'
import InstanciaTime from './instancias/InstanciaTime.js'

export const MAX_LENGTH_TEXT = 280;

export const ID_TEXT = 1;
export const ID_NUMBER = 2;
export const ID_CHECKBOX = 3;
export const ID_SELECT_SIMPLE = 4;
export const ID_IMAGEN = 5;
export const ID_ADJUNTO = 6;
export const ID_FIRMA = 7;
export const ID_SINO = 8;
export const ID_SELECT_COMPUESTO = 9;
export const ID_NUMBER_COMPUESTO = 10;
export const ID_MEDIDOR = 11;
export const ID_DATE = 12;
export const ID_TIME = 13;

export const ID_ESTADO_APROBACION_VALIDO = 1;
export const ID_ESTADO_APROBACION_INVALIDO = 2;
export const ID_ESTADO_APROBACION_SIN_COMPROBACION = 3;
export const ID_ESTADO_APROBACION_PENDIENTE = 4;


export const TEMPLATE_ATRIBUTO = {
	nombre: 'Atributo',
	value: '',
	valorDefault: '',
	description: '',
	required: false,
	tipoValor: ID_TEXT,
	estado: ID_ESTADO_APROBACION_SIN_COMPROBACION,
	activo: true,
	extraParams: {},
	estadosInformativos: [],
	genera_ticket: false,
	genera_ticket_gestoria: false,
	genera_rop: false,
	// servicio_id: null,
	tareas: [],
}

export const TEMPLATE_ATRIBUTO_MEDIDOR = {
	nombre: 'Medidor',
	value: '',
	valorDefault: '',
	description: '',
	required: false,
	tipoValor: ID_MEDIDOR,
	estado: ID_ESTADO_APROBACION_SIN_COMPROBACION,
	activo: true,
	extraParams: {},
	genera_ticket: false,
	genera_rop: false,
	// servicio_id: null,
	tareas: [],
}

export const ESTADOS_APROBACION = {}
export const INSTANCIAS_DICT = {}


ESTADOS_APROBACION[ID_ESTADO_APROBACION_VALIDO] = {
	estado: ID_ESTADO_APROBACION_VALIDO,
	text: 'APROBADO',
	icon: 'fa fa-2x fa-check',
}

ESTADOS_APROBACION[ID_ESTADO_APROBACION_INVALIDO] = {
	estado: ID_ESTADO_APROBACION_INVALIDO,
	text: 'DESAPROBADO',
	icon: 'fa fa-2x fa-window-close',
}

ESTADOS_APROBACION[ID_ESTADO_APROBACION_SIN_COMPROBACION] = {
	estado: ID_ESTADO_APROBACION_SIN_COMPROBACION,
	text: 'NO APLICA',
	icon: 'fa fa-2x fa-low-vision',
}

INSTANCIAS_DICT[ID_TEXT] = {
	id: ID_TEXT,
	label: 'Texto',
	icono: 'fa fa-file-text-o',
	clase: InstanciaText,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['equipamiento', 'checklist', 'modelo-tipo']
}
INSTANCIAS_DICT[ID_NUMBER] = {
	id: ID_NUMBER,
	label: 'Numérico',
	icono: 'fa fa-sort-numeric-asc',
	clase: InstanciaNumber,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['equipamiento', 'modelo-tipo']
}
INSTANCIAS_DICT[ID_CHECKBOX] = {
	id: ID_CHECKBOX,
	label: 'Checkbox',
	icono: 'fa fa-check-circle',
	clase: InstanciaSwitch,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['equipamiento', 'modelo-tipo']
}
INSTANCIAS_DICT[ID_SELECT_SIMPLE] = {
	id: ID_SELECT_SIMPLE,
	label: 'Select',
	icono: 'fa fa-list',
	clase: InstanciaSelect,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraSelectSimple,
	modulos: ['equipamiento', 'modelo-tipo']
}
INSTANCIAS_DICT[ID_IMAGEN] = {
	id: ID_IMAGEN,
	label: 'Imagen',
	icono: 'fa fa-picture-o',
	clase: InstanciaImagen,
	conEstados: false,
	conAdjunto: true,
	extra: ExtraBasic,
	modulos: ['equipamiento', 'checklist']
}
INSTANCIAS_DICT[ID_ADJUNTO] = {
	id: ID_ADJUNTO,
	label: 'Adjunto',
	icono: 'fa fa-files-o',
	clase: InstanciaAdjunto,
	conEstados: false,
	conAdjunto: true,
	extra: ExtraBasic,
	modulos: ['equipamiento', 'checklist']
}
INSTANCIAS_DICT[ID_FIRMA] = {
	id: ID_FIRMA,
	label: 'Firma',
	icono: 'fa fa-handshake-o',
	clase: InstanciaFirma,
	conEstados: false,
	conAdjunto: true,
	extra: ExtraBasic,
	modulos: ['equipamiento', 'checklist']
}
INSTANCIAS_DICT[ID_SINO] = {
	id: ID_SINO,
	label: "Si / No",
	icono: 'fa fa-check-square-o',
	clase: InstanciaSiNo,
	conEstados: true,
	conAdjunto: false,
	extra: ExtraSiNo,
	modulos: ['checklist']
}
INSTANCIAS_DICT[ID_SELECT_COMPUESTO] = {
	id: ID_SELECT_COMPUESTO,
	label: 'Opciones Desplegables',
	icono: 'fa fa-th-list',
	clase: InstanciaSelect,
	conEstados: true,
	conAdjunto: false,
	extra: ExtraSelectCompuesto,
	modulos: ['checklist', 'llantas']
}
INSTANCIAS_DICT[ID_NUMBER_COMPUESTO] = {
	id: ID_NUMBER_COMPUESTO,
	label: 'Numérico',
	icono: 'fa fa-sort-numeric-asc',
	clase: InstanciaNumberRange,
	conEstados: true,
	conAdjunto: false,
	extra: ExtraNumber,
	modulos: ['checklist']
}

INSTANCIAS_DICT[ID_MEDIDOR] = {
	id: ID_MEDIDOR,
	label: 'Odómetro/Horometro',
	icono: '',
	clase: InstanciaMedidor,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['checklist', 'equipamiento'],
	hiddenOption: true,
}
INSTANCIAS_DICT[ID_DATE] = {
	id: ID_DATE,
	label: 'Fecha',
	icono: 'fa fa-calendar',
	clase: InstanciaDate,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['checklist']
}
INSTANCIAS_DICT[ID_TIME] = {
	id: ID_TIME,
	label: 'Tiempo',
	icono: 'fa fa-clock-o',
	clase: InstanciaTime,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['checklist']
}