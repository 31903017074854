import React, { Component } from "react";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Config from "../../commons/config/Config.js";
import cambioPosicion from "../../assets/images/llantas/cambioposicion.png";
import * as Utils from '../../commons/utils/Utils';

class LlantaCambio extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    this.dataService = "/llantas";
    this.state = {
      props: this.props,
      formulario: null,
      recapadoObservation: "",
      llanta: this.props.llanta,
      llantas: [],
      llantaCambio: "",
      errors: [],
      loading: false,
      submitLoading: false,
      llantaOrigenSelectLoading: false,
      llantaDestinoSelectLoading: false,
    };
    this.handleChangeRecapadoObservation = this.handleChangeRecapadoObservation.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.ajaxHandler.subscribe(this);
    this.initForm();
  }
  componentWillUnmount() {}

  initForm() {
    let component = this;
    if (this.props.llanta) {
      this.setState({llantaDestinoSelectLoading: true})
      Promise.all([Utils.getData("/llantas/llantasdemovil/" + component.props.llanta.movil.id)]).then((data) => {
        component.setState({
          llantas: component.initSelectLlantas(data[0], component.state.llanta),
        });
        this.setState({ loading: false });
        this.setState({llantaDestinoSelectLoading: false})

      });
    }
  }

  initSelectLlantas(data, llanta) {
    let dataNew = [];
    data.forEach(function (element) {
      if (element.id !== llanta.id) dataNew.push(element);
    });
    return dataNew;
  }

  getData(service) {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/()json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  handleChangeRecapadoObservation(event) {
    event.persist();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ recapadoObservation: value });
  }

  handleSelectChange(event, name) {
    this.setState({ llantaCambio: this.state.llantas[event.target.value] });
  }

  handleLlantaChange(event) {
    if (event.target.value === "") {
      this.setState({ llanta: null });
    } else {
      let llanta = this.props.llantasDelAuto[event.target.value];
      this.setState({ llanta: llanta, llantaDestinoSelectLoading: true });
      Promise.all([Utils.getData("/llantas/llantasdemovil/" + llanta.movil.id)]).then((data) => {
        this.setState({
          llantas: this.initSelectLlantas(data[0], this.state.llanta),
          llantaDestinoSelectLoading: false,
        });
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitLoading: true });
    Utils.sendData(
      '/llantas/cambioposicionllantas', 
      'POST', 
      JSON.stringify({
        llantaIntercambioUno: this.state.llanta.id,
        llantaIntercambioDos: this.state.llantaCambio.id,
      })
    )
    .then((e) => {
      this.props.handleSubmitCambioLlanta();
      window.scrollTo(0, 0);
    })
    .catch((e) => 
      console.log(e)
    ).finally(() => {
      this.setState({ submitLoading: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="llantas-modulo">
          <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
            <div className="text-center">
              <img src={cambioPosicion} alt={"/"} style={{ height: "100px", width: "100px" }} />
            </div>
            <div className="text-center mt-1">
              <p className="llantas-modulo">
                {this.props.intl.formatMessage({
                  id: "llantaCambio.render.modal_llanta_cambio.label_posicion_origen",
                  defaultMessage: "Posición Origen",
                })}
                : {this.state.llanta ? this.state.llanta.llanta_posicion.posicion : ""}
              </p>
              <p className="llantas-modulo">
                {this.props.intl.formatMessage({
                  id: "llantaCambio.render.modal_llanta_cambio.label_selecione_posicion_de_destino",
                  defaultMessage: "Seleccione posición de destino de vehiculo",
                })}
                : {this.state.llanta ? (this.state.llanta.movil ? this.state.llanta.movil.dominio : "") : ""}
              </p>
            </div>
            {this.props.fromAsignacion ? (
              <div className="row my-2">
                <div className="col-5 d-flex align-items-center">
                  <p className="mb-0 text-center">Posicion de Origen:</p>
                </div>
                <select className="col-7 form-control" onChange={(e) => this.handleLlantaChange(e)}>
                  <option value="" key="">
                    {this.props.intl.formatMessage({
                      id: "llantaCambio.render.modal_llanta_cambio.select_defecto_posicion_de_destino",
                      defaultMessage: "Seleccione",
                    })}
                  </option>
                  {this.props.llantasDelAuto.map((llanta, index) => (
                    <option value={index} key={index}>
                      {llanta.llanta_posicion.label} -{" "}
                      {llanta.serial} - {llanta.llanta_marca.nombre}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-5 d-flex align-items-center">
                <p className="mb-0 text-center">Posicion de Destino:</p>
              </div>
              <select
                className="col-7 form-control"
                onChange={this.handleSelectChange}
                disabled={!this.state.llanta || this.state.llantaDestinoSelectLoading}
              >
                <option value="" key="">
                  {this.state.llantaDestinoSelectLoading
                    ? this.props.intl.formatMessage({
                        id: "llantaCambio.render.modal_llanta_cambio.select_defecto_posicion_de_destino_cargando",
                        defaultMessage: "Cargando",
                      })
                    : this.props.intl.formatMessage({
                        id: "llantaCambio.render.modal_llanta_cambio.select_defecto_posicion_de_destino",
                        defaultMessage: "Seleccione",
                      })}
                </option>
                {this.state.llantas.map((llanta, index) => (
                  <option value={index} key={index}>
                    {llanta.llanta_posicion.label} -{" "}
                    {llanta.serial} - {llanta.llanta_marca.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-center d-flex justify-content-end mt-1">
              {this.state.submitLoading ? (
                <button className="btn btn-primary mr-1" disabled={this.state.submitLoading}>
                  <i className="la la-spinner spinner"></i>
                  {this.props.intl.formatMessage({
                    id: "llantaAsignacionesDetails.render.button_guardando",
                    defaultMessage: " Guardando",
                  })}
                </button>
              ) : (
                <button type="submit" className="btn btn-primary mr-1" disabled={!this.state.llantaCambio}>
                  <i className="fa fa-check-circle"></i>
                  {this.props.intl.formatMessage({
                    id: "llantaAsignacionesDetails.render.button_guardar",
                    defaultMessage: " Guardar",
                  })}
                </button>
              )}

              <button
                type="button"
                className="btn btn-danger mr-1"
                onClick={(e) => this.props.handleModalCloseCambioLlanta(e)}
                disabled={this.state.submitLoading}
              >
                <i className="fa fa-times-circle"></i>
                {this.props.intl.formatMessage({
                  id: "llantaAsignacionesDetails.render.button_cancelar",
                  defaultMessage: " Cancelar",
                })}
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default LlantaCambio;
