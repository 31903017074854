import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  CircularProgress,
  Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import Security from '../../commons/security/Security.js'
import 'xlsx/dist/xlsx.full.min.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import BasicCardInfo from '../ui/BasicCardInfo.js'
import MovilesGridRow from './MovilesGridRow'
import MovilesGridFiltrosExterno from './MovilesGridFiltros'
import MovilesGridExport from './MovilesGridExport'
import MovilesGridImport from './MovilesGridImport'
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import BasicCardMultipleValuesInfo from "../ui/BasicCardMultipleValuesInfo.js";

var pdfMake = require('pdfmake/build/pdfmake.js')
var pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

const PAGINATION_INIT ={
  page: 0,
  count: 0,
  perPage: 10,
}

const FILTER_QUERY_INIT = {
  estado:"",
  unidad: "",
  chasis: "",
  dominio: "",
  medidor: "",
  base: "",
  pais: "",
  region: "",
  subRegion: "",
  titular: "",
  modelo: "",
  marca: "",
  tipo: "",
  centroCosto: "",
  cecosDireccion: "",
  cecosMercado: "",
  cecosGerencia: "",
  cecosArea: "",
  responsable1: "",
  companiaOrigen: "",
  filtroKpi: "",
  showInactivos: '',
  medidorDesde: '',
	medidorHasta: ''
}

const SORT_QUERY_INIT = {
  estado: [],
  unidad: "",
  chasis: "",
  dominio: "",
  medidor: "",
  base: "",
  pais: "",
  region: "",
  subRegion: "",
  titular: "",
  modelo: "",
  marca: "",
  tipo: "",
  centroCosto: "",
  cecosDireccion: "",
  cecosMercado: "",
  cecosGerencia: "",
  cecosArea: "",
  responsable1: "",
  companiaOrigen: "",
}

const getStorage = () =>{
  let storage = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))
  if(storage && storage.activo){
   return true
  }
  else{
    return false
  }
}

const MovilesGrid = (props) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [redirectTo, setRedirectTo] = useState(null)
  
  const [searchActive, setSearchActive] = useState(false)
  const [pagination, setPagination] = useState(JSON.parse(JSON.stringify(PAGINATION_INIT)))
  const [paginationCount, setPaginationCount] = useState(0);

  const [persisteFiltros,setPersisteFiltros] = useState(getStorage())

  const [filterResetInputs,setFilterResetInputs] = useState(false)
  const [filterQuery, setFilterQuery] = useState(null)
  
  const [sortQuery, setSortQuery] = useState(JSON.parse(JSON.stringify(SORT_QUERY_INIT)))

  const [dropdownActive, setDropdownActive] = useState(false);
  
  const [moviles, setMoviles] = useState([])
  const [estados,setEstados] = useState([])

  //Alarmas Controles
  const [summaryAlerts, setSummaryAlerts] = useState(null)
  const [alertsClicked, setAlertsClicked] = useState([])

  //Enabled Columns Config
  const [columnsEnabled,setColumnsEnabled] = useState({
    editKmEnabled:false,
    paisEnabled:false,
    regionEnabled:false,
    subRegionEnabled:false,
    unidadEnabled:false,
    titularEnabled:false,
    companiaOrigenEnabled:false,
    centroCostosEnabled:false,
    cecosDireccionEnabled:false,
    cecosMercadoEnabled:false,
    cecosGerenciaEnabled:false,
    cecosAreaEnabled:false,
    responsable1Enabled:false,
    chasisEnabled:false,
    baseEnabled:false,
  })
  
  //Security Permissions
  const [permissions,setPermissions] = useState({
    canModifyKM:false,
    movilesVisualizar:false,
    movilesModificar:false,
    movilesCrear:false,
    ticketsListar:false,
    vencimientosListar:false,
    vencimientosCrear:false,
    preventivosListar:false,
    correctivosCrear:false
  })
  
  const [modalExport,setModalExport] = useState(false)
  const [modalImport,setModalImport] = useState(false)
  const [modalImportMedidor,setModalImportMedidor] = useState(false)
  const [stateImport, setStateImport] = useState(false)
  const [selectFilter, setSelectFilter] = useState(null)
  useEffect(() => {
    setLoading(true)
    if (Security.hasPermission("MOVILES_LISTAR")) {
      configColumns()
      securityPermission()
      filterQuery && fetchMoviles()

      controlesAlarmas()
    } else {
      setRedirectTo("/error")
    }
    //componentWillUnmount
    return () => { }
  }, [pagination,filterQuery,sortQuery,stateImport])
  
  const configColumns = () => {
    //ColumnsEnabled
    let aux = {...columnsEnabled}
      aux.paisEnabled = ConfigBusiness.get('moviles.pais.grilla') === 'true' ? true : false
      aux.subRegionEnabled = ConfigBusiness.get('moviles.subRegion.grilla') === 'true' ? true : false
      aux.editKmEnabled = ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? true : false
      aux.regionEnabled = ConfigBusiness.get('moviles.region.grilla') === 'true' ? true : false
      aux.unidadEnabled = ConfigBusiness.get('moviles.unidad.grilla') === 'true' ? true : false
      aux.titularEnabled = ConfigBusiness.get('moviles.titular.grilla') === 'true' ? true : false
      aux.companiaOrigenEnabled = ConfigBusiness.get('moviles.companiaOrigen.grilla') === 'true' ? true : false
      aux.centroCostosEnabled = ConfigBusiness.get('moviles.centroCostos.grilla') === 'true' ? true : false
      
      aux.cecosDireccionEnabled = ConfigBusiness.get('moviles.cecosDireccion.grilla') === 'true' ? true : false
      aux.cecosMercadoEnabled = ConfigBusiness.get('moviles.cecosMercado.grilla') === 'true' ? true : false
      aux.cecosGerenciaEnabled = ConfigBusiness.get('moviles.cecosGerencia.grilla') === 'true' ? true : false
      aux.cecosAreaEnabled = ConfigBusiness.get('moviles.cecosArea.grilla') === 'true' ? true : false
      
      aux.responsable1Enabled = ConfigBusiness.get('moviles.responsable1.grilla') === 'true' ? true : false
      aux.chasisEnabled = ConfigBusiness.get('moviles.chasis.grilla') === 'true' ? true : false
      aux.baseEnabled = ConfigBusiness.get('moviles.base.grilla') === 'true' ? true : false
    setColumnsEnabled(aux)
  }

  const securityPermission = () => {
    let aux = {...permissions}
      aux.canModifyKM = Security.hasPermission('MOVILES_MODIFICAR_KM')
      aux.movilesVisualizar = Security.hasPermission('MOVILES_VISUALIZAR')
      aux.movilesModificar = Security.hasPermission('MOVILES_MODIFICAR')
      aux.movilesCrear = Security.hasPermission('MOVILES_CREAR')
      aux.ticketsListar = Security.hasPermission('TICKETS_MOVIL_LISTAR')
      aux.vencimientosListar = Security.hasPermission('VENCIMIENTOS_MOVIL_LISTAR')
      aux.vencimientosCrear = Security.hasPermission('VENCIMIENTOS_CREAR')
      aux.preventivosListar = Security.hasPermission('PREVENTIVOS_MOVIL_LISTAR')
      aux.correctivosCrear = Security.hasPermission('MANTENIMIENTOS_CORRECTIVOS_CREAR')
    setPermissions(aux)   
  }

  const generacionMasiva =  ConfigBusiness.get('tickets.generacionMasiva.habilitado') === 'true' ? true : false
  const [selectedMoviles, setSelectedMoviles] = useState([])
  const [allChecked, setAllCheked] = useState(false)

  const handleChecked = movil => e => {
    let selected = [...selectedMoviles]
    
    if (movil == 'all') {
      selected = [...moviles]
      setAllCheked(true)
    
    } else if (movil == 'none') {
      selected = []
      setAllCheked(false)
    
    } else {
      let idx = selected.indexOf(movil)
    
      if(selected.includes(movil)) selected.splice(idx, 1)
      else selected.push(movil)
      setAllCheked(true)
    }

    setSelectedMoviles(selected);
  }

  const controlesAlarmas = () => {
    Utils.getData(`/moviles/controles/alarmas`).then((data) => {
      let controles = data ? data : null;
      let alertsClicked = [];

      for(let alarm in controles){
        alertsClicked[controles[alarm].filter] = false;
      }

      // if(component.props.location.state && component.props.location.state.filter){
      //   alertsClicked[component.props.location.state.filter] = true;
      // }

      setSummaryAlerts(controles)
      // setAlertsClicked(alertsClicked)
    }).catch((err) => {
      console.log(err)
    })
  }
  const fetchMoviles = () => {
    Promise.all([
      Utils.getData(`/moviles/newGrid${getQuery()}`),
      ConfigBusiness.get('moviles.estados'),
    ])
    .then((data) => {
      setMoviles(data[0].moviles)
      let estados = data[1] ? data[1].split(',') : null;
      let menuEstado = [];
      estados.forEach(estado => {
        menuEstado.push({ value: estado, label: estado });
      });
      setEstados(menuEstado);

      setLoading(false)
      setStateImport(false)
      setPaginationCount(data[0].pagination.count)
    })
    .catch((err) => {
      setLoading(false)
    })
  };

  const getQuery = () => {
    //pagination
    let myQuery = "?page=" + pagination.page
    myQuery += "&perPage=" + pagination.perPage

    //filter
    myQuery += '&marca=' + filterQuery.marca;
    myQuery += "&estado=" + filterQuery.estado;
    myQuery += "&unidad=" + filterQuery.unidad;
    myQuery += "&chasis=" + filterQuery.chasis;
    myQuery += "&dominio=" + filterQuery.dominio;
    myQuery += "&modelo=" + filterQuery.modelo;
    myQuery += "&medidor=" + filterQuery.medidor;
    myQuery += "&tipo=" + filterQuery.tipo;
    myQuery += "&pais=" + filterQuery.pais;
    myQuery += "&region=" + filterQuery.region;
    myQuery += "&subRegion=" + filterQuery.subRegion;
    myQuery += "&base=" + filterQuery.base;
    myQuery += "&titular=" + filterQuery.titular;
    myQuery += "&companiaOrigen=" + filterQuery.companiaOrigen;
    myQuery += "&centroCosto=" + filterQuery.centroCosto;
    myQuery += "&cecosDireccion=" + filterQuery.cecosDireccion;
    myQuery += "&cecosMercado=" + filterQuery.cecosMercado;
    myQuery += "&cecosGerencia=" + filterQuery.cecosGerencia;
    myQuery += "&cecosArea=" + filterQuery.cecosArea;
    myQuery += "&responsable1=" + filterQuery.responsable1;
    myQuery += "&filtroKpi=" + filterQuery.filtroKpi;
    myQuery += "&showInactivos=" + filterQuery.showInactivos;
    myQuery += "&medidorDesde=" + filterQuery.medidorDesde;
    myQuery += "&medidorHasta=" + filterQuery.medidorHasta;

    //sort
    myQuery += '&marcaSort=' + sortQuery.marca;
    myQuery += "&estadoSort=" + sortQuery.estado;
    myQuery += "&unidadSort=" + sortQuery.unidad;
    myQuery += "&chasisSort=" + sortQuery.chasis;
    myQuery += "&dominioSort=" + sortQuery.dominio;
    myQuery += "&modeloSort=" + sortQuery.modelo;
    myQuery += "&medidorSort=" + sortQuery.medidor;
    myQuery += "&tipoSort=" + sortQuery.tipo;
    myQuery += "&paisSort=" + sortQuery.pais;
    myQuery += "&regionSort=" + sortQuery.region;
    myQuery += "&subRegionSort=" + sortQuery.subRegion;
    myQuery += "&baseSort=" + sortQuery.base;
    myQuery += "&titularSort=" + sortQuery.titular;
    myQuery += "&companiaOrigenSort=" + sortQuery.companiaOrigen;
    myQuery += "&centroCostoSort=" + sortQuery.centroCosto;
    myQuery += "&cecosDireccionSort=" + sortQuery.cecosDireccion;
    myQuery += "&cecosMercadoSort=" + sortQuery.cecosMercado;
    myQuery += "&cecosGerenciaSort=" + sortQuery.cecosGerencia;
    myQuery += "&cecosAreaSort=" + sortQuery.cecosArea;
    myQuery += "&responsable1Sort=" + sortQuery.responsable1;

    return myQuery;
  }

    const handleChangePage = (event, newPage) => {
      setPagination({ ...pagination, page: newPage });
    }
  
    const handleChangeRowsPerPage = (event) => {
      setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
    }

    const requestSort = (key) => {
      let query = { ...sortQuery }
      let aux = sortQuery[key];
      
      query["marca"] = "";
      query["estado"] = "";
      query["unidad"] = "";
      query["chasis"] = "";
      query["dominio"] = "";
      query["modelo"] = ""; 
      query["medidor"] = "";
      query["tipo"] = "";
      query["pais"] = "";
      query["region"] = "";
      query["subRegion"] = "";
      query["base"] = "";
      query["titular"] = "";
      query["companiaOrigen"] = "";
      query["centroCosto"] = "";
      query["cecosDireccion"] = "";
      query["cecosMercado"] = "";
      query["cecosGerencia"] = "";
      query["cecosArea"] = "";
      query["responsable1"] = "";
      
      pagination.page = 0;
      
      if (!aux) query[key] = "asc";
      else if (aux === "desc") query[key] = "asc";
      else query[key] = "desc";
      setSortQuery(query);
    };

    const handleFilterReset = () => {
      setPagination(JSON.parse(JSON.stringify(PAGINATION_INIT)));
      setFilterQuery(JSON.parse(JSON.stringify(FILTER_QUERY_INIT)));
      setSortQuery(JSON.parse(JSON.stringify(SORT_QUERY_INIT)));
      setDropdownActive(false);
      setSearchActive(false);
      setAlertsClicked([])
      setPersisteFiltros(false)
      setFilterResetInputs(true)
    }

    const handleAlertClick = (filter) =>{
      let copyClicked = alertsClicked;
      //si el filtro estaba seleccionado y se vuelve a clickear, se resetea
      let reset = false;
      if(alertsClicked[filter]){
        reset = true;
        copyClicked[filter] = !alertsClicked[filter];
      } else{
        for(let alarm in alertsClicked){
          copyClicked[alarm] = false;
        }
        copyClicked[filter] = true;
      }

      setAlertsClicked(copyClicked)
      
      let query = { ...filterQuery };
      query['filtroKpi'] = reset ? "" : filter;
      setFilterQuery(query);
    }

    const handleModalExportOpen = () => {
      setModalExport(true)
    };
  
    const handleModalExportClose = () => {
      setModalExport(false)
    };

    const handleModalImportOpen = () => {
      setModalImport(true)
    };

    const handleModalImportMedidorOpen = () => {
      setModalImportMedidor(true)
    };

    const generarCorrectivos = () => {
      if(selectedMoviles.length == 1) {
        let movilId = selectedMoviles[0]
        let dominio = moviles.find(movil => movil.id == movilId).dominio
        setRedirectTo('/correctivos/add/movil/'+ movilId +'/'+ dominio)
      } else {
        setRedirectTo({pathname: '/correctivos/add/moviles', state: {moviles: selectedMoviles}})
      }
    }
    /**
         * Responde siempre que el filtro cambie
         *  - acutaliza paginado en pagina 0
         *  - otros
         */
    const handleFilterChange = () => {
      //actualiza paginado a pagina inicial (0) 
      setPagination(prevState => ({ ...prevState, page: 0 }));
      //otros
      //...`
    }
    
  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      {summaryAlerts &&
        <div className="row justify-content-center">
          {summaryAlerts.map((alert, index, array)=>{
            return(
              <div key={index} className={"col-xl-2 col-lg-4 col-md-6 col-12 cards-alerts-"+array.length} style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem",paddingBottom: "0.5rem"}}>
                <BasicCardMultipleValuesInfo
                  id={index}
                  onClickAlert={(filtro)=>handleAlertClick(filtro)}
                  values={alert}
                  selectFilter={selectFilter}
                  intl={intl}
                ></BasicCardMultipleValuesInfo>
              </div>
            );
          })}
        </div>
      }
      <MovilesGridExport open={modalExport} handleClose={handleModalExportClose} queryFilters={getQuery}/>

      <MovilesGridImport 
        openImport={modalImport}
        openImportMedidor={modalImportMedidor}
        setModalImport={setModalImport}
        setModalImportMedidor={setModalImportMedidor}
        setStateImport={setStateImport}
        intl={intl}
      />

      <div className="card">
        <div className="card-content collpase show">
          <div className="card-body card-dashboard">
            <div className="container-fluid mb-1">
              <div className="row dt-icons">
                <div className="col">
                  {permissions.movilesCrear && 
                    <StyledTooltip
                      title={intl.formatMessage({
                        id: 'movilesGrid.render.button_add.title_nuevo',
                        defaultMessage: 'Nuevo',
                      })}
                      arrow
                      placement="right"
                    >
                      <div
                        className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                        style={{ height: 55 }}
                        onClick={() => setRedirectTo(props.match.url + '/add')}
                      >
                        <i className="ft-plus"></i>
                      </div>
                    </StyledTooltip>
                  }
                    <div
                      className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                      style={{ height: 52 }}
                      onClick={() => setDropdownActive(!dropdownActive)}
                    >
                      {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                    </div>
                </div>
                <div className="col">
                  {searchActive && (
                    <div className="btn-dt-main round-icon">
                      <div
                        className="text-center"
                        style={{
                          height: '25px',
                          borderRadius: '3px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingLeft: '8px',
                          backgroundColor: '#5AAC43',
                          color: 'white',
                        }}
                      >
                        <div
                          className="text-center"
                          style={{
                            width: '100%',
                            fontSize: '12px',
                            fontWeight: '500',
                          }}
                        >
                          {moviles.length} de {paginationCount} resultados de busqueda
                        </div>
                        <div className="resultados-busqueda-notif">
                          <i
                            className="las la-times la-xs cursor-pointer"
                            onClick={()=>handleFilterReset()}
                          ></i>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col">
                  <ButtonsTableTop 
                    intl={intl}
                    permissions={permissions}
                    handleModalExportOpen={handleModalExportOpen} 
                    handleModalImportOpen={handleModalImportOpen} 
                    handleModalImportMedidorOpen={handleModalImportMedidorOpen}
                  />
                </div>
              </div>
            </div>
          
            {/* <MovilesGridFiltros/> */}
            <MovilesGridFiltrosExterno
              columnsEnabled={columnsEnabled}
              dropdownActive={dropdownActive}
              setDropdownActive={setDropdownActive}
              persisteFiltros={persisteFiltros}
              setPersisteFiltros={setPersisteFiltros}
              filterResetInputs={filterResetInputs}
              setFilterResetInputs={setFilterResetInputs}
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              searchActive={searchActive}
              setSearchActive={setSearchActive}
              loading={loading}
              pagination={pagination}
              kpi={props.location.state}
              handleAlertClick={handleAlertClick}
              handleFilterChange={handleFilterChange}
            />
            <TableContainer>
              <Table aria-label="clases table" className="table-hover">
                {(generacionMasiva && selectedMoviles.length > 0 ) ? (
                <TableHead>
                  <TableRow>
                    <StyledTableCell show={generacionMasiva && selectedMoviles.length > 0 } colSpan={16}>
                      <input type="checkbox" checked={allChecked} onChange={handleChecked('none')} style={{marginRight: "15px"}} className="customCheckbox"/>
                      <span style={{marginRight: "10px"}}>{selectedMoviles.length}</span>
                      <FormattedMessage id="movilesGrid.render.generacionMasiva.seleccionados" defaultMessage="Seleccionados "/>
                        | 
                      <StyledTooltip 
                        title={intl.formatMessage({
                          id: 'render.generacionMasiva.crear_correctivos',
                          defaultMessage: 'Crear Correctivos',
                        })}
                        arrow
                        placement="top"
                      >
                        <i
                        type="button"
                        onClick={generarCorrectivos}
                        className="fa fa-wrench fa-1_4x cursor-pointer"
                        style={{ marginLeft: "10px" }}
                        ></i>
                      </StyledTooltip>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                ) : (
                <TableHead>
                  <TableRow>
                    {/* ID */}
                    <StyledTableCell show={generacionMasiva && selectedMoviles.length == 0 } style={{ maxWidth: "22px" }}>
                      <StyledTooltip 
                        title={intl.formatMessage({
                          id: 'render.generacionMasiva.seleccionar_todos',
                          defaultMessage: 'Seleccionar Todos',
                        })}
                        arrow
                        placement="top"
                      >
                        <input type="checkbox" checked={allChecked} onChange={handleChecked('all')} style={{marginRight: "15px"}} className="customCheckbox"/>
                      </StyledTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left" onClick={()=>requestSort("marca")} style={{ cursor: "pointer"}}>
                      <FormattedMessage id="movilesGrid.rennder.column_marca.label" defaultMessage="Marca" />
                      <img className="ml-1"
                          src={sortQuery.marca === "" ? Sort : sortQuery.marca === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box>
                      <div className="dropdown">
                          <i
                            id="dLabel"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="fa fa-filter cursor-pointer"
                            style={{ marginRight: "10px" }}
                          ></i>
                          {/* <button id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Dropdown trigger
                        </button> */}
                          <div className="dropdown-menu" aria-labelledby="dLabel">
                            {/* <p className="dropdown-item">Action</p> */}
                            {estados
                              ? estados.map((estado,i) => (
                                <p
                                  key={i}
                                  className="cursor-pointer dropdown-item"
                                  style={{ margin: "0", padding: "5px 5px" }}
                                  onClick={() => setFilterQuery({...filterQuery,estado:estado.value})}
                                >
                                  {estado.label}
                                </p>
                              ))
                              : ""}
                          </div>
                        </div>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.unidadEnabled} align="left" onClick={()=>requestSort("unidad")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_unidad.label" defaultMessage="Unidad" />
                      <img className="ml-1"
                          src={sortQuery.unidad === "" ? Sort : sortQuery.unidad === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.chasisEnabled} align="left" onClick={()=>requestSort("chasis")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_chasis.label" defaultMessage="Chasis" />
                       <img className="ml-1"
                          src={sortQuery.chasis === "" ? Sort : sortQuery.chasis === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" onClick={()=>requestSort("dominio")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_dominio.label" defaultMessage="Dominio" />
                       <img className="ml-1"
                          src={sortQuery.dominio === "" ? Sort : sortQuery.dominio === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" onClick={()=>requestSort("modelo")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_modelo.label" defaultMessage="Modelo" />
                       <img className="ml-1"
                          src={sortQuery.modelo === "" ? Sort : sortQuery.modelo === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_prox_tareas.label" defaultMessage="Prox. Tareas" />
                    </StyledTableCell>
                    <StyledTableCell align="left" onClick={()=>requestSort("tipo")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_tipo.label" defaultMessage="Tipo" />
                       <img className="ml-1"
                          src={sortQuery.tipo === "" ? Sort : sortQuery.tipo === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.paisEnabled} align="left" onClick={()=>requestSort("pais")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_pais.label" defaultMessage="País" />
                       <img className="ml-1"
                          src={sortQuery.pais === "" ? Sort : sortQuery.pais === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.regionEnabled} align="left" onClick={()=>requestSort("region")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_region.label" defaultMessage="Región" />
                       <img className="ml-1"
                          src={sortQuery.region === "" ? Sort : sortQuery.region === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.subRegionEnabled} align="left" onClick={()=>requestSort("subRegion")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_subRegion.label" defaultMessage="Sub.Región" />
                       <img className="ml-1"
                          src={sortQuery.subRegion === "" ? Sort : sortQuery.subRegion === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.baseEnabled} align="left" onClick={()=>requestSort("base")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_base.label" defaultMessage="Base" />
                       <img className="ml-1"
                          src={sortQuery.base === "" ? Sort : sortQuery.base === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.titularEnabled} align="left" onClick={()=>requestSort("titular")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_titular.label" defaultMessage="Titular" />
                       <img className="ml-1"
                          src={sortQuery.titular === "" ? Sort : sortQuery.titular === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.companiaOrigenEnabled} align="left" onClick={()=>requestSort("companiaOrigen")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_cia_origen.label" defaultMessage="Cía. Origen" />
                       <img className="ml-1"
                          src={sortQuery.companiaOrigen === "" ? Sort : sortQuery.companiaOrigen === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.centroCostosEnabled} align="left" onClick={()=>requestSort("centroCosto")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_centro_costos.label" defaultMessage="Centro de Costos" />
                       <img className="ml-1"
                          src={sortQuery.centroCosto === "" ? Sort : sortQuery.centroCosto === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.cecosDireccionEnabled} align="left" onClick={()=>requestSort("cecosDireccion")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_centro_costos_direccion.label" defaultMessage="CC. Dirección" />
                       <img className="ml-1"
                          src={sortQuery.cecosDireccion === "" ? Sort : sortQuery.cecosDireccion === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.cecosMercadoEnabled} align="left" onClick={()=>requestSort("cecosMercado")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_centro_costos_mercado.label" defaultMessage="CC. Mercado" />
                       <img className="ml-1"
                          src={sortQuery.cecosMercado === "" ? Sort : sortQuery.cecosMercado === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.cecosGerenciaEnabled} align="left" onClick={()=>requestSort("cecosGerencia")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_centro_costos_gerencia.label" defaultMessage="CC. Gerencia" />
                       <img className="ml-1"
                          src={sortQuery.cecosGerencia === "" ? Sort : sortQuery.cecosGerencia === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.cecosAreaEnabled} align="left" onClick={()=>requestSort("cecosArea")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_centro_costos_area.label" defaultMessage="CC. Area" />
                       <img className="ml-1"
                          src={sortQuery.cecosArea === "" ? Sort : sortQuery.cecosArea === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={columnsEnabled.responsable1Enabled} align="left" onClick={()=>requestSort("responsable1")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_responsable1.label" defaultMessage="Responsable 1" />
                       <img className="ml-1"
                          src={sortQuery.responsable1 === "" ? Sort : sortQuery.responsable1 === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left" onClick={()=>requestSort("medidor")} style={{ cursor: "pointer" }}>
                      <FormattedMessage id="movilesGrid.rennder.column_medidor.label" defaultMessage="Medidor" />
                      <img className="ml-1"
                          src={sortQuery.medidor === "" ? Sort : sortQuery.medidor === "asc" ? SortAsc : SortDesc}
                          alt={"/"}
                          style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell show={(columnsEnabled.editKmEnabled && permissions.canModifyKM) ? true : false} align="left">
                      <FormattedMessage id="movilesGrid.rennder.column_update_km.label" defaultMessage="Actualizar Medidor" />
                    </StyledTableCell>
                    <StyledTableCell align="left"><FormattedMessage id="movilesGrid.rennder.column_acciones.label" defaultMessage="Acciones" /></StyledTableCell>
                  </TableRow>
                </TableHead>
                )}
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={14} align="center">
                        <CircularProgress />
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    (moviles.length > 0 ?
                    moviles.map((movil) => (
                      <MovilesGridRow
                        columnsEnabled={columnsEnabled}
                        permissions={permissions}
                        key={'movilesGridRow_' + movil.id}
                        baseUrl = {props.match.url} 
                        movil={movil}
                        generacionMasiva={generacionMasiva}
                        selectedMoviles={selectedMoviles}
                        setSelectedMoviles={setSelectedMoviles}
                        handleChecked={handleChecked}
                      />
                    ))
                    :
                    <TableRow>
                      <StyledTableCell colSpan={14} align="center">
                        <FormattedMessage id="movilesGrid.rennder.no_data_table" defaultMessage="No se encontraron resultados..." />
                      </StyledTableCell>
                    </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={paginationCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Mostrar"
              labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const ButtonsTableTop = (props)=> {
  const intl = props.intl
  const permissions = props.permissions
  return(
    <div className="dt-buttons btn-group">
      <StyledTooltip
        title={intl.formatMessage({
          id: 'movilesGrid.tools_export.title_exportar_a_excel',
          defaultMessage: 'Exportar a Excel',
        })}
        arrow
        placement="top"
      >
        <button onClick={()=>props.handleModalExportOpen()} className="btn btn-secondary buttons-collection" type="button">
          <span>
            <i className="ft-upload"></i>
          </span>
        </button>
      </StyledTooltip>
      {(permissions.movilesCrear || permissions.movilesModificar || permissions.canModifyKM) && 
        <div className="dropdown">
          <StyledTooltip
            title={intl.formatMessage({
              id: 'movilesGrid.tools_import.title_importar',
              defaultMessage: 'Importar',
            })}
            arrow
            placement="top"
          >
            <button className="btn buttons-collection dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>
                <i className="ft-download"></i>
              </span>
            </button>
          </StyledTooltip>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              {(permissions.movilesCrear || permissions.movilesModificar) &&
              <button onClick={()=>props.handleModalImportOpen()} className="btn-light dropdown-item" style={{cursor: "pointer"}} type="button">
                <FormattedMessage id="movilesGrid.tools_import.importar_vehiculos" defaultMessage="Importar Vehículos" />
              </button>
              }
              {(permissions.canModifyKM) &&
              <button onClick={()=>props.handleModalImportMedidorOpen()} className="btn-light dropdown-item" style={{cursor: "pointer"}} type="button">
                <FormattedMessage id="movilesGrid.tools_import.importar_lecturas" defaultMessage="Importar Lecturas de Medidor" />
              </button>
              }
            </div>
        </div>
      }
    </div>
  )
}

export default MovilesGrid
                          
