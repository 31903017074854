import React, { Component } from 'react'
// import { Route, Switch } from 'react-router-dom'
import Iframe from 'react-iframe'
import Security from '../../commons/security/Security.js'
import { Redirect } from 'react-router-dom'

class Osticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null
        }
    }

    componentWillMount() {
        if (!Security.hasPermission('REPORTAR_PROBLEMA')) {
            this.setState({
                redirectTo: '/error'
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}

                <Iframe url="https://soporte.vec.com.ar/tickets_autologin.php"
                    width="100%"
                    height="800px"
                    id="myId"
                    className="embed-responsive-item"
                    display="initial"
                    position="relative"
                    allowFullScreen />
            </React.Fragment>
        )
    }
}

export default Osticket;
