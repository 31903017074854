import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'
import Switch from "react-switch"

const ESTADOS = [{ id: 1, nombre: 'Activo'},{ id: 0, nombre: 'Inactivo'}]

class InstanciaSwitch extends InstanciaBasic {
	constructor(props) {
    super(props);
		this.castValue = this.castValue.bind(this);
  }

	castValue(value) {
		return Boolean(value)
	}

	render() {
      return (
        <React.Fragment>
					<Switch
						onChange={(value) => this.handleChangeAndUpdateFather(value)}
						checked={this.props.atributo[this.valueKey]}
						options={ESTADOS}
						valueKey='id'
						labelKey='nombre'
						disabled={this.props.disabled}
						offColor="#FF4961"
						onColor="#28D094"
					/>
        </React.Fragment>
      )
  }
}

export default InstanciaSwitch;
