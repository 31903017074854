import Select from 'react-select'
import 'react-select/dist/react-select.css'
import createClass from 'create-react-class';
import React, { Component } from 'react'

const CustomSelectOption = createClass({
	handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onSelect(this.props.option, event);
	},
	handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
	},
	handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
	},
	render () {
		return (
				<>
					{
						this.props.option.hiddenOption ? '' :
						<div className={this.props.className}
							onMouseDown={this.handleMouseDown}
							onMouseEnter={this.handleMouseEnter}
							onMouseMove={this.handleMouseMove}
							title={this.props.option.title}>
			        <i className={`${this.props.option.icono} width-1vw mr-1`} />
			        <span>{this.props.option.label}</span>
						</div>
					}
				</>
		);
	}
});

const CustomSelectValue = props => (
  <div className="Select-value" title={props.value.label}>
    <span className="Select-value-label">
      <i className={`${props.value.icono} width-1vw mr-1`} />
  		<span>{props.value.label}</span>
    </span>
  </div>
)

const SelectWithIcons = props =>
	<Select
	  {...props}
	  valueComponent={CustomSelectValue}
	  optionComponent={CustomSelectOption}
	/>
export default SelectWithIcons;
