import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as Utils from "../../commons/utils/Utils";

const LlantaRepuestosSelect = (props) => {
  const [llantas, setLlantas] = useState(null);
  const [llantaSelected, setLlantaSelected] = useState(null);
  useEffect(() => {
    let urlQueryUnidadMedidor = props.movil
      ? "unidadMedidorId=" + props.movil.unidadMedidorId
      : "";
    Utils.getData(
      "/llantas/" + props.llantaId + "/repuestos/select?" + urlQueryUnidadMedidor
    ).then((res) => {
      setLlantas(res);
    });
  }, []);

  const handleAsyncSelectLoad = (value, callback) => {
    let urlQueryUnidadMedidor = props.movil
      ? "unidadMedidorId=" + props.movil.unidadMedidorId
      : "";
    return Utils.getData(
      "/llantas/" +
        props.llantaId +
        "/repuestos/select?" +
        urlQueryUnidadMedidor +
        "&serial=" +
        `%${value}%`
    ).then((res) => {
      // setLlantas(res);
      return {
        options: res.map((el) => ({
          ...el,
          label:
            el.serial +
            " - " +
            el.llanta_marca.nombre +
            " - " +
            el.llanta_modelo.llanta_medida.nombre,
        })),
        complete: true,
      };
    });
  };

  const handleSelectChange = (e) => {
    setLlantaSelected(e);
    props.changeLlantaRepuesto(e);
  };

  return (
    <>
      {props.llantaId ? (
        // <Select
        //   name="llantaRepuestoSelect"
        //   className="select-personal-llantas z-15"
        //   maxMenuHeight={10}
        //   placeholder={props.intl.formatMessage({
        //     id: "llantaBaja.select_placeholder_cautin_sn",
        //     defaultMessage: "Cautin / SN",
        //   })}
        //   options={
        //     llantas
        //       ? llantas.map((el) => ({
        //           ...el,
        //           label:
        //             el.serial +
        //             " - " +
        //             el.llanta_marca.nombre +
        //             " - " +
        //             el.llanta_modelo.llanta_medida.nombre,
        //         }))
        //       : []
        //   }
        //   valueKey="id"
        //   labelKey="label"
        //   value={llantaSelected}
        //   onChange={handleSelectChange}
        // />
        <Select.Async
          valueKey="id"
          labelKey="label"
          name="select-async-llantas"
          autoload={false}
          filterOptions={(options, filter, currentValues) => {
            return options;
          }}
          placeholder={props.intl.formatMessage({
            id: "llantaAsignacionesDetails.render.modal_asignar_llanta.select_placeholder_cautin",
            defaultMessage: "Cautin / SN",
          })}
          loadOptions={(value, callback) =>
            handleAsyncSelectLoad(value, callback)
          }
          options={
            llantas
              ? llantas.map((el) => ({
                  ...el,
                  label:
                    el.serial +
                    " - " +
                    el.llanta_marca.nombre +
                    " - " +
                    el.llanta_modelo.llanta_medida.nombre,
                }))
              : []
          }
          onChange={handleSelectChange}
          value={llantaSelected}
        />
      ) : (
        <Select
          name="llantaRepuestoSelect"
          className="select-personal-llantas z-15"
          maxMenuHeight={10}
          placeholder={props.intl.formatMessage({
            id: "llantaBaja.select_placeholder_cautin_sn",
            defaultMessage: "Cautin / SN",
          })}
          options={[]}
          value={null}
          disabled={true}
        />
      )}
    </>
  );
};

export default LlantaRepuestosSelect;
