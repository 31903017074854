import { defineMessage } from 'react-intl';

export default {
    'errors.default': defineMessage({
        id: 'errors.default',
        defaultMessage: 'Ha ocurrido un error'
    }),
    'login.errors.usuario_no_existe': defineMessage({
        id: 'login.errors.usuario_no_existe',
        defaultMessage: 'El usuario no existe.'
    }),
    'login.errors.contraseña_incorrecta': defineMessage({
        id: 'login.errors.contraseña_incorrecta',
        defaultMessage: 'La contraseña es incorrecta.'
    }),
    'login.errors.activar_cuenta': defineMessage({
        id: 'login.errors.activar_cuenta',
        defaultMessage: 'Debe activar su cuenta.'
    }),
    'forgotPassword.errors.email_ingresado_no_corresponde_usuario_activo': defineMessage({
        id: 'forgotPassword.errors.email_ingresado_no_corresponde_usuario_activo',
        defaultMessage: 'El email ingresado no corresponde a un usuario activo.'
    }),
    'forgotPassword.errors.debe_activar_cuenta': defineMessage({
        id: 'forgotPassword.errors.debe_activar_cuenta',
        defaultMessage: 'Debe activar su cuenta.'
    }),
    'changePassword.errors.contrasenia_debe_ser_diferente_anteriores': defineMessage({
        id: 'changePassword.errors.contrasenia_debe_ser_diferente_anteriores',
        defaultMessage: 'La contraseña debe ser diferente a las anteriores.'
    }),
    'changePassword.errors.contrasenia_nueva_diferente_actual': defineMessage({
        id: 'changePassword.errors.contrasenia_nueva_diferente_actual',
        defaultMessage: 'La contraseña nueva debe ser diferente a la actual.'
    }),
    'changePassword.errors.contrasenia_actual_incorrecta': defineMessage({
        id: 'changePassword.errors.contrasenia_actual_incorrecta',
        defaultMessage: 'La contraseña actual es incorrecta.'
    }),
    'changePassword.errors.sesion_no_corresponde_usuario_activo': defineMessage({
        id: 'changePassword.errors.sesion_no_corresponde_usuario_activo',
        defaultMessage: 'La sesión no corresponde a un usuario activo.'
    }),
    'changePassword.errors.contrasenias_ingresadas_no_coinciden': defineMessage({
        id: 'changePassword.errors.contrasenias_ingresadas_no_coinciden',
        defaultMessage: 'Las contraseñas ingresadas no coinciden.'
    }),
    'azureCallback.errors.faltan_parametros_validar': defineMessage({
        id: 'azureCallback.errors.faltan_parametros_validar',
        defaultMessage: 'Faltan parámetros para validar su cuenta'
    }),
    'azureCallback.errors.token_no_encontrado_servidor': defineMessage({
        id: 'azureCallback.errors.token_no_encontrado_servidor',
        defaultMessage: 'Error: token no encontrado en respuesta del servidor'
    }),
    'azureCallback.errors.pedir_token_usuario': defineMessage({
        id: 'azureCallback.errors.pedir_token_usuario',
        defaultMessage: 'Error: error al pedir el token del usuario'
    }),
    'azureCallback.errors.lado_del_servidor': defineMessage({
        id: 'azureCallback.errors.lado_del_servidor',
        defaultMessage: 'Error: error del lado del servidor'
    }),
    'internalLogin.errors.acceso_denegado': defineMessage({
        id: 'internalLogin.errors.acceso_denegado',
        defaultMessage: 'Acceso denegado.'
    }),
    'recoverPassword.errors.clave_activacion_invalida': defineMessage({
        id: 'recoverPassword.errors.clave_activacion_invalida',
        defaultMessage: 'Clave de activación inválida.'
    }),
    'recoverPassword.errors.clave_expiro': defineMessage({
        id: 'recoverPassword.errors.clave_expiro',
        defaultMessage: 'La clave de activación expiró.'
    }),
    'recoverPassword.errors.contrasenia_diferente_anteriores': defineMessage({
        id: 'recoverPassword.errors.contrasenia_diferente_anteriores',
        defaultMessage: 'La contraseña debe ser diferente a las anteriores.'
    }),
    'recoverPassword.errors.contrasenias_coinciden': defineMessage({
        id: 'recoverPassword.errors.contrasenias_coinciden',
        defaultMessage: 'Las contraseñas ingresadas no coinciden.'
    }),
    'combustiblesAbm.errors.no_pudo_obtener_archivo_modelo': defineMessage({
        id: 'combustiblesAbm.errors.no_pudo_obtener_archivo_modelo',
        defaultMessage: 'No se pudo obtener el archivo modelo'
    }),
    'correctivosAbm.errors.deber_ingresar_al_menos_una_tarea': defineMessage({
        id: 'correctivosAbm.errors.deber_ingresar_al_menos_una_tarea',
        defaultMessage: 'Debe ingresar al menos una tarea.'
    }),
    'facturasAbm.errors.no_puede_dejar_datos_factura_sin_completar': defineMessage({
        id: 'facturasAbm.errors.no_puede_dejar_datos_factura_sin_completar',
        defaultMessage: 'No puede dejar datos de la factura sin completar.'
    }),
    'infraccionesService.errors.no_se_pudo_obtener_archivo_modelo': defineMessage({
        id: 'infraccionesService.errors.no_se_pudo_obtener_archivo_modelo',
        defaultMessage: 'No se pudo obtener el archivo modelo'
    }),
    'oTAbm.errors.no_puede_dejar_datos_sin_completar': defineMessage({
        id: 'oTAbm.errors.no_puede_dejar_datos_sin_completar',
        defaultMessage: 'No puede dejar datos sin completar.'
    }),
    'oTAbm.errors.debe_seleccionar_al_menos_un_ticket': defineMessage({
        id: 'oTAbm.errors.debe_seleccionar_al_menos_un_ticket',
        defaultMessage: 'Debe seleccionar al menos un ticket.'
    }),
    'personaController.errors.la_persona_tiene_tickets_pendientes_gestionar_cancelar_los_mismos_para_poder_realizar_el_cambio_de_estado': defineMessage({
        id: 'personaController.errors.la_persona_tiene_tickets_pendientes_gestionar_cancelar_los_mismos_para_poder_realizar_el_cambio_de_estado',
        defaultMessage: 'La persona tiene tickets pendientes, por favor gestionar/cancelar los mismos para poder realizar el cambio de estado de la persona'
    }),
    'personaController.errors.la_persona_tiene_controles_activos_desactivar_los_mismos_para_poder_realizar_cambio_de_estado': defineMessage({
        id: 'personaController.errors.la_persona_tiene_controles_activos_desactivar_los_mismos_para_poder_realizar_cambio_de_estado',
        defaultMessage: 'La persona tiene controles activos, favor desactivar los mismos para poder realizar el cambio de estado de la persona'
    }),
    'personaController.errors.la_persona_se_encuentra_asociada_a_un_vehiculo,_remover_dicha_relacion': defineMessage({
        id: 'personaController.errors.la_persona_se_encuentra_asociada_a_un_vehiculo,_remover_dicha_relacion',
        defaultMessage: 'La persona tiene controles activos, favor desactivar los mismos para poder realizar el cambio de estado de la persona'
    }),
    'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea': defineMessage({
        id: 'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea',
        defaultMessage: 'Debe ingresar al menos una tarea.'
    }),
    'personaRepository.errors.direccion_email_existe': defineMessage({
        id: 'personaRepository.errors.direccion_email_existe',
        defaultMessage: 'La dirección de email ingresada ya existe.'
    }),
    'personaRepository.errors.nombre_usuario_existe': defineMessage({
        id: 'personaRepository.errors.nombre_usuario_existe',
        defaultMessage: 'El nombre de Usuario ingresado ya existe.'
    }),
    'personaRepository.errors.llave_ingresada_no_valida': defineMessage({
        id: 'personaRepository.errors.llave_ingresada_no_valida',
        defaultMessage: 'La llave ingresada no es válida.'
    }),
    'almacenController.errors.no_existe_almacen_con_ese_id': defineMessage({
        id: 'almacenController.errors.no_existe_almacen_con_ese_id',
        defaultMessage: 'No existe el almacen con ese id'
    }),
    'almacenRepository.errors.almacen_ya_existe': defineMessage({
        id: 'almacenRepository.errors.almacen_ya_existe',
        defaultMessage: 'El almacen ya existe.'
    }),
    'authController.errors.acceso_denegado': defineMessage({
        id: 'authController.errors.acceso_denegado',
        defaultMessage: 'Acceso denegado.'
    }),
    'authController.errors.azure_token_grant_invalido': defineMessage({
        id: 'authController.errors.azure_token_grant_invalido',
        defaultMessage: 'Azure token_grant inválido'
    }),
    'authService.errors.no_se_encontro_una_sesion_activa': defineMessage({
        id: 'authController.errors.no_se_encontro_una_sesion_activa',
        defaultMessage: 'No se encontró una sesión activa.'
    }),
    'authService.errors.contrasenia_debe_tener_una_longitud_entre': defineMessage({
        id: 'authService.errors.contrasenia_debe_tener_una_longitud_entre',
        defaultMessage: 'La contraseña debe tener una longitud entre 8 y 16 caracteres.'
    }),
    'authService.errors.contrasenia_contener_al_menos_una_letra': defineMessage({
        id: 'authService.errors.contrasenia_contener_al_menos_una_letra',
        defaultMessage: 'La contraseña debe contener al menos una letra.'
    }),
    'authService.errors.contrasenia_contener_al_menos_un_numero': defineMessage({
        id: 'authService.errors.contrasenia_contener_al_menos_un_numero',
        defaultMessage: 'La contraseña debe contener al menos un número.'
    }),
    'authService.errors.contrasenia_no_debe_contener_espacios_en_blanco': defineMessage({
        id: 'authService.errors.contrasenia_no_debe_contener_espacios_en_blanco',
        defaultMessage: 'La contraseña no debe contener espacios en blanco.'
    }),
    'authService.errors.contrasenia_no_debe_contener': defineMessage({
        id: 'authService.errors.contrasenia_no_debe_contener',
        defaultMessage: 'La contraseña debe contener sólo letras, números y alguno de los siguientes símbolos . ! ¿ ? @ $ & # % * + - _ ='
    }),
    'authService.errors.contrasenia_no_incluir_nombre_de_usuario': defineMessage({
        id: 'authService.errors.contrasenia_no_incluir_nombre_de_usuario',
        defaultMessage: 'La contraseña no puede incluir el nombre de usuario.'
    }),
    'basesRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'basesRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'basesRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'basesRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'basesRepository.errors.ya_existe_base_con_ese_nombre': defineMessage({
        id: 'basesRepository.errors.ya_existe_base_con_ese_nombre',
        defaultMessage: 'Ya existe una Base con ese nombre.'
    }),
    'centrosBeneficiosRepository.errors.existe_centro_beneficio_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'centrosBeneficiosRepository.errors.existe_centro_beneficio_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Centro de Beneficio con ese nombre y debe ser unico.'
    }),
    'centrosBeneficiosRepository.errors.no_se_puede_borrar_centro_beneficios_esta_asociada_otro_elemento': defineMessage({
        id: 'centrosBeneficiosRepository.errors.no_se_puede_borrar_centro_beneficios_esta_asociada_otro_elemento',
        defaultMessage: 'No se puede borrar el Centro de Beneficios ya que esta asociada a otro elemento.'
    }),
    'centrosCostosRepository.errors.existe_centro_costo_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'centrosCostosRepository.errors.existe_centro_costo_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Centro de Costo con ese nombre y debe ser unico.'
    }),
    'centrosCostosRepository.errors.no_se_puede_borrar_centro_costos_esta_asociada_otro_elemento': defineMessage({
        id: 'centrosCostosRepository.errors.no_se_puede_borrar_centro_costos_esta_asociada_otro_elemento',
        defaultMessage: 'No se puede borrar el Centro de Costos ya que esta asociada a otro elemento.'
    }),
    'combustibleBanderasRepository.errors.error_al_ingresar_nueva_bandera_combustible': defineMessage({
        id: 'combustibleBanderasRepository.errors.error_al_ingresar_nueva_bandera_combustible',
        defaultMessage: 'Error al ingresar una nueva bandera de combustible'
    }),
    'combustiblesController.errors.contacte_al_administrador': defineMessage({
        id: 'combustiblesController.errors.contacte_al_administrador',
        defaultMessage: 'Contacte al administrador.'
    }),
    'combustiblesController.errors.error_al_subir_el_archivo': defineMessage({
        id: 'combustiblesController.errors.error_al_subir_el_archivo',
        defaultMessage: 'Ocurrió un error al subir el archivo'
    }),
    'combustiblesService.errors.no_pudo_realizar_importacion_fallo_creacion_log': defineMessage({
        id: 'combustiblesService.errors.no_pudo_realizar_importacion_fallo_creacion_log',
        defaultMessage: 'NO se pudo realizar la importación. Falló la creación del log'
    }),
    'combustiblesRepository.errors.el_movil_ingresado_invalido': defineMessage({
        id: 'combustiblesRepository.errors.el_movil_ingresado_invalido',
        defaultMessage: 'El móvil ingresado para la carga es inválido'
    }),
    'combustiblesRepository.errors.carga_combustible_estaduplicada': defineMessage({
        id: 'combustiblesRepository.errors.carga_combustible_estaduplicada',
        defaultMessage: 'La carga de combustible está duplicada'
    }),
    'combustiblesRepository.errors.fecha_hora_de_carga_combustible_invalida': defineMessage({
        id: 'combustiblesRepository.errors.fecha_hora_de_carga_combustible_invalida',
        defaultMessage: 'La Fecha y Hora de carga de combustible es inválida'
    }),
    'combustiblesRepository.errors.error_base_datos': defineMessage({
        id: 'combustiblesRepository.errors.error_base_datos',
        defaultMessage: 'Error en la base de datos'
    }),
    'combustiblesRepository.errors.no_se_pudieron_leer_columnas_del_archivo': defineMessage({
        id: 'combustiblesRepository.errors.no_se_pudieron_leer_columnas_del_archivo',
        defaultMessage: 'No se pudieron leer las columnas del archivo. Contacte al administrador'
    }),
    'combustiblesRepository.errors.la_carga_no_existe': defineMessage({
        id: 'combustiblesRepository.errors.la_carga_no_existe',
        defaultMessage: 'La carga que se quiere actualizar no existe'
    }),
    'combustiblesRepository.errors.carga_combustible_duplicada': defineMessage({
        id: 'combustiblesRepository.errors.carga_combustible_duplicada',
        defaultMessage: 'La carga de combustible está duplicada'
    }),
    'combustiblesRepository.errors.error_buscar_datos': defineMessage({
        id: 'combustiblesRepository.errors.error_buscar_datos',
        defaultMessage: 'Error al buscar los datos'
    }),
    'combustiblesRepository.errors.archivo_no_cumple_formato_de_EDENRED_faltan_columnas': defineMessage({
        id: 'combustiblesRepository.errors.archivo_no_cumple_formato_de_EDENRED_faltan_columnas',
        defaultMessage: 'El archivo no cumple el formato de EDENRED, faltan columnas'
    }),
    'combustiblesRepository.errors.encabezado_no_esta_en_fila_correcta': defineMessage({
        id: 'combustiblesRepository.errors.encabezado_no_esta_en_fila_correcta',
        defaultMessage: 'El encabezado no esta en la fila correcta.'
    }),
    'combustiblesRepository.errors.archivo_no_cumple_formato_YPF_faltan_columnas': defineMessage({
        id: 'combustiblesRepository.errors.archivo_no_cumple_formato_YPF_faltan_columnas',
        defaultMessage: 'El archivo no cumple el formato de YPF, faltan columnas.'
    }),
    'combustiblesRepository.errors.archivo_no_cumple_formato_COPEC_faltan_columnas': defineMessage({
        id: 'combustiblesRepository.errors.archivo_no_cumple_formato_COPEC_faltan_columnas',
        defaultMessage: 'El archivo no cumple el formato de COPEC, faltan columnas.'
    }),
    'combustiblesRepository.basicCardInfo.description.gasto_total_de_combustible': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.gasto_total_de_combustible',
        defaultMessage: 'Gasto Total de Combustible'
    }),
    'combustiblesRepository.basicCardInfo.description.total_de_litros_consumidos': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.total_de_litros_consumidos',
        defaultMessage: 'Total de Litros Consumidos'
    }),
    'combustiblesRepository.basicCardInfo.description.promedio_de_km_litro': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.promedio_de_km_litro',
        defaultMessage: 'Promedio de Km/litro'
    }),
    'combustiblesRepository.basicCardInfo.description.promedio_de_costo_litro': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.promedio_de_costo_litro',
        defaultMessage: 'Promedio de Costo/litro'
    }),
    'combustibleTiposRepository.errors.ingresar_nueva_bandera_combustible': defineMessage({
        id: 'combustibleTiposRepository.errors.ingresar_nueva_bandera_combustible',
        defaultMessage: 'Error al ingresar una nueva bandera de combustible'
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion',
        defaultMessage: 'No hay datos del rendimiento esperado '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion_medidor': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion_medidor',
        defaultMessage: 'No se pudo calcular el rendimiento esperado'
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_carga_sin_recorrido': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_carga_sin_recorrido',
        defaultMessage: 'Rendimiento menor al esperado: realizó carga sin recorrer kilómetros'
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_menor': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_menor',
        defaultMessage: 'Rendimiento menor al esperado (tolerancia mínima): '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_superior': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_superior',
        defaultMessage: 'Rendimiento superior al esperado (tolerancia máxima): '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_valido': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_valido',
        defaultMessage: 'Rendimiento esperado (tolerancia): '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_init': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_init',
        defaultMessage: ' '
    }),
    'estacionesServicioRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'estacionesServicioRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'facturasRepository.errors.la_factura_existe': defineMessage({
        id: 'facturasRepository.errors.la_factura_existe',
        defaultMessage: 'La factura ya existe.'
    }),
    'gerenciadoresRepository.errors.existe_un_gerenciador_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'gerenciadoresRepository.errors.existe_un_gerenciador_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Gerenciador con ese nombre y debe ser unico.'
    }),
    'infraccionesRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'infraccionesRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'infraccionesRepository.errors.no_se_puede_borrar_la_infraccion': defineMessage({
        id: 'infraccionesRepository.errors.no_se_puede_borrar_la_infraccion',
        defaultMessage: 'No se puede borrar la Infracción.'
    }),
    'infraccionesRepository.errors.dominio_ingresado_ya_existe': defineMessage({
        id: 'infraccionesRepository.errors.dominio_ingresado_ya_existe',
        defaultMessage: 'El dominio ingresado ya existe.'
    }),
    'insumoMarcaRepository.errors.marca_de_insumo_repuesto_ya_existe': defineMessage({
        id: 'insumoMarcaRepository.errors.marca_de_insumo_repuesto_ya_existe',
        defaultMessage: 'La marca de insumo/repuesto ya existe.'
    }),
    'insumoMarcaController.errors.no_existe_marca_con_ese_id': defineMessage({
        id: 'insumoMarcaController.errors.no_existe_marca_con_ese_id',
        defaultMessage: 'No existe la marca con ese id'
    }),
    'itemCategoriaController.errors.no_existe_categoria': defineMessage({
        id: 'itemCategoriaController.errors.no_existe_categoria',
        defaultMessage: 'No existe la categoria'
    }),
    'itemCategoriaRepository.errors.no_existe_categoria': defineMessage({
        id: 'itemCategoriaRepository.errors.no_existe_categoria',
        defaultMessage: 'La categoría ya existe.'
    }),
    'itemController.errors.no_existe_el_item_con_ese_id': defineMessage({
        id: 'itemController.errors.no_existe_el_item_con_ese_id',
        defaultMessage: 'No existe el item con ese id'
    }),
    'itemSubCategoriaController.errors.no_existe_la_subcategoria': defineMessage({
        id: 'itemSubCategoriaController.errors.no_existe_la_subcategoria',
        defaultMessage: 'No existe la subcategoria'
    }),
    'localidadController.errors.debe_ingresar_una_provincia_valida': defineMessage({
        id: 'localidadController.errors.debe_ingresar_una_provincia_valida',
        defaultMessage: 'Error, debe ingresar una provincia valida'
    }),
    'marcaService.errors.no_se_pudo_obtener_la_imagen_en_la_carpeta': defineMessage({
        id: 'marcaService.errors.no_se_pudo_obtener_la_imagen_en_la_carpeta',
        defaultMessage: 'No se pudo obtener la imagen en la carpeta.'
    }),
    'marcaRepository.errors.existe_marca_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'marcaRepository.errors.existe_marca_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe una Marca con ese nombre y debe ser única.'
    }),
    'marcaRepository.errors.no_puede_borrar_marca_ya_asociada_a_otro_elemento': defineMessage({
        id: 'marcaRepository.errors.no_puede_borrar_marca_ya_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Marca ya que esta asociada a otro elemento.'
    }),
    'modelosRepository.errors.existe_modelo_con_ese_nombre_con_ese_tipo_y_modelo_debe_ser_unico': defineMessage({
        id: 'modelosRepository.errors.existe_modelo_con_ese_nombre_con_ese_tipo_y_modelo_debe_ser_unico',
        defaultMessage: 'Ya existe un Modelo con ese nombre con ese Tipo y Modelo, debe ser único.'
    }),
    'modelosRepository.errors.no_se_puede_borrar_modelo_asociado_a_otro_elemento': defineMessage({
        id: 'modelosRepository.errors.no_se_puede_borrar_modelo_asociado_a_otro_elemento',
        defaultMessage: 'No se puede borrar el Modelo ya que esta asociado a otro elemento.'
    }),
    'modeloTiposRepository.errors.existe_un_tipo_modelo_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'modeloTiposRepository.errors.existe_un_tipo_modelo_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Tipo de Modelo con ese Nombre y debe ser único.'
    }),
    'modeloTiposRepository.errors.no_se_puede_borrar_tipo_modelo_asociada_a_otro_elemento': defineMessage({
        id: 'modeloTiposRepository.errors.no_se_puede_borrar_tipo_modelo_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar el Tipo de Modelo ya que esta asociada a otro elemento.'
    }),
    'movilController.errors.token_no_es_correcto': defineMessage({
        id: 'movilController.errors.token_no_es_correcto',
        defaultMessage: 'Error, el token ingresado no es correcto'
    }),
    'movilController.errors.campos_latitud_longitud_no_pudieron_validarse': defineMessage({
        id: 'movilController.errors.campos_latitud_longitud_no_pudieron_validarse',
        defaultMessage: 'Error, los campos latitud y longitud no pudieron validarse'
    }),
    'movilController.errors.debe_ingresar_la_fecha': defineMessage({
        id: 'movilController.errors.debe_ingresar_la_fecha',
        defaultMessage: 'Error, debe ingresar la fecha'
    }),
    'movilFuenteRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'movilFuenteRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'movilRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'movilRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'movilRepository.errors.error_al_actualizar_tareas_del_movil': defineMessage({
        id: 'movilRepository.errors.error_al_actualizar_tareas_del_movil',
        defaultMessage: 'Error al actualizar tareas del móvil'
    }),
    'movilRepository.errors.error_cambiar_de_estado': defineMessage({
        id: 'movilRepository.errors.error_cambiar_de_estado',
        defaultMessage: 'Error Cambiar de estado'
    }),
    'movilRepository.errors.parametro_incorrecto_modificar_km': defineMessage({
        id: 'movilRepository.errors.parametro_incorrecto_modificar_km',
        defaultMessage: 'Parámetro incorrecto para modificar el km del móvil.'
    }),
    'movilRepository.errors.parametro_incorrecto_modificar_km': defineMessage({
        id: 'movilRepository.errors.parametro_incorrecto_modificar_km',
        defaultMessage: 'Parámetro incorrecto para modificar el km del móvil.'
    }),
    'movilRepository.errors.no_existe_el_movil_con_ese_id': defineMessage({
        id: 'movilRepository.errors.no_existe_el_movil_con_ese_id',
        defaultMessage: 'No existe el móvil con ese id.'
    }),
    'movilRepository.errors.dominio_ingresado_ya_existe': defineMessage({
        id: 'movilRepository.errors.dominio_ingresado_ya_existe',
        defaultMessage: 'El dominio ingresado ya existe.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_Plan': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_Plan',
        defaultMessage: 'Este movil tiene Tickets Preventivos activos, no puede cambiar el Plan.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_estado': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_estado',
        defaultMessage: 'Este movil tiene Tickets Preventivos activos, no puede cambiar el estado.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado',
        defaultMessage: 'Este movil tiene Tickets de Vencimientos activos, no puede cambiar el estado.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_Plan': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_Plan',
        defaultMessage: 'Este movil tiene Tickets de Vencimientos activos, no puede cambiar el Plan.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado',
        defaultMessage: 'Este movil tiene Tickets de Vencimientos activos, no puede cambiar el estado.'
    }),
    'movimientoStockController.errors.no_existe_el_objeto_buscado': defineMessage({
        id: 'movimientoStockController.errors.no_existe_el_objeto_buscado',
        defaultMessage: 'No existe el objeto buscado'
    }),
    'movimientoStockController.errors.no_se_puede_obtener_movimiento': defineMessage({
        id: 'movimientoStockController.errors.no_se_puede_obtener_movimiento',
        defaultMessage: 'No se puede obtener el movimiento'
    }),
    'movimientoStockController.errors.no_se_puede_eliminar_movimiento_stock': defineMessage({
        id: 'movimientoStockController.errors.no_se_puede_eliminar_movimiento_stock',
        defaultMessage: 'No se puede eliminar un movimiento de stock'
    }),
    'movimientoStockController.errors.no_se_puede_editar_movimiento_stock': defineMessage({
        id: 'movimientoStockController.errors.no_se_puede_editar_movimiento_stock',
        defaultMessage: 'No se puede editar un movimiento de stock'
    }),
    'movimientoStockRepository.errors.tipo_movimiento_stock_invalido': defineMessage({
        id: 'movimientoStockRepository.errors.tipo_movimiento_stock_invalido',
        defaultMessage: 'Tipo de movimiento de stock inválido'
    }),
    'movimientoStockRepository.errors.tipo_movimiento_incorrecto': defineMessage({
        id: 'movimientoStockRepository.errors.tipo_movimiento_incorrecto',
        defaultMessage: 'tipo movimiento incorrecto'
    }),
    'oTService.errors.no_se_pudo_obtener_archivo_modelo': defineMessage({
        id: 'oTService.errors.no_se_pudo_obtener_archivo_modelo',
        defaultMessage: 'No se pudo obtener el archivo modelo'
    }),
    'oTService.errors.fecha_vencimiento_es_obligatorio': defineMessage({
        id: 'oTService.errors.fecha_vencimiento_es_obligatorio',
        defaultMessage: 'Fecha de Vencimiento es obligatorio'
    }),
    'oTRepository.errors.tipo_archivo_no_reconocido': defineMessage({
        id: 'oTRepository.errors.tipo_archivo_no_reconocido',
        defaultMessage: 'El tipo de archivo no reconocido'
    }),
    'oTRepository.errors.archivo_no_cumple_formato_tickets_gestoria': defineMessage({
        id: 'oTRepository.errors.archivo_no_cumple_formato_tickets_gestoria',
        defaultMessage: 'El archivo no cumple el formato de Tickets de Gestoria'
    }),
    'perfilesRepository.errors.no_existe_el_permiso': defineMessage({
        id: 'perfilesRepository.errors.no_existe_el_permiso',
        defaultMessage: 'No existe el permiso.'
    }),
    'perfilesRepository.errors.existe_un_perfil_con_el_nombre_debe_ser_unico': defineMessage({
        id: 'perfilesRepository.errors.existe_un_perfil_con_el_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un perfil con el nombre y debe ser único.'
    }),
    'personaRepository.errors.direccion_email_ya_existe_debe_ser_unica': defineMessage({
        id: 'personaRepository.errors.direccion_email_ya_existe_debe_ser_unica',
        defaultMessage: 'La dirección de email ya existe debe ser única.'
    }),
    'personaRepository.errors.la_llave_ingresada_esta_asignada_a_otra_persona': defineMessage({
        id: 'personaRepository.errors.la_llave_ingresada_esta_asignada_a_otra_persona',
        defaultMessage: 'La llave ingresada está asignada a otra persona.'
    }),
    'personaRepository.errors.el_legajo_ingresado_ya_existe': defineMessage({
        id: 'personaRepository.errors.el_legajo_ingresado_ya_existe',
        defaultMessage: 'El legajo ingresado ya existe.'
    }),
    'planMantenimientoPreventivoRepository.errors.no_se_puede_borrar_region_esta_asociada_otro_elemento': defineMessage({
        id: 'planMantenimientoPreventivoRepository.errors.no_se_puede_borrar_region_esta_asociada_otro_elemento',
        defaultMessage: 'No se puede borrar la Región ya que esta asociada a otro elemento.'
    }),
    'planMantenimientoPreventivoRepository.errors.existe_plan_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'planMantenimientoPreventivoRepository.errors.existe_plan_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe un Plan con ese nombre y debe ser única.'
    }),
    'plantillaController.errors.no_existe_la_plantilla': defineMessage({
        id: 'plantillaController.errors.no_existe_la_plantilla',
        defaultMessage: 'No existe la plantilla'
    }),
    'plantillaRepository.errors.plantilla_con_ese_codigo_existe': defineMessage({
        id: 'plantillaRepository.errors.plantilla_con_ese_codigo_existe',
        defaultMessage: 'La plantilla con ese código ya existe.'
    }),
    'plantillaItemController.errors.no_existe_el_item': defineMessage({
        id: 'plantillaItemController.errors.no_existe_el_item',
        defaultMessage: 'No existe el item'
    }),
    'plantillaItemController.errors.item_no_corresponde_a_la_plantilla': defineMessage({
        id: 'plantillaItemController.errors.item_no_corresponde_a_la_plantilla',
        defaultMessage: 'El item no corresponde a la plantilla'
    }),
    'provinciaController.errors.debe_ingresar_un_pais_valido': defineMessage({
        id: 'provinciaController.errors.debe_ingresar_un_pais_valido',
        defaultMessage: 'Error, debe ingresar un pais valido'
    }),
    'provinciaRepository.errors.la_provincia_ya_existe': defineMessage({
        id: 'provinciaRepository.errors.la_provincia_ya_existe',
        defaultMessage: 'La provincia ya existe.'
    }),
    'regionRepository.errors.no_se_puede_borrar_region_esta_asociada_a_otro_elemento': defineMessage({
        id: 'regionRepository.errors.no_se_puede_borrar_region_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Región ya que esta asociada a otro elemento.'
    }),
    'regionRepository.errors.existe_region_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'regionRepository.errors.existe_region_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe una Región con ese nombre y debe ser única.'
    }),
    'servicioRepository.errors.no_se_puede_borrar_servicio_esta_asociada_a_otro_elemento': defineMessage({
        id: 'servicioRepository.errors.no_se_puede_borrar_servicio_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar el Servicio ya que está asociada a otro elemento.'
    }),
    'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Servicio con ese nombre y debe ser único.'
    }),
    'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Servicio con ese nombre y debe ser único.'
    }),
    'subregionRepository.errors.no_se_puede_borrar_sub_region_esta_asociada_a_otro_elemento': defineMessage({
        id: 'subregionRepository.errors.no_se_puede_borrar_sub_region_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Sub Región ya que esta asociada a otro elemento.'
    }),
    'subregionRepository.errors.existe_sub_region_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'subregionRepository.errors.existe_sub_region_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe una Sub Región con ese nombre y debe ser única.'
    }),
    'talleresRepository.errors.existe_taller_con_el_nombre_debe_ser_unico': defineMessage({
        id: 'talleresRepository.errors.existe_taller_con_el_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Taller con el nombre y debe ser único.'
    }),
    'talleresRepository.errors.existe_un_taller_con_esa_razon_social_debe_ser_unica': defineMessage({
        id: 'talleresRepository.errors.existe_un_taller_con_esa_razon_social_debe_ser_unica',
        defaultMessage: 'Ya existe un Taller con esa Razón Social y debe ser única.'
    }),
    'tareaRepository.errors.no_se_puede_borrar_la_tarea_esta_asociada_a_otro_elemento': defineMessage({
        id: 'tareaRepository.errors.no_se_puede_borrar_la_tarea_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Tarea ya que está asociada a otro elemento.'
    }),
    'tareaRepository.errors.existe_tarea_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'tareaRepository.errors.existe_tarea_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Tarea con ese Nombre y debe ser único.'
    }),
    'unidadDeMedidaController.errors.no_existe_la_unidad_con_ese_id': defineMessage({
        id: 'unidadDeMedidaController.errors.no_existe_la_unidad_con_ese_id',
        defaultMessage: 'No existe la unidad con ese id'
    }),
    'unidadDeMedidaService.errors.no_se_puede_modificar_una_unidad_de_sistema': defineMessage({
        id: 'unidadDeMedidaService.errors.no_se_puede_modificar_una_unidad_de_sistema',
        defaultMessage: 'No se puede modificar una unidad de sistema'
    }),
    'unidadDeMedidaRepository.errors.abreviatura_de_la_unidad_medida_ya_existe': defineMessage({
        id: 'unidadDeMedidaRepository.errors.abreviatura_de_la_unidad_medida_ya_existe',
        defaultMessage: 'La abreviatura de la unidad de medida ya existe.'
    }),
    'usuarioRepository.errors.el_usuario_ingresado_ya_existe': defineMessage({
        id: 'usuarioRepository.errors.el_usuario_ingresado_ya_existe',
        defaultMessage: 'El usuario ingresado ya existe.'
    }),
    'vencimientosRepository.valida_que_haya_o_persona_o_movil': defineMessage({
        id: 'vencimientosRepository.valida_que_haya_o_persona_o_movil',
        defaultMessage: 'Valida que haya o persona o movil'
    }),
    'vencimientosRepository.errors.archivo_sin_datos': defineMessage({
        id: 'vencimientosRepository.errors.archivo_sin_datos',
        defaultMessage: 'El archivo no contiene datos'
    }),
    'vencimientosRepository.errors.archivo_no_cumple_formato_faltan_columnas': defineMessage({
        id: 'vencimientosRepository.errors.archivo_no_cumple_formato_faltan_columnas',
        defaultMessage: 'Archivo no cumple formato, faltan columnas'
    }),
    'vencimientosRepository.errors.no_se_pudieron_leer_columnas_del_archivo': defineMessage({
        id: 'vencimientosRepository.errors.no_se_pudieron_leer_columnas_del_archivo',
        defaultMessage: 'No se pudieron leer las columnas del archivo'
    }),
    'vencimientosRepository.errors.ingreso_dni_dominio_un_campo': defineMessage({
        id: 'vencimientosRepository.errors.ingreso_dni_dominio_un_campo',
        defaultMessage: 'Ingreso DNI y dominio solo se admite un solo campo'
    }),
    'vencimientosRepository.errors.debe_escribir_dni_dominio': defineMessage({
        id: 'vencimientosRepository.errors.debe_escribir_dni_dominio',
        defaultMessage: 'Debe escribir un DNI o dominio'
    }),
    'vencimientosRepository.errors.servicio_requerido': defineMessage({
        id: 'vencimientosRepository.errors.servicio_requerido',
        defaultMessage: 'El servicio es requerido'
    }),
    'vencimientosRepository.errors.servicio_vencimiento_no_encontrado': defineMessage({
        id: 'vencimientosRepository.errors.servicio_vencimiento_no_encontrado',
        defaultMessage: 'No se encontró el servicio de vencimiento'
    }),
    'vencimientosRepository.errors.entidad_no_encontrada': defineMessage({
        id: 'vencimientosRepository.errors.entidad_no_encontrada',
        defaultMessage: 'Entidad no encontrada para el servicio'
    }),
    'vencimientosRepository.errors.fecha_expedicion_no_valida': defineMessage({
        id: 'vencimientosRepository.errors.fecha_expedicion_no_valida',
        defaultMessage: 'Fecha de expedicion no válida'
    }),
    'vencimientosRepository.errors.fecha_vencimiento_no_valida': defineMessage({
        id: 'vencimientosRepository.errors.fecha_vencimiento_no_valida',
        defaultMessage: 'Fecha de vencimiento no válida'
    }),
    'vencimientosRepository.errors.fecha_expedicion_superior_fecha_actual': defineMessage({
        id: 'vencimientosRepository.errors.fecha_expedicion_superior_fecha_actual',
        defaultMessage: 'La fecha de expedicion no puede ser superior a la fecha actual'
    }),
    'vencimientosRepository.errors.fecha_vencimiento_no_valida': defineMessage({
        id: 'vencimientosRepository.errors.fecha_vencimiento_no_valida',
        defaultMessage: 'La fecha de vencimiento no es válida'
    }),
    'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea': defineMessage({
        id: 'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea',
        defaultMessage: 'Debe ingresar al menos una tarea.'
    }),
    'infraccionesRepository.basicCardInfo.description.total': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.total',
        defaultMessage: 'Total'
    }),
    'infraccionesRepository.basicCardInfo.description.pendientes_confirmacion': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.pendientes_confirmacion',
        defaultMessage: 'Pendientes de Confirmación'
    }),
    'infraccionesRepository.basicCardInfo.description.pendientes_pago': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.pendientes_pago',
        defaultMessage: 'Pendientes de Pago'
    }),
    'infraccionesRepository.basicCardInfo.description.abonadas': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.abonadas',
        defaultMessage: 'Abonadas'
    }),
    'movilRepository.basicCardInfo.description.sin_posicion_gps': defineMessage({
        id: 'movilRepository.basicCardInfo.description.sin_posicion_gps',
        defaultMessage: 'Sin Posición GPS'
    }),
    'movilRepository.basicCardInfo.description.en_taller': defineMessage({
        id: 'movilRepository.basicCardInfo.description.en_taller',
        defaultMessage: 'En Taller'
    }),
    'movilRepository.basicCardInfo.description.doc_movil_vencida': defineMessage({
        id: 'movilRepository.basicCardInfo.description.doc_movil_vencida',
        defaultMessage: 'Doc. Móvil Vencida'
    }),
    'movilRepository.basicCardInfo.description.doc_chofer_vencida': defineMessage({
        id: 'movilRepository.basicCardInfo.description.doc_chofer_vencida',
        defaultMessage: 'Doc. Chofer Vencida'
    }),
    'movilRepository.basicCardInfo.description.vh_con_infracciones': defineMessage({
        id: 'movilRepository.basicCardInfo.description.vh_con_infracciones',
        defaultMessage: 'Vh. con Infracciones'
    }),
    'movilRepository.basicCardInfo.description.vh_con_cargas_invalidas': defineMessage({
        id: 'movilRepository.basicCardInfo.description.vh_con_cargas_invalidas',
        defaultMessage: 'Vh. con Cargas Inválidas'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_PREVENTIVOS': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_PREVENTIVOS',
        defaultMessage: 'MANT. PREVENTIVOS'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_CORRECTIVOS': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_CORRECTIVOS',
        defaultMessage: 'MANT. CORRECTIVOS'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_MOVILES': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_MOVILES',
        defaultMessage: 'VENCIMIENTOS - MÓVILES'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_PERSONAS': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_PERSONAS',
        defaultMessage: 'VENCIMIENTOS - PERSONAS'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.GESTORIA': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.GESTORIA',
        defaultMessage: 'GESTORÍA'
    }),
    'llantaAsignacionController.errors.llanta_ya_esta_asignada': defineMessage({
        id: 'llantaAsignacionController.errors.llanta_ya_esta_asignada',
        defaultMessage: 'La llanta ya esta asignada a un móvil'
    }),
    'llantaAsignacionController.errors.llanta_en_recapado': defineMessage({
        id: 'llantaAsignacionController.errors.llanta_en_recapado',
        defaultMessage: 'La llanta se encuentra en recapado'
    }),
    'llantaController.errors.add_banda_es_requerida': defineMessage({
        id: 'llantaController.errors.add_banda_es_requerida',
        defaultMessage: 'La Base es requerida.'
    }),
    'llantaController.errors.add_llanta_posicion_en_movil_en_uso': defineMessage({
        id: 'llantaController.errors.add_llanta_posicion_en_movil_en_uso',
        defaultMessage: 'La posición selecionada en el móvil esta en uso.'
    }),
    'llantaController.errors.llanta_cambio_medidor_en_movil_en_uso': defineMessage({
        id: 'llantaController.errors.llanta_cambio_medidor_en_movil_en_uso',
        defaultMessage: 'No puede cambiar el medidor de una llanta asignada a un móvil.'
    }),
    'llantaAsignacionController.errors.llanta_no_pertenece_al_movil': defineMessage({
        id: 'llantaAsignacionController.errors.llanta_no_pertenece_al_movil',
        defaultMessage: 'Llanta no pertence al móvil.'
    }),
    'llantaController.errors.supero_recapados_maximo': defineMessage({
        id: 'llantaController.errors.supero_recapados_maximo',
        defaultMessage: 'No se permiten mas recapados en la llanta.'
    }),
    'llantaModeloController.errors.punto_medicion.milimetro_warning_debe_se_menor_a_milimitro_inicio': defineMessage({
        id: 'llantaModeloController.errors.punto_medicion.milimetro_warning_debe_se_menor_a_milimitro_inicio',
        defaultMessage: 'Espesor de recapado debe ser menor a mm en momento de compra.'
    }),
    'llantaModeloController.errors.punto_medicion.valores_no_pueden_ser_vacios': defineMessage({
        id: 'llantaModeloController.errors.punto_medicion.valores_no_pueden_ser_vacios',
        defaultMessage: 'El valores de un punto de medición no pueden ser vacíos.'
    }),
    'llantaModeloController.errors.punto_medicion.milimetro_danger_debe_se_menor_a_milimitro_warning': defineMessage({
        id: 'llantaModeloController.errors.punto_medicion.milimetro_danger_debe_se_menor_a_milimitro_warning',
        defaultMessage: 'Espesor de baja debe ser menor al espesor de recapado.'
    }),
    'llantaModeloController.errors.llanta_modelo_ya_existe': defineMessage({
        id: 'llantaModeloController.errors.llanta_modelo_ya_existe',
        defaultMessage: 'Ya existe un modelo de llanta con los datos ingresados.'
    }),
    'llantaModeloController.errors.existen_llantas_asociadas': defineMessage({
        id: 'llantaModeloController.errors.existen_llantas_asociadas',
        defaultMessage: 'Operacion denegada, existen llantas asociadas a este modelo.'
    }),
    'llantaAsignacionesGrid.kpis_card.llantas_entre_0mm_y_3mm': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.llantas_entre_0mm_y_3mm',
        defaultMessage: 'llantas e/ 0 y 3mm'
    }),
    'llantaAsignacionesGrid.kpis_card.llantas_entre_3mm_y_6mm': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.llantas_entre_3mm_y_6mm',
        defaultMessage: 'llantas e/ 3 y 6mm'
    }),
    'llantaAsignacionesGrid.kpis_card.inspeciones_vencidas': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.inspeciones_vencidas',
        defaultMessage: 'Inspeciones Vencidas'
    }),
    'llantaAsignacionesGrid.kpis_card.KMs_promedio_por_llanta': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.KMs_promedio_por_llanta',
        defaultMessage: 'KMs promedio por llanta'
    }),
    'llantaAsignacionesGrid.kpis_card.desgaste_cada_10000km': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.desgaste_cada_10000km',
        defaultMessage: 'Desgaste (c/ 1000km)'
    }),
    'llantasGrid.kpis_card.llantas_entre_0mm_y_3mm': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_entre_0mm_y_3mm',
        defaultMessage: 'llantas e/ 0 y 3mm'
    }),
    'llantasGrid.kpis_card.llantas_entre_3mm_y_6mm': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_entre_3mm_y_6mm',
        defaultMessage: 'llantas e/ 3 y 6mm'
    }),
    'llantasGrid.kpis_card.inspeciones_vencidas': defineMessage({
        id: 'llantasGrid.kpis_card.inspeciones_vencidas',
        defaultMessage: 'Inspeciones Vencidas'
    }),
    'llantasGrid.kpis_card.KMs_promedio_por_llanta': defineMessage({
        id: 'llantasGrid.kpis_card.KMs_promedio_por_llanta',
        defaultMessage: 'KMs promedio por llanta'
    }),
    'llantasGrid.kpis_card.desgaste_cada_10000km': defineMessage({
        id: 'llantasGrid.kpis_card.desgaste_cada_10000km',
        defaultMessage: 'Desgaste (c/ 10000km)'
    }),
    'llantasGrid.kpis_card.llantas_sin_asignar': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_sin_asignar',
        defaultMessage: 'Llantas sin Asignar'
    }),
    'llantaInspeccionesAbm.errors.firma_requerida': defineMessage({
        id: 'llantaInspeccionesAbm.errors.firma_requerida',
        defaultMessage: 'La firma es requerida'
    }),
    'llantaInspeccionesAbm.errors.odometro_es_requerido': defineMessage({
        id: 'llantaInspeccionesAbm.errors.odometro_es_requerido',
        defaultMessage: 'El odometro es requerido'
    }),
    'llantaController.errors.forzar_recapado_requiere_movil': defineMessage({
        id: 'llantaController.errors.forzar_recapado_requiere_movil',
        defaultMessage: 'Para forzar el recapado se requiere un móvil asignado'
    }),
    'llantaController.errors.servicio_REENCAUCHE_no_encontrado': defineMessage({
        id: 'llantaController.errors.servicio_REENCAUCHE_no_encontrado',
        defaultMessage: 'Servicio de REENCAUCHE no encontrado'
    }),
    'llantaController.errors.llanta_no_existe': defineMessage({
        id: 'llantaController.errors.llanta_no_existe',
        defaultMessage: 'Llanta no existe'
    }),
    'llantaController.errors.llanta_no_existe': defineMessage({
        id: 'llantaController.errors.ingrese_reemplazo',
        defaultMessage: 'Debe asignar una Llanta de Reemplazo'
    }),
    'llantaController.errors.llanta_tiene_asignada_un_movil': defineMessage({
        id: 'llantaController.errors.llanta_tiene_asignada_un_movil',
        defaultMessage: 'Llanta esta asignada a un móvil'
    }),
    'llantaController.errors.llanta_no_tiene_ticket_de_recapado': defineMessage({
        id: 'llantaController.errors.llanta_no_tiene_ticket_de_recapado',
        defaultMessage: 'Llanta no tiene tickets de recapado asignado'
    }),
    'llantaController.errors.llanta_a_desasignar_no_tiene_asigndo_un_movil': defineMessage({
        id: 'llantaController.errors.llanta_a_desasignar_no_tiene_asigndo_un_movil',
        defaultMessage: 'Llanta a desasignar no tiene asignado un móvil'
    }),
    'llantaController.errors.llanta_a_desasignar_requiere_reemplazo': defineMessage({
        id: 'llantaController.errors.llanta_a_desasignar_requiere_reemplazo',
        defaultMessage: 'Llanta a desasignar requiere reemplazo'
    }),
    'llantasGrid.kpis_card.llantas_rodando': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_rodando',
        defaultMessage: 'Llantas Rodando'
    }),
    'dashboard.kpiMovilesDisponibles.titulo': defineMessage({
        id: 'dashboard.kpiMovilesDisponibles.titulo',
        defaultMessage: 'Móviles Operativos'
    }),
    'dashboard.kpiActivos.titulo': defineMessage({
        id: 'dashboard.kpiActivos.titulo',
        defaultMessage: 'Moviles Cargados/Registrados'
    }),
    'dashboard.kpiFueraDeServicio.titulo': defineMessage({
        id: 'dashboard.kpiFueraDeServicio.titulo',
        defaultMessage: 'Móviles Fuera de Servicio'
    }),
    'dashboard.kpiEnComodato.titulo': defineMessage({
        id: 'dashboard.kpiEnComodato.titulo',
        defaultMessage: 'Móviles En Comodato'
    }),
    'dashboard.kpiEnTaller.titulo': defineMessage({
        id: 'dashboard.kpiEnTaller.titulo',
        defaultMessage: 'Móviles En Reparación'
    }),
    'dashboard.kpiSinGps.titulo': defineMessage({
        id: 'dashboard.kpiSinGps.titulo',
        defaultMessage: 'Móviles Sin Disp. Sat.'
    }),
    'dashboard.kpiTicketsEnGestion.titulo': defineMessage({
        id: 'dashboard.kpiTicketsEnGestion.titulo',
        defaultMessage: 'Tickets en Gestión'
    }),
    'dashboard.kpiPresupuestados.titulo': defineMessage({
        id: 'dashboard.kpiPresupuestados.titulo',
        defaultMessage: 'Tickets Presupuestados'
    }),
    'dashboard.kpiListoParaRetirar.titulo': defineMessage({
        id: 'dashboard.kpiListoParaRetirar.titulo',
        defaultMessage: 'Tickets Listos para Retirar'
    }),
    'dashboard.kpiAprobados.titulo': defineMessage({
        id: 'dashboard.kpiAprobados.titulo',
        defaultMessage: 'Tickets Aprobados'
    }),
    'dashboard.kpiFueraDeServicio.titulo': defineMessage({
        id: 'dashboard.kpiFueraDeServicio.titulo',
        defaultMessage: 'Móviles Fuera de Servicio'
    }),
    'llantaController.errors.formulario_finalizar_recapado_incompleto': defineMessage({
        id: 'llantaController.errors.formulario_finalizar_recapado_incompleto',
        defaultMessage: 'Complete todos los campos.'
    }),
    'llantas.column_desgaste_cada_mil.restante_odometro.no_es_posible_el_calculo': defineMessage({
        id: 'llantas.column_desgaste_cada_mil.restante_odometro.no_es_posible_el_calculo',
        defaultMessage: 'No es posible el calculo, faltan datos'
    }),
    'llantaModeloController.errors.clase.clase_invalida_en_unidad_medidor': defineMessage({
        id: 'llantaModeloController.errors.clase.clase_invalida_en_unidad_medidor',
        defaultMessage: 'Clase no pertence a la unidad de medidor'
    }),
    'llantaModeloController.errors.unidad_medidor.unidad_medidor_invalido': defineMessage({
        id: 'llantaModeloController.errors.unidad_medidor.unidad_medidor_invalido',
        defaultMessage: 'Unidad medidor invalido'
    }),
    'llantasGrid.kpis_card.llantas_en_nivel_de_reenc_baja': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_en_nivel_de_reenc_baja',
        defaultMessage: 'En nivel de Reenc./Baja'
    }),
    'llantasGrid.kpis_card.llantas_proximas_a_reenc_baja': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_proximas_a_reenc_baja',
        defaultMessage: 'Próximas a Reenc./Baja'
    }),
    'llantaController.errors.ya_existe_una_llanta': defineMessage({
        id: 'llantaController.errors.ya_existe_una_llanta',
        defaultMessage: 'Ya existe una llanta con el serial ingresado.'
    }),
    'llantaController.errors.tipo_de_llanta_incompatible_con_movil': defineMessage({
        id: 'llantaController.errors.tipo_de_llanta_incompatible_con_movil',
        defaultMessage: 'Tipo de llanta incompatible con el vehículo'
    }),
    'llantaController.errors.llanta_baja.tipo_de_llanta_reemplazo_incompatible_con_movil': defineMessage({
        id: 'llantaController.errors.llanta_baja.tipo_de_llanta_reemplazo_incompatible_con_movil',
        defaultMessage: 'Tipo de llanta reemplazo incompatible con el vehículo'
    }),
    'llantaController.errors.base_requerida_para_desasignar': defineMessage({
        id: 'llantaController.errors.base_requerida_para_desasignar',
        defaultMessage: 'Base es requerida para desasignar'
    }),
    'llantaModeloController.errors.llanta_modelo_no_existe': defineMessage({
        id: 'llantaModeloController.errors.llanta_modelo_no_existe',
        defaultMessage: 'Modelo de llanta no existe'
    }),
    'llantaModeloController.errors.llanta_modelo_tiene_llantas_asignadas_no_se_puede_cambiar_unidad_medidor': defineMessage({
        id: 'llantaModeloController.errors.llanta_modelo_tiene_llantas_asignadas_no_se_puede_cambiar_unidad_medidor',
        defaultMessage: 'Modelo tiene llantas asignadas a  vehículos, no se puede cambiar unidad medidor.'
    }),
    'llantasGrid.kpis_card.Hrs_promedio_por_llanta': defineMessage({
        id: 'llantasGrid.kpis_card.Hrs_promedio_por_llanta',
        defaultMessage: 'Hrs promedio por llanta'
    }),
    'llantasGrid.kpis_card.desgaste_cada_10000hrs': defineMessage({
        id: 'llantasGrid.kpis_card.desgaste_cada_10000hrs',
        defaultMessage: 'Desgaste (c/ 1000Hrs)'
    }),
    'llantaController.errors.add_llanta_posicion_es_requerido': defineMessage({
        id: 'llantaController.errors.add_llanta_posicion_es_requerido',
        defaultMessage: 'Selecione una posición'
    }),
    'llantasRepository.errors.encabezado_no_esta_en_fila_correcta': defineMessage({
        id: 'movilesRepository.errors.encabezado_no_esta_en_fila_correcta',
        defaultMessage: 'Encabezado no esta en fila correcta.'
    }),
    'llantasRepository.errors.archivo_no_cumple_formato_faltan_columnas': defineMessage({
        id: 'llantasRepository.errors.archivo_no_cumple_formato_faltan_columnas',
        defaultMessage: 'Archivo no cumple formato faltan columnas.'
    }),
    'ropAbm.errors.ya_existe_rop_para_equipo': defineMessage({
        id: 'rops.errors.ya_existe_rop_para_equipo',
        defaultMessage: 'Ya existe un ROP para este equipo.'
    }),
    'kpi_moviles.gps': defineMessage({
        id: 'kpi_moviles.gps',
        defaultMessage: 'GPS'
    }),
    'kpi_moviles.gps_no_reportan': defineMessage({
        id: 'kpi_moviles.gps_no_reportan',
        defaultMessage: 'GPS no reportan'
    }),
    'kpi_moviles.gps_vehiculos_sin_GPS': defineMessage({
        id: 'kpi_moviles.gps_vehiculos_sin_GPS',
        defaultMessage: 'Vehículos sin GPS'
    }),
    'error.odometro_mayor_al_actual': defineMessage({
        id: 'error.odometro_mayor_al_actual',
        defaultMessage: 'Odómetro tiene que ser mayor al actual'
    }),
    'form.error': defineMessage({
        id: 'form.error',
        defaultMessage: 'Error'
    }),
    'login.errors.invalido': defineMessage({
        id: 'login.errors.invalido',
        defaultMessage: '  La combinación de nombre de usuario y contraseña no existe en nuestro sistema'
    }),
    'llantaController.no_se_puede_dar_de_baja_la_lanta_tickets_pendientes': defineMessage({
        id: 'llantaController.no_se_puede_dar_de_baja_la_lanta_tickets_pendientes',
        defaultMessage: 'No se puede dar de baja la llanta, tiene tickets pendientes'
    }),
    'tickets.sinTraduccion': defineMessage({
        id: 'tickets.sinTraduccion',
        defaultMessage: 'Falta traduccion'
    }),
    'tickets_estados.PENDIENTES': defineMessage({
        id: 'tickets_estados.PENDIENTES',
        defaultMessage: 'PENDIENTES'
    }),
    'tickets_estado.ABIERTO': defineMessage({
        id: 'tickets_estados.ABIERTO',
        defaultMessage: 'ABIERTO'
    }),
    'tickets_estado.ADIC_PRESUPUESTADO': defineMessage({
        id: 'tickets_estado.ADIC_PRESUPUESTADO',
        defaultMessage: 'ADIC PRESUPUESTADO'
    }),
    'tickets_estado.APROBADO': defineMessage({
        id: 'tickets_estado.APROBADO',
        defaultMessage: 'APROBADO'
    }),
    'tickets_estado.CANCELADO': defineMessage({
        id: 'tickets_estado.CANCELADO',
        defaultMessage: 'CANCELADO'
    }),
    'tickets_estado.CERRADO': defineMessage({
        id: 'tickets_estado.CERRADO',
        defaultMessage: 'CERRADO'
    }),
    'tickets_estado.CONFIRMAR_REPARACION': defineMessage({
        id: 'tickets_estado.CONFIRMAR_REPARACION',
        defaultMessage: 'CONFIRMAR REPARACION'
    }),
    'tickets_estado.COTIZAR_ADICIONAL': defineMessage({
        id: 'tickets_estado.COTIZAR_ADICIONAL',
        defaultMessage: 'COTIZAR ADICIONAL'
    }),
    'tickets_estado.EN_ESCRIBANIA': defineMessage({
        id: 'tickets_estado.EN_ESCRIBANIA',
        defaultMessage: 'EN ESCRIBANIA'
    }),
    'tickets_estado.EN_FORMULARIO': defineMessage({
        id: 'tickets_estado.EN_FORMULARIO',
        defaultMessage: 'EN FORMULARIO'
    }),
    'tickets_estado.EN_GESTION': defineMessage({
        id: 'tickets_estado.EN_GESTION',
        defaultMessage: 'EN GESTION'
    }),
    'tickets_estado.EN_REPARACION': defineMessage({
        id: 'tickets_estado.EN_REPARACION',
        defaultMessage: 'EN REPARACION'
    }),
    'tickets_estado.LISTO_PARA_RETIRAR': defineMessage({
        id: 'tickets_estado.LISTO_PARA_RETIRAR',
        defaultMessage: 'LISTO PARA RETIRAR'
    }),
    'tickets_estado.PRESUPUESTADO': defineMessage({
        id: 'tickets_estado.PRESUPUESTADO',
        defaultMessage: 'PRESUPUESTADO'
    }),
    'tickets_estado.PRESUPUESTADO_SV': defineMessage({
        id: 'tickets_estado.PRESUPUESTADO_SV',
        defaultMessage: 'PRESUPUESTADO SV'
    }),
    'tickets_estado.PROVISORIO': defineMessage({
        id: 'tickets_estado.PROVISORIO',
        defaultMessage: 'PROVISORIO'
    }),
    'tickets_estado.RECOTIZAR': defineMessage({
        id: 'tickets_estado.RECOTIZAR',
        defaultMessage: 'RECOTIZAR'
    }),
    'tickets_estado.RECOTIZAR_SV': defineMessage({
        id: 'tickets_estado.RECOTIZAR_SV',
        defaultMessage: 'RECOTIZAR SV'
    }),
    'tickets_estado.EN_REVISION': defineMessage({
        id: 'tickets_estado.EN_REVISION',
        defaultMessage: 'EN REVISION'
    }),
    'tickets_estado.FUERA_DE_ABONO': defineMessage({
        id: 'tickets_estado.FUERA_DE_ABONO',
        defaultMessage: 'FUERA DE ABONO'
    }),
    'tickets_estado.A_REPARAR': defineMessage({
        id: 'tickets_estado.A_REPARAR',
        defaultMessage: 'A REPARAR'
    }),
    'tickets_estado.VALIDADO_AUDITOR': defineMessage({
        id: 'tickets_estado.VALIDADO_AUDITOR',
        defaultMessage: 'VALIDADO AUDITOR'
    }),
    'tickets_estado.SOLICITUD_DE_PEDIDO': defineMessage({
        id: 'tickets_estado.SOLICITUD_DE_PEDIDO',
        defaultMessage: 'SOLICITUD DE PEDIDO'
    }),
    'tickets_tipo.DEFAULT': defineMessage({
        id: 'tickets_tipo.DEFAULT',
        defaultMessage: 'POR DEFECTO'
    }),
    'tickets_tipo.PREVENTIVO': defineMessage({
        id: 'tickets_tipo.PREVENTIVO',
        defaultMessage: 'PREVENTIVO'
    }),
    'tickets_tipo.VENCIMIENTO': defineMessage({
        id: 'tickets_tipo.VENCIMIENTO',
        defaultMessage: 'VENCIMIENTO'
    }),
    'tickets_tipo.GESTORIA': defineMessage({
        id: 'tickets_tipo.GESTORIA.PROVISORIO',
        defaultMessage: 'GESTORIA'
    }),
    'tickets_tipo.CORRECTIVO': defineMessage({
        id: 'tickets_tipo.CORRECTIVO',
        defaultMessage: 'CORRECTIVO'
    }),
    'tickets_entidad.movil': defineMessage({
        id: 'tickets_entidad.movil',
        defaultMessage: 'MOVIL'
    }),
    'tickets_entidad.llanta': defineMessage({
        id: 'tickets_entidad.llanta',
        defaultMessage: "LLANTA"
    }),
    'permission.REPORTES_NO_REPORTAN': defineMessage({
        id: 'permission.REPORTES_NO_REPORTAN',
        defaultMessage: "REPORTES_NO_REPORTAN"
    }),
    'permission.REPORTES_ACTUALIZACION_KM': defineMessage({
        id: 'permission.REPORTES_ACTUALIZACION_KM',
        defaultMessage: "REPORTES_ACTUALIZACION_KM"
    }),
    'permission.REPORTES_KM_MOVIL_LISTAR': defineMessage({
        id: 'permission.REPORTES_KM_MOVIL_LISTAR',
        defaultMessage: "REPORTES_KM_MOVIL_LISTAR"
    }),
    'permission.REPORTES_MOVILES_INMOVILIZADOS': defineMessage({
        id: 'permission.REPORTES_MOVILES_INMOVILIZADOS',
        defaultMessage: "REPORTES_MOVILES_INMOVILIZADOS"
    }),
    'permission.DASHBOARD_GASTOS': defineMessage({
        id: 'permission.DASHBOARD_GASTOS',
        defaultMessage: "DASHBOARD_GASTOS"
    }),
    'permission.PERMISOS_LISTAR': defineMessage({
        id: 'permission.PERMISOS_LISTAR',
        defaultMessage: "PERMISOS_LISTAR"
    }),
    'permission.PERFILES_LISTAR': defineMessage({
        id: 'permission.PERFILES_LISTAR',
        defaultMessage: "PERFILES_LISTAR"
    }),
    'permission.PERFILES_CREAR': defineMessage({
        id: 'permission.PERFILES_CREAR',
        defaultMessage: "PERFILES_CREAR"
    }),
    'permission.PERFILES_DUPLICAR': defineMessage({
        id: 'permission.PERFILES_DUPLICAR',
        defaultMessage: "PERFILES_DUPLICAR"
    }),
    'permission.PERFILES_VISUALIZAR': defineMessage({
        id: 'permission.PERFILES_VISUALIZAR',
        defaultMessage: "PERFILES_VISUALIZAR"
    }),
    'permission.PERFILES_MODIFICAR': defineMessage({
        id: 'permission.PERFILES_MODIFICAR',
        defaultMessage: "PERFILES_MODIFICAR"
    }),
    'permission.PERFILES_ELIMINAR': defineMessage({
        id: 'permission.PERFILES_ELIMINAR',
        defaultMessage: "PERFILES_ELIMINAR"
    }),
    'permission.PERSONAS_LISTAR': defineMessage({
        id: 'permission.PERSONAS_LISTAR',
        defaultMessage: "PERSONAS_LISTAR"
    }),
    'permission.PERSONAS_CREAR': defineMessage({
        id: 'permission.PERSONAS_CREAR',
        defaultMessage: "PERSONAS_CREAR"
    }),
    'permission.PERSONAS_VISUALIZAR': defineMessage({
        id: 'permission.PERSONAS_VISUALIZAR',
        defaultMessage: "PERSONAS_VISUALIZAR"
    }),
    'permission.PERSONAS_MODIFICAR': defineMessage({
        id: 'permission.PERSONAS_MODIFICAR',
        defaultMessage: "PERSONAS_MODIFICAR"
    }),
    'permission.PERSONAS_BLANQUEAR_CLAVE': defineMessage({
        id: 'permission.PERSONAS_BLANQUEAR_CLAVE',
        defaultMessage: "PERSONAS_BLANQUEAR_CLAVE"
    }),
    'permission.MOVILES_LISTAR': defineMessage({
        id: 'permission.MOVILES_LISTAR',
        defaultMessage: "MOVILES_LISTAR"
    }),
    'permission.MOVILES_CREAR': defineMessage({
        id: 'permission.MOVILES_CREAR',
        defaultMessage: "MOVILES_CREAR"
    }),
    'permission.MOVILES_VISUALIZAR': defineMessage({
        id: 'permission.MOVILES_VISUALIZAR',
        defaultMessage: "MOVILES_VISUALIZAR"
    }),
    'permission.MOVILES_MODIFICAR': defineMessage({
        id: 'permission.MOVILES_MODIFICAR',
        defaultMessage: "MOVILES_MODIFICAR"
    }),
    'permission.MOVILES_CHOFERES_HISTORICO_LISTAR': defineMessage({
        id: 'permission.MOVILES_CHOFERES_HISTORICO_LISTAR',
        defaultMessage: "MOVILES_CHOFERES_HISTORICO_LISTAR"
    }),
    'permission.MOVILES_CECOS_HISTORICO_LISTAR': defineMessage({
        id: 'permission.MOVILES_CECOS_HISTORICO_LISTAR',
        defaultMessage: "MOVILES_CECOS_HISTORICO_LISTAR"
    }),
    'permission.MOVILES_GUARDA_HISTORICO_LISTAR': defineMessage({
        id: 'permission.MOVILES_GUARDA_HISTORICO_LISTAR',
        defaultMessage: "MOVILES_GUARDA_HISTORICO_LISTAR"
    }),
    'permission.MOVILES_MODIFICAR_KM': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_KM',
        defaultMessage: "MOVILES_MODIFICAR_KM"
    }),
    'permission.MOVILES_MODIFICAR_DATOS_GENERALES': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_DATOS_GENERALES',
        defaultMessage: "MOVILES_MODIFICAR_DATOS_GENERALES"
    }),
    'permission.MOVILES_MODIFICAR_CHOFERES': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_CHOFERES',
        defaultMessage: "MOVILES_MODIFICAR_CHOFERES"
    }),
    'permission.MOVILES_MODIFICAR_ADMINISTRACION': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_ADMINISTRACION',
        defaultMessage: "MOVILES_MODIFICAR_ADMINISTRACION"
    }),
    'permission.MOVILES_MODIFICAR_DIMENSIONES': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_DIMENSIONES',
        defaultMessage: "MOVILES_MODIFICAR_DIMENSIONES"
    }),
    'permission.MOVILES_MODIFICAR_LUGAR_GUARDA': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_LUGAR_GUARDA',
        defaultMessage: "MOVILES_MODIFICAR_LUGAR_GUARDA"
    }),
    'permission.MOVILES_MODIFICAR_EQUIPAMIENTO': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_EQUIPAMIENTO',
        defaultMessage: "MOVILES_MODIFICAR_EQUIPAMIENTO"
    }),
    'permission.MOVILES_MODIFICAR_MANTENIMIENTO': defineMessage({
        id: 'permission.MOVILES_MODIFICAR_MANTENIMIENTO',
        defaultMessage: "MOVILES_MODIFICAR_MANTENIMIENTO"
    }),
    'permission.MOVILES_CANCELAR_TICKETS_GESTORIA': defineMessage({
        id: 'permission.MOVILES_CANCELAR_TICKETS_GESTORIA',
        defaultMessage: "MOVILES_CANCELAR_TICKETS_GESTORIA"
    }),
    'permission.MOVILES_LISTAR_TODOS_LOS_CHOFERES': defineMessage({
        id: 'permission.MOVILES_LISTAR_TODOS_LOS_CHOFERES',
        defaultMessage: "MOVILES_LISTAR_TODOS_LOS_CHOFERES"
    }),
    'permission.MOVILES_VISUALIZAR_ATRIBUTOS_CONTRATO': defineMessage({
        id: 'permission.MOVILES_VISUALIZAR_ATRIBUTOS_CONTRATO',
        defaultMessage: "MOVILES_VISUALIZAR_ATRIBUTOS_CONTRATO"
    }),
    'permission.MOVILES_EXPORTAR_COSTO_PREVENTIVO': defineMessage({
        id: 'permission.MOVILES_EXPORTAR_COSTO_PREVENTIVO',
        defaultMessage: "MOVILES_EXPORTAR_COSTO_PREVENTIVO"
    }),
    'permission.MOVILES_EXPORTAR_COSTO_COMBUSTIBLES': defineMessage({
        id: 'permission.MOVILES_EXPORTAR_COSTO_COMBUSTIBLES',
        defaultMessage: "MOVILES_EXPORTAR_COSTO_COMBUSTIBLES"
    }),
    'permission.MOVILES_EXPORTAR_COSTO_CORRECTIVOS': defineMessage({
        id: 'permission.MOVILES_EXPORTAR_COSTO_CORRECTIVOS',
        defaultMessage: "MOVILES_EXPORTAR_COSTO_CORRECTIVOS"
    }),
    'permission.MOVILES_EXPORTAR_COSTO_VENCIMIENTOS': defineMessage({
        id: 'permission.MOVILES_EXPORTAR_COSTO_VENCIMIENTOS',
        defaultMessage: "MOVILES_EXPORTAR_COSTO_VENCIMIENTOS"
    }),
    'permission.MOVILES_EXPORTAR_COSTO_GESTORIA': defineMessage({
        id: 'permission.MOVILES_EXPORTAR_COSTO_GESTORIA',
        defaultMessage: "MOVILES_EXPORTAR_COSTO_GESTORIA"
    }),
    'permission.MOVILES_EXPORTAR_COSTO_INFRACCIONES': defineMessage({
        id: 'permission.MOVILES_EXPORTAR_COSTO_INFRACCIONES',
        defaultMessage: "MOVILES_EXPORTAR_COSTO_INFRACCIONES"
    }),
    'permission.MOVILES_DATOS_GENERALES_HISTORICO_LISTAR': defineMessage({
        id: 'permission.MOVILES_DATOS_GENERALES_HISTORICO_LISTAR',
        defaultMessage: "MOVILES_DATOS_GENERALES_HISTORICO_LISTAR"
    }),
    'permission.LLANTAS_LISTAR': defineMessage({
        id: 'permission.LLANTAS_LISTAR',
        defaultMessage: "LLANTAS_LISTAR"
    }),
    'permission.LLANTAS_CREAR': defineMessage({
        id: 'permission.LLANTAS_CREAR',
        defaultMessage: "LLANTAS_CREAR"
    }),
    'permission.LLANTAS_VISUALIZAR': defineMessage({
        id: 'permission.LLANTAS_VISUALIZAR',
        defaultMessage: "LLANTAS_VISUALIZAR"
    }),
    'permission.LLANTAS_VISUALIZAR_ODOMETRO': defineMessage({
        id: 'permission.LLANTAS_VISUALIZAR_ODOMETRO',
        defaultMessage: "LLANTAS_VISUALIZAR_ODOMETRO"
    }),
    'permission.LLANTAS_ELIMINAR': defineMessage({
        id: 'permission.LLANTAS_ELIMINAR',
        defaultMessage: "LLANTAS_ELIMINAR"
    }),
    'permission.LLANTAS_MODIFICAR': defineMessage({
        id: 'permission.LLANTAS_MODIFICAR',
        defaultMessage: "LLANTAS_MODIFICAR"
    }),
    'permission.LLANTAS_ODOMETRO_MODIFICAR': defineMessage({
        id: 'permission.LLANTAS_ODOMETRO_MODIFICAR',
        defaultMessage: "LLANTAS_ODOMETRO_MODIFICAR"
    }),
    'permission.LLANTAS_MODELOS_VISUALIZAR': defineMessage({
        id: 'permission.LLANTAS_MODELOS_VISUALIZAR',
        defaultMessage: "LLANTAS_MODELOS_VISUALIZAR"
    }),
    'permission.LLANTAS_MODELOS_LISTAR': defineMessage({
        id: 'permission.LLANTAS_MODELOS_LISTAR',
        defaultMessage: "LLANTAS_MODELOS_LISTAR"
    }),
    'permission.LLANTAS_MODELOS_CREAR': defineMessage({
        id: 'permission.LLANTAS_MODELOS_CREAR',
        defaultMessage: "LLANTAS_MODELOS_CREAR"
    }),
    'permission.LLANTAS_MODELOS_MODIFICAR': defineMessage({
        id: 'permission.LLANTAS_MODELOS_MODIFICAR',
        defaultMessage: "LLANTAS_MODELOS_MODIFICAR"
    }),
    'permission.LLANTAS_MODELOS_ELIMINAR': defineMessage({
        id: 'permission.LLANTAS_MODELOS_ELIMINAR',
        defaultMessage: "LLANTAS_MODELOS_ELIMINAR"
    }),
    'permission.LLANTAS_MARCAS_VISUALIZAR': defineMessage({
        id: 'permission.LLANTAS_MARCAS_VISUALIZAR',
        defaultMessage: "LLANTAS_MARCAS_VISUALIZAR"
    }),
    'permission.LLANTAS_MARCAS_LISTAR': defineMessage({
        id: 'permission.LLANTAS_MARCAS_LISTAR',
        defaultMessage: "LLANTAS_MARCAS_LISTAR"
    }),
    'permission.LLANTAS_MARCAS_CREAR': defineMessage({
        id: 'permission.LLANTAS_MARCAS_CREAR',
        defaultMessage: "LLANTAS_MARCAS_CREAR"
    }),
    'permission.LLANTAS_MARCAS_MODIFICAR': defineMessage({
        id: 'permission.LLANTAS_MARCAS_MODIFICAR',
        defaultMessage: "LLANTAS_MARCAS_MODIFICAR"
    }),
    'permission.LLANTAS_MARCAS_ELIMINAR': defineMessage({
        id: 'permission.LLANTAS_MARCAS_ELIMINAR',
        defaultMessage: "LLANTAS_MARCAS_ELIMINAR"
    }),
    'permission.LLANTAS_INSPECCIONES_VISUALIZAR': defineMessage({
        id: 'permission.LLANTAS_INSPECCIONES_VISUALIZAR',
        defaultMessage: "LLANTAS_INSPECCIONES_VISUALIZAR"
    }),
    'permission.LLANTAS_INSPECCIONES_LISTAR': defineMessage({
        id: 'permission.LLANTAS_INSPECCIONES_LISTAR',
        defaultMessage: "LLANTAS_INSPECCIONES_LISTAR"
    }),
    'permission.LLANTAS_INSPECCIONES_CREAR': defineMessage({
        id: 'permission.LLANTAS_INSPECCIONES_CREAR',
        defaultMessage: "LLANTAS_INSPECCIONES_CREAR"
    }),
    'permission.LLANTAS_INSPECCIONES_MODIFICAR': defineMessage({
        id: 'permission.LLANTAS_INSPECCIONES_MODIFICAR',
        defaultMessage: "LLANTAS_INSPECCIONES_MODIFICAR"
    }),
    'permission.LLANTAS_INSPECCIONES_ELIMINAR': defineMessage({
        id: 'permission.LLANTAS_INSPECCIONES_ELIMINAR',
        defaultMessage: "LLANTAS_INSPECCIONES_ELIMINAR"
    }),
    'permission.LLANTAS_ASIGNACIONES_LISTAR': defineMessage({
        id: 'permission.LLANTAS_ASIGNACIONES_LISTAR',
        defaultMessage: "LLANTAS_ASIGNACIONES_LISTAR"
    }),
    'permission.LLANTAS_MANTENIMIENTOS_CORRECTIVOS_CREAR': defineMessage({
        id: 'permission.LLANTAS_MANTENIMIENTOS_CORRECTIVOS_CREAR',
        defaultMessage: "LLANTAS_MANTENIMIENTOS_CORRECTIVOS_CREAR"
    }),
    'permission.LLANTAS_ODOMETRO_VISUALIZAR': defineMessage({
        id: 'permission.LLANTAS_ODOMETRO_VISUALIZAR',
        defaultMessage: "LLANTAS_ODOMETRO_VISUALIZAR"
    }),
    'permission.LLANTAS_SIN_NECESIDAD_REEMPLAZO': defineMessage({
        id: 'permission.LLANTAS_SIN_NECESIDAD_REEMPLAZO',
        defaultMessage: "LLANTAS_SIN_NECESIDAD_REEMPLAZO"
    }),
    'permission.USUARIO_VER_TODAS_LAS_LLANTAS': defineMessage({
        id: 'permission.USUARIO_VER_TODAS_LAS_LLANTAS',
        defaultMessage: "USUARIO_VER_TODAS_LAS_LLANTAS"
    }),
    'permission.MARCAS_LISTAR': defineMessage({
        id: 'permission.MARCAS_LISTAR',
        defaultMessage: "MARCAS_LISTAR"
    }),
    'permission.MARCAS_CREAR': defineMessage({
        id: 'permission.MARCAS_CREAR',
        defaultMessage: "MARCAS_CREAR"
    }),
    'permission.MARCAS_VISUALIZAR': defineMessage({
        id: 'permission.MARCAS_VISUALIZAR',
        defaultMessage: "MARCAS_VISUALIZAR"
    }),
    'permission.MARCAS_MODIFICAR': defineMessage({
        id: 'permission.MARCAS_MODIFICAR',
        defaultMessage: "MARCAS_MODIFICAR"
    }),
    'permission.MODELOS_LISTAR': defineMessage({
        id: 'permission.MODELOS_LISTAR',
        defaultMessage: "MODELOS_LISTAR"
    }),
    'permission.MODELOS_CREAR': defineMessage({
        id: 'permission.MODELOS_CREAR',
        defaultMessage: "MODELOS_CREAR"
    }),
    'permission.MODELOS_VISUALIZAR': defineMessage({
        id: 'permission.MODELOS_VISUALIZAR',
        defaultMessage: "MODELOS_VISUALIZAR"
    }),
    'permission.MODELOS_MODIFICAR': defineMessage({
        id: 'permission.MODELOS_MODIFICAR',
        defaultMessage: "MODELOS_MODIFICAR"
    }),
    'permission.MODELOS_ELIMINAR': defineMessage({
        id: 'permission.MODELOS_ELIMINAR',
        defaultMessage: "MODELOS_ELIMINAR"
    }),
    'permission.MODELO_TIPOS_LISTAR': defineMessage({
        id: 'permission.MODELO_TIPOS_LISTAR',
        defaultMessage: "MODELO_TIPOS_LISTAR"
    }),
    'permission.MODELO_TIPOS_CREAR': defineMessage({
        id: 'permission.MODELO_TIPOS_CREAR',
        defaultMessage: "MODELO_TIPOS_CREAR"
    }),
    'permission.MODELO_TIPOS_VISUALIZAR': defineMessage({
        id: 'permission.MODELO_TIPOS_VISUALIZAR',
        defaultMessage: "MODELO_TIPOS_VISUALIZAR"
    }),
    'permission.MODELO_TIPOS_MODIFICAR': defineMessage({
        id: 'permission.MODELO_TIPOS_MODIFICAR',
        defaultMessage: "MODELO_TIPOS_MODIFICAR"
    }),
    'permission.MODELO_TIPOS_ELIMINAR': defineMessage({
        id: 'permission.MODELO_TIPOS_ELIMINAR',
        defaultMessage: "MODELO_TIPOS_ELIMINAR"
    }),
    'permission.EQUIPAMIENTO_TIPOS_LISTAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_TIPOS_LISTAR',
        defaultMessage: "EQUIPAMIENTO_TIPOS_LISTAR"
    }),
    'permission.EQUIPAMIENTO_TIPOS_CREAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_TIPOS_CREAR',
        defaultMessage: "EQUIPAMIENTO_TIPOS_CREAR"
    }),
    'permission.EQUIPAMIENTO_TIPOS_VISUALIZAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_TIPOS_VISUALIZAR',
        defaultMessage: "EQUIPAMIENTO_TIPOS_VISUALIZAR"
    }),
    'permission.EQUIPAMIENTO_TIPOS_MODIFICAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_TIPOS_MODIFICAR',
        defaultMessage: "EQUIPAMIENTO_TIPOS_MODIFICAR"
    }),
    'permission.EQUIPAMIENTO_LISTAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_LISTAR',
        defaultMessage: "EQUIPAMIENTO_LISTAR"
    }),
    'permission.EQUIPAMIENTO_CREAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_CREAR',
        defaultMessage: "EQUIPAMIENTO_CREAR"
    }),
    'permission.EQUIPAMIENTO_VISUALIZAR': defineMessage({
        id: 'permission.EQUIPAMIENTO_VISUALIZAR',
        defaultMessage: "EQUIPAMIENTO_VISUALIZAR"
    }),
    'permission.CHECKLIST_LISTAR': defineMessage({
        id: 'permission.CHECKLIST_LISTAR',
        defaultMessage: "CHECKLIST_LISTAR"
    }),
    'permission.CHECKLIST_LISTAR_HISTORICO': defineMessage({
        id: 'permission.CHECKLIST_LISTAR_HISTORICO',
        defaultMessage: "CHECKLIST_LISTAR_HISTORICO"
    }),
    'permission.CHECKLIST_LISTAR_TIPO_FORMULARIOS': defineMessage({
        id: 'permission.CHECKLIST_LISTAR_TIPO_FORMULARIOS',
        defaultMessage: "CHECKLIST_LISTAR_TIPO_FORMULARIOS"
    }),
    'permission.CHECKLIST_CREAR_TIPO_FORMULARIOS': defineMessage({
        id: 'permission.CHECKLIST_CREAR_TIPO_FORMULARIOS',
        defaultMessage: "CHECKLIST_CREAR_TIPO_FORMULARIOS"
    }),
    'permission.CHECKLIST_EDITAR_TIPO_FORMULARIOS': defineMessage({
        id: 'permission.CHECKLIST_EDITAR_TIPO_FORMULARIOS',
        defaultMessage: "CHECKLIST_EDITAR_TIPO_FORMULARIOS"
    }),
    'permission.CHECKLIST_VISUALIZAR_TIPO_FORMULARIOS': defineMessage({
        id: 'permission.CHECKLIST_VISUALIZAR_TIPO_FORMULARIOS',
        defaultMessage: "CHECKLIST_VISUALIZAR_TIPO_FORMULARIOS"
    }),
    'permission.CHECKLIST_CREAR_HISTORICO': defineMessage({
        id: 'permission.CHECKLIST_CREAR_HISTORICO',
        defaultMessage: "CHECKLIST_CREAR_HISTORICO"
    }),
    'permission.CHECKLIST_EDITAR_HISTORICO': defineMessage({
        id: 'permission.CHECKLIST_EDITAR_HISTORICO',
        defaultMessage: "CHECKLIST_EDITAR_HISTORICO"
    }),
    'permission.CHECKLIST_VALIDAR_HISTORICO': defineMessage({
        id: 'permission.CHECKLIST_VALIDAR_HISTORICO',
        defaultMessage: "CHECKLIST_VALIDAR_HISTORICO"
    }),
    'permission.CHECKLIST_VISUALIZAR_HISTORICO': defineMessage({
        id: 'permission.CHECKLIST_VISUALIZAR_HISTORICO',
        defaultMessage: "CHECKLIST_VISUALIZAR_HISTORICO"
    }),
    'permission.BASES_LISTAR': defineMessage({
        id: 'permission.BASES_LISTAR',
        defaultMessage: "BASES_LISTAR"
    }),
    'permission.BASES_CREAR': defineMessage({
        id: 'permission.BASES_CREAR',
        defaultMessage: "BASES_CREAR"
    }),
    'permission.BASES_VISUALIZAR': defineMessage({
        id: 'permission.BASES_VISUALIZAR',
        defaultMessage: "BASES_VISUALIZAR"
    }),
    'permission.BASES_MODIFICAR': defineMessage({
        id: 'permission.BASES_MODIFICAR',
        defaultMessage: "BASES_MODIFICAR"
    }),
    'permission.BASES_MODIFICAR_INFORMACION_COMPLEMENTARIA': defineMessage({
        id: 'permission.BASES_MODIFICAR_INFORMACION_COMPLEMENTARIA',
        defaultMessage: "BASES_MODIFICAR_INFORMACION_COMPLEMENTARIA"
    }),
    'permission.USUARIO_TRABAJAR_VEHICULOS': defineMessage({
        id: 'permission.USUARIO_TRABAJAR_VEHICULOS',
        defaultMessage: "USUARIO_TRABAJAR_VEHICULOS"
    }),
    'permission.USUARIO_TRABAJA_CIADEORIGEN': defineMessage({
        id: 'permission.USUARIO_TRABAJA_CIADEORIGEN',
        defaultMessage: "USUARIO_TRABAJA_CIADEORIGEN"
    }),
    'permission.GERENCIADORES_LISTAR': defineMessage({
        id: 'permission.GERENCIADORES_LISTAR',
        defaultMessage: "GERENCIADORES_LISTAR"
    }),
    'permission.GERENCIADORES_CREAR': defineMessage({
        id: 'permission.GERENCIADORES_CREAR',
        defaultMessage: "GERENCIADORES_CREAR"
    }),
    'permission.GERENCIADORES_VISUALIZAR': defineMessage({
        id: 'permission.GERENCIADORES_VISUALIZAR',
        defaultMessage: "GERENCIADORES_VISUALIZAR"
    }),
    'permission.GERENCIADORES_MODIFICAR': defineMessage({
        id: 'permission.GERENCIADORES_MODIFICAR',
        defaultMessage: "GERENCIADORES_MODIFICAR"
    }),
    'permission.GERENCIADORES_ELIMINAR': defineMessage({
        id: 'permission.GERENCIADORES_ELIMINAR',
        defaultMessage: "GERENCIADORES_ELIMINAR"
    }),
    'permission.MANTENIMIENTOS_CORRECTIVOS_CREAR': defineMessage({
        id: 'permission.MANTENIMIENTOS_CORRECTIVOS_CREAR',
        defaultMessage: "MANTENIMIENTOS_CORRECTIVOS_CREAR"
    }),
    'permission.DASHBOARD_OPERATIVO_VISUALIZAR': defineMessage({
        id: 'permission.DASHBOARD_OPERATIVO_VISUALIZAR',
        defaultMessage: "DASHBOARD_OPERATIVO_VISUALIZAR"
    }),
    'permission.DASHBOARD_OPERATIVO2_VISUALIZAR': defineMessage({
        id: 'permission.DASHBOARD_OPERATIVO2_VISUALIZAR',
        defaultMessage: "DASHBOARD_OPERATIVO2_VISUALIZAR"
    }),
    'permission.DASHBOARD_ANALITICO_VISUALIZAR': defineMessage({
        id: 'permission.DASHBOARD_ANALITICO_VISUALIZAR',
        defaultMessage: "DASHBOARD_ANALITICO_VISUALIZAR"
    }),
    'permission.USUARIO_POSEER_MOVIL': defineMessage({
        id: 'permission.USUARIO_POSEER_MOVIL',
        defaultMessage: "USUARIO_POSEER_MOVIL"
    }),
    'permission.USUARIO_ACTUAR_COMO_GERENCIADOR': defineMessage({
        id: 'permission.USUARIO_ACTUAR_COMO_GERENCIADOR',
        defaultMessage: "USUARIO_ACTUAR_COMO_GERENCIADOR"
    }),
    'permission.USUARIO_TRABAJAR_BASES': defineMessage({
        id: 'permission.USUARIO_TRABAJAR_BASES',
        defaultMessage: "USUARIO_TRABAJAR_BASES"
    }),
    'permission.USUARIO_VER_TODOS_LOS_MOVILES': defineMessage({
        id: 'permission.USUARIO_VER_TODOS_LOS_MOVILES',
        defaultMessage: "USUARIO_VER_TODOS_LOS_MOVILES"
    }),
    'permission.USUARIO_VER_TODOS_LOS_TICKETS': defineMessage({
        id: 'permission.USUARIO_VER_TODOS_LOS_TICKETS',
        defaultMessage: "USUARIO_VER_TODOS_LOS_TICKETS"
    }),
    'permission.USUARIO_TRABAJAR_CON_TODAS_LAS_BASES': defineMessage({
        id: 'permission.USUARIO_TRABAJAR_CON_TODAS_LAS_BASES',
        defaultMessage: "USUARIO_TRABAJAR_CON_TODAS_LAS_BASES"
    }),
    'permission.USUARIO_FLOTA_OPERACIONES': defineMessage({
        id: 'permission.USUARIO_FLOTA_OPERACIONES',
        defaultMessage: "USUARIO_FLOTA_OPERACIONES"
    }),
    'permission.USUARIO_SUPERADMIN': defineMessage({
        id: 'permission.USUARIO_SUPERADMIN',
        defaultMessage: "USUARIO_SUPERADMIN"
    }),
    'permission.USUARIO_VER_TODOS_LAS_PERSONAS': defineMessage({
        id: 'permission.USUARIO_VER_TODOS_LAS_PERSONAS',
        defaultMessage: "USUARIO_VER_TODOS_LAS_PERSONAS"
    }),
    'permission.USUARIO_TRABAJAR_CC': defineMessage({
        id: 'permission.USUARIO_TRABAJAR_CC',
        defaultMessage: "USUARIO_TRABAJAR_CC"
    }),
    'permission.USUARIO_TRABAJAR_FACTURACION': defineMessage({
        id: 'permission.USUARIO_TRABAJAR_FACTURACION',
        defaultMessage: "USUARIO_TRABAJAR_FACTURACION"
    }),
    'permission.USUARIO_EDITAR_SYNC': defineMessage({
        id: 'permission.USUARIO_EDITAR_SYNC',
        defaultMessage: "USUARIO_EDITAR_SYNC"
    }),
    'permission.ES_RESPONSABLE_MOVIL': defineMessage({
        id: 'permission.ES_RESPONSABLE_MOVIL',
        defaultMessage: "ES_RESPONSABLE_MOVIL"
    }),
    'permission.ES_SUPERVISOR_MOVIL': defineMessage({
        id: 'permission.ES_SUPERVISOR_MOVIL',
        defaultMessage: "ES_SUPERVISOR_MOVIL"
    }),
    'permission.TICKETS_APROBAR_AUDITOR': defineMessage({
        id: 'permission.TICKETS_APROBAR_AUDITOR',
        defaultMessage: "TICKETS_APROBAR_AUDITOR"
    }),
    'permission.TICKETS_A_RECOTIZAR_AUDITOR': defineMessage({
        id: 'permission.TICKETS_A_RECOTIZAR_AUDITOR',
        defaultMessage: "TICKETS_A_RECOTIZAR_AUDITOR"
    }),
    'permission.TICKETS_LISTAR': defineMessage({
        id: 'permission.TICKETS_LISTAR',
        defaultMessage: "TICKETS_LISTAR"
    }),
    'permission.TICKETS_VISUALIZAR': defineMessage({
        id: 'permission.TICKETS_VISUALIZAR',
        defaultMessage: "TICKETS_VISUALIZAR"
    }),
    'permission.TICKETS_VER_HISTORIA': defineMessage({
        id: 'permission.TICKETS_VER_HISTORIA',
        defaultMessage: "TICKETS_VER_HISTORIA"
    }),
    'permission.TICKETS_VER_OTROS_TICKETS': defineMessage({
        id: 'permission.TICKETS_VER_OTROS_TICKETS',
        defaultMessage: "TICKETS_VER_OTROS_TICKETS"
    }),
    'permission.TICKETS_VER_INFORMACION': defineMessage({
        id: 'permission.TICKETS_VER_INFORMACION',
        defaultMessage: "TICKETS_VER_INFORMACION"
    }),
    'permission.TICKETS_VER_TAREAS': defineMessage({
        id: 'permission.TICKETS_VER_TAREAS',
        defaultMessage: "TICKETS_VER_TAREAS"
    }),
    'permission.TICKETS_CARGAR_PRESUPUESTO': defineMessage({
        id: 'permission.TICKETS_CARGAR_PRESUPUESTO',
        defaultMessage: "TICKETS_CARGAR_PRESUPUESTO"
    }),
    'permission.TICKETS_VER_PRESUPUESTOS': defineMessage({
        id: 'permission.TICKETS_VER_PRESUPUESTOS',
        defaultMessage: "TICKETS_VER_PRESUPUESTOS"
    }),
    'permission.TICKETS_COMENTAR': defineMessage({
        id: 'permission.TICKETS_COMENTAR',
        defaultMessage: "TICKETS_COMENTAR"
    }),
    'permission.TICKETS_CERRAR_PREVENTIVOS': defineMessage({
        id: 'permission.TICKETS_CERRAR_PREVENTIVOS',
        defaultMessage: "TICKETS_CERRAR_PREVENTIVOS"
    }),
    'permission.TICKETS_CERRAR_CORRECTIVOS': defineMessage({
        id: 'permission.TICKETS_CERRAR_CORRECTIVOS',
        defaultMessage: "TICKETS_CERRAR_CORRECTIVOS"
    }),
    'permission.TICKETS_CERRAR_VENCIMIENTOS': defineMessage({
        id: 'permission.TICKETS_CERRAR_VENCIMIENTOS',
        defaultMessage: "TICKETS_CERRAR_VENCIMIENTOS"
    }),
    'permission.TICKETS_CANCELAR_PREVENTIVOS': defineMessage({
        id: 'permission.TICKETS_CANCELAR_PREVENTIVOS',
        defaultMessage: "TICKETS_CANCELAR_PREVENTIVOS"
    }),
    'permission.TICKETS_CANCELAR_CORRECTIVOS': defineMessage({
        id: 'permission.TICKETS_CANCELAR_CORRECTIVOS',
        defaultMessage: "TICKETS_CANCELAR_CORRECTIVOS"
    }),
    'permission.TICKETS_CANCELAR_VENCIMIENTOS': defineMessage({
        id: 'permission.TICKETS_CANCELAR_VENCIMIENTOS',
        defaultMessage: "TICKETS_CANCELAR_VENCIMIENTOS"
    }),
    'permission.TICKETS_ASIGNAR': defineMessage({
        id: 'permission.TICKETS_ASIGNAR',
        defaultMessage: "TICKETS_ASIGNAR"
    }),
    'permission.TICKETS_PRESUPUESTAR': defineMessage({
        id: 'permission.TICKETS_PRESUPUESTAR',
        defaultMessage: "TICKETS_PRESUPUESTAR"
    }),
    'permission.TICKETS_APROBAR': defineMessage({
        id: 'permission.TICKETS_APROBAR',
        defaultMessage: "TICKETS_APROBAR"
    }),
    'permission.TICKETS_RECOTIZAR': defineMessage({
        id: 'permission.TICKETS_RECOTIZAR',
        defaultMessage: "TICKETS_RECOTIZAR"
    }),
    'permission.TICKETS_PASAR_LISTO_PARA_RETIRAR': defineMessage({
        id: 'permission.TICKETS_PASAR_LISTO_PARA_RETIRAR',
        defaultMessage: "TICKETS_PASAR_LISTO_PARA_RETIRAR"
    }),
    'permission.TICKETS_COTIZAR_ADICIONAL': defineMessage({
        id: 'permission.TICKETS_COTIZAR_ADICIONAL',
        defaultMessage: "TICKETS_COTIZAR_ADICIONAL"
    }),
    'permission.TICKETS_MODIFICAR_FECHA_REALIZADO': defineMessage({
        id: 'permission.TICKETS_MODIFICAR_FECHA_REALIZADO',
        defaultMessage: "TICKETS_MODIFICAR_FECHA_REALIZADO"
    }),
    'permission.TICKETS_MODIFICAR_GERENCIADOR': defineMessage({
        id: 'permission.TICKETS_MODIFICAR_GERENCIADOR',
        defaultMessage: "TICKETS_MODIFICAR_GERENCIADOR"
    }),
    'permission.TICKETS_MODIFICAR_TALLER': defineMessage({
        id: 'permission.TICKETS_MODIFICAR_TALLER',
        defaultMessage: "TICKETS_MODIFICAR_TALLER"
    }),
    'permission.TICKETS_MODIFICAR_EN_TALLER': defineMessage({
        id: 'permission.TICKETS_MODIFICAR_EN_TALLER',
        defaultMessage: "TICKETS_MODIFICAR_EN_TALLER"
    }),
    'permission.TICKETS_MODIFICAR_PRESUPUESTADOS': defineMessage({
        id: 'permission.TICKETS_MODIFICAR_PRESUPUESTADOS',
        defaultMessage: "TICKETS_MODIFICAR_PRESUPUESTADOS"
    }),
    'permission.TICKETS_CONFIRMAR_REPARACION': defineMessage({
        id: 'permission.TICKETS_CONFIRMAR_REPARACION',
        defaultMessage: "TICKETS_CONFIRMAR_REPARACION"
    }),
    'permission.TICKETS_ENVIAR_A_REPARAR': defineMessage({
        id: 'permission.TICKETS_ENVIAR_A_REPARAR',
        defaultMessage: "TICKETS_ENVIAR_A_REPARAR"
    }),
    'permission.TICKETS_APROBAR_ABONO': defineMessage({
        id: 'permission.TICKETS_APROBAR_ABONO',
        defaultMessage: "TICKETS_APROBAR_ABONO"
    }),
    'permission.TICKETS_RECHAZAR_ABONO': defineMessage({
        id: 'permission.TICKETS_RECHAZAR_ABONO',
        defaultMessage: "TICKETS_RECHAZAR_ABONO"
    }),
    'permission.TICKETS_PERSONA_LISTAR': defineMessage({
        id: 'permission.TICKETS_PERSONA_LISTAR',
        defaultMessage: "TICKETS_PERSONA_LISTAR"
    }),
    'permission.TICKETS_MOVIL_LISTAR': defineMessage({
        id: 'permission.TICKETS_MOVIL_LISTAR',
        defaultMessage: "TICKETS_MOVIL_LISTAR"
    }),
    'permission.TICKETS_SINIESTRO_MODIFICAR': defineMessage({
        id: 'permission.TICKETS_SINIESTRO_MODIFICAR',
        defaultMessage: "TICKETS_SINIESTRO_MODIFICAR"
    }),
    'permission.TICKETS_RECOTIZAR_AUDITOR': defineMessage({
        id: 'permission.TICKETS_RECOTIZAR_AUDITOR',
        defaultMessage: "TICKETS_RECOTIZAR_AUDITOR"
    }),
    'permission.TICKETS_EDITAR_CENTRO_COSTOS': defineMessage({
        id: 'permission.TICKETS_EDITAR_CENTRO_COSTOS',
        defaultMessage: "TICKETS_EDITAR_CENTRO_COSTOS"
    }),
    'permission.TICKETS_CREAR': defineMessage({
        id: 'permission.TICKETS_CREAR',
        defaultMessage: "TICKETS_CREAR"
    }),
    'permission.TICKETS_GESTORIA_CREAR': defineMessage({
        id: 'permission.TICKETS_GESTORIA_CREAR',
        defaultMessage: "TICKETS_GESTORIA_CREAR"
    }),
    'permission.TICKETS_EDITAR_KM_REALIZADO': defineMessage({
        id: 'permission.TICKETS_EDITAR_KM_REALIZADO',
        defaultMessage: "TICKETS_EDITAR_KM_REALIZADO"
    }),
    'permission.TICKETS_VER_DETALLE_EAM': defineMessage({
        id: 'permission.TICKETS_VER_DETALLE_EAM',
        defaultMessage: "TICKETS_VER_DETALLE_EAM"
    }),
    'permission.TICKETS_EXPORTAR_HISTORICO': defineMessage({
        id: 'permission.TICKETS_EXPORTAR_HISTORICO',
        defaultMessage: "TICKETS_EXPORTAR_HISTORICO"
    }),
    'permission.PERMISO_APRO_N1': defineMessage({
        id: 'permission.PERMISO_APRO_N1',
        defaultMessage: "PERMISO_APRO_N1"
    }),
    'permission.PERMISO_APRO_N2': defineMessage({
        id: 'permission.PERMISO_APRO_N2',
        defaultMessage: "PERMISO_APRO_N2"
    }),
    'permission.PERMISO_APRO_N3': defineMessage({
        id: 'permission.PERMISO_APRO_N3',
        defaultMessage: "PERMISO_APRO_N3"
    }),
    'permission.VENCIMIENTOS_LISTAR': defineMessage({
        id: 'permission.VENCIMIENTOS_LISTAR',
        defaultMessage: "VENCIMIENTOS_LISTAR"
    }),
    'permission.VENCIMIENTOS_CREAR': defineMessage({
        id: 'permission.VENCIMIENTOS_CREAR',
        defaultMessage: "VENCIMIENTOS_CREAR"
    }),
    'permission.VENCIMIENTOS_VISUALIZAR': defineMessage({
        id: 'permission.VENCIMIENTOS_VISUALIZAR',
        defaultMessage: "VENCIMIENTOS_VISUALIZAR"
    }),
    'permission.VENCIMIENTOS_MODIFICAR': defineMessage({
        id: 'permission.VENCIMIENTOS_MODIFICAR',
        defaultMessage: "VENCIMIENTOS_MODIFICAR"
    }),
    'permission.VENCIMIENTOS_ELIMINAR': defineMessage({
        id: 'permission.VENCIMIENTOS_ELIMINAR',
        defaultMessage: "VENCIMIENTOS_ELIMINAR"
    }),
    'permission.VENCIMIENTOS_FORZAR': defineMessage({
        id: 'permission.VENCIMIENTOS_FORZAR',
        defaultMessage: "VENCIMIENTOS_FORZAR"
    }),
    'permission.VENCIMIENTOS_GENERAR': defineMessage({
        id: 'permission.VENCIMIENTOS_GENERAR',
        defaultMessage: "VENCIMIENTOS_GENERAR"
    }),
    'permission.VENCIMIENTOS_PERSONA_LISTAR': defineMessage({
        id: 'permission.VENCIMIENTOS_PERSONA_LISTAR',
        defaultMessage: "VENCIMIENTOS_PERSONA_LISTAR"
    }),
    'permission.VENCIMIENTOS_MOVIL_LISTAR': defineMessage({
        id: 'permission.VENCIMIENTOS_MOVIL_LISTAR',
        defaultMessage: "VENCIMIENTOS_MOVIL_LISTAR"
    }),
    'permission.PREVENTIVOS_LISTAR': defineMessage({
        id: 'permission.PREVENTIVOS_LISTAR',
        defaultMessage: "PREVENTIVOS_LISTAR"
    }),
    'permission.PREVENTIVOS_CREAR': defineMessage({
        id: 'permission.PREVENTIVOS_CREAR',
        defaultMessage: "PREVENTIVOS_CREAR"
    }),
    'permission.PREVENTIVOS_VISUALIZAR': defineMessage({
        id: 'permission.PREVENTIVOS_VISUALIZAR',
        defaultMessage: "PREVENTIVOS_VISUALIZAR"
    }),
    'permission.PREVENTIVOS_MODIFICAR': defineMessage({
        id: 'permission.PREVENTIVOS_MODIFICAR',
        defaultMessage: "PREVENTIVOS_MODIFICAR"
    }),
    'permission.PREVENTIVOS_ELIMINAR': defineMessage({
        id: 'permission.PREVENTIVOS_ELIMINAR',
        defaultMessage: "PREVENTIVOS_ELIMINAR"
    }),
    'permission.PREVENTIVOS_FORZAR': defineMessage({
        id: 'permission.PREVENTIVOS_FORZAR',
        defaultMessage: "PREVENTIVOS_FORZAR"
    }),
    'permission.PREVENTIVOS_GENERAR': defineMessage({
        id: 'permission.PREVENTIVOS_GENERAR',
        defaultMessage: "PREVENTIVOS_GENERAR"
    }),
    'permission.PREVENTIVOS_MOVIL_LISTAR': defineMessage({
        id: 'permission.PREVENTIVOS_MOVIL_LISTAR',
        defaultMessage: "PREVENTIVOS_MOVIL_LISTAR"
    }),
    'permission.PREVENTIVOS_IMPORTAR': defineMessage({
        id: 'permission.PREVENTIVOS_IMPORTAR',
        defaultMessage: "PREVENTIVOS_IMPORTAR"
    }),
    'permission.TAREAS_LISTAR': defineMessage({
        id: 'permission.TAREAS_LISTAR',
        defaultMessage: "TAREAS_LISTAR"
    }),
    'permission.TAREAS_CREAR': defineMessage({
        id: 'permission.TAREAS_CREAR',
        defaultMessage: "TAREAS_CREAR"
    }),
    'permission.TAREAS_VISUALIZAR': defineMessage({
        id: 'permission.TAREAS_VISUALIZAR',
        defaultMessage: "TAREAS_VISUALIZAR"
    }),
    'permission.TAREAS_MODIFICAR': defineMessage({
        id: 'permission.TAREAS_MODIFICAR',
        defaultMessage: "TAREAS_MODIFICAR"
    }),
    'permission.TAREAS_ELIMINAR': defineMessage({
        id: 'permission.TAREAS_ELIMINAR',
        defaultMessage: "TAREAS_ELIMINAR"
    }),
    'permission.SERVICIOS_LISTAR': defineMessage({
        id: 'permission.SERVICIOS_LISTAR',
        defaultMessage: "SERVICIOS_LISTAR"
    }),
    'permission.SERVICIOS_CREAR': defineMessage({
        id: 'permission.SERVICIOS_CREAR',
        defaultMessage: "SERVICIOS_CREAR"
    }),
    'permission.SERVICIOS_VISUALIZAR': defineMessage({
        id: 'permission.SERVICIOS_VISUALIZAR',
        defaultMessage: "SERVICIOS_VISUALIZAR"
    }),
    'permission.SERVICIOS_MODIFICAR': defineMessage({
        id: 'permission.SERVICIOS_MODIFICAR',
        defaultMessage: "SERVICIOS_MODIFICAR"
    }),
    'permission.SERVICIOS_ELIMINAR': defineMessage({
        id: 'permission.SERVICIOS_ELIMINAR',
        defaultMessage: "SERVICIOS_ELIMINAR"
    }),
    'permission.REPORTAR_PROBLEMA': defineMessage({
        id: 'permission.REPORTAR_PROBLEMA',
        defaultMessage: "REPORTAR_PROBLEMA"
    }),
    'permission.AYUDA_ACCEDER': defineMessage({
        id: 'permission.AYUDA_ACCEDER',
        defaultMessage: "AYUDA_ACCEDER"
    }),
    'permission.INFORMES_ACCEDER': defineMessage({
        id: 'permission.INFORMES_ACCEDER',
        defaultMessage: "INFORMES_ACCEDER"
    }),
    'permission.AVL_ACCEDER': defineMessage({
        id: 'permission.AVL_ACCEDER',
        defaultMessage: "AVL_ACCEDER"
    }),
    'permission.PLANES_MANTENIMIENTO_LISTAR': defineMessage({
        id: 'permission.PLANES_MANTENIMIENTO_LISTAR',
        defaultMessage: "PLANES_MANTENIMIENTO_LISTAR"
    }),
    'permission.PLANES_MANTENIMIENTO_CREAR': defineMessage({
        id: 'permission.PLANES_MANTENIMIENTO_CREAR',
        defaultMessage: "PLANES_MANTENIMIENTO_CREAR"
    }),
    'permission.PLANES_MANTENIMIENTO_MODIFICAR': defineMessage({
        id: 'permission.PLANES_MANTENIMIENTO_MODIFICAR',
        defaultMessage: "PLANES_MANTENIMIENTO_MODIFICAR"
    }),
    'permission.PLANES_MANTENIMIENTO_VISUALIZAR': defineMessage({
        id: 'permission.PLANES_MANTENIMIENTO_VISUALIZAR',
        defaultMessage: "PLANES_MANTENIMIENTO_VISUALIZAR"
    }),
    'permission.PLANES_MANTENIMIENTO_ELIMINAR': defineMessage({
        id: 'permission.PLANES_MANTENIMIENTO_ELIMINAR',
        defaultMessage: "PLANES_MANTENIMIENTO_ELIMINAR"
    }),
    'permission.REGIONES_LISTAR': defineMessage({
        id: 'permission.REGIONES_LISTAR',
        defaultMessage: "REGIONES_LISTAR"
    }),
    'permission.REGIONES_CREAR': defineMessage({
        id: 'permission.REGIONES_CREAR',
        defaultMessage: "REGIONES_CREAR"
    }),
    'permission.REGIONES_VISUALIZAR': defineMessage({
        id: 'permission.REGIONES_VISUALIZAR',
        defaultMessage: "REGIONES_VISUALIZAR"
    }),
    'permission.REGIONES_MODIFICAR': defineMessage({
        id: 'permission.REGIONES_MODIFICAR',
        defaultMessage: "REGIONES_MODIFICAR"
    }),
    'permission.REGIONES_ELIMINAR': defineMessage({
        id: 'permission.REGIONES_ELIMINAR',
        defaultMessage: "REGIONES_ELIMINAR"
    }),
    'permission.SUBREGIONES_LISTAR': defineMessage({
        id: 'permission.SUBREGIONES_LISTAR',
        defaultMessage: "SUBREGIONES_LISTAR"
    }),
    'permission.SUBREGIONES_CREAR': defineMessage({
        id: 'permission.SUBREGIONES_CREAR',
        defaultMessage: "SUBREGIONES_CREAR"
    }),
    'permission.SUBREGIONES_VISUALIZAR': defineMessage({
        id: 'permission.SUBREGIONES_VISUALIZAR',
        defaultMessage: "SUBREGIONES_VISUALIZAR"
    }),
    'permission.SUBREGIONES_MODIFICAR': defineMessage({
        id: 'permission.SUBREGIONES_MODIFICAR',
        defaultMessage: "SUBREGIONES_MODIFICAR"
    }),
    'permission.SUBREGIONES_ELIMINAR': defineMessage({
        id: 'permission.SUBREGIONES_ELIMINAR',
        defaultMessage: "SUBREGIONES_ELIMINAR"
    }),
    'permission.TALLERES_LISTAR': defineMessage({
        id: 'permission.TALLERES_LISTAR',
        defaultMessage: "TALLERES_LISTAR"
    }),
    'permission.TALLERES_CREAR': defineMessage({
        id: 'permission.TALLERES_CREAR',
        defaultMessage: "TALLERES_CREAR"
    }),
    'permission.TALLERES_VISUALIZAR': defineMessage({
        id: 'permission.TALLERES_VISUALIZAR',
        defaultMessage: "TALLERES_VISUALIZAR"
    }),
    'permission.TALLERES_MODIFICAR': defineMessage({
        id: 'permission.TALLERES_MODIFICAR',
        defaultMessage: "TALLERES_MODIFICAR"
    }),
    'permission.TALLERES_ELIMINAR': defineMessage({
        id: 'permission.TALLERES_ELIMINAR',
        defaultMessage: "TALLERES_ELIMINAR"
    }),
    'permission.CENTRO_COSTOS_LISTAR': defineMessage({
        id: 'permission.CENTRO_COSTOS_LISTAR',
        defaultMessage: "CENTRO_COSTOS_LISTAR"
    }),
    'permission.CENTRO_COSTOS_CREAR': defineMessage({
        id: 'permission.CENTRO_COSTOS_CREAR',
        defaultMessage: "CENTRO_COSTOS_CREAR"
    }),
    'permission.CENTRO_COSTOS_MODIFICAR': defineMessage({
        id: 'permission.CENTRO_COSTOS_MODIFICAR',
        defaultMessage: "CENTRO_COSTOS_MODIFICAR"
    }),
    'permission.CENTRO_COSTOS_VISUALIZAR': defineMessage({
        id: 'permission.CENTRO_COSTOS_VISUALIZAR',
        defaultMessage: "CENTRO_COSTOS_VISUALIZAR"
    }),
    'permission.CENTRO_COSTOS_ELIMINAR': defineMessage({
        id: 'permission.CENTRO_COSTOS_ELIMINAR',
        defaultMessage: "CENTRO_COSTOS_ELIMINAR"
    }),
    'permission.CENTRO_BENEFICIOS_LISTAR': defineMessage({
        id: 'permission.CENTRO_BENEFICIOS_LISTAR',
        defaultMessage: "CENTRO_BENEFICIOS_LISTAR"
    }),
    'permission.CENTRO_BENEFICIOS_CREAR': defineMessage({
        id: 'permission.CENTRO_BENEFICIOS_CREAR',
        defaultMessage: "CENTRO_BENEFICIOS_CREAR"
    }),
    'permission.CENTRO_BENEFICIOS_MODIFICAR': defineMessage({
        id: 'permission.CENTRO_BENEFICIOS_MODIFICAR',
        defaultMessage: "CENTRO_BENEFICIOS_MODIFICAR"
    }),
    'permission.CENTRO_BENEFICIOS_VISUALIZAR': defineMessage({
        id: 'permission.CENTRO_BENEFICIOS_VISUALIZAR',
        defaultMessage: "CENTRO_BENEFICIOS_VISUALIZAR"
    }),
    'permission.CENTRO_BENEFICIOS_ELIMINAR': defineMessage({
        id: 'permission.CENTRO_BENEFICIOS_ELIMINAR',
        defaultMessage: "CENTRO_BENEFICIOS_ELIMINAR"
    }),
    'permission.FACTURAS_LISTAR': defineMessage({
        id: 'permission.FACTURAS_LISTAR',
        defaultMessage: "FACTURAS_LISTAR"
    }),
    'permission.FACTURAS_APROBAR': defineMessage({
        id: 'permission.FACTURAS_APROBAR',
        defaultMessage: "FACTURAS_APROBAR"
    }),
    'permission.FACTURAS_RECHAZAR': defineMessage({
        id: 'permission.FACTURAS_RECHAZAR',
        defaultMessage: "FACTURAS_RECHAZAR"
    }),
    'permission.FACTURAS_CREAR': defineMessage({
        id: 'permission.FACTURAS_CREAR',
        defaultMessage: "FACTURAS_CREAR"
    }),
    'permission.FACTURAS_VISUALIZAR': defineMessage({
        id: 'permission.FACTURAS_VISUALIZAR',
        defaultMessage: "FACTURAS_VISUALIZAR"
    }),
    'permission.FACTURAS_MODIFICAR': defineMessage({
        id: 'permission.FACTURAS_MODIFICAR',
        defaultMessage: "FACTURAS_MODIFICAR"
    }),
    'permission.FACTURAS_ELIMINAR': defineMessage({
        id: 'permission.FACTURAS_ELIMINAR',
        defaultMessage: "FACTURAS_ELIMINAR"
    }),
    'permission.FACTURAS_VER_PREFACTURADAS': defineMessage({
        id: 'permission.FACTURAS_VER_PREFACTURADAS',
        defaultMessage: "FACTURAS_VER_PREFACTURADAS"
    }),
    'permission.FACTURAS_EXPORTAR': defineMessage({
        id: 'permission.FACTURAS_EXPORTAR',
        defaultMessage: "FACTURAS_EXPORTAR"
    }),
    'permission.CATEGORIAS_VISUALIZAR': defineMessage({
        id: 'permission.CATEGORIAS_VISUALIZAR',
        defaultMessage: "CATEGORIAS_VISUALIZAR"
    }),
    'permission.CATEGORIAS_MODIFICAR': defineMessage({
        id: 'permission.CATEGORIAS_MODIFICAR',
        defaultMessage: "CATEGORIAS_MODIFICAR"
    }),
    'permission.CATEGORIAS_CREAR': defineMessage({
        id: 'permission.CATEGORIAS_CREAR',
        defaultMessage: "CATEGORIAS_CREAR"
    }),
    'permission.CATEGORIAS_ELIMINAR': defineMessage({
        id: 'permission.CATEGORIAS_ELIMINAR',
        defaultMessage: "CATEGORIAS_ELIMINAR"
    }),
    'permission.CATEGORIAS_LISTAR': defineMessage({
        id: 'permission.CATEGORIAS_LISTAR',
        defaultMessage: "CATEGORIAS_LISTAR"
    }),
    'permission.COMBUSTIBLES_LISTAR': defineMessage({
        id: 'permission.COMBUSTIBLES_LISTAR',
        defaultMessage: "COMBUSTIBLES_LISTAR"
    }),
    'permission.COMBUSTIBLES_CREAR': defineMessage({
        id: 'permission.COMBUSTIBLES_CREAR',
        defaultMessage: "COMBUSTIBLES_CREAR"
    }),
    'permission.COMBUSTIBLES_VISUALIZAR': defineMessage({
        id: 'permission.COMBUSTIBLES_VISUALIZAR',
        defaultMessage: "COMBUSTIBLES_VISUALIZAR"
    }),
    'permission.COMBUSTIBLES_LOG_VISUALIZAR': defineMessage({
        id: 'permission.COMBUSTIBLES_LOG_VISUALIZAR',
        defaultMessage: "COMBUSTIBLES_LOG_VISUALIZAR"
    }),
    'permission.COMBUSTIBLES_MODIFICAR': defineMessage({
        id: 'permission.COMBUSTIBLES_MODIFICAR',
        defaultMessage: "COMBUSTIBLES_MODIFICAR"
    }),
    'permission.COMBUSTIBLES_APROBAR': defineMessage({
        id: 'permission.COMBUSTIBLES_APROBAR',
        defaultMessage: "COMBUSTIBLES_APROBAR"
    }),
    'permission.COMBUSTIBLES_RECHAZAR': defineMessage({
        id: 'permission.COMBUSTIBLES_RECHAZAR',
        defaultMessage: "COMBUSTIBLES_RECHAZAR"
    }),
    'permission.COMBUSTIBLES_VALIDAR': defineMessage({
        id: 'permission.COMBUSTIBLES_VALIDAR',
        defaultMessage: "COMBUSTIBLES_VALIDAR"
    }),
    'permission.CALENDARIO_LISTAR': defineMessage({
        id: 'permission.CALENDARIO_LISTAR',
        defaultMessage: "CALENDARIO_LISTAR"
    }),
    'permission.USUARIO_ACTUAR_COMO_ESCRIBANO': defineMessage({
        id: 'permission.USUARIO_ACTUAR_COMO_ESCRIBANO',
        defaultMessage: "USUARIO_ACTUAR_COMO_ESCRIBANO"
    }),
    'permission.USUARIO_ACTUAR_COMO_GESTOR': defineMessage({
        id: 'permission.USUARIO_ACTUAR_COMO_GESTOR',
        defaultMessage: "USUARIO_ACTUAR_COMO_GESTOR"
    }),
    'permission.USUARIO_TRABAJAR_GESTORIA': defineMessage({
        id: 'permission.USUARIO_TRABAJAR_GESTORIA',
        defaultMessage: "USUARIO_TRABAJAR_GESTORIA"
    }),
    'permission.OT_LISTAR': defineMessage({
        id: 'permission.OT_LISTAR',
        defaultMessage: "OT_LISTAR"
    }),
    'permission.OT_CREAR': defineMessage({
        id: 'permission.OT_CREAR',
        defaultMessage: "OT_CREAR"
    }),
    'permission.OT_VISUALIZAR': defineMessage({
        id: 'permission.OT_VISUALIZAR',
        defaultMessage: "OT_VISUALIZAR"
    }),
    'permission.OT_MODIFICAR': defineMessage({
        id: 'permission.OT_MODIFICAR',
        defaultMessage: "OT_MODIFICAR"
    }),
    'permission.OT_ELIMINAR': defineMessage({
        id: 'permission.OT_ELIMINAR',
        defaultMessage: "OT_ELIMINAR"
    }),
    'permission.OT_EXPORTAR': defineMessage({
        id: 'permission.OT_EXPORTAR',
        defaultMessage: "OT_EXPORTAR"
    }),
    'permission.OT_IMPORTAR': defineMessage({
        id: 'permission.OT_IMPORTAR',
        defaultMessage: "OT_IMPORTAR"
    }),
    'permission.OT_COMPRAR_FORMULARIOS': defineMessage({
        id: 'permission.OT_COMPRAR_FORMULARIOS',
        defaultMessage: "OT_COMPRAR_FORMULARIOS"
    }),
    'permission.OT_ENVIAR_ESCRIBANIA': defineMessage({
        id: 'permission.OT_ENVIAR_ESCRIBANIA',
        defaultMessage: "OT_ENVIAR_ESCRIBANIA"
    }),
    'permission.OT_GESTIONAR': defineMessage({
        id: 'permission.OT_GESTIONAR',
        defaultMessage: "OT_GESTIONAR"
    }),
    'permission.OT_PASAR_PROVISORIO': defineMessage({
        id: 'permission.OT_PASAR_PROVISORIO',
        defaultMessage: "OT_PASAR_PROVISORIO"
    }),
    'permission.OT_CERRAR': defineMessage({
        id: 'permission.OT_CERRAR',
        defaultMessage: "OT_CERRAR"
    }),
    'permission.OT_CANCELAR': defineMessage({
        id: 'permission.OT_CANCELAR',
        defaultMessage: "OT_CANCELAR"
    }),
    'permission.INFRACCIONES_LISTAR': defineMessage({
        id: 'permission.INFRACCIONES_LISTAR',
        defaultMessage: "INFRACCIONES_LISTAR"
    }),
    'permission.MOSTRAR_BOTON_GOOGLE_PLAY': defineMessage({
        id: 'permission.MOSTRAR_BOTON_GOOGLE_PLAY',
        defaultMessage: "MOSTRAR_BOTON_GOOGLE_PLAY"
    }),
    'permission.INFRACCIONES_CREAR': defineMessage({
        id: 'permission.INFRACCIONES_CREAR',
        defaultMessage: "INFRACCIONES_CREAR"
    }),
    'permission.INFRACCIONES_MODIFICAR': defineMessage({
        id: 'permission.INFRACCIONES_MODIFICAR',
        defaultMessage: "INFRACCIONES_MODIFICAR"
    }),
    'permission.INFRACCIONES_VISUALIZAR': defineMessage({
        id: 'permission.INFRACCIONES_VISUALIZAR',
        defaultMessage: "INFRACCIONES_VISUALIZAR"
    }),
    'permission.INFRACCIONES_INFORMAR_PAGO': defineMessage({
        id: 'permission.INFRACCIONES_INFORMAR_PAGO',
        defaultMessage: "INFRACCIONES_INFORMAR_PAGO"
    }),
    'permission.INFRACCIONES_ELIMINAR': defineMessage({
        id: 'permission.INFRACCIONES_ELIMINAR',
        defaultMessage: "INFRACCIONES_ELIMINAR"
    }),
    'permission.INFRACCIONES_VALIDAR': defineMessage({
        id: 'permission.INFRACCIONES_VALIDAR',
        defaultMessage: "INFRACCIONES_VALIDAR"
    }),
    'permission.INFRACCIONES_ADJUNTAR': defineMessage({
        id: 'permission.INFRACCIONES_ADJUNTAR',
        defaultMessage: "INFRACCIONES_ADJUNTAR"
    }),
    'permission.TRANSACCIONES_LISTAR': defineMessage({
        id: 'permission.TRANSACCIONES_LISTAR',
        defaultMessage: "TRANSACCIONES_LISTAR"
    }),
    'permission.TRANSACCIONES_GENERAR': defineMessage({
        id: 'permission.TRANSACCIONES_GENERAR',
        defaultMessage: "TRANSACCIONES_GENERAR"
    }),
    'permission.GPS_DEVICES_LIST': defineMessage({
        id: 'permission.GPS_DEVICES_LIST',
        defaultMessage: "GPS_DEVICES_LIST"
    }),
    'permission.GPS_DEVICES_CREATE': defineMessage({
        id: 'permission.GPS_DEVICES_CREATE',
        defaultMessage: "GPS_DEVICES_CREATE"
    }),
    'permission.GPS_DEVICES_VIEW': defineMessage({
        id: 'permission.GPS_DEVICES_VIEW',
        defaultMessage: "GPS_DEVICES_VIEW"
    }),
    'permission.GPS_DEVICES_EDIT': defineMessage({
        id: 'permission.GPS_DEVICES_EDIT',
        defaultMessage: "GPS_DEVICES_EDIT"
    }),
    'permission.GPS_DEVICES_DELETE': defineMessage({
        id: 'permission.GPS_DEVICES_DELETE',
        defaultMessage: "GPS_DEVICES_DELETE"
    }),
    'permission.API_LOCATION_CREAR': defineMessage({
        id: 'permission.API_LOCATION_CREAR',
        defaultMessage: "API_LOCATION_CREAR"
    }),
    'permission.EXECUTE_CRON': defineMessage({
        id: 'permission.EXECUTE_CRON',
        defaultMessage: "EXECUTE_CRON"
    }),
    'permission.PANOL_LISTAR': defineMessage({
        id: 'permission.PANOL_LISTAR',
        defaultMessage: "PANOL_LISTAR"
    }),
    'permission.PANOL_PLANTILLAS_LISTAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_LISTAR',
        defaultMessage: "PANOL_PLANTILLAS_LISTAR"
    }),
    'permission.PANOL_CONFIGURACION_LISTAR': defineMessage({
        id: 'permission.PANOL_CONFIGURACION_LISTAR',
        defaultMessage: "PANOL_CONFIGURACION_LISTAR"
    }),
    'permission.PANOL_ALMACEN_CREAR': defineMessage({
        id: 'permission.PANOL_ALMACEN_CREAR',
        defaultMessage: "PANOL_ALMACEN_CREAR"
    }),
    'permission.PANOL_ALMACEN_INGRESAR_ITEM': defineMessage({
        id: 'permission.PANOL_ALMACEN_INGRESAR_ITEM',
        defaultMessage: "PANOL_ALMACEN_INGRESAR_ITEM"
    }),
    'permission.PANOL_ALMACEN_EGRESAR_ITEM': defineMessage({
        id: 'permission.PANOL_ALMACEN_EGRESAR_ITEM',
        defaultMessage: "PANOL_ALMACEN_EGRESAR_ITEM"
    }),
    'permission.PANOL_ALMACEN_TRANSFERIR': defineMessage({
        id: 'permission.PANOL_ALMACEN_TRANSFERIR',
        defaultMessage: "PANOL_ALMACEN_TRANSFERIR"
    }),
    'permission.PANOL_ALMACEN_AJUSTAR_STOCK': defineMessage({
        id: 'permission.PANOL_ALMACEN_AJUSTAR_STOCK',
        defaultMessage: "PANOL_ALMACEN_AJUSTAR_STOCK"
    }),
    'permission.PANOL_PLANTILLAS_VISUALIZAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_VISUALIZAR',
        defaultMessage: "PANOL_PLANTILLAS_VISUALIZAR"
    }),
    'permission.PANOL_PLANTILLAS_MODIFICAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_MODIFICAR',
        defaultMessage: "PANOL_PLANTILLAS_MODIFICAR"
    }),
    'permission.PANOL_PLANTILLAS_ELIMINAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_ELIMINAR',
        defaultMessage: "PANOL_PLANTILLAS_ELIMINAR"
    }),
    'permission.PANOL_PLANTILLAS_CREAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_CREAR',
        defaultMessage: "PANOL_PLANTILLAS_CREAR"
    }),
    'permission.PANOL_CONFIGURACION_ELIMINAR': defineMessage({
        id: 'permission.PANOL_CONFIGURACION_ELIMINAR',
        defaultMessage: "PANOL_CONFIGURACION_ELIMINAR"
    }),
    'permission.PANOL_CONFIGURACION_MODIFICAR': defineMessage({
        id: 'permission.PANOL_CONFIGURACION_MODIFICAR',
        defaultMessage: "PANOL_CONFIGURACION_MODIFICAR"
    }),
    'permission.PANOL_CONFIGURACION_CREAR': defineMessage({
        id: 'permission.PANOL_CONFIGURACION_CREAR',
        defaultMessage: "PANOL_CONFIGURACION_CREAR"
    }),
    'permission.PANOL_CATEGORIAS_MODIFICAR': defineMessage({
        id: 'permission.PANOL_CATEGORIAS_MODIFICAR',
        defaultMessage: "PANOL_CATEGORIAS_MODIFICAR"
    }),
    'permission.PANOL_CATEGORIAS_ELIMINAR': defineMessage({
        id: 'permission.PANOL_CATEGORIAS_ELIMINAR',
        defaultMessage: "PANOL_CATEGORIAS_ELIMINAR"
    }),
    'permission.PANOL_CATEGORIAS_CREAR': defineMessage({
        id: 'permission.PANOL_CATEGORIAS_CREAR',
        defaultMessage: "PANOL_CATEGORIAS_CREAR"
    }),
    'permission.PANOL_ITEMS_CREAR': defineMessage({
        id: 'permission.PANOL_ITEMS_CREAR',
        defaultMessage: "PANOL_ITEMS_CREAR"
    }),
    'permission.PANOL_MARCAS_CREAR': defineMessage({
        id: 'permission.PANOL_MARCAS_CREAR',
        defaultMessage: "PANOL_MARCAS_CREAR"
    }),
    'permission.PANOL_MARCAS_MODIFICAR': defineMessage({
        id: 'permission.PANOL_MARCAS_MODIFICAR',
        defaultMessage: "PANOL_MARCAS_MODIFICAR"
    }),
    'permission.PANOL_MARCAS_ELIMINAR': defineMessage({
        id: 'permission.PANOL_MARCAS_ELIMINAR',
        defaultMessage: "PANOL_MARCAS_ELIMINAR"
    }),
    'permission.PANOL_STOCK_AJUSTE_CREAR': defineMessage({
        id: 'permission.PANOL_STOCK_AJUSTE_CREAR',
        defaultMessage: "PANOL_STOCK_AJUSTE_CREAR"
    }),
    'permission.PANOL_STOCK_AJUSTE_ELIMINAR': defineMessage({
        id: 'permission.PANOL_STOCK_AJUSTE_ELIMINAR',
        defaultMessage: "PANOL_STOCK_AJUSTE_ELIMINAR"
    }),
    'permission.PANOL_STOCK_EGRESO_CREAR': defineMessage({
        id: 'permission.PANOL_STOCK_EGRESO_CREAR',
        defaultMessage: "PANOL_STOCK_EGRESO_CREAR"
    }),
    'permission.PANOL_STOCK_EGRESO_ELIMINAR': defineMessage({
        id: 'permission.PANOL_STOCK_EGRESO_ELIMINAR',
        defaultMessage: "PANOL_STOCK_EGRESO_ELIMINAR"
    }),
    'permission.PANOL_STOCK_CREAR': defineMessage({
        id: 'permission.PANOL_STOCK_CREAR',
        defaultMessage: "PANOL_STOCK_CREAR"
    }),
    'permission.PANOL_STOCK_ELIMINAR': defineMessage({
        id: 'permission.PANOL_STOCK_ELIMINAR',
        defaultMessage: "PANOL_STOCK_ELIMINAR"
    }),
    'permission.PANOL_STOCK_TRANSFERENCIA_CREAR': defineMessage({
        id: 'permission.PANOL_STOCK_TRANSFERENCIA_CREAR',
        defaultMessage: "PANOL_STOCK_TRANSFERENCIA_CREAR"
    }),
    'permission.PANOL_PLANTILLAS_DETALLE_CREAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_DETALLE_CREAR',
        defaultMessage: "PANOL_PLANTILLAS_DETALLE_CREAR"
    }),
    'permission.PANOL_PLANTILLAS_DETALLE_ELIMINAR': defineMessage({
        id: 'permission.PANOL_PLANTILLAS_DETALLE_ELIMINAR',
        defaultMessage: "PANOL_PLANTILLAS_DETALLE_ELIMINAR"
    }),
    'permission.PANOL_SUBCATEGORIAS_CREAR': defineMessage({
        id: 'permission.PANOL_SUBCATEGORIAS_CREAR',
        defaultMessage: "PANOL_SUBCATEGORIAS_CREAR"
    }),
    'permission.PANOL_SUBCATEGORIAS_MODIFICAR': defineMessage({
        id: 'permission.PANOL_SUBCATEGORIAS_MODIFICAR',
        defaultMessage: "PANOL_SUBCATEGORIAS_MODIFICAR"
    }),
    'permission.PANOL_SUBCATEGORIAS_ELIMINAR': defineMessage({
        id: 'permission.PANOL_SUBCATEGORIAS_ELIMINAR',
        defaultMessage: "PANOL_SUBCATEGORIAS_ELIMINAR"
    }),
    'permission.PANOL_MEDIDAS_VISUALIZAR': defineMessage({
        id: 'permission.PANOL_MEDIDAS_VISUALIZAR',
        defaultMessage: "PANOL_MEDIDAS_VISUALIZAR"
    }),
    'permission.PANOL_MEDIDAS_MODIFICAR': defineMessage({
        id: 'permission.PANOL_MEDIDAS_MODIFICAR',
        defaultMessage: "PANOL_MEDIDAS_MODIFICAR"
    }),
    'permission.PANOL_MEDIDAS_ELIMINAR': defineMessage({
        id: 'permission.PANOL_MEDIDAS_ELIMINAR',
        defaultMessage: "PANOL_MEDIDAS_ELIMINAR"
    }),
    'permission.PANOL_ITEMS_MODIFICAR': defineMessage({
        id: 'permission.PANOL_ITEMS_MODIFICAR',
        defaultMessage: "PANOL_ITEMS_MODIFICAR"
    }),
    'permission.PANOL_ITEMS_ELIMINAR': defineMessage({
        id: 'permission.PANOL_ITEMS_ELIMINAR',
        defaultMessage: "PANOL_ITEMS_ELIMINAR"
    }),
    'permission.ROPS_LISTAR': defineMessage({
        id: 'permission.ROPS_LISTAR',
        defaultMessage: "ROPS_LISTAR"
    }),
    'permission.ROPS_CREAR': defineMessage({
        id: 'permission.ROPS_CREAR',
        defaultMessage: "ROPS_CREAR"
    }),
    'permission.ROPS_VISUALIZAR': defineMessage({
        id: 'permission.ROPS_VISUALIZAR',
        defaultMessage: "ROPS_VISUALIZAR"
    }),
    'permission.ROPS_RECOVERY': defineMessage({
        id: 'permission.ROPS_RECOVERY',
        defaultMessage: "ROPS_RECOVERY"
    }),
    'permission.ROP_LOG_VISUALIZAR': defineMessage({
        id: 'permission.ROP_LOG_VISUALIZAR',
        defaultMessage: "ROP_LOG_VISUALIZAR"
    }),
    'permission.GASTOS_LISTAR': defineMessage({
        id: 'permission.GASTOS_LISTAR',
        defaultMessage: "GASTOS_LISTAR"
    }),
    'permission.GASTOS_VISUALIZAR': defineMessage({
        id: 'permission.GASTOS_VISUALIZAR',
        defaultMessage: "GASTOS_VISUALIZAR"
    }),
    'permission.GASTOS_EDITAR': defineMessage({
        id: 'permission.GASTOS_EDITAR',
        defaultMessage: "GASTOS_EDITAR"
    }),
    'permission.GASTOS_CREAR': defineMessage({
        id: 'permission.GASTOS_CREAR',
        defaultMessage: "GASTOS_CREAR"
    }),
    'permission.GASTOS_LISTAR_HISTORICO': defineMessage({
        id: 'permission.GASTOS_LISTAR_HISTORICO',
        defaultMessage: "GASTOS_LISTAR_HISTORICO"
    }),
    'permission.GASTOS_ELIMINAR': defineMessage({
        id: 'permission.GASTOS_ELIMINAR',
        defaultMessage: "GASTOS_ELIMINAR"
    }),
    'permission.GASTOS_TIPO_LISTAR': defineMessage({
        id: 'permission.GASTOS_TIPO_LISTAR',
        defaultMessage: "GASTOS_TIPO_LISTAR"
    }),
    'permission.GASTOS_TIPO_VISUALIZAR': defineMessage({
        id: 'permission.GASTOS_TIPO_VISUALIZAR',
        defaultMessage: "GASTOS_TIPO_VISUALIZAR"
    }),
    'permission.GASTOS_TIPO_EDITAR': defineMessage({
        id: 'permission.GASTOS_TIPO_EDITAR',
        defaultMessage: "GASTOS_TIPO_EDITAR"
    }),
    'permission.GASTOS_TIPO_CREAR': defineMessage({
        id: 'permission.GASTOS_TIPO_CREAR',
        defaultMessage: "GASTOS_TIPO_CREAR"
    }),
    'permission.GASTOS_TIPO_ELIMINAR': defineMessage({
        id: 'permission.GASTOS_TIPO_ELIMINAR',
        defaultMessage: "GASTOS_TIPO_ELIMINAR"
    }),
    'combustiblesGridNew.kpis_card.cargas_validas': defineMessage({
        id: 'combustiblesGridNew.kpis_card.cargas_validas',
        defaultMessage: "Cargas Válidas"
    }),
    'combustiblesGridNew.kpis_card.cargas_sin_comprobacion': defineMessage({
        id: 'combustiblesGridNew.kpis_card.cargas_sin_comprobacion',
        defaultMessage: "Cargas Sin Comprobación"
    }),
    'combustiblesGridNew.kpis_card.cantidad_cargas_invalidas': defineMessage({
        id: 'combustiblesGridNew.kpis_card.cantidad_cargas_invalidas',
        defaultMessage: "Cantidad de Cargas Inválidas"
    }),
    'combustiblesGridNew.kpis_card.vh_con_cargas_invalidas': defineMessage({
        id: 'combustiblesGridNew.kpis_card.vh_con_cargas_invalidas',
        defaultMessage: "Vh. con Cargas Inválidas"
    }),
    'combustiblesGridNew.kpis_card.monto_cargas_invalidas': defineMessage({
        id: 'combustiblesGridNew.kpis_card.monto_cargas_invalidas',
        defaultMessage: "Monto Cargas Inválidas"
    }),
    'combustiblesGridNew.kpis_card.litros_cargas_invalidas': defineMessage({
        id: 'combustiblesGridNew.kpis_card.litros_cargas_invalidas',
        defaultMessage: "Litros Cargas Inválidas"
    }),
    'formularioController.errors.checklist_no_procesado_dado_vehiculo_con_ROP_correctivo_pendiente': defineMessage({
        id: 'formularioController.errors.checklist_no_procesado_dado_vehiculo_con_ROP_correctivo_pendiente',
        defaultMessage: "No se ha procesado el checklist dado que este vehículo cuenta con un ROP correctivo pendiente. Para poder crear el checklist primero se deberá solucionar el ROP en curso."
    }),
    'infracciones.errors.acta_duplicada': defineMessage({
        id: 'infracciones.errors.acta_duplicada',
        defaultMessage: 'No se puede realizar la acción. El número de acta ya existe.'
    }),
    'ticketsRepository.errors.encabezado_no_esta_en_fila_correcta': defineMessage({
        id: 'ticketsRepository.errors.encabezado_no_esta_en_fila_correcta',
        defaultMessage: "Encabezado no esta en fila correcta"
    }),
    'ticketsRepository.errors.archivo_no_cumple_formato_faltan_columnas': defineMessage({
        id: 'ticketsRepository.errors.archivo_no_cumple_formato_faltan_columnas',
        defaultMessage: "Archivo no cumple formato o faltan columnas"
    }),
    'ticketsRepository.errors.no_se_pudieron_leer_columnas_del_archivo': defineMessage({
        id: 'ticketsRepository.errors.no_se_pudieron_leer_columnas_del_archivo',
        defaultMessage: "No se ppudieron leer las oclumnas del archivo"
    }),
    'ticketsRepository.errors.el_movil_es_requerido': defineMessage({
        id: 'ticketsRepository.errors.el_movil_es_requerido',
        defaultMessage: "El móvil es requerido"
    }),
    'ticketsRepository.errors.el_tipo_servicio_es_requerido': defineMessage({
        id: 'ticketsRepository.errors.el_tipo_servicio_es_requerido',
        defaultMessage: "Tipo de servicio es requerido"
    }),
    'ticketsRepository.errors.el_servicio_es_requerido': defineMessage({
        id: 'ticketsRepository.errors.el_servicio_es_requerido',
        defaultMessage: "El servicio es requerido"
    }),
    'ticketsRepository.errors.fecha_ticket_requerida': defineMessage({
        id: 'ticketsRepository.errors.fecha_ticket_requerida',
        defaultMessage: "Fecha de ticket requerida"
    }),
    'ticketsRepository.errors.fecha_realizado_requerida': defineMessage({
        id: 'ticketsRepository.errors.fecha_realizado_requerida',
        defaultMessage: "Fecha de realizado requerida"
    }),
    'ticketsRepository.errors.gerenciador_requerido': defineMessage({
        id: 'ticketsRepository.errors.gerenciador_requerido',
        defaultMessage: "El gerenciador es requerido"
    }),
    'ticketsRepository.errors.movil_no_existe': defineMessage({
        id: 'ticketsRepository.errors.movil_no_existe',
        defaultMessage: "No existe el móvil con ese dominio "
    }),
    'ticketsRepository.errors.tipo_servicio_no_valido': defineMessage({
        id: 'ticketsRepository.errors.tipo_servicio_no_valido',
        defaultMessage: "El tipo de servicio debe ser CORRECTIVO O PREVENTIVO"
    }),
    'ticketsRepository.errors.tipo_servicio_no_encontrado': defineMessage({
        id: 'ticketsRepository.errors.tipo_servicio_no_encontrado',
        defaultMessage: "No se encontró el tipo de servicio"
    }),
    'ticketsRepository.errors.tipo_servicio_y_tarea_no_asociadas': defineMessage({
        id: 'ticketsRepository.errors.tipo_servicio_y_tarea_no_asociadas',
        defaultMessage: "Una o varias tareas no estan asociadas al servicio"
    }),
    'ticketsRepository.errors.tarea_no_encontrada': defineMessage({
        id: 'ticketsRepository.errors.tarea_no_encontrada',
        defaultMessage: "Una o varias tareas no encontradas"
    }),
    'ticketsRepository.errors.formato_fecha_ticket_invalida': defineMessage({
        id: 'ticketsRepository.errors.formato_fecha_ticket_invalida',
        defaultMessage: "La fecha del ticket no está en un formato válido"
    }),
    'ticketsRepository.errors.formato_fecha_hora_realizado_invalida': defineMessage({
        id: 'ticketsRepository.errors.formato_fecha_hora_realizado_invalida',
        defaultMessage: "La fecha de realizado del ticket no está en un formato válido"
    }),
    'ticketsRepository.errors.formato_fecha_turno_invalida': defineMessage({
        id: 'ticketsRepository.errors.formato_fecha_turno_invalida',
        defaultMessage: "La fecha del turno no está en un formato válido"
    }),
    'ticketsRepository.errors.formato_fecha_presupuesto_invalida': defineMessage({
        id: 'ticketsRepository.errors.formato_fecha_presupuesto_invalida',
        defaultMessage: "La fecha del presupuesto no está en un formato válido"
    }),
    'ticketsRepository.errors.presupuesto_mano_obra_requerido': defineMessage({
        id: 'ticketsRepository.errors.presupuesto_mano_obra_requerido',
        defaultMessage: "El Presupuesto de mano de obra es requerido"
    }),
    'ticketsRepository.errors.fecha_realizado_menor_ticket': defineMessage({
        id: 'ticketsRepository.errors.fecha_realizado_menor_ticket',
        defaultMessage: "La fecha Realizado debe ser mayor o igual a la fecha del ticket"
    }),
    'ticketsRepository.errors.fecha_turno_menor_igual_fecha_actual': defineMessage({
        id: 'ticketsRepository.errors.fecha_turno_menor_igual_fecha_actual',
        defaultMessage: "La fecha del turno debe ser menor o igual a la fecha actual"
    }),
    'ticketsRepository.errors.km_generado_numerico': defineMessage({
        id: 'ticketsRepository.errors.km_generado_numerico',
        defaultMessage: "El Km generado debe ser numérico"
    }),
    'ticketsRepository.errors.km_realizado_numerico': defineMessage({
        id: 'ticketsRepository.errors.km_realizado_numerico',
        defaultMessage: "El Km realizado debe ser numérico"
    }),
    'ticketsRepository.errors.km_realizado_mayor_generado': defineMessage({
        id: 'ticketsRepository.errors.km_realizado_mayor_generado',
        defaultMessage: "El Km realizado debe ser mayor o igual al generado"
    }),
    'ticketsRepository.errors.gerenciador_no_existe': defineMessage({
        id: 'ticketsRepository.errors.gerenciador_no_existe',
        defaultMessage: "El gerenciador no existe o no esta activo"
    }),
    'ticketsRepository.errors.taller_no_existe': defineMessage({
        id: 'ticketsRepository.errors.taller_no_existe',
        defaultMessage: "El taller no existe o no está activo"
    }),
    'ticketsRepository.errors.no_vinculado_gerenciador': defineMessage({
        id: 'ticketsRepository.errors.no_vinculado_gerenciador',
        defaultMessage: "El taller No está vinculado al Gerenciador"
    }),
    'ticketsRepository.errors.fecha_ticket_mayor_actual': defineMessage({
        id: 'ticketsRepository.errors.fecha_ticket_mayor_actual',
        defaultMessage: "La fecha del ticket debe ser menor o igual a la fecha del día"
    }),
    'ticketsRepository.errors.la_fecha_del_presupuesto_debe_ser_menor_a_fecha_de_hoy': defineMessage({
        id: 'ticketsRepository.errors.la_fecha_del_presupuesto_debe_ser_menor_a_fecha_de_hoy',
        defaultMessage: "La fecha del presupuesto debe ser menor o igual a la fecha del día"
    }),
    'ticketsRepository.errors.el_presupuesto_mano_obra_debe_ser_numerico': defineMessage({
        id: 'ticketsRepository.errors.el_presupuesto_mano_obra_debe_ser_numerico',
        defaultMessage: "El presupuesto de mano de obra debe ser numérico"
    }),
    'ticketsRepository.errors.el_presupuesto_repuestos_debe_ser_numerico': defineMessage({
        id: 'ticketsRepository.errors.el_presupuesto_repuestos_debe_ser_numerico',
        defaultMessage: "El presupuesto de repuestos debe ser numérico"
    }),
    'ticketsRepository.errors.los_impuestos_presupuesto_deben_ser_numericos': defineMessage({
        id: 'ticketsRepository.errors.los_impuestos_presupuesto_deben_ser_numericos',
        defaultMessage: "Los impuestos del presupuesto deben ser numéricos"
    }),
    'ticketsRepository.errors.el_total_presupuesto_debe_ser_numerico': defineMessage({
        id: 'ticketsRepository.errors.el_total_presupuesto_debe_ser_numerico',
        defaultMessage: "El total del presupuesto debe ser numérico"
    }),
    'ticketsRepository.errors.el_total_presupuesto_debe_ser_suma_componentes': defineMessage({
        id: 'ticketsRepository.errors.el_total_presupuesto_debe_ser_suma_componentes',
        defaultMessage: "El total del presupuesto debe ser igual a la suma de Mano de Obra + Repuestos + Impuestos"
    }),
    'ticketsRepository.errors.no_se_guardo_ticket_cerrado': defineMessage({
        id: 'ticketsRepository.errors.no_se_guardo_ticket_cerrado',
        defaultMessage: "No se guardó el ticket cerrado"
    }),
    'ticketsRepository.errors.tarea_no_asociada': defineMessage({
        id: 'ticketsRepository.errors.tarea_no_asociada',
        defaultMessage: "La tarea no está asociada con el servicio"
    }),
    'ticketsRepository.errors.ticket_ya_registrado': defineMessage({
        id: 'ticketsRepository.errors.ticket_ya_registrado',
        defaultMessage: "Ya existe un ticket registrado"
    }),
    'ticketsRepository.errors.impuestos_no_numericos': defineMessage({
        id: 'ticketsRepository.errors.impuestos_no_numericos',
        defaultMessage: "Los impuiestos del presupuesto debe ser numérico"
    }),
    'ticketsRepository.errors.total_no_numerico': defineMessage({
        id: 'ticketsRepository.errors.total_no_numerico',
        defaultMessage: "El total del presupuesto debe ser numérico"
    }),
    'ticketsRepository.errors.ticket_no_guardado': defineMessage({
        id: 'ticketsRepository.errors.ticket_no_guardado',
        defaultMessage: "No se guardó el ticket cerrado"
    }),
    'ticketsRepository.errors.ticket_ya_existente': defineMessage({
        id: 'ticketsRepository.errors.ticket_ya_existente',
        defaultMessage: "Ya existe un ticket registrado"
    }),
    'ticketsRepository.errors.fecha_realizado_menor_igual_fecha_actual': defineMessage({
        id: 'ticketsRepository.errors.fecha_realizado_menor_igual_fecha_actual',
        defaultMessage: "La fecha de realizado debe ser menor o igual a la fecha actual"
    }),
    'ticketsRepository.errors.archivo_sin_datos': defineMessage({
        id: 'ticketsRepository.errors.archivo_sin_datos',
        defaultMessage: "El archivo no contiene datos"
    }),
    'ticketsRepository.errors.debe_ser_s_n': defineMessage({
        id: 'ticketsRepository.errors.debe_ser_s_n',
        defaultMessage: "Es sinisetro debe tener un valor S o N"
    }),
    'ticketsRepository.errors.nro_siniestro_sin_casilla_s': defineMessage({
        id: 'ticketsRepository.errors.nro_siniestro_sin_casilla_s',
        defaultMessage: "Se registro nro de sinistro pero no se activo casilla Es Siniestro"
    }),
    'formulario.mesnsajes.checklist_cerrado': defineMessage({
        id: 'formulario.mesnsajes.checklist_cerrado',
        defaultMessage: "Checklist Cerrado"
    }),
    'División_no_existe': defineMessage({
        id: 'División_no_existe',
        defaultMessage: "División no existe"
    }),
    'División_requeridad': defineMessage({
        id: 'División_requeridad',
        defaultMessage: "División requeridad"
    }),
    'No_se_pudo_eliminar_la_división_tiene_moviles_asignados': defineMessage({
        id: 'No_se_pudo_eliminar_la_división_tiene_moviles_asignados',
        defaultMessage: "No se pudo eliminar la división, tiene moviles asignados"
    }),
    'Debes_ingresar_al_menos_una_tarea_o_un_repuesto': defineMessage({
        id: 'Debes_ingresar_al_menos_una_tarea_o_un_repuesto',
        defaultMessage: "Debes ingresar al menos una tarea o un repuesto"
    })
}
