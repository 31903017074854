import React, { useState } from "react";
import {FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  Modal,
  Backdrop,
  Grow,
} from '@material-ui/core'
import swal from 'sweetalert'
import MovilesImportar from './MovilesImportar'
import MovilesMedidorImportar from './MovilesMedidorImportar'
import MovilesImportarReporte from './MovilesImportarReporte'
import $ from 'jquery'

const MovilesGridImport = (props) => {
  const [loading, setLoading] = useState(false)
  const intl = props.intl

  const setModalImport = props.setModalImport
  const setModalImportMedidor = props.setModalImportMedidor

  const [importReportModal,setImportReportModal] = useState(false)
  const [importReportMedidorModal,setImportReportMedidorModal] = useState(false)
  const [reporteImportacion, setReporteImportacion] = useState(null)
  const [mensajeImportacion, setMensajeImportacion] = useState('')

	const handleCancelModal = (message, subtitle, error) => {
		setModalImport(false)
	}

  const handleCloseModal = (message, filasFallaron, error) => {
		setModalImport(false)
		console.log(message);
		console.log(error);
		if (message !== "" && error) {
			if (error.codigo === 4010) {
				swal(intl.formatMessage({ id: 'movilesGrid.alert.sesion_expiro', defaultMessage: 'La sesión expiró' }), intl.formatMessage({ id: 'movilesGrid.alert.ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' }), "error").then(()=>{
					window.location.reload();
				});
			} else if (error.codigo) {
				swal(intl.formatMessage({ id: 'movilesGrid.alert.error', defaultMessage: 'Error' }), message, "error");
			} else {
				swal(message, error, "error");
			}
		} else {
			if(filasFallaron.filas_fallaron.length == 0){
				swal(message, "", "success").then((data) => {
          // Recargar Grilla Moviles
          props.setStateImport(true)
				});
			} else{
        setReporteImportacion(filasFallaron.filas_fallaron)
        setMensajeImportacion(message)
        setImportReportModal(true)
				// this.setState({reporteImportacion: filasFallaron.filas_fallaron, mensajeImportacion:message}, ()=>{
				// 	$('#import_report_modal').modal({ backdrop: 'static' });
				// });
			}
		}
	}

  const handleReportCloseModal = () => {
		setImportReportModal(false)
		// this.table.ajax.reload();
	}

  const handleCancelMedidorModal = (message, subtitle, error) => {
		setModalImportMedidor(false)
	}

  const handleCloseMedidorModal = (message, filasFallaron, error) => {
		setModalImportMedidor(false)
		if (message !== "" && error) {
			if (error.codigo === 4010) {
				swal(intl.formatMessage({ id: 'movilesGrid.alert.sesion_expiro', defaultMessage: 'La sesión expiró' }), intl.formatMessage({ id: 'movilesGrid.alert.ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' }), "error").then(()=>{
					window.location.reload();
				});
			} else if (error.codigo) {
				swal(intl.formatMessage({ id: 'movilesGrid.alert.error', defaultMessage: 'Error' }), message, "error");
			} else {
				swal(message, error, "error");
			}
		} else {
			if(filasFallaron.filas_fallaron.length == 0){
				swal(message, "", "success").then((data) => {
          // Recargar Grilla Moviles
          props.setStateImport(true)        
				});
			} else{
        setReporteImportacion(filasFallaron.filas_fallaron)
        setMensajeImportacion(message)
        setImportReportMedidorModal(true)
				// this.setState({reporteImportacion: filasFallaron.filas_fallaron, mensajeImportacion:message}, ()=>{
				// 	$('#import_report_medidor_modal').modal({ backdrop: 'static' });
				// });
			}
		}
	}

  const handleReportCloseMedidorModal = () =>{
    setImportReportMedidorModal(false)
		// this.table.ajax.reload();
	}


  const handleExportError = () => {
		// let component = this;
		// this.setState({loading: true});
    let auxReporteImportacion = JSON.stringify({
      dataToExport: reporteImportacion[1]
    })

    Utils.sendDataBlob('/moviles/exportar-errores-medidor','POST',auxReporteImportacion)
      .then((data) => {
        let fileBlob = new File([data],'errores-medidor.xlsx')
        let fileUrl = URL.createObjectURL(fileBlob);
        $("<a />", {
          href: fileUrl,
          download: "errores-medidor.xlsx"
        }).appendTo("body").get(0).click();
      })
      .catch(() => {
			})	
      .finally(() => {
        handleReportCloseMedidorModal();
        // this.setState({loading: false});
      });
	}

  return(
    <>
      {/* MODAL IMPORTS VEHÍCULOS */}

      <Modal open={props.openImport} 
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Grow in={props.openImport}>
          <div className="col-8" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel1">
                    <FormattedMessage id="movilesGrid.tools.import.button.importar" defaultMessage=" Importar"/>
                  </h4>
                  <button type="button" className="close" onClick={()=>handleCancelModal()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey">
                  <form className="form">
                    <MovilesImportar callbackClose={handleCloseModal} callbackCancel={handleCancelModal}/>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Grow>
      </Modal>
      {/* Errors */}
      <Modal open={importReportModal} 
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
        >
          <Grow in={importReportModal}>
            <div className="col-12" tabIndex="-1" role="dialog" aria-labelledby="importReportLabel" style={{ margin: "auto" }}>
              <div className="modal-lg modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-fleet">
                    <h4 className="modal-title text-white" id="importReportLabel">Reporte de la Importación</h4>
                    <button type="button" className="close" onClick={()=>handleReportCloseModal()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  </div>
                  <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                    {reporteImportacion ?
                    <MovilesImportarReporte data={reporteImportacion} callbackReportClose={handleReportCloseModal}></MovilesImportarReporte	>
                    : null}
                  </div>
                  <div className="modal-footer modal-grey">
                    <button type="button" className="btn btn-default btn-fleet" onClick={()=>handleReportCloseModal()}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </Grow>
      </Modal>

      {/* MODAL IMPORT MEDIDORES */}
      <Modal open={props.openImportMedidor} 
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
        >
          <Grow in={props.openImportMedidor}>
            <div className="" tabIndex="-1" id="import_medidor_modal" role="dialog" aria-labelledby="myMedidorModalLabel1" style={{ margin: "auto" }}>
              <div className="modal-xl modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-fleet">
                    <h4 className="modal-title text-white" id="myMedidorModalLabel1">
                      <FormattedMessage id="movilesGrid.tools.import.button.medidor_importar" defaultMessage=" Importar"/>
                    </h4>
                    <button type="button" className="close" onClick={()=>handleCancelMedidorModal()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  </div>
                  <div className="modal-body">
                    <form className="form">
                      <MovilesMedidorImportar callbackClose={handleCloseMedidorModal} callbackCancel={handleCancelMedidorModal}/>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Grow>
      </Modal>
      {/* Erros */}
      <Modal open={importReportMedidorModal} 
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
        >
          <Grow in={importReportMedidorModal}>
            <div className="col-12" tabIndex="-1" id="import_report_medidor_modal" role="dialog" aria-labelledby="importMedidorReportLabel" style={{ margin: "auto" }}>
              <div className="modal-lg modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-fleet">
                    <h4 className="modal-title text-white" id="importMedidorReportLabel">Reporte de la Importación</h4>
                    <button type="button" className="close" onClick={()=>handleReportCloseMedidorModal()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  </div>
                  <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                    {reporteImportacion ?
                      <MovilesImportarReporte data={reporteImportacion} callbackReportClose={handleReportCloseMedidorModal}></MovilesImportarReporte>
                    : null}
                  </div>
                  <div className="modal-footer modal-grey">
                    <button type="button" className="btn btn-primary btn-fleet" onClick={() => handleExportError()}>Descargar Reporte</button>
                    <button type="button" className="btn btn-default btn-fleet" onClick={()=>handleReportCloseMedidorModal()}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </Grow>
      </Modal>
    </>
  )
}
export default MovilesGridImport