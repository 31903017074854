import React, { Component } from 'react'
import ExtraBasic from './ExtraBasic.js'
import { WithContext as ReactTags } from 'react-tag-input';
import * as Constants from '../constants.js'
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ExtraSiNo extends ExtraBasic {
	constructor(props) {
    const ESTADOS_POSIBLES = JSON.parse(JSON.stringify(Object.values(Constants.ESTADOS_APROBACION)))
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      checks: ESTADOS_POSIBLES
    }
  }

	componentDidMount() {
		if (this.props.extraParams && this.props.extraParams.checks) {
      this.handleChangeAndUpdateFather(this.props.extraParams);
    } else {
      this.handleChangeAndUpdateFather(this.state);
    }
	}

  handleInputChange(event, index) {
		const target = event.target;
		const value = target.value;
    this.state.checks[index].text = value
		this.handleChangeAndUpdateFather(this.state)
	}


	render() {
      return (
        <React.Fragment>
				<div className="form-group row">
					{/* Opciones */}
					<label className="col-md-3 label-control col-form-label" htmlFor="default">
						Checklist:
					</label>
					<div className="col-md-9">
            {this.state.checks.map((check, index) =>
              <div className="input-group" key={index}>
                <span className="input-group-addon"><i className={Constants.ESTADOS_APROBACION[check.estado].icon}></i></span>
                <input
                disabled={this.props.disabled}
                type="text"
                className="form-control"
                value={check.text}
                onChange={event => this.handleInputChange(event, index)} />
              </div>
            )}
					</div>
				</div>
        </React.Fragment>
      )
  }
}

export default ExtraSiNo;
