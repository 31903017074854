import React, { Component } from 'react'
import * as Constants from '../constants.js'
import InstanciaBasic from './InstanciaBasic.js'

class InstanciaInput extends InstanciaBasic {
	constructor(props) {
    super(props);
  }

	render() {
      return (
        <React.Fragment>
					<input
						disabled={this.props.disabled}
						type="text"
						className="form-control"
						value={this.props.atributo[this.valueKey]}
						onChange={this.handleInputChange}
						maxLength={Constants.MAX_LENGTH_TEXT}
					/>
        </React.Fragment>
      )
  }
}

export default InstanciaInput;
