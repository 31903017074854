import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import 'moment/min/locales'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class TareasAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();
    
		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        nombre: '',
        activo: true,
        servicios: [],
        topeAprobacion: 0,
        precio: 0,
        externalCode: ''
      },
      servicios: [],
      serviciosLoading: false,
      estados: [{ id: 1, nombre: 'Activo'},{ id: 0, nombre: 'Inactivo'}],
      errors: [],
      showExternalCode: ConfigBusiness.get('frontend.showExternalCode') == 'true' ? true : false,
      
			loading: false
    };
    
    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleServicioChange = this.handleServicioChange.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);

    let validators = {
      'formData.nombre': (value) => Validator.notEmpty(value),
      'formData.servicios': (value) => Validator.notEmpty(value)
    }

    if(ConfigBusiness.get('tareas.montoAutoAprobacion.habilitado') === 'true'){
      validators['formData.topeAprobacion'] = (value) => Validator.notEmpty(value)
    }

    this.formValidation = new FormValidation({
      component: this,			
			validators: validators
    });
    
  }


  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('TAREAS_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('TAREAS_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('TAREAS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
  }
  initForm() {
		this.setState({ loading: true });
    let component = this;
    
		Promise.all([
      this.ajaxHandler.getJson('/servicios/select',null),
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/tareas/' + this.state.props.match.params.id) : null,
		]).then((data) => {
      let servicios = data[0];
      let formData = data[1];

      // Servicios asociados a la tarea
      if(formData && formData.servicios){
        let FDServicios = formData.servicios;
        formData.servicios = FDServicios.map(servicio => {
          if (this.state.showExternalCode) {
            return {"value": servicio.id, "label": servicio.external_code ? servicio.external_code+' - '+servicio.nombre : servicio.nombre}
          } else {
            return {"value": servicio.id, "label": servicio.nombre}
          }
        });
      }
      component.setState({
        servicios: servicios
			});
			if(formData) component.setState({
				formData: formData
			});

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
    
	}
  /*
  getData(service, param) {
    let serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
		return fetch(serviceURL, {
      method: 'GET', 
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  */
   
  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }
  

  handleServicioChange(servicio) {

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.servicios = servicio;
		this.setState({
			formData: formDataCopy		
		});
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    this.ajaxHandler.fetch('/tareas' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {
        
        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
    
	}


	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/tareas'
		});
	}

	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;

    let state = this.state;
   
		return (

			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}				
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">      
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="tareasAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="tareasAbm.render.general_data.campos_requeridos" defaultMessage="campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* Tarea */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="tareasAbm.render.general_data.label_tarea" defaultMessage="Tarea "/>*:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'tareasAbm.render.general_data.placeholder_tarea', defaultMessage: 'Tarea' })} value={formData.nombre} onChange={this.handleInputFormChange} />   
                                <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                              </div>
                              )}															
                            </div>
                          </div>
                        </div>
                        
                        {/* External Code */}
                        {this.state.showExternalCode && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="externalCode">
                              <FormattedMessage id="tareasAbm.render.general_data.label_external_code" defaultMessage="External Code "/>:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.externalCode}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="externalCode" name="externalCode" placeholder={this.props.intl.formatMessage({ id: 'tareasAbm.render.general_data.placeholder_external_code', defaultMessage: '' })} value={formData.externalCode} onChange={this.handleInputFormChange} />   
                              </div>
                              )}															
                            </div>
                          </div>
                        </div>
                        )}
                      </div>
                      
                      <div className="row">
                      {/* Servicio */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="servicios">
                              <FormattedMessage id="tareasAbm.render.general_data.label_servicios" defaultMessage="Servicios "/>*:
                            </label>
                            <div className="col-md-9">
                              <div>
                                <Select
                                  id="servicios"
                                  name="servicios"
                                  placeholder={!state.serviciosLoading ? this.props.intl.formatMessage({ id: 'tareasAbm.render.general_data.placeholder_servicios', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.servicios}
                                  valueKey='value'
                                  labelKey='label'
                                  value={this.state.formData.servicios}
                                  onChange={(e) => this.handleServicioChange(e)}
                                  isLoading={state.serviciosLoading}
                                  disabled={state.serviciossLoading || this.props.action === 'VIEW'}
                                  multi={true}
                                  removeSelected={true}
                                  closeMenuOnSelect={true}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.servicios.pristine || validationState.formData.servicios.valid}>{validationState.formData.servicios.message}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                        {/* Tope Aprobacion */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('tareas.montoAutoAprobacion.habilitado') === 'true' ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="topeAprobacion">
                              <FormattedMessage id="tareasAbm.render.general_data.label_monto_max_aprobacion" defaultMessage="Monto Max. Aprobación:"/>*
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.topeAprobacion}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="topeAprobacion" name="topeAprobacion" placeholder={this.props.intl.formatMessage({ id: 'tareasAbm.render.general_data.placeholder_monto_max_aprobacion', defaultMessage: 'Monto Max. de Aprobación' })} value={formData.topeAprobacion} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Precio */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('tareas.precio.habilitado') === 'true' ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="precio">
                              <FormattedMessage id="tareasAbm.render.general_data.precio" defaultMessage="Precio:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.precio}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="precio" name="precio" placeholder={this.props.intl.formatMessage({ id: 'tareasAbm.render.general_data.placeholder_precio', defaultMessage: 'Precio' })} value={formData.precio} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="tareasAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">     
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="tareasAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'tareasAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'tareasAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TareasAbm);