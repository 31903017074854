import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'
import * as Constants from '../constants.js'

class InstanciaNumberRange extends InstanciaBasic {
	constructor(props) {
    super(props);
		this.recalcularEstado = this.recalcularEstado.bind(this);
		this.castValue = this.castValue.bind(this);

  }

	recalcularEstado(value) {
		value = this.castValue(value)
		let rango = this.props.atributo.extraParams.range
		if (value && rango) {
			if (rango.max && value > rango.max)
				return Constants.ID_ESTADO_APROBACION_INVALIDO

			if (rango.min && value < rango.min)
				return Constants.ID_ESTADO_APROBACION_INVALIDO

			return Constants.ID_ESTADO_APROBACION_VALIDO
		}
		else
			return Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION
	}

	castValue(value) {
		if (value) {
			if (isNaN(value))
				return 0
			else
				return Number(value)
		} else
			return ''
	}

	render() {
      return (
        <React.Fragment>
					<input
						disabled={this.props.disabled}
						type="number"
						className="form-control"
						value={this.props.atributo[this.valueKey]}
						onChange={this.handleInputChange}
					/>
        </React.Fragment>
      )
  }
}

export default InstanciaNumberRange;
