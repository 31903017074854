import React, { Component } from 'react'
import ExtraBasic from './ExtraBasic.js'
import { WithContext as ReactTags } from 'react-tag-input';
import * as Constants from '../constants.js'
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ExtraSiNo extends ExtraBasic {
	constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      range: {
          max: '',
          min: ''
      }
    }
  }

	componentDidMount() {
		if (this.props.extraParams && this.props.extraParams.range) {
      this.handleChangeAndUpdateFather(this.props.extraParams);
    } else {
      this.handleChangeAndUpdateFather(this.state);
    }
	}

  handleInputChange(event, index) {
		const target = event.target;
		const value = target.value;
    this.state.range[index] = value
		this.handleChangeAndUpdateFather(this.state)
	}


	render() {
      return (
        <React.Fragment>
				<div className="form-group row">
					<label className="col-md-3 label-control col-form-label" htmlFor="default">
						Rango de aprobación:
					</label>
          <div className="col-md-9">
            <input
              disabled={this.props.disabled}
              type="text"
              placeholder="Min"
              className="form-control"
              value={this.state.range.min}
              onChange={event => this.handleInputChange(event, 'min')} />
            <input
              disabled={this.props.disabled}
              type="text"
              placeholder="Max"
              className="form-control"
              value={this.state.range.max}
              onChange={event => this.handleInputChange(event, 'max')} />
					</div>
				</div>
        </React.Fragment>
      )
  }
}

export default ExtraSiNo;
