import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'

class InstanciaInput extends InstanciaBasic {
	constructor(props) {
    super(props);
  }

	render() {
      return (
        <React.Fragment>
					<input
						disabled={this.props.disabled}
						type="text"
						className="form-control"
						value={this.props.atributo[this.valueKey]}
						onChange={this.handleInputChange}
					/>
        </React.Fragment>
      )
  }
}

export default InstanciaInput;
