import React, { Component } from "react";
import Loading from "../ui/Loading.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Config from "../../commons/config/Config.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import recapadoIcon from "../../assets/images/llantas/recapadoicon.png";
import FormValidation from "../../commons/validation/FormValidation.js";
import Validator from "../../commons/validation/Validator.js";
import Select from "react-select";
import deleteSVG from "../../assets/images/llantas/svg/delete.svg";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import LlantaRepuestosSelect from "./LlantaRepuestosSelect.jsx";
import LlantaBasesSelect from "./LlantaBasesSelect.jsx";
import Security from '../../commons/security/Security.js';

class LlantaBaja extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    this.dataService = "/llantas";
    console.log(this.props.llanta);
    this.state = {
      props: this.props,
      llanta: this.props.llanta,
      formData: {
        llanta: this.props.llanta,
        llanta_reemplazo: "",
        causaDeBaja: "",
      },
      llantas: [],
      llantasSelect: [],
      causasDeBaja: [],
      bases: [],
      errors: [],
      loading: false,
      unidadMedidorChange: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.formValidation = new FormValidation({
      component: this,
      validators: {
        "formData.llanta_reemplazo": (value) => Validator.notEmpty(value),
        "formData.causaDeBaja": (value) => Validator.notEmpty(value),
        "formData.base": (value) => Validator.notEmpty(value),
      },
    });
  }

  componentWillMount() {
    this.ajaxHandler.subscribe(this);
    this.initForm();
  }
  componentWillUnmount() {}

  initForm() {
    this.setState({ loading: false });
    Promise.all([ConfigBusiness.get("llantas.causasDeBaja"),
      this.getData("/bases/select-filtered-by-user/base/10"),
    	])
        .then((data) => {
          let causasDeBaja = data[0] ? data[0].split(",").map((e) => ({ value: e, label: e })) : [];
          this.setState({
            causasDeBaja: causasDeBaja,
            bases: data[1],
          });
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
  }

  handleFormChangeSelectObject(event, name) {
    if (name === 'llanta' && event !== null) {
      this.setState({llanta: event})
    }
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = event;
    this.setState({ formData: formDataCopy });
  }

  handleFormChangeSelect(event, name) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = event;
    this.setState({ formData: formDataCopy });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.loading) {
      this.setState({ loading: true });
      let component = this;
      
      this.ajaxHandler
        .fetch("/llantas/" + this.state.llanta.id + "/baja", {
          method: "PUT",
          body: JSON.stringify({
            ...this.state.formData,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization-Token": localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.status !== 400) {
            component.props.handleSubmit();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.ajaxHandler.handleResponseErrorsIsValid(error);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }

  }

  getData(service) {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  handleLlantaRepuestoChange(e) {
    let unidad = e ? (e.unidad_medidor_id ? (e.unidad_medidor_id !== this.state.formData.llanta.unidad_medidor_id) : false) : false
    this.setState({ unidadMedidorChange: unidad })
    this.handleFormChangeSelectObject(e, "llanta_reemplazo");
  }

  render() {
    this.formValidation.validate();
    let validationState = this.formValidation.state;

    return (
      <React.Fragment>
        <div className="mx-2 text-nowrap" style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "1rem", maxWidth: "350px" }}>
          <div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
            {this.state.errors.map((e, i) => (
              <li key={i} style={{whiteSpace: "initial"}}>
                {this.props.intl.formatMessage({
                  ...(backendStrings[e]
                    ? backendStrings[e]
                    : backendStrings["errors.default"])}
                    )}
              </li>
            ))}
          </div>
          <form className="form form-horizontal" ref="form">
            <div className="text-center">
              <img
                src={deleteSVG}
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <div className="text-center mt-2">
              <h3>
                {this.props.intl.formatMessage({
                  id:"llantaBaja.header_baja_de_llanta",
                  defaultMessage:"Baja de llanta"
                })}{" "}
                : {this.state.llanta ? this.state.llanta.serial : ""}
              </h3>
            </div>
            {this.props.fromAsignacion ? (
              <div className="form-group row mt-2">
                <label htmlFor="llantaReemplazo" className="col-12 col-form-label">
                  {this.props.intl.formatMessage({
                    id: "llantaBaja.llanta_origen_label",
                    defaultMessage: "Llanta a reemplazar",
                  })}
                  * :
                </label>
                <div className="col-12">
                  <Select
                    name="llantaOriginal"
                    className="select-personal-llantas z-15"
                    maxMenuHeight={10}
                    placeholder={this.props.intl.formatMessage({
                      id: "llantaBaja.select_placeholder_cautin_sn",
                      defaultMessage: "Cautin / SN",
                    })}
                    options={this.props.llantasDelAuto}
                    valueKey="id"
                    labelKey="serial"
                    value={this.state.formData.llanta ? this.state.formData.llanta : ""}
                    onChange={(e) => this.handleFormChangeSelectObject(e, "llanta")}
                  />
                  <div
                    className="help-block text-danger field-message"
                    hidden={
                      validationState.formData.llanta_reemplazo.pristine ||
                      validationState.formData.llanta_reemplazo.valid
                    }
                  >
                    {validationState.formData.llanta_reemplazo.message}
                  </div>
                </div>
              </div>
            ) : ''}
            {this.state.llanta ? (
              this.state.llanta.movil_id && !Security.hasPermission('LLANTAS_SIN_NECESIDAD_REEMPLAZO') ? (
                <div className="form-group row mt-2">
                  <label htmlFor="llantaReemplazo" className="col-12 col-form-label">
                    {this.props.intl.formatMessage({
                      id: "llantaBaja.llanta_reemplazo_label",
                      defaultMessage: "Llanta reemplazo",
                    })}
                    * :
                  </label>
                  <div className="col-12">
                    {/* <Select
                      name="llanta"
                      className="select-personal-llantas z-15"
                      maxMenuHeight={10}
                      placeholder={this.props.intl.formatMessage({
                        id: "llantaBaja.select_placeholder_cautin_sn",
                        defaultMessage: "Cautin / SN",
                      })}
                      options={this.state.llantasSelect}
                      valueKey="id"
                      labelKey="label"
                      value={this.state.formData.llanta_reemplazo ? this.state.formData.llanta_reemplazo : ""}
                      onChange={(e) => this.handleFormChangeSelectObject(e, "llanta_reemplazo")}
                    /> */}
                    <LlantaRepuestosSelect  llantaId={this.state.llanta.id} movil={this.props.movil} intl={this.props.intl} changeLlantaRepuesto={(e) => this.handleLlantaRepuestoChange(e)}/>
                    <div
                      className="help-block text-danger field-message"
                      hidden={
                        validationState.formData.llanta_reemplazo.pristine ||
                        validationState.formData.llanta_reemplazo.valid
                      }
                    >
                      {validationState.formData.llanta_reemplazo.message}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {this.state.unidadMedidorChange &&
                <p className="my-1 reemplazo-label-danger">La llanta seleccionada posee diferente medidor. Se reiniciaran los datos de odometro.</p>
              }
            <div className="form-group row mt-2">
              <label htmlFor="causasDeBaja" className="col-12 col-form-label">
                {this.props.intl.formatMessage({
                  id: "llantaBaja.llanta_causa_de_baja_label",
                  defaultMessage: "Causa de Baja",
                })}
                * :
              </label>
              <div className="col-12">
                <Select
                  name="causasDeBaja"
                  className="select-personal-llantas z-15"
                  maxMenuHeight={10}
                  placeholder={this.props.intl.formatMessage({
                    id: "llantaBaja.select_placeholder_causa_de_baja",
                    defaultMessage: "Causa de Baja",
                  })}
                  options={this.state.causasDeBaja}
                  valueKey="value"
                  labelKey="label"
                  value={this.state.formData.causaDeBaja ? this.state.formData.causaDeBaja : ""}
                  onChange={(e) => this.handleFormChangeSelect(e, "causaDeBaja")}
                />
                <div
                  className="help-block text-danger field-message"
                  hidden={validationState.formData.causaDeBaja.pristine || validationState.formData.causaDeBaja.valid}
                >
                  {validationState.formData.causaDeBaja.message}
                </div>
              </div>
            </div>
            <div className="form-group row mt-2 text-nowrap d-flex align-items-center">
              <label htmlFor="milimetrosRecapado" className="col-12">
                {this.props.intl.formatMessage({
                  id: "llantaDesasignar.select_base",
                  defaultMessage: "Seleccione una Base",
                })}
                * :
              </label>
              <div className="col-12">
                {/* <Select
                  name="base"
                  className="select-personal-llantas z-15"
                  maxMenuHeight={10}
                  placeholder={this.props.intl.formatMessage({
                    id: "llantaDesasignar.select_placeholder_llanta_reemplazo",
                    defaultMessage: "Base",
                  })}
                  isLoading={this.state.basesLoading}
                  options={this.state.bases}
                  valueKey="value"
                  labelKey="label"
                  value={this.state.formData.base ? this.state.formData.base : ""}
                  onChange={(e) => this.handleFormChangeSelectObject(e, "base")}
                /> */}
                <LlantaBasesSelect intl={this.props.intl} changeBase={(e) => this.handleFormChangeSelectObject(e, "base")} />
                <div
                  className="help-block text-danger field-message"
                  hidden={validationState.formData.base.pristine || validationState.formData.base.valid}
                >
                  {validationState.formData.base.message}
                </div>
              </div>
            </div>
            <div className="text-center mt-1 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary mr-1"
                onClick={this.handleSubmit}
                disabled={!this.state.formData.llanta ? true :
                  (this.state.formData.llanta.movil ? 
                    ((this.state.formData.base ? false : true) || (this.state.formData.causaDeBaja ? false : true) || (Security.hasPermission('LLANTAS_SIN_NECESIDAD_REEMPLAZO') ? false : (this.state.formData.llanta_reemplazo ? false : true))) :
                    (this.state.formData.base && this.state.formData.causaDeBaja ? false : true))}
                // disabled={this.props.llanta.movil_id ? !validationState.form.valid : !this.state.formData.causaDeBaja || state.loading}
              >
                <i className="fa fa-check-circle"></i>
                {" Guardar"}
              </button>
              <button
              type="button"
                className="btn btn-danger mr-1"
                onClick={(e) => this.props.close(e)}
              >
                <i className="fa fa-times-circle"></i>
                {' Cancelar'}
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default LlantaBaja;
