import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class InstanciaTime extends InstanciaBasic {
	constructor(props) {
        super(props);
        this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    }

    handleDatePickerFormChange(event) {
        this.props.handleChange(this.valueKey, event.format('HH:mm:ss'));
    }

    handleDatePickerFormRawChange(date) {
        
    }

	render() {
      return (
        <React.Fragment>
            <DatePicker id = "hora"
                name = "hora"
                className = "form-control date-picker-placeholder"
                selected = { this.props.atributo[this.valueKey] === null || this.props.atributo[this.valueKey]==='' || this.props.atributo[this.valueKey] === '00:00' ? null : moment(this.props.atributo[this.valueKey], "HH:mm:ss") }
                onChange = { (event) => this.handleDatePickerFormChange(event) }
                onChangeRaw = { (event) => this.handleDatePickerFormRawChange(event.target.value) }
                showTimeSelect showTimeSelectOnly timeIntervals = { 10 }
                dateFormat = "h:mm a"
                placeholderText = "HH:mm am"
                timeCaption = "Hora"
            />
        </React.Fragment>
      )
  }
}

export default InstanciaTime;
