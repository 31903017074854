import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';

class Dialog {
	static alert(intl,options) {
		swal({
			title: options && options.title ? options.title : intl.formatMessage({ id: 'dialog.errors.error_interno_del_sistema.', defaultMessage: 'Error interno del sistema.' }),
			text: options && options.text ? options.text : intl.formatMessage({ id: 'dialog.errors.contacte_al_administrador', defaultMessage: 'Contacte al administrador.' }),
			icon: options && options.icon ? options.icon : "error",
			buttons: {
				confirm: {
					text: intl.formatMessage({ id: 'dialog.errors.button_aceptar', defaultMessage: 'Aceptar' }),
					value: true,
					visible: true,
					className: "btn btn-primary",
					closeModal: true
				}
			}
		});
	}
}

export default Dialog;