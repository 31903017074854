import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';

class AjaxHandler {
	constructor(component) {
		this.component = null;
	}

	subscribe(component) {
		this.component = component;
	}

	unsubscribe(component) {
		this.component = null;
	}

	getJson(service) {
		if(this.component) {
			return new Promise((resolve, reject) => {
				this.fetch(service, {
					method: 'GET',
				}).then(response => {
					if(this.component) {
						resolve(response.json());
					} else {
						return null;
					}
				}).catch(error => {
                    if(error.name === 'AbortError'){
                        reject(error);
                        return;
                    }
					if(this.component) {
						reject(error);
					} else {
						return null;
					}
				});
			});
		} else {
			console.log('AjaxHandler: Component not subscribed.');
			return null;
		}
	}

	getJsonOptions(service,options){
		if(this.component) {
			return new Promise((resolve, reject) => {
				this.fetch(service, options).then(response => {
					if(this.component) {
						resolve(response.json());
					} else {
						return null;
					}
				}).catch(error => {
          if(error.name === 'AbortError'){
              reject(error);
              return;
          }
					if(this.component) {
						reject(error);
					} else {
						return null;
					}
				});
			});
		} else {
			console.log('AjaxHandler: Component not subscribed.');
			return null;
		}
	}

	fetch(service, options) {
		if(this.component) {
			return new Promise((resolve, reject) => {
				fetch(Config.get('apiUrlBase') + service,
					Object.assign(options, {
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization-Token': localStorage.getItem("token")
						}
					})
				)
				.then(response => {
					if(this.component) {
						this.handleResponseErrorsIsValid(response.clone())
						.then(error => {
							if(!error) {
								resolve(response);
							} else {
								reject(new TypeError(error));
							}
						});
					} else {
						return null;
					}
				}).catch(error => {
					if (error.name === 'AbortError') {return;}
					if(this.component) {
						if(error) this.handleError(error);
						reject(error);
					} else {
						return null;
					}
				});
			});
		} else {
			console.log('AjaxHandler: Component not subscribed.');
			return null;
		}
	}

	handleResponseErrorsIsValid(response) {
		let component = this.component;
		return new Promise((resolve, reject) => {
			let message, detail, messageResponse, detailResponse;
			if(response.status === 401) {
				message = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_401.error_la_sesion_expiro', defaultMessage: 'La sesión expiró.' });
				detail = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_401.error_por_favor_ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' });
			} else if(response.status === 403) {
				message = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_403.error_no_tiene_permisos_para_realizar_esta_accion', defaultMessage: 'No tiene permisos para realizar esta acción.' });
				detail = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_403.error_contacte_al_administrador', defaultMessage: 'Contacte al administrador.' });
			} else if(response.status >= 404) {
				message = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_404.error_interno_del_sistema', defaultMessage: 'Error interno del sistema.' });
				detail = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_404.error_contacte_al_administrador', defaultMessage: 'Contacte al administrador.' });
			}

			if(message) {
				let waitForResponse = new Promise((resolve, reject) => {
					if(response.responseJSON) {
						messageResponse = response.responseJSON.mensaje;
						detailResponse = response.responseJSON.detail;
						resolve();
					} else if(response instanceof Response) {
						response.json()
						.then((data) => {
							messageResponse = data.mensaje;
							detailResponse = data.detail;
							resolve();
						})
						.catch(() => {
							console.log('Error');
						});
					} else {
						resolve();
					}
				});

				waitForResponse.then(() => {
					if(response.status === 401) {
						swal({
							title: message,
							text: detail,
							icon: "error",
							buttons: {
								confirm: {
									text: component.props.intl.formatMessage({ id: 'ajaxHandler.errors_401.error_button_aceptar', defaultMessage: 'Aceptar' }),
									value: true,
									visible: true,
									className: "btn btn-primary",
									closeModal: true
								}
							}
						}).then((isConfirm) => {
							if (isConfirm) {
								window.location.reload();
							}
						});
						resolve(message);
					} else if(response.status === 403) {
						swal({
							title: messageResponse ? messageResponse : message,
							text: detail + (detailResponse ? ('\n' + detailResponse) : ''),
							icon: "error",
							buttons: {
								confirm: {
									text: component.props.intl.formatMessage({ id: 'ajaxHandler.errors_403.error_button_aceptar', defaultMessage: 'Aceptar' }),
									value: true,
									visible: true,
									className: "btn btn-primary",
									closeModal: true
								}
							}
						});
						resolve(message);
					} else if(response.status >= 404) {
						swal({
							title: message,
							text: detail,
							icon: "error",
							buttons: {
								confirm: {
									text: component.props.intl.formatMessage({ id: 'ajaxHandler.errors_404.error_button_aceptar', defaultMessage: 'Aceptar' }),
									value: true,
									visible: true,
									className: "btn btn-primary",
									closeModal: true
								}
							}
						}).then((isConfirm) => {
							if (isConfirm) {
								resolve(message);
							}
						});
					}
				})
				.catch(() => {
					console.log('Error');
				});
			} else {
				resolve(null);
			}
		});
	}

	handleError(error) {
		let component = this.component;
		let title = "Error"
		let text = ""
		let button = 'OK'
		if (component && component.props) {
			title = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_handleError.error_interno_del_sistema', defaultMessage: 'Error interno del sistema.' })
			text = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_handleError.contacte_al_administrador', defaultMessage: 'Contacte al administrador.' })
			button = component.props.intl.formatMessage({ id: 'ajaxHandler.errors_handleError.button_aceptar', defaultMessage: 'Aceptar' })
		}
		if(error) {
			swal({
				title: error.message ? error.message : title,
				text: text,
				icon: "error",
				buttons: {
					confirm: {
						text: button,
						value: true,
						visible: true,
						className: "btn btn-primary",
						closeModal: true
					}
				}
			});
		}
	}
}

export default AjaxHandler;
