import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/min/locales'
import Select from 'react-select'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';

class UsuariosMovilesAbm extends Component {

    constructor(props) {
        super(props);
        this.ajaxHandler = new AjaxHandler();
        moment.locale('es');

        this.state = {
            redirectTo: null,
            props: this.props,
            formData: {
                id: props.match.params.id,
                moviles: null
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);

        this.formValidation = new FormValidation({
            component: this,
            validators: {
            }
        })
    }

    componentDidMount() {
        if((Security.hasPermission('USUARIOSMOVILES_MODIFICAR') && this.state.props.action === 'EDIT') ||
            (Security.hasPermission('USUARIOSMOVILES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
            this.ajaxHandler.subscribe(this);
            this.initForm();
        } else {
            this.setState({
                redirectTo: '/error'
            });
        }    
    }

    initForm() {
        let component = this;
        if(this.state.props.action === 'VIEW') this.loadFormData();
        
        if(this.state.props.action === 'EDIT') {
            this.setState({              
                loading: this.state.props.action === 'EDIT',               
                movilesOptionsLoading: true,
            });

            Promise.all([            
                this.ajaxHandler.getJson('/moviles/usuarios')
            ]).then((data) => {
                component.setState({                    
                    movilesOptionsLoading: false
                });

                let movilesOptions = data[0] ? data[0].map(e => ({value: e.value, label: e.label})): [];

                component.setState({                   
                    movilesOptions: movilesOptions                    
                }, () => {
                    component.state.props.action === 'EDIT' ? this.loadFormData() : this.initDualListbox();
                });
                
            }).catch(function(error) {
                component.ajaxHandler.handleError(error);
            });
        }
    }

    loadFormData() {
        let component = this;
        this.setState({ loading: true });
        Promise.all([            
            this.ajaxHandler.getJson('/moviles/usuarios'),
            this.ajaxHandler.getJson('/personas/usuariosmoviles/' + this.state.props.match.params.id)
        ]).then(data => {                         
            let movilesOptions = data[0] ? data[0].map(e => ({value: e.value, label: e.label})): [];
            let moviles = data[1] ? data[1].map(e => ({value: e.value, label: e.label})): [];            

            component.setState({
                movilesOptions: movilesOptions,
                formData: {
                    id: this.state.props.match.params.id,
                    moviles: moviles
                }
            })            

        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    handleCancel(event) {
        this.exit();
    }

    componentWillUnmount() {
        this.ajaxHandler.unsubscribe();
    }

    exit() {
        this.setState({
            redirectTo: '/usuariosmoviles'
        });
    }

    handleFormChangeSelectString(object) {    
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData)); 
        formDataCopy["moviles"] = object === null ? '' : object;        
        this.setState({
            formData: formDataCopy
        });               
    }

    handleSubmit(event) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        this.setState({
            formData: formDataCopy,
            loading: true
        }, () => {
            let component = this;
            
            this.ajaxHandler.fetch('/personas/usuariosmoviles/'+this.state.formData.id, {
                method: 'PUT',
                body: JSON.stringify({
                    ...this.state.formData
                }),
            }).then(response => {
                if(response.status !== 400) {
                    component.exit();
                } else {
                    response.json()
                        .then(data => {
                            this.setState({
                                errors: data.detalle
                            });
                        });
                }

                window.scrollTo(0,0);
            }).catch((error) => {
                this.ajaxHandler.handleError(error);
            }).finally(() => {
                this.setState({ loading: false });
            });
        });

        event.preventDefault();
    }

    render() {
        this.formValidation.validate();
        let state = this.state;
        let formData = state.formData;
        let validationState = this.formValidation.state;
                
        let moviles = formData.moviles && formData.moviles.map(function(el) {            
            return {value: el.value, label: el.label};
        });  
                
        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                {this.state.loading && <Loading />}
                <div className="row">
                    <div className="col-md-12">
                        <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
                            <div className="form-body">
                                <div className="card pull-up">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <h4 className="form-section">
                                                <i className="la la-truck"></i>
                                                <FormattedMessage id="usuariosMovilesAbm.render.general_data.header_datos_generales" defaultMessage=" Móviles "/>                                                
                                            </h4>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label className="col-md-3 label-control col-form-label" htmlFor="Móviles">
                                                            <FormattedMessage id="usuariosMovilesAbm.render.labor_data.label_usuarios_moviles" defaultMessage="Móviles:"/>
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Select
                                                                id="moviles"
                                                                name="moviles"
                                                                placeholder={!state.movilesOptionsLoading ? this.props.intl.formatMessage({ id: 'usuariosMovilesAbm.render.labor_data.placeholder_usuarios_moviles', defaultMessage: 'Seleccione' }) : ''}
                                                                options={this.state.movilesOptions}
                                                                valueKey='value'
                                                                labelKey='label'
                                                                multi={true}                              
                                                                value={moviles}
                                                                onChange={(e) => this.handleFormChangeSelectString(e)}
                                                                isLoading={state.movilesOptionsLoading}
                                                                disabled={this.props.action === 'VIEW' || state.movilesOptionsLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!this.props.hideBack && (
                                    <div className="card pull-up">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <div className="text-cd text-right">
                                                    {this.props.action !== 'VIEW' && (
                                                    <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
                                                    <i className="fa fa-check-circle"></i><FormattedMessage id="personasAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                                                    </button>
                                                    )}
                                                    <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                                                    <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'personasAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'personasAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(UsuariosMovilesAbm);
