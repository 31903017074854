import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from './PanolGrid.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'
import Loading from '../ui/Loading.js'
import dtConfig from './PanolGrid.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

import {
	getItems,
	createItems,
	updateItems,
	deleteItems,
	getMedidas,
	getSubCategorias,
	getMarcas
}
from './MetodosPanol.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ItemAbm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			isLoaded: false,
			grid: null,
			create: null,
			update: null,
			delete: null,
			subcategorias: [],
			medidas: [],
			marcas: [],
			showExternalCode: ConfigBusiness.get('frontend.showExternalCode') === 'true' ? true : false,
		}
	}
	async componentDidMount() {

    	let component = this;
    	let filters = null;
		let getGrid = await getItems().then(res=>{
    		let dataR = res.data;
	        this.setState({
	            grid: dataR
	        })

	    });

		const fetchLoad = async () => {

			this.setState({isLoading: true});
			Promise.all([
				  	await getMedidas().then(res=>{
			   				let appendState = res.data;
			            	this.setState({ medidas: this.state.medidas.concat(appendState) });
			       			return this.state.medidas
			    	}),
			    	await getSubCategorias().then(res=>{
			   				let appendState = res.data;
			            	this.setState({ subcategorias: this.state.subcategorias.concat(appendState) });
			       			return this.state.subcategorias
			    	}),
				   	await getMarcas().then(res=>{
		    			let appendState = res.data;
		    			this.setState({ marcas: this.state.marcas.concat(appendState) });

			       		return this.state.marcas

			    }),
			])
			.then(allResults => {
				this.setState({isLoading: false})
				this.forceUpdate();
			})
			.catch(err => console.log(err))
		}
		fetchLoad();
	    const config = {
	    	headers: {
	    		'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
	    	 }
    	}
       	let table = $(this.refs.main).DataTable(
       		Object.assign({
			//dom: '<"data-table-wrapper">',
			stateSave: true,
			stateSaveCallback: function(settings,data) {
				localStorage.setItem( 'DataTables_ItemAbm', JSON.stringify(data) );
			},
			stateLoadCallback: function(settings) {
				return JSON.parse( localStorage.getItem( 'DataTables_ItemAbm' ) );
			},

           	ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + '/panol/items/grid',

					headers: {
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function(res){
						return component.state.grid
					},
					error: function (xhr, error, thrown) {
					},
					cache: false
				},
			initComplete: function (settings) {
				let stateColumns = JSON.parse(localStorage.getItem('DataTables_ItemAbm')).columns;
				var sets = settings;
				var index = 0;
				table.search("producto").draw();
				this.api().columns().every( function () {
					var column = this;
					if(sets.aoColumns[index].filterType){
						if(sets.aoColumns[index].filterType === 'select') {
							var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'itemAbm.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
								.appendTo( $(column.footer()).empty() )
								.on( 'change', function () {
									var val = $.fn.dataTable.util.escapeRegex($(this).val());
									column
										.search( val ? val : '', true, false )
										.draw();
								});
							if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
								filters[sets.aoColumns[index].name].map(e => {
									select.append('<option value="'+e.value+'">'+e.label+'</option>');
									return true;
								});
							} else {
								column.data().unique().sort().each( function ( d, j ) {
									select.append( '<option value="'+d+'">'+d+'</option>' );
								});
							}
							if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
						}
						if(sets.aoColumns[index].filterType === 'input') {
							var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'itemAbm.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
							if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
							input.appendTo($(column.footer()).empty());
							input.on( 'keyup change', function () {
								if ( column.search() !== this.value ) {
									column
									.search( this.value )
									.draw()
									.ajax.reload(null, false);
								}
							});
						}
					}
					index++;
					return '';
				});
				$('tfoot tr').appendTo('thead');
			},
			sDom: 'lrtip',
           	columns: [
           		{
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_id.label_id', defaultMessage: 'id' }),
	            className: "text-center",
	            visible: false,
	            data: "id"
	            },
           		{
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_description.label_external_code', defaultMessage: 'External Code' }),
	            className: component.state.showExternalCode ? "panolRow" : "d-none panolRow",
	            data: "externalCode",
	            filterType: 'input',
	            },
           		{
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_description.label_descripcion', defaultMessage: 'Descripcion' }),
	            className: "panolRow",
	            data: "descripcion",
	            filterType: 'input'
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_idSubCat.label_idSubCat', defaultMessage: 'idSubCat' }),
	            className: "",
	            visible: false,
	            data: "subcategoria",
				render: function (data, type, row) {
					return data && data.id ? data.id : "";
				},
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_subcategory.label_sub_categoria', defaultMessage: 'Sub-Categoria' }),
	            className: "panolRow",
	            data: "subcategoria",
	            filterType: 'input',
				render: function (data, type, row) {
					return data && data.nombre ? data.nombre : "";
				},
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_idUnidad.label_idUnidad', defaultMessage: 'idUnidad' }),
	            className: "",
	            visible: false,
	            data: "unidad",
				render: function (data, type, row) {
					return data && data.id ? data.id : "";
				},
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_unit.label_unidad', defaultMessage: 'Unidad' }),
	            className: "panolRow",
	            data: "unidad",
	            filterType: 'input',
				render: function (data, type, row) {
					return data && data.descripcion ? data.descripcion : "";
				},
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_type_item.label_tipo_de_item', defaultMessage: 'Tipo de item' }),
	            className: "panolRow",
	            data: "tipo",
	            filterType: 'input'
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_nro_parte.label_nro_parte', defaultMessage: 'Nro de Parte' }),
	            className: "panolRow",
	            data: "numeroDeParte",
	            filterType: 'input'
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_reference.label_referencia', defaultMessage: 'Referencia' }),
	            className: "panolRow",
	            data: "referencia",
	            filterType: 'input'
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_idMarca.label_idMarca', defaultMessage: 'idMarca' }),
	            className: "",
	            visible: false,
	            data: "marca",
				render: function (data, type, row) {
					return data && data.id ? data.id : "";
				},
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_marca.label_marca', defaultMessage: 'Marca' }),
	            className: "panolRow",
	            data: "marca",
	            filterType: 'input',
				render: function (data, type, row) {
					return data && data.nombre ? data.nombre : "";
				},
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'itemAbm.column_cost.label_costo', defaultMessage: 'Costo' }),
	            className: "panolRow",
	            data: "costo",
	            filterType: 'input'
	            },
	            {
				orderable: false,
				data: null,
				className: "",
				width: '120px',
	            render: function () {
					let html =
						(Security.hasPermission('PANOL_ITEMS_MODIFICAR') ? `
						<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'itemAbm.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" >
							<i class="fa fa-pencil fa-xs"></i>
						</button>`: '') +
                        (Security.hasPermission('PANOL_ITEMS_ELIMINAR') ? `
						<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'itemAbm.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
							<i class="fa fa-trash fa-xs"></i>
						</button>`: '');
                    return html;
				}
        	}],
        	search: {
			    search: "Producto"
			},
        	drawCallback: function () {
						$(this).find('.action').on('click', function () {

							if($(this).hasClass('edit')) {
								let data = table.row( $(this).parents('tr') ).data();
								let dataId = data.id;
								$("#editItemsModal #editIdItem").val(data.id);
								$("#editItemsModal #editNombreItem").val(data.descripcion);
								$("#editItemsModal #editSubCatItem").val(data.subcategoria ? data.subcategoria.id : null);
								$("#editItemsModal #editMedidaItem").val(data.unidad ? data.unidad.id : null);
								$("#editItemsModal #editTipoItem").val(data.tipo);
								$("#editItemsModal #editNroParteItem").val(data.numeroDeParte);
								$("#editItemsModal #editReferenciaItem").val(data.referencia);
								$("#editItemsModal #editMarcaItem").val(data.marca ? data.marca.id : null);
								$("#editItemsModal #editCostoItem").val(data.costo);
								$("#editItemsModal #editExternalCodeItem").val(data.externalCode);
								$('#editItemsModal').on('shown.bs.modal', function() {
								    $('#editNombreItem').trigger('focus');
								    $("#editNombreItem").keyup(function(e)
									{
									    if (e.which == 13)
									    {
									        $(this).submit();
									    }
									});
								  });
								$("#editItemsModal").modal({show: true});
							}
							if($(this).hasClass('delete')) {
								swal({
								title: component.props.intl.formatMessage({ id: 'itemAbm.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
								text: null,
								icon: "warning",
								buttons: {
									confirm: {
										text: component.props.intl.formatMessage({ id: 'itemAbm.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
										value: true,
										visible: true,
										className: "btn btn-primary",
										closeModal: false
									},
									cancel: {
										text: component.props.intl.formatMessage({ id: 'itemAbm.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
										value: null,
										visible: true,
										className: "btn btn-danger",
										closeModal: true,
									}
								}
							}).then((isConfirm) => {
								if (isConfirm) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									deleteItems(dataId).then(res => {
										let dataR = res.mensaje;
										swal(component.props.intl.formatMessage({ id: 'itemAbm.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										let getGrid2 = getItems().then(res2=>{
							    		let dataR2 = res2.data;
									        component.setState({
									            grid: dataR2
									        })
										table.ajax.reload();
									    })
									})
									.catch(function(error) {
										swal(component.props.intl.formatMessage({ id: 'itemAbm.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
									});
								}
							}); //fin accion

							}
						});

					},

		},dtConfig)
		) //fin dt
		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();

		this.setState({ loading: false });

		new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'itemAbm.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 1,2 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_export.title_exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: component.props.intl.formatMessage({ id: 'itemAbm.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_export.select_copiar', defaultMessage: 'Copiar' })
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_export.select_exportar_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_export.select_exportar_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'itemAbm.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},

			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});

    }
	componentWillUnmount(){
		/*
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
       */
    }

    shouldComponentUpdate() {
       return false;
    }

    onChange = (e) => {
    this.props.history.push(`/panol/config${e.target.value}`);
  }
  	onManoClick = (e) => {
  		$("#example").DataTable().search("Mano de obra").draw();
  		$(".btnProducto").css('opacity', 1).css('cursor', 'pointer');
  		$(".btnMano").css('opacity', 0.4).css('cursor', 'default');
  	}
  	onProductoClick = (e) => {
  		$("#example").DataTable().search("Producto").draw();
  		$(".btnProducto").css('opacity', 0.4).css('cursor', 'default');
  		$(".btnMano").css('opacity', 1).css('cursor', 'pointer');
  	}
	onModalEditSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		updateItems(e).then(res => {
						$(this).find('form').trigger('reset');
						$("#editItemsModal").modal("hide");
						swal(component.props.intl.formatMessage({ id: 'itemAbm.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
						let getGrid2 = getItems().then(res2=>{
			    			let dataR2 = res2.data;
					    	component.setState({
				            grid: dataR2
					    	})
								table.ajax.reload();
				    })
				}).catch(res => {
						swal(component.props.intl.formatMessage({ id: 'itemAbm.modal_edit.information_error', defaultMessage: 'Error' }), "", "error");
				})
	}
	onModalNewSubmit = (e) => {
				e.preventDefault();
				let component = this;
				let table = $(this.refs.main).DataTable();
				createItems(e).then(res =>{
				    $("#newItemsModal").find('form').trigger('reset');
				    $("#newItemsModal").modal("hide");
						swal(component.props.intl.formatMessage({ id: 'itemAbm.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");
						let getGrid2 = getItems().then(res2=>{
		  					let dataR2 = res2.data;
			        	component.setState({
			            	grid: dataR2
			        	})
								table.ajax.reload();
			    	})
				}).catch(res => {
						swal(component.props.intl.formatMessage({ id: 'itemAbm.modal_add.information_error', defaultMessage: 'Error' }), "", "error");
				})
	}

    render() {
    	/*
    	const { error, isLoaded, items } = this.state;
	    if (error) {
	      return <div>Error: {error.message}</div>;
	    } else if (isLoaded == false) {
	      return <div>Loading...</div>;
	    } else if (isLoaded == true){
	    	*/
	    if (this.state.isLoading) return <div><FormattedMessage id="itemAbm.render.loading" defaultMessage="Cargando..."/></div>;
	      return (
        	<React.Fragment>
        	{/*
        	{this.state.loading && <Loading />}
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        	*/}
    		<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-header">
							<h1><FormattedMessage id="itemAbm.render.select_module.header_seleccione_el_modulo_a_configurar" defaultMessage="Seleccione el módulo a configurar"/></h1>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-4">
									<select className="form-control form-control-lg" defaultValue="/items" onChange={this.onChange}>
									  <option value="">Medidas</option>
									  <option value="/marcas">Marcas</option>
									  <option value="/categorias">Categorias</option>
									  <option value="/subcategorias">Sub-Categorias</option>
									  <option value="/items">Items</option>
									</select>
								</div>
								<div className="col-4 btnProducto" style={{cursor: 'pointer'}} style={{opacity: '0.4'}} onClick={this.onProductoClick}>
									<div className="card bg-gradient-directional-preventivo">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<i className="ft-check-circle text-white font-large-2 float-left"></i>
													</div>
													<div className="media-body text-white text-right">
														<h3 className="text-white"></h3>
														<span><FormattedMessage id="itemAbm.render.dataTable_search.productos" defaultMessage="Productos"/></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-4 btnMano" style={{cursor: 'pointer'}} onClick={this.onManoClick}>
									<div className="card bg-gradient-directional-correctivo">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<i className="icon-wrench text-white font-large-2 float-left"></i>
													</div>
													<div className="media-body text-white text-right">
														<h3 className="text-white"></h3>
														<span><FormattedMessage id="itemAbm.render.dataTable_search.mano_obra" defaultMessage="Mano de Obra"/></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
				{/*modal new tems*/}
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                            {Security.renderIfHasPermission('PANOL_ITEMS_CREAR', (
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'itemAbm.render.button_add_category.title_nuevo_item', defaultMessage: 'Nuevo Item' })}
												data-toggle="modal"
												data-target="#newItemsModal"
												>
												<i className="ft-plus"></i>
											</div>))}

										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover" width="100%">
										<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className=""></th>
													<th className={this.state.showExternalCode ? "dt-search-header" : "dt-search-header d-none"}><FormattedMessage id="itemAbm.render.table_column_external_code" defaultMessage="Ext Code"/></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_descripcion" defaultMessage="Descripcion"/></th>
													<th className=""></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_sub_categoria" defaultMessage="Sub-Categoria"/></th>
													<th className=""></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_unidad" defaultMessage="Unidad"/></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_tipo_item" defaultMessage="Tipo de item"/></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_nro_parte" defaultMessage="Nro de  parte"/></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_referencia" defaultMessage="Referencia"/></th>
													<th className=""></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_marca" defaultMessage="Marca"/></th>
													<th className="dt-search-header"><FormattedMessage id="itemAbm.render.table_column_costo" defaultMessage="Costo"/></th>
													<th className=""></th>
												</tr>
											</tfoot>
									</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
			{/*---Modal nueva Marca---*/}
				<div className="modal fade" id="newItemsModal" tabIndex="-1" role="dialog" aria-labelledby="newtemsModalLabel" aria-hidden="true">
				  <div className="modal-dialog modal-lg" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="newItemsModalLabel"><FormattedMessage id="itemAbm.render.modal_add.header_nuevo_item" defaultMessage="Nuevo Item"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalNewSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-6">
						          	<div className="form-group">
							           	<label htmlFor="nombreItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_descripcion" defaultMessage="Descripcion del Item:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="descripcion"
							            	id="newNombreItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_add.placeholder_descripcion', defaultMessage: 'Ej: aceite elaion 15w40' })}
							            />
						          	</div>
					          	</div>
						        <div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="newSubCatItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_subCategoria" defaultMessage="SubCategoria:"/></label>
								            <select className="form-control" id="newSubCatItem" name="subcategoria">
												  {this.state.subcategorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
						          	</div>
						        </div>
						        <div className="col-md-2">
						          	<div className="form-group">
							           	<label htmlFor="newCostoItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_costo" defaultMessage="Costo:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="costo"
							            	id="newCostoItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_add.placeholder_costo', defaultMessage: 'Ej: 1250' })}
							            />
						          	</div>
					          	</div>
						        <div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="newMedidaItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_unidad_medida" defaultMessage="Unidad de medida:"/></label>
								            <select className="form-control" id="newMedidaItem" name="unidad">
												  {this.state.medidas.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.descripcion}</option>
								                    })}
											</select>
						          	</div>
						        </div>
						        <div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="newTipoItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_tipo" defaultMessage="Tipo:"/></label>
								            <select className="form-control" id="newTipoItem" name="tipo">
												  <option value="/">Seleccione un tipo</option>
												  <option value="Producto">Producto</option>
												  <option value="Mano de obra">Mano de obra</option>
											</select>
						          	</div>
						        </div>
						        <div className="col-md-4">
						          	<div className="form-group">
							           	<label htmlFor="nroParteItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_nro_de_parte" defaultMessage="Nro de parte:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="numeroDeParte"
							            	id="nroDeParteItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_add.placeholder_nro_de_parte', defaultMessage: 'Ej: 000153654' })}
							            />
						          	</div>
					          	</div>
					          	<div className="col-md-8">
						          	<div className="form-group">
							           	<label htmlFor="referenciaItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_referencia" defaultMessage="Referencia:"/></label>
							            <textarea
							            	className="form-control"
							            	name="referencia"
							            	id="referenciaItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_add.placeholder_referencia', defaultMessage: 'Ej: alguna referencia de algo' })}
							            />
						          	</div>
					          	</div>
					          	<div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="newMarcaItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_marca" defaultMessage="Marca:"/></label>
								            <select className="form-control" id="newMarcaItem" name="marca">
												  {this.state.marcas.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
						          	</div>
						        </div>
										{this.state.showExternalCode && (
										<div className="col-md-4">
											<div className="form-group">
												<label htmlFor="externalCodeItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_add.label_external_code" defaultMessage="External Code:"/></label>
												<input
													type="text"
													className="form-control"
													name="externalCode"
													id="externalCodeItem"
													placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_add.placeholder_external_code', defaultMessage: 'External Code' })}
												/>
											</div>
										</div>
										)}
				          	</div>
				        </div>
					    <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="itemAbm.render.modal_add.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="itemAbm.render.modal_add.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					    </div>
				      </form>
				    </div>
				  </div>
				</div>

				{/*---Modal edit Item---*/}
				<div className="modal fade" id="editItemsModal" tabIndex="-1" role="dialog" aria-labelledby="editItemsModalLabel" aria-hidden="true">
				  <div className="modal-dialog modal-lg" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="editItemsModalLabel"><FormattedMessage id="itemAbm.render.modal_edit.header_editar_item" defaultMessage="Editar Item"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalEditSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-6">
						          	<div className="form-group">
						          		<input
							            	type="hidden"
							            	className="form-control"
							            	name="id"
							            	id="editIdItem"
							            />
							           	<label htmlFor="editNombreItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_descripcion" defaultMessage="Descripcion del Item:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="descripcion"
							            	id="editNombreItem"
							            />
						          	</div>
					          	</div>
						        <div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="editSubCatItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_subCategoria" defaultMessage="SubCategoria:"/></label>
								            <select className="form-control" id="editSubCatItem" name="subcategoria">
												  {this.state.subcategorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
						          	</div>
						        </div>
						        <div className="col-md-2">
						          	<div className="form-group">
							           	<label htmlFor="editCostoItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_costo" defaultMessage="Costo:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="costo"
							            	id="editCostoItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_edit.placeholder_costo', defaultMessage: 'Ej: 1250' })}
							            />
						          	</div>
					          	</div>
						        <div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="editMedidaItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_unidad_de_medida" defaultMessage="Unidad de medida:"/></label>
								            <select className="form-control" id="editMedidaItem" name="unidad">
												  {this.state.medidas.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.descripcion}</option>
								                    })}
											</select>
						          	</div>
						        </div>
						        <div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="editTipoItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_tipo" defaultMessage="Tipo:"/></label>
								            <select className="form-control" id="editTipoItem" name="tipo">
												  <option value="/">Seleccione un tipo</option>
												  <option value="Producto">Producto</option>
												  <option value="Mano de obra">Mano de obra</option>
											</select>
						          	</div>
						        </div>
						        <div className="col-md-4">
						          	<div className="form-group">
							           	<label htmlFor="editNroParteItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_nro_de_parte" defaultMessage="Nro de parte:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="numeroDeParte"
							            	id="editNroParteItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_edit.placeholder_nro_de_parte', defaultMessage: 'Ej: 000153654' })}
							            />
						          	</div>
					          	</div>
					          	<div className="col-md-8">
						          	<div className="form-group">
							           	<label htmlFor="referenciaItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_referencia" defaultMessage="Referencia:"/></label>
							            <textarea
							            	className="form-control"
							            	name="referencia"
							            	id="editReferenciaItem"
							            	placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_edit.placeholder_referencia', defaultMessage: 'Ej: alguna referencia de algo' })}
							            />
						          	</div>
					          	</div>
					          	<div className="col-md-4">
						          	<div className="form-group">
							            <label htmlFor="editMarcaItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_marca" defaultMessage="Marca:"/></label>
								            <select className="form-control" id="editMarcaItem" name="marca">
												  {this.state.marcas.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
						          	</div>
						        </div>
											{this.state.showExternalCode && (
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="editExternalCodeItem" className="col-form-label"><FormattedMessage id="itemAbm.render.modal_edit.lavel_external_code" defaultMessage="External Code:"/></label>
													<input
														type="text"
														className="form-control"
														name="externalCode"
														id="editExternalCodeItem"
														placeholder={this.props.intl.formatMessage({ id: 'itemAbm.render.modal_edit.placeholder_external_code', defaultMessage: 'External Code' })}
													/>
												</div>
											</div>
											)}
										</div>
				        </div>
					    <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="itemAbm.render.modal_edit.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="itemAbm.render.modal_edit.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					    </div>
				      </form>
				    </div>
				  </div>
				</div>


			</div>
		</React.Fragment>
            );
		//}
    }
}

export default injectIntl(ItemAbm);
