import $ from "jquery";
import swal from "sweetalert";
import React, { useState, useLayoutEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import mainButton from "../../../assets/images/llantas/llantas.svg";
import firstButton from "../../../assets/images/llantas/png1.png";
import secondButton from "../../../assets/images/llantas/png2.png";
import deleteSVG from "../../../assets/images/llantas/svg/delete.svg"
import upgradePNG from "../../../assets/images/llantas/svg/upgrade.png"
import activateSVG from "../../../assets/images/llantas/svg/on-button.svg"
import desasignarSVG from "../../../assets/images/llantas/svg/lug-wrench.svg"
import { FloatingMenu, MainButton, ChildButton } from "react-floating-button-menu";

const Fab = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [llantaId, setllantaId] = useState(props.llanta_id);
  const handleChangeDelete = (props) => {};

  useLayoutEffect(() => {
    $(".llanta-fab").tooltip();
    document.addEventListener('mousedown', changeState, false)
  }, [])

  const changeState = (e) => {
    if (e.target.className !== "llanta-fab") {
      setIsOpen(false)
    }
  }

  const addTicket = () => {
    props.addTicket();
  };
  const forceRecapado = () => {
    if (props.llanta.recapadas >= props.llanta.llanta_modelo.recapado) swal("No se puede recapar", "", "warning");
    else props.forceRecapado();
  };

  const baja = () => {

    if (props.llanta.ticket_id) 
      swal(props.intl.formatMessage({
        id:"fab.no_se_puede_dar_de_baja_la_lanta_tickets_pendientes",
        defaultMessage:"No se puede dar de baja la llanta, tiene tickets pendientes"
      }), "", "warning")
    else
      props.eventDelete(props.llanta_id);
  };

  return (
    <div>
      {props.llanta.activo === 1 ? (
        <FloatingMenu slideSpeed={500} direction="right" isOpen={isOpen}>
          <MainButton
            className="llanta-fab"
            data-toggle="tooltip"
            data-placement="top"
            title={props.intl.formatMessage({
              id: 'llantaAsignacionesDetails_acciones_acciones_label',
              defaultMessage: 'Acciones',
            })}
            iconResting={
              <img id={props.llantaId} className="llanta-fab" style={{ height: 30 }} src={mainButton} alt="/" />
            }
            iconActive={
              <img id={props.llantaId} className="llanta-fab" style={{ height: 30 }} src={mainButton} alt="/" />
            }
            size={50}
            onClick={() => setIsOpen(!isOpen)}
          />
          <ChildButton
            className="llanta-fab"
            data-toggle="tooltip"
            data-placement="top"
            title={props.intl.formatMessage({
              id: 'llantaAsignacionesDetails_acciones_cambioPosicion_label',
              defaultMessage: 'Rotar',
            })}
            icon={<img className="llanta-fab" style={{ height: 50 }} src={firstButton} alt="/" />}
            size={40}
            onClick={() => props.eventCambioLlanta(llantaId)}
          />
          <ChildButton
            className="llanta-fab"
            data-toggle="tooltip"
            data-placement="top"
            title={props.intl.formatMessage({
              id: 'llantaAsignacionesDetails_acciones_reparar_label',
              defaultMessage: 'Reparar',
            })}
            icon={<img className="llanta-fab" style={{ height: 50 }} src={secondButton} alt="/" />}
            size={40}
            onClick={() => addTicket()}
          />
          <ChildButton
            className="llanta-fab"
            background={props.llanta.ticket_id ? "yellow" : "#22C1BD"}
            data-toggle="tooltip"
            data-placement="top"
            title={props.intl.formatMessage({
              id: 'llantaAsignacionesDetails_acciones_reencauche_label',
              defaultMessage: 'Reencauche',
            })}
            icon={<img className="llanta-fab" style={{ height: 50 }} src={upgradePNG} alt="/" />}
            size={40}
            onClick={() => forceRecapado()}
          />
          {props.llanta.movil ? (
            <ChildButton
              background="#22C1BD"
              className="llanta-fab"
              data-toggle="tooltip"
              data-placement="top"
              title={props.intl.formatMessage({
                id: 'llantaAsignacionesDetails_acciones_desasignar_label',
                defaultMessage: 'Desasignar del Movil',
              })}
              icon={<img className="llanta-fab" style={{ height: 30 }} src={desasignarSVG} alt="/" />}
              size={40}
              onClick={() => props.eventDesasignar(llantaId)}
            />
          ) : (
            <div></div>
          )}
          <ChildButton
            background="#F94962"
            className="llanta-fab"
            data-toggle="tooltip"
            data-placement="top"
            title={props.intl.formatMessage({
              id: 'llantaAsignacionesDetails_acciones_baja_label',
              defaultMessage: 'Dar de Baja',
            })}
            icon={<img className="llanta-fab" style={{ height: 30 }} src={deleteSVG} alt="/" />}
            size={40}
            onClick={() => baja()}
          />
        </FloatingMenu>
      ) : (
        <FloatingMenu slideSpeed={500} direction="right" isOpen={isOpen}>
          <MainButton
            className="llanta-fab"
            data-toggle="tooltip"
            data-placement="top"
            title="Acciones"
            iconResting={
              <img id={props.llantaId} className="llanta-fab" style={{ height: 30 }} src={mainButton} alt="/" />
            }
            iconActive={
              <img id={props.llantaId} className="llanta-fab" style={{ height: 30 }} src={mainButton} alt="/" />
            }
            size={50}
            onClick={() => setIsOpen(!isOpen)}
          />
          <ChildButton
            background="#22C1BD"
            className="llanta-fab"
            data-toggle="tooltip"
            data-placement="top"
            title="Reactivar llanta"
            icon={<img className="llanta-fab" style={{ height: 30 }} src={activateSVG} alt="/" />}
            size={40}
            onClick={() => props.eventReactivar(llantaId)}
          />
        </FloatingMenu>
      )}
    </div>
  );
};

export default Fab;
