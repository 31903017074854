import React, { useState, useRef, useEffect } from "react";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";

const sendData = (service, data) => {
  let serviceURL = Config.get("apiUrlBase") + service;
  return fetch(serviceURL, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization-Token": localStorage.getItem("token"),
    },
    body: JSON.stringify({ activo: 1 }),
  });
};

const ajaxHandler = new AjaxHandler();

const LlantaReactivarModal = (props) => {
  const formRef = useRef();

  useEffect(() => {}, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    sendData("/llantas/reactivar/" + props.id, 1)
      .then((response) => {
        return response.status === 204
          ? props.success("Llanta Reactivada con Exito!")
          : props.failure("Ocurrio un error.");
      })
      .catch((error) => {
        props.failure("Ocurrio un error.");
      });
  };
  return (
    <form className="form form-horizontal" ref={formRef} onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center w-100">
        <p>Esta seguro que desea reactivar esta llanta?</p>
      </div>
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary mr-1">
          <i className="fa fa-check-circle mr-1"></i>
          Reactivar
        </button>
        <button type="button" className="btn btn-danger" onClick={() => props.dismiss()}>
          <i className="fa fa-times-circle mr-1"></i>
          Cancelar
        </button>
      </div>
    </form>
  );
};
export default LlantaReactivarModal;
