import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import PreventivoVerde from '../../assets/images/tareas/preventivo_verde.svg'
import PreventivoRojo from '../../assets/images/tareas/preventivo_rojo.svg'
import PreventivoAmarillo from '../../assets/images/tareas/preventivo_amarillo.svg'
import PreventivoTicketRojo from '../../assets/images/tareas/preventivostickets-06-rojo.svg'
import VencimientoVerde from '../../assets/images/tareas/vencimiento_verde.svg'
import VencimientoRojo from '../../assets/images/tareas/vencimiento_rojo.svg'
import VencimientoAmarillo from '../../assets/images/tareas/vencimiento_amarillo.svg'
import VencimientoTicketRojo from '../../assets/images/tareas/vencimientostickets-07-rojo.svg'
import Calendario from '../../assets/images/tareas/calendario.svg'
import Autopista from '../../assets/images/tareas/autopista.svg'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import Switch from "react-switch"
import { Transform } from 'stream';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class BasesGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.dataService = '/bases/grid';
		this.columnsToPrint = [2,3,4,5,6,7];
		moment.locale('es');

		this.state = {
			redirectTo: null,
			tipo: null,
			editKmEnabled: false,
			maxDifferenceKM: 0,
			canModifyKM: false,
			regionEnabled: false,
			centroCostosEnabled: false,

			dataToExport :{
				base : true,
				subregion : true,
				region : true,
				gerenciador : true,
				activa : true,
				jefe : true,
				responsable : true,
				tipo : true,
				centroCostos : true,
				administrador : true,
				telefono : true,
				calle : true,
				numero : true,
				observaciones : true,
				pais : true,
				provincia : true,
				localidad : true
				
			},
			ExportarTodo : true
		}
	}

	handleDataExport(nombre){
		const miState = {...this.state};
		switch (nombre) {
			case "base":
				miState.dataToExport.base = !miState.dataToExport.base;
		break;
			case "subregion":
				miState.dataToExport.subregion = !miState.dataToExport.subregion;
		break;
			case "region":
			miState.dataToExport.region = !miState.dataToExport.region;
		break;
			case "jefe":
			miState.dataToExport.jefe = !miState.dataToExport.jefe;
		break;
			case "responsable":
			miState.dataToExport.responsable = !miState.dataToExport.responsable;
		break;
			case "gerenciador":
			miState.dataToExport.gerenciador = !miState.dataToExport.gerenciador;
		break;
			case "activa":
			miState.dataToExport.activa = !miState.dataToExport.activa;
		break;
			case "tipo":
			miState.dataToExport.tipo = !miState.dataToExport.tipo;
		break;
			case "centroCostos":
			miState.dataToExport.centroCostos = !miState.dataToExport.centroCostos;
		break;		
			case "administrador":
			miState.dataToExport.administrador = !miState.dataToExport.administrador;
		break;
			case "telefono":
			miState.dataToExport.telefono = !miState.dataToExport.telefono;
		break;
			case "calle":
			miState.dataToExport.calle = !miState.dataToExport.calle;
		break;
			case "numero":
			miState.dataToExport.numero = !miState.dataToExport.numero;
		break;
			case "aobservaciones":
			miState.dataToExport.observaciones = !miState.dataToExport.observaciones;
		break;
			case "pais":
			miState.dataToExport.pais = !miState.dataToExport.pais;
		break;
			case "provincia":
			miState.dataToExport.provincia = !miState.dataToExport.provincia;
		break;
			case "localidad":
			miState.dataToExport.localidad = !miState.dataToExport.localidad;
		break;
		}
		this.setState(miState);
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('BASES_LISTAR')) {
	      this.ajaxHandler.subscribe(this);
				this.initGrid(this.props.intl);
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').tooltip('dispose');
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	handleExportExcel() {
		let component = this;
		this.setState({loading:true});
		component.ajaxHandler.fetch('/bases/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				colsAExportar: this.state.dataToExport
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-bases.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}
	
	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												{Security.renderIfHasPermission('BASES_CREAR', (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'basesGrid.button.nuevo.title', defaultMessage: 'Nuevo' })}>
													<i className="ft-plus"></i>
												</div>
												))}
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover dataTableMovil" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
												<th className=""></th>
													<th className="p-0"></th>
													<th className="dt-search-header"><FormattedMessage id="basesGrid.column.base" defaultMessage="Base"/></th>
													<th className="dt-search-header"><FormattedMessage id="basesGrid.column.region" defaultMessage="Región"/></th>
													<th className="dt-search-header"><FormattedMessage id="basesGrid.column.sub_region" defaultMessage="Sub Región"/></th>
													<th className="dt-search-header"><FormattedMessage id="basesGrid.column.jefe" defaultMessage="Jefe"/></th>
													<th className="dt-search-header"><FormattedMessage id="basesGrid.column.responsable" defaultMessage="Responsable"/></th>
													<th className="dt-search-header"><FormattedMessage id="basesGrid.column.gerenciador" defaultMessage="Gerenciador"/></th>
													<th className=""></th>
													
													
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="basesGrid.exportar_bases" defaultMessage=" Exportar Bases"/></h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<div className="row">
								<div className="col-md-12">
									{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
										{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
									</div>*/}
									<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
										<div className="form-body">
											<div className="card pull-up">
												<div className="card-content">
													<div className="card-body">
														<h4 className="form-section">
															<i className="la la-columns"></i><FormattedMessage id="basesGrid.modal.columnas_exportar" defaultMessage=" Columnas a exportar"/>
														</h4>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="base">
																	<FormattedMessage id="basesGrid.modal.base" defaultMessage="Base:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("base")}
																	checked = {this.state.dataToExport.base ? true : false}
																	value={this.state.dataToExport.base}
																	id = "base"
																	name = "base"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"										
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="subregion">
																	<FormattedMessage id="basesGrid.modal.sub_region" defaultMessage="Sub Región:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("subregion")}
																	checked = {this.state.dataToExport.subregion ? true : false}
																	value={this.state.dataToExport.subregion}
																	id = "subregion"
																	name = "subregion"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"														
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="region">
																	<FormattedMessage id="basesGrid.modal.region" defaultMessage="Region:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("region")}
																	checked = {this.state.dataToExport.region ? true : false}
																	value={this.state.dataToExport.region}
																	id = "region"
																	name = "region"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"										
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="jefe">
																<FormattedMessage id="basesGrid.modal.jefe" defaultMessage="Jefe:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("jefe")}
																	checked = {this.state.dataToExport.jefe ? true : false}
																	value={this.state.dataToExport.jefe}
																	id = "jefe"
																	name = "jefe"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="responsable">
																	<FormattedMessage id="basesGrid.modal.responsable" defaultMessage="Responsable:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("responsable")}
																	checked = {this.state.dataToExport.responsable ? true : false}
																	value={this.state.dataToExport.responsable}
																	id = "responsable"
																	name = "responsable"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"														
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="gerenciador">
																<FormattedMessage id="basesGrid.modal.gerenciador" defaultMessage="Gerenciador:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("gerenciador")}
																	checked = {this.state.dataToExport.gerenciador ? true : false}
																	value={this.state.dataToExport.gerenciador}
																	id = "gerenciador"
																	name = "gerenciador"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="activa">
																	<FormattedMessage id="basesGrid.modal.activa" defaultMessage="Activa:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("activa")}
																	checked = {this.state.dataToExport.activa ? true : false}
																	value={this.state.dataToExport.activa}
																	id = "activa"
																	name = "activa"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"										
																/>
															</div>
															{(this.state.centroCostosEnabled) ? 
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="centroCostos">
																	<FormattedMessage id="basesGrid.modal.centro_costos" defaultMessage="Activa:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("centroCostos")}
																	checked = {this.state.dataToExport.centroCostos ? true : false}
																	value={this.state.dataToExport.centroCostos}
																	id = "centroCostos"
																	name = "centroCostos"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>: ""}
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="tipo">
																	<FormattedMessage id="basesGrid.modal.tipo" defaultMessage="Tipo:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("tipo")}
																	checked = {this.state.dataToExport.tipo ? true : false}
																	value={this.state.dataToExport.tipo}
																	id = "tipo"
																	name = "tipo"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"														
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="administrador">
																	<FormattedMessage id="basesGrid.modal.administrador" defaultMessage="Administrador:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("administrador")}
																	checked = {this.state.dataToExport.administrador ? true : false}
																	value={this.state.dataToExport.administrador}
																	id = "administrador"
																	name = "administrador"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"										
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="telefono">
																	<FormattedMessage id="basesGrid.modal.telefono" defaultMessage="Teléfono:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("telefono")}
																	checked = {this.state.dataToExport.telefono ? true : false}
																	value={this.state.dataToExport.telefono}
																	id = "telefono"
																	name = "telefono"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"										
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="calle">
																	<FormattedMessage id="basesGrid.modal.calle" defaultMessage="Calle:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("calle")}
																	checked = {this.state.dataToExport.calle ? true : false}
																	value={this.state.dataToExport.calle}
																	id = "calle"
																	name = "calle"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>																				
														</div>
														<div className="row m-1">
														<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="numero">
																	<FormattedMessage id="basesGrid.modal.nro" defaultMessage="Nro.:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("numero")}
																	checked = {this.state.dataToExport.numero ? true : false}
																	value={this.state.dataToExport.numero}
																	id = "numero"
																	name = "numero"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"														
																/>
															</div>
														<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="observaciones">
																	<FormattedMessage id="basesGrid.modal.observaciones" defaultMessage="Observaciones:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("observaciones")}
																	checked = {this.state.dataToExport.observaciones ? true : false}
																	value={this.state.dataToExport.observaciones}
																	id = "observaciones"
																	name = "observaciones"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"														
																/>
															</div>
														
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="pais">
																<FormattedMessage id="basesGrid.modal.pais" defaultMessage="País:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("pais")}
																	checked = {this.state.dataToExport.pais ? true : false}
																	value={this.state.dataToExport.pais}
																	id = "pais"
																	name = "pais"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"										
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="provincia">
																	<FormattedMessage id="basesGrid.modal.provincia" defaultMessage="Provincia:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("provincia")}
																	checked = {this.state.dataToExport.provincia ? true : false}
																	value={this.state.dataToExport.provincia}
																	id = "provincia"
																	name = "provincia"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"														
																/>
															</div>
														</div>
														<div className="row m-1">
														<div className=" col-md-3">
															<label className="col-md-6 label-control col-form-label" htmlFor="localidad">
																<FormattedMessage id="basesGrid.modal.localidad" defaultMessage="Localidad:"/>
															</label>
															<Switch
																onChange = {(event) => this.handleDataExport("localidad")}
																checked = {this.state.dataToExport.localidad ? true : false}
																value={this.state.dataToExport.localidad}
																id = "localidad"
																name = "localidad"
																offColor = "#FF4961"
																onColor = "#28D094"
																disabled = {false}
																className =  "col-md-6 align-middle"
															/>
														</div>
													</div>
														
														
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-12 alert alert-info small" role="alert">
											<FormattedMessage id="basesGrid.modal.information" defaultMessage="Esta acción puede demorar unos minutos."/>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="modal-footer modal-grey">
							<button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcel.bind(this)} ><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="basesGrid.modal.button.exportar_excel" defaultMessage=" Exportar a Excel"/></button>
							<button type="button" className="btn btn-danger" onClick={this.handleExportCancel} ><i className="fa fa-times-circle"></i><FormattedMessage id="basesGrid.modal.button.cancelar" defaultMessage=" Cancelar"/></button>
						</div>
					</div>
				</div>
			</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.getData('/gerenciadores/select-filtered-by-user'),	
			this.getData('/regiones/select'),				
			this.getData('/subregiones/select-all'),
			this.getData('/personas/select-jefe'),
			this.getData('/personas/select-responsable')
		]).then((data) => {
			let filters = {
				gerenciador: data[0] ? data[0] : [],	
				region: data[1] ? data[1] : [],		
				subregion: data[2] ? data[2] : [],	
				jefe: data[3] ? data[3] : [],		
				responsable: data[4] ? data[4] : []	
			};
			let dataToExportCopy = JSON.parse(JSON.stringify(component.state.dataToExport));
			dataToExportCopy.centroCostos = ConfigBusiness.get('bases.centroCostos.habilitado') === 'true' ? true : false;
			component.setState({
				/*
				editKmEnabled: ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? true : false,
				maxDifferenceKM: ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? ConfigBusiness.get('moviles.editaKm.maximo') : 0,
				canModifyKM: Security.hasPermission('MOVILES_MODIFICAR_KM'),
				*/
				regionEnabled: ConfigBusiness.get('tickets.region.habilitado') === 'true' ? true : false,
				centroCostosEnabled: ConfigBusiness.get('bases.centroCostos.habilitado') === 'true' ? true : false,
				dataToExport: dataToExportCopy
			});

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_BasesGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_BasesGrid'));
					},
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService,
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_BasesGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'basesGrid.filter.placeholder', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'basesGrid.base.filter.placeholder', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}

								//menu-estado
								if (sets.aoColumns[index].filterType === 'menuEstado') {
																		
									var menuEstado = $('<div class="btn-group dropright"><button type="button" class="dropdown-toggle button-embudo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ft-filter"></i></button><div class="dropdown-menu" id="menuEstadoDrop"><h6 class="dropdown-header">Estado</h6></div></div>')
											.appendTo($(column.footer()).empty());
										
											filters[sets.aoColumns[index].name].map(e => {
												$('#menuEstadoDrop').append('<button class="dropdown-item" value="' + e.value + '">' + e.label + '</button>');
												return true;
											});
											$('#menuEstadoDrop').on('click', function(e) {
												var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
													.search(e.target.value ? e.target.value : '', true, false)
												.draw();
											});
									if (stateColumns && stateColumns[index].search.search) menuEstado.val(stateColumns[index].search.search);
										
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [{
						orderable: false,
							title: '',
							data: 'descripcion',
							
							render: function (data, type, row) {
								return '<div class="dt-user-avatar">'+row.descripcion.substr(0,1)+'</div>';
							},
							filterType: 'none'
					}, {
						title: '',
								data: 'activa',
								width: 3,
								padding: 0,
								orderable: false,
								className: 'p-0',
								render: function (data, type, full, meta) {
									return '';
								},
								createdCell: function (td, cellData, rowData, row, col) {
									if (cellData) {
										$(td).addClass('status-green');
									} else {
										$(td).addClass('status-red');
									}
								},
								filterType: 'none'
					}, {
						title: this.props.intl.formatMessage({ id: 'basesGrid.select.column.base', defaultMessage: 'Base' }),
						className: 'all',
						data: 'descripcion',
						filterType: 'input'
					}, {
						name: 'subregion',
						title: this.props.intl.formatMessage({ id: 'basesGrid.select.column.subRegion', defaultMessage: 'Sub Región' }),
						className: 'all',
						data: 'subregion',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'region',
						title: this.props.intl.formatMessage({ id: 'basesGrid.select.column.region', defaultMessage: 'Region' }),
						className: 'all',
						data: 'region',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'jefe',
						title: this.props.intl.formatMessage({ id: 'basesGrid.select.column.jefe', defaultMessage: 'Jefe' }),
						className: 'all',
						orderable: false,
						data: 'jefe',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
		            }, {
						name: 'responsable',
						title: this.props.intl.formatMessage({ id: 'basesGrid.select.column.responsable', defaultMessage: 'Responsable' }),
						className: 'all',
						data: 'responsable',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'gerenciador',
						title: this.props.intl.formatMessage({ id: 'basesGrid.select.column.gerenciador', defaultMessage: 'Gerenciador' }),
						className: (component.state.regionEnabled) ? 'all' : 'd-none',
						data: 'gerenciador',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.razonSocial ? data.razonSocial : '';
							}
						},
						filterType: 'select'
					}, {
						orderable: false,
						data: null,
						className: "text-center all",
						width: '120px',
						render: function(data, type, full, meta) {
							let html =
								(Security.hasPermission('BASES_VISUALIZAR') ? `
								<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" data-title="Ver" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('BASES_MODIFICAR') ? `
								<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" data-title="Editar" data-togle="tooltip" data-placement="top">
									<i class="fa fa-pencil fa-xs"></i>
								</button>` : '') 
							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'

					}],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							
							component.setState({
								redirectTo: redirectTo
							});
						});
					}
				}, datatablesConfig(this.props.intl))

				
			);			

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: this.props.intl.formatMessage({ id: 'basesGrid.filter.reset.restaurar_filtros', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 0, 'asc' ]);
						dt.ajax.reload();
					}
				}, {
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>Listado de Móviles</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: this.props.intl.formatMessage({ id: 'basesGrid.filter.imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: this.props.intl.formatMessage({ id: 'basesGrid.select.columns.seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: this.props.intl.formatMessage({ id: 'basesGrid.exportar_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						component.setState({
							exportFechaDesde: moment().startOf('year'),
							exportFechaHasta: moment()
						}, () => {
							$('#export_modal').modal({backdrop: 'static'});
						});
					}
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

}

export default injectIntl(BasesGrid);
