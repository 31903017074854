import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import { withRouter } from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
am4core.useTheme(am4themes_animated);

class BasicCardMultipleValuesInfo extends Component{   
  
    constructor(props) {
        super(props);

        this.ajaxHandler = new AjaxHandler();

        moment.locale('es');


        this.state = {
            redirectTo: null,
            props: this.props,
            backColor: 'white',
            clicked: false
        };

        this.clickedColor = '#e2e2e2';
    }

    componentDidMount(){
      //this.setState({backColor: this.props.values.backColor});
    }

    handleFilter(filter){
      if (filter === this.props.selectFilter)
        this.props.onClickAlert("");
      else
        this.props.onClickAlert(filter);
    }

    render() {
      let values = this.props.values;
      //let props = {value: 100, description: "alguna descripción", entity: "moviles", image: CargasVede.svg, backColor:"#d7dcf3", iconSize:"60px"}
      
      return (
        <div className="card pull-up">
          <div className="card-content" style={{ backgroundColor: this.props.backColor, borderRadius: ".45rem" }}>
            <div className="card-body" style={{ padding: "15px" }}>
              <div className="text-right">
                <h5 className="text-muted text-bold-500"><FormattedMessage {...(backendStrings[values.description] ? backendStrings[values.description] : backendStrings['errors.default'])}/></h5>
              </div>
              <div className="media d-flex">
                <div className="align-self-center">
                  <img width={ values.iconSize } height={ values.iconSize } src={require('../../assets/images/'+ values.entity +'/'+ values.image)}/>
                </div>
                <div className="media-body text-right">
                  <h3 className="text-bold-600">
                    {values.items.map((item, index) => {
                      let leftLabel = item.leftLabel ? item.defaultLabel + " " : "";
                      let leftLabelId = item.leftLabel ? item.label : ""
                      let rightLabel = item.rightLabel ? " " + item.defaultLabel : "";
                      let rightLabelId = item.rightLabel ? item.label : "";
                      let value = !item ? 0 : item.value;
                      let decimalNumber = item ? item.decimalNumber : 0;
                      return  (
                      <span
                        style={{ 
                          cursor: item.cursor,
                          color: (this.props.selectFilter === item.filter && item.filter) ? 'gray' : ''
                        }}
                        onClick={item.cursor == 'pointer' ? this.handleFilter.bind(this,item.filter): null}
                        key={index}
                        > 
                        {item.translation ? (
                          <p>
                            {leftLabelId ? 
                              <FormattedMessage {...(backendStrings[leftLabelId] ? backendStrings[leftLabelId] : backendStrings['errors.default'])}/>
                            : ''}{' '}
                            {(decimalNumber !== 0 ? parseFloat(value).toFixed(decimalNumber) : value)}
                            {' '}{rightLabelId ?
                              <FormattedMessage {...(backendStrings[rightLabelId] ? backendStrings[rightLabelId] : backendStrings['errors.default'])}/>
                            : ''}
                          </p>
                          )
                          : 
                          <p>
                            {leftLabel+ ' ' + (decimalNumber !== 0 ? parseFloat(value).toFixed(decimalNumber) : value) + ' ' + rightLabel}
                          </p>
                        }
                        </span>
                      );
                    })}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

} export default BasicCardMultipleValuesInfo;
