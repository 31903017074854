import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import Config from '../../../commons/config/Config.js'
import $ from 'jquery'
import Bar from './Bar.js'
import Security from '../../../commons/security/Security'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../../lang/backendStrings.js';

am4core.useTheme(am4themes_animated);

class Tabla extends Component{   
  
constructor(props) {
  super(props);

  this.ajaxHandler = new AjaxHandler();

 moment.locale('es');


this.state = {
  redirectTo: null,
  props: this.props
  
  };

  //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);

}
componentWillReceiveProps(nextProps){ 
 }

 componentDidMount(){
  if(Security.hasPermission('DASHBOARD_OPERATIVO2_VISUALIZAR')) {
    this.ajaxHandler.subscribe(this);
  } else {
      this.setState({
        redirectTo: '/error'
      });
  }
 }

  componentWillUnmount() {
    
    $('.tooltip').tooltip("dispose");
  }

  handleExportExcel(fila) {
		let component = this;
    this.setState({ loading: true });
    let dataToExport = {dominio:true, ticket: true, gerenciador:true, diasTranscurridos: true, fechaDesde: true, fechaHasta: true,
      listoParaRetirar: null, cierre: null, aprobado:null, presupuestado: null, creado: null, fechaTurno:null,  fechaAsignoTurno: null, asignoGerenciador: null};
    let filtro = '';
    switch (fila) { 
      case 'Cerrado Ticket':
        filtro = "export_cerrado";
        dataToExport.listoParaRetirar= true;
        dataToExport.cierre = true;
        break;
      case 'Tiempo de Servicio':
        dataToExport.listoParaRetirar= true;
        dataToExport.aprobado= true;
        filtro = "export_listoParaRetirar";
        break;
      case 'Aprobar Ticket':
        dataToExport.presupuestado= true;
        dataToExport.aprobado= true;
        filtro = "export_aprobado"
        break;
      case 'Presupuestar Ticket':
        dataToExport.creado= true;
        dataToExport.presupuestado= true;
        filtro = "export_presupuestado";
        break;
      case 'Días hasta Turno':
        dataToExport.fechaTurno= true;
        dataToExport.creado= true;
        filtro = "export_turno";
        break;
      case 'Asignación de Turno':
        dataToExport.fechaAsignoTurno= true;
        dataToExport.creado= true;
        filtro = "export_asignacionTurno";
        break;
      case 'Asignación de Gerenciador':
        dataToExport.asignoGerenciador= true;
        dataToExport.creado= true;
        filtro = "export_asignacionGerenciador"
        break;
    }

		component.ajaxHandler.fetch('/tabla/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				fechaDesde: moment(this.props.fechaDesde).format('YYYY-MM-DD'),
        fechaHasta: moment(this.props.fechaHasta).format('YYYY-MM-DD'),
        tipoTicket: this.props.tipo.label,
        gerenciador: this.props.gerenciador.value,
        filtro: filtro,
        colsAExportar: dataToExport,
        factura: this.props.soloFactura 
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-estadisticas.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

render() {
  
  return(
    
    <div className="box box-info" style={{width: "100%"}}>
        <div className="table-responsive">
          <table className="table no-margin">
            <thead>
            <tr>
              <th><FormattedMessage id="tabla.columns.label.accion" defaultMessage="Acción"/></th>
              <th><FormattedMessage id="tabla.columns.label.objetivo" defaultMessage="Objetivo"/></th>
              <th><FormattedMessage id="tabla.columns.label.promedio" defaultMessage="Promedio"/></th>
              <th><FormattedMessage id="tabla.columns.label.mas_menos" defaultMessage="+/-"/></th>
              {this.props.mostrarColumnasGerenciador ? <th><FormattedMessage id="tabla.columns.label.ultimos_quince_resultados" defaultMessage="Últimos 15 Resultados"/></th> : ''}
              {this.props.mostrarColumnasGerenciador ? <th><FormattedMessage id="tabla.columns.label.exportar" defaultMessage="Exportar"/></th>: ''}
            </tr>
            </thead>
            <tbody>
            {this.props.tabla.map((tabla, indexT)=>{
                return(
                  <tr key={indexT}>
                    <td>{this.props.intl.formatMessage({ id: tabla.accionLabelId, defaultMessage: tabla.accion })}{tabla.accion === "Cerrado Ticket" && this.props.soloFactura ? this.props.intl.formatMessage({ id: 'tabla.columns.accion.information_additional.solo_con_factura', defaultMessage: ' (solo con factura)' }) : ""}</td>
                    <td>{tabla.objetivo}</td>
                    <td>{tabla.avg}</td>
                    <td style={{color: parseInt(tabla.masMenos)>0 ? "#800000": "#008000"}}>{tabla.masMenos ? tabla.masMenos+'%' : ''}</td>
                    {this.props.mostrarColumnasGerenciador ?
                      <td>
                        <table><tbody><tr>
                    {tabla.resultados.map((resultados, index)=>{
                      $('[data-tooltip="tooltip"]').tooltip({ trigger: "hover" });
                    return(   
                      parseInt(resultados.value) === 0 ?
                       ''
                       : 
                      parseInt(resultados.value) > 0 ? 
                      <td className="barras" key={index} style={{backgroundColor: parseInt(resultados.value)<0 ? "#800000": "#008000"}}></td> 
                      :
                      <td className="barras" key={index} style={{backgroundColor: parseInt(resultados.value)<0 ? "#800000": "#008000"}}
                      data-tooltip="tooltip" data-placement="top" title="" data-original-title={resultados.tooltip}
                      ><a href={"/tickets/"+resultados.ticket} className="nav-link" target="_blank" key={'enlace'+index.toString()}></a></td>
                    );
                    
                    })}</tr>
                    </tbody>
                    </table>
                    </td> : ''}
                    {this.props.mostrarColumnasGerenciador ?
                      <td style={{textAlign: "center"}} onClick={this.handleExportExcel.bind(this, tabla.accion)}><i style={{cursor: "pointer"}} className="ft-upload" ></i></td>
                    : ''}
                  </tr>
                );
              })}                        
            </tbody>
          </table>
        </div>
      </div>      
  );
}

} export default injectIntl(Tabla);

