import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import 'bootstrap4-duallistbox';
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css';
import FormValidation from '../../commons/validation/FormValidation.js';
import Validator from '../../commons/validation/Validator.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import Loading from '../ui/Loading.js';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/min/locales';
import 'react-datepicker/dist/react-datepicker.css';
import Security from '../../commons/security/Security.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PuntoDinamico from '../puntoDinamico/PuntoDinamico';
import * as ConstantsAttribute from '../puntoDinamico/constants';
import * as Utils from '../../commons/utils/Utils';
import * as ConstantsMedidor from '../unidadMedidor/constants.js';
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js';
import swal2 from 'sweetalert2';

class LlantasModelosAbm extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();
    moment.locale('es');
    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        nombre: '',
        marca: null,
        medida: null,
        psi: 100,
        desgaste_prematuro: '',
        recapado: '',
        fechaAlta: null,
        mmInicio: '',
        puntosMedicion: [],
        unidad_medidor_id: 1,
      },
      originalData: {},
      loading: false,
      medidas: null,
      marcas: [],
      errors: [],
      psiSelected: [{ value: 1, label: 100 }],
    };
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
    this.handleAddAtributoDinamico = this.handleAddAtributoDinamico.bind(this);
    this.handleDeleteAtributoDinamico = this.handleDeleteAtributoDinamico.bind(this);
    this.handleChangeUnidadMedidor = this.handleChangeUnidadMedidor.bind(this);

    this.formValidation = new FormValidation({
      component: this,
      validators: {
        'formData.nombre': (value) => Validator.notEmpty(value),
        'formData.marca': (value) => Validator.notEmpty(value),
        'formData.medida': (value) => Validator.notEmpty(value),
        'formData.psi': (value) => Validator.notEmpty(value),
        'formData.fechaAlta': (value) => Validator.notEmpty(value),
        'formData.mmInicio': (value) => Validator.notEmpty(value),
      },
    });
  }

  componentDidMount() {
    if (
      (Security.hasPermission('LLANTAS_MODELOS_CREAR') && this.state.props.action === 'ADD') ||
      (Security.hasPermission('LLANTAS_MODELOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
      (Security.hasPermission('LLANTAS_MODELOS_VISUALIZAR') && this.state.props.action === 'VIEW')
    ) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error',
      });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  onDragEnd(result) {
    this.setState({ loading: true });
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let atributos_dinamicos = formDataCopy.puntosMedicion;
    let atributoEnMovimiento = atributos_dinamicos[result.source.index];
    atributos_dinamicos.splice(result.source.index, 1);
    atributos_dinamicos.splice(result.destination.index, 0, atributoEnMovimiento);
    this.setState({ formData: formDataCopy }, () => this.setState({ loading: false }));
  }

  initForm() {
    let component = this;

    if (this.state.props.action === 'VIEW' || this.state.props.action === 'EDIT')
      this.loadFormData();
    else this.handleAddAtributoDinamico();

    Promise.all([
      this.ajaxHandler.getJson('/llantamarcas'),
      this.ajaxHandler.getJson('/llantamedidas'),
    ]).then((data) => {
      this.setState({ marcas: data[0] });
      this.setState({ medidas: data[1] });
    }).catch(function(error) {
      console.log(error);
    });
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler
      .getJson('/llantamodelos/' + this.state.props.match.params.id)
      .then((data) => {
        console.log(data);
        let formData = {};
        formData = data;
        console.log(formData);
        formData.puntosMedicion = data.puntos_medicion;
        formData.medida = { id: data.llanta_medida.id, nombre: data.llanta_medida.nombre };
        formData.puntosMedicion = formData.puntosMedicion.map((atributo) => {
          atributo.value = atributo.valorDefault;
          return atributo;
        });
        formData.marca = { id: data.llanta_marca.id, nombre: data.llanta_marca.nombre };
        //formData.clase = {id: data.llanta_marca.id, la: data.llanta_marca.nombre };
        this.setState({ formData: formData, originalData: formData });
      }).catch(function(error) {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleAddAtributoDinamico() {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let templateAtributoCopy = JSON.parse(JSON.stringify(ConstantsAttribute.TEMPLATE_ATRIBUTO));
    formDataCopy.puntosMedicion.push(templateAtributoCopy);
    this.setState({
      formData: formDataCopy,
    });
  }

  handleDeleteAtributoDinamico(index) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.puntosMedicion.splice(index, 1);
    this.setState({
      formData: formDataCopy,
    });
  }

  handleChangeAtributoDinamico(index, key, value) {
    let atributoDinamico = this.state.formData.puntosMedicion[index];
    atributoDinamico[key] = value;
    if (key == 'value') atributoDinamico.valorDefault = value;
    this.setState({
      formData: this.state.formData,
    });
  }

  handleInputFormChange(event) {
    event.persist();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    this.setState({ formData: formDataCopy });
  }

  handleSelectChange(event) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['marca'] = event;
    this.setState({ formData: formDataCopy });
  }

  handleMedidaSelect(event) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['medida'] = event;
    this.setState({ formData: formDataCopy });
  }

  handleChangeUnidadMedidor(event) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['unidad_medidor_id'] = event;
    this.setState({ formData: formDataCopy });
  }

  handleDatePickerFormChange(name, event) {
    let date = event ? event.format() : null;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = date;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = date === '' ? null : date;
    this.setState({
      formData: formDataCopy,
    });
  }

  checkForDuplicate() {
    return new Promise((resolve, reject) => {
      Utils.sendData(`/llantamodelos/checkDuplicated`, 'POST', this.state.formData)
        .then(() => resolve())
        .catch((err) => {
          this.setState({
            errors: err.response.data.detalle,
          });
          reject(err);
        });
    });
  }

  createLlanta() {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    this.setState({ loading: true });
    let component = this;
    this.state.formData.puntosMedicion.map((atributo, index) => {
      atributo.order = index;
      return atributo;
    });

    this.ajaxHandler
      .fetch('/llantamodelos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
        method: this.props.action === 'ADD' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...this.state.formData,
        }),
      })
      .then((response) => {
        if (response.status !== 400) {
          component.exit();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleResponseErrorsIsValid(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.action === 'EDIT') {
      this.createLlanta();
    } else {
      this.checkForDuplicate()
        .then(() => this.createLlanta())
        .catch((err) => console.log(err));
    }
  }

  handleCancel(event) {
    this.exit();
  }

  exit() {
    this.setState({
      redirectTo: '/llantamodelos',
    });
  }
  render() {
    this.formValidation.validate();
    let marca = this.state.formData.marca
      ? this.state.marcas.find((e) => e.value === this.state.formData.marca.id)
      : null;
    let state = this.state;
    let formData = state.formData;
    let validationState = this.formValidation.state;

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage
                    {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}
                  />
                </li>
              ))}
            </div>
            <form className="form form-horizontal" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i>{' '}
                        <FormattedMessage
                          id="llantasModelosAbm.render.general_data.header_datos_generales"
                          defaultMessage="Datos Generales"
                        />{' '}
                        <div className="float-right" style={{ fontSize: '14px' }}>
                          *{' '}
                          <FormattedMessage
                            id="llantasModelosAbm.render.general_data.campos_requeridos"
                            defaultMessage="campos requeridos"
                          />
                        </div>
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="diseño"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_modelo"
                                defaultMessage="Modelo"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.nombre ? this.state.formData.nombre : ''}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="nombre"
                                    name="nombre"
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'llantasModelosAbm.render.general_data.placeholder_modelo',
                                      defaultMessage: 'Requerido',
                                    })}
                                    value={this.state.formData.nombre}
                                    onChange={this.handleInputFormChange}
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.nombre.pristine ||
                                      validationState.formData.nombre.valid
                                    }
                                  >
                                    {validationState.formData.nombre.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="psi">
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_psi"
                                defaultMessage="PSI"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.psi ? Number.parseFloat(this.state.formData.psi).toFixed(1) : ''}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="psi"
                                    name="psi"
                                    placeholder="100.0"
                                    value={this.state.formData.psi}
                                    onChange={this.handleInputFormChange}
                                    step="0.01"
                                    pattern="^\d+(?:\.\d{1,2})?$"
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.psi.pristine ||
                                      validationState.formData.psi.valid
                                    }
                                  >
                                    {validationState.formData.psi.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ANOTHER ROW */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="marca"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_marca"
                                defaultMessage="Marca"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.marca
                                    ? this.state.formData.marca.nombre
                                    : ''}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="marca"
                                    name="marca"
                                    className="select-personal-llantas"
                                    // placeholder={!state.basesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_base', defaultMessage: 'Seleccione' }) : ''}
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'llantasModelosAbm.render.general_data.placeholder_marca',
                                      defaultMessage: 'Seleccione',
                                    })}
                                    options={this.state.marcas}
                                    valueKey="id"
                                    labelKey="nombre"
                                    onChange={(e) => this.handleSelectChange(e)}
                                    value={this.state.formData.marca}
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.marca.pristine ||
                                      validationState.formData.marca.valid
                                    }
                                  >
                                    {validationState.formData.marca.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="medida"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_medida"
                                defaultMessage="Medida"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.medida
                                    ? this.state.formData.medida.nombre
                                    : ''}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="medida"
                                    name="medida"
                                    // placeholder={!state.basesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_base', defaultMessage: 'Seleccione' }) : ''}
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'llantasModelosAbm.render.general_data.placeholder_medida',
                                      defaultMessage: 'Seleccione',
                                    })}
                                    className="select-personal-llantas"
                                    options={this.state.medidas}
                                    valueKey="id"
                                    labelKey="nombre"
                                    disabled={this.state.medidas ? false : true}
                                    onChange={(e) => this.handleMedidaSelect(e)}
                                    value={this.state.formData.medida}
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.medida.pristine ||
                                      validationState.formData.medida.valid
                                    }
                                  >
                                    {validationState.formData.medida.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ANOTHER ROW */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="Reencauches"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_reencauches"
                                defaultMessage="Reencauches"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.recapado !== null
                                    ? this.state.formData.recapado
                                    : ''}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="recapado"
                                    name="recapado"
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'llantasModelosAbm.render.general_data.placeholder_recapados',
                                      defaultMessage: 'Requerido',
                                    })}
                                    value={this.state.formData.recapado}
                                    onChange={this.handleInputFormChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="mmInicio"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_mmInicio"
                                defaultMessage="Mm en Momento de Compra"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.mmInicio ? this.state.formData.mmInicio : ''}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="mmInicio"
                                    name="mmInicio"
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'llantasModelosAbm.render.general_data.placeholder_mmInicio',
                                      defaultMessage: 'Requerido',
                                    })}
                                    value={this.state.formData.mmInicio}
                                    onChange={this.handleInputFormChange}
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.mmInicio.pristine ||
                                      validationState.formData.mmInicio.valid
                                    }
                                  >
                                    {validationState.formData.mmInicio.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ANOTHER ROW */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="fechaAlta"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_fechaAlta"
                                defaultMessage="Fecha de Alta"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.fechaAlta
                                    ? moment(this.state.formData.fechaAlta).format('YYYY/MM/DD')
                                    : ''}
                                </div>
                              ) : (
                                <div>
                                  <DatePicker
                                    id="fechaAlta"
                                    name="fechaAlta"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={this.props.intl.formatMessage({
                                      id: 'llantasModelosAbm.render.general_data.placeholder_fecha_de_alta',
                                      defaultMessage: 'DD/MM/AAAA',
                                    })}
                                    selected={
                                      formData.fechaAlta === null ||
                                      formData.fechaAlta === '0000-00-00'
                                        ? null
                                        : moment(formData.fechaAlta)
                                    }
                                    onChange={(event) =>
                                      this.handleDatePickerFormChange('fechaAlta', event)
                                    }
                                    onChangeRaw={(event) =>
                                      this.handleDatePickerFormRawChange(
                                        'fechaAlta',
                                        event.target.value,
                                      )
                                    }
                                    maxDate={moment()}
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.fechaAlta.pristine ||
                                      validationState.formData.fechaAlta.valid
                                    }
                                  >
                                    {validationState.formData.fechaAlta.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="fechaAlta"
                            >
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_desgaste_prematuro"
                                defaultMessage="Desgaste Prematuro"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.desgaste_prematuro
                                    ? this.state.formData.desgaste_prematuro
                                    : ''}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="number"
                                    className="form-control col-md-5"
                                    id="desgaste_prematuro"
                                    name="desgaste_prematuro"
                                    placeholder="mm"
                                    value={this.state.formData.desgaste_prematuro}
                                    onChange={this.handleInputFormChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ANOTHER ROW */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="tipo">
                              <FormattedMessage
                                id="llantasModelosAbm.render.general_data.label_unidad_medidor"
                                defaultMessage="Unidad Medidor"
                              /> :
                            </label>
                            <div className="col-md-8">
                              <SelectWithIcons
                                placeholder="Tipo de llanta"
                                valueKey="id"
                                disabled={true}
                                labelKey="label"
                                clearable={false}
                                onChange={(e) => this.handleChangeUnidadMedidor(e.id)}
                                value={this.state.formData.unidad_medidor_id}
                                options={Object.values(ConstantsMedidor.TIPOS_UNIDAD_MEDIDOR)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.loading && (
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={'1'}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={snapshot.isDraggingOver ? { background: 'lightblue' } : {}}
                        >
                          {this.state.formData.puntosMedicion.map(
                            (atributoDinamico, index) =>
                              atributoDinamico &&
                              (atributoDinamico.activo || this.state.mostrarInactivos) && (
                                <PuntoDinamico
                                  isLlanta={true}
                                  mostrarInactivos={this.state.mostrarInactivos}
                                  handleDelete={this.handleDeleteAtributoDinamico}
                                  index={index}
                                  key={index}
                                  previsualizacion={true}
                                  modulo={'llantas'}
                                  disabled={this.state.props.action === 'VIEW'}
                                  draggable={this.state.props.action !== 'VIEW'}
                                  handleChange={this.handleChangeAtributoDinamico}
                                  formulario={atributoDinamico}
                                  maxValue={this.state.formData.mmInicio}
                                />
                              ),
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-success pull-left mr-1"
                              onClick={this.handleAddAtributoDinamico}
                            >
                              <i className="fa fa-plus"></i>{' '}
                              <FormattedMessage
                                id="llantasModelosAbm.render.add_attribute_button.agregar_atributo_dinamico"
                                defaultMessage="Agregar Atributo Dinámico"
                              />
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary mr-1"
                              disabled={!validationState.form.valid || (this.state.formData.recapado!== '' ? false : true) || (this.state.formData.desgaste_prematuro!== '' ? false : true)}
                            >
                              <i className="fa fa-check-circle"></i>
                              <FormattedMessage
                                id="llantasModelosAbm.finish_button.guardar"
                                defaultMessage=" Guardar"
                              />
                            </button>
                          </>
                        ) : (
                          ''
                        )}
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(e) => this.handleCancel(e)}
                        >
                          <i className="fa fa-times-circle"></i>{' '}
                          {this.props.action === 'VIEW'
                            ? this.props.intl.formatMessage({
                                id: 'llantasModelosAbm.finish_button.volver',
                                defaultMessage: 'Volver',
                              })
                            : this.props.intl.formatMessage({
                                id: 'llantasModelosAbm.finish_button.cancelar',
                                defaultMessage: 'Cancelar',
                              })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(LlantasModelosAbm);
