import $ from 'jquery'
import FormValidation from '../../commons/validation/FormValidation.js'
import Loading from '../ui/Loading.js'
import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import Validator from '../../commons/validation/Validator.js'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import axios from 'axios'
import Switch from "react-switch"

const ajaxConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
    }
}

class GPSModelsABM extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTo: null,
            props: this.props,
            formData: {
                id: 0,
                brand_id: 0,
                brand_name: '',
                name: '',
                provides_odometer: false,
            },
            errors: [],
            loading: false
        }
        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'formData.name': (value) => Validator.notEmpty(value),
                'formData.brand_id': (value) => Validator.notEmpty(value)
            }
        })

        this.handleInputFormChange = this.handleInputFormChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleProvidesOdometerChange = this.handleProvidesOdometerChange.bind(this);
    }

    initForm() {
        let component = this
        if (this.state.props.action === 'ADD') {
            this.setState({ loading: true })

            let formData = { ...this.state.formData }
            formData.brand_id = this.state.props.match.params.id
            this.setState({formData})

            axios.get(Config.get('apiUrlBase') + '/gps_devices/brands/' + this.state.props.match.params.id, ajaxConfig)
                .then((response) => {
                    let formData = { ...this.state.formData }
                    formData.brand_name = response.data.name
                    component.setState({
                        formData: formData
                    })
                }).catch(function(error) {
                    console.log(error)
                    component.exit()
                }).finally(() => {
                    this.setState({ loading: false })
                })
            window.scrollTo(0, 0)
            $('#name').focus()
        }

        if (this.state.props.action === 'EDIT') {
            this.setState({ loading: true })

            axios.get(Config.get('apiUrlBase') + '/gps_devices/models/' + this.state.props.match.params.id, ajaxConfig)
                .then((response) => {
                    let formData = response.data
                    formData.brand_name = response.data.brand.name
                    component.setState({
                        formData: formData
                    })
                }).catch(function(error) {
                    console.log(error)
                    component.exit()
                }).finally(() => {
                    this.setState({ loading: false })
                })
            window.scrollTo(0, 0)
            $('#name').focus()
        }
    }

    componentWillMount() {
        if(
            (this.state.props.action === 'ADD' && Security.hasPermission('GPS_DEVICES_CREATE'))
            || (this.state.props.action === 'EDIT' && Security.hasPermission('GPS_DEVICES_EDIT'))
            || (this.state.props.action === 'VIEW' && Security.hasPermission('GPS_DEVICES_VIEW'))
        ) {
            this.initForm()
        } else {
            this.setState({ redirectTo: '/error' })
        }
    }

    componentWillUnmount() {
        $('div.tooltip[role="tooltip"]').remove()
    }

    handleSubmit(event) {
        this.setState({ loading: true })
        let component = this
        axios({
            method: this.props.action === 'ADD' ? 'post' : 'put',
            url: Config.get('apiUrlBase') + '/gps_devices/models' + (this.props.action === 'ADD' ? '' : ('/' + this.state.formData.id)),
            headers: ajaxConfig.headers,
            data: JSON.stringify({
                ...this.state.formData
            })
        }).then(response => {
            if(response.status !== 400) {
                component.exit()
            } else {
                response.json().then(data => {
                    this.setState({ errors: data.detalle })
                })
            }
            window.scrollTo(0,0)
        }).catch((error) => {

        }).finally(() => {
            this.setState({ loading: false })
        })
        event.preventDefault()
    }

    handleCancel(event) {
        this.exit();
    }

    handleInputFormChange(event) {
        const target = event.target
        const name = target.name
        const value = target.type === 'checkbox' ? target.checked : target.value

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData))
        formDataCopy[name] = value
        this.setState({
            formData: formDataCopy
        })
    }

    handleProvidesOdometerChange(provides_odometer) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy['provides_odometer'] = provides_odometer;
        this.setState({
            formData: formDataCopy
        });
    }

    exit() {
        this.setState({
            redirectTo: '/gps/brands/' + this.state.formData.brand_id
        })
    }

    render() {
        this.formValidation.validate()
        let formData = this.state.formData
        let validationState = this.formValidation.state

        let action = (this.props.action == 'ADD')? 'NUEVO': ((this.props.action == 'EDIT')? 'EDITAR' : 'VER')

        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                {this.state.loading && <Loading />}

                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
                            {this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
                        </div>
                        <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
                            <div className="form-body">
                                <div className="card pull-up">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <h4 className="form-section">
                                                <i className="icon-home"></i>{action}: modelo de GPS <div className="float-right" style={{fontSize:'14px'}}>* campos requeridos</div>
                                            </h4>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row"> {/* CAMBIAR DESDE CSS */} 
                                                        <div className="col-md-6">
                                                            <br></br><br></br><br></br>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* MARCA */}
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="brand_id">
                                                                    Marca *:
                                                                </label>
                                                                <div className="col-md-8">
                                                                    <div className="form-control-static col-form-label form-value">{formData.brand_name}</div>
                                                                    <input type="hidden" id="brand_id" name="brand_id" value={formData.brand_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* NOMBRE */}
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="name">
                                                                    Nombre *:
                                                                </label>
                                                                <div className="col-md-8">
                                                                    {this.props.action === 'VIEW' ? (
                                                                        <div className="form-control-static col-form-label form-value">{formData.name}</div>
                                                                    ) : (
                                                                        <div>
                                                                            <input type="text" className="form-control" id="name" name="name" placeholder="Nombre" value={formData.name} onChange={this.handleInputFormChange} autoFocus />
                                                                            <div className="help-block text-danger field-message" hidden={validationState.formData.name.pristine || validationState.formData.name.valid}>{validationState.formData.name.message}</div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* PROVIDES_ODOMETER */}
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="provides_odometer">
                                                                    Provee odómetro:
                                                                </label>
                                                                <div className="col-md-8">
                                                                    <div>
                                                                        <Switch
                                                                            onChange={this.handleProvidesOdometerChange}
                                                                            checked={formData.provides_odometer ? formData.provides_odometer : false}
                                                                            id="provides_odometer"
                                                                            name="provides_odometer"
                                                                            disabled={this.state.props.action === 'VIEW' ? true : false }
                                                                            offColor="#FF4961"
                                                                            onColor="#28D094"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card pull-up">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="text-cd text-right">
                                                {this.props.action !== 'VIEW' && (
                                                    <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                                                        <i className="fa fa-check-circle"></i> Guardar
                                                    </button>
                                                )}
                                                <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                                                    <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? 'Volver' : 'Cancelar'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default GPSModelsABM
