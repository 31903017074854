import React, { Component } from 'react'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import { Redirect } from 'react-router-dom'
import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import swal from 'sweetalert'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import {FormattedMessage, injectIntl} from 'react-intl';

class GPSModelsGrid extends Component {
    constructor(props) {
        super(props)
        this.ajaxHandler = new AjaxHandler()
        this.dataService = '/gps_devices/brands/' + this.props.brandId + '/models/grid'
        this.columnsToPrint = [0, 1]

        this.state = {
            redirectTo: null,
        }
    }

    componentDidMount(nextProps, nextState) {
        if(Security.hasPermission('GPS_DEVICES_LIST')) {
            this.ajaxHandler.subscribe(this)
            this.initGrid()
        } else {
            this.setState({
                redirectTo: '/error'
            })
        }
    }

    componentWillUnmount() {
        this.ajaxHandler.unsubscribe()
        $('div.tooltip[role="tooltip"]').tooltip('dispose');
        $('div.tooltip[role="tooltip"]').remove()
        if(this.table) this.table.destroy()
    }

    handleNew(event) {
        this.setState({
            redirectTo: this.props.match.url + '/models/add'
        });
    }

    initGrid() {
        try {
            let filters = {
              "provides_odometer": [
                {
                 value: 1,
                 label: 'Sí',
               },
               {
                 value: 0,
                 label: 'No'
               }
              ]

            }
            let component = this
            this.table = $(this.refs.grid).DataTable(
                Object.assign({
                    dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
                    stateSave: true,
                    stateSaveCallback: function(settings, data) {
                         localStorage.setItem('DataTables_GPSModelsGrid', JSON.stringify(data))
                     },
                    stateLoadCallback: function(settings) {
                        return JSON.parse(localStorage.getItem('DataTables_GPSModelsGrid'))
                    },
                    processing: true,
                    serverSide: true,
                    ajax: {
                        type: 'GET',
                        url: Config.get('apiUrlBase') + this.dataService,
                        headers: {
                            'Authorization-Token': localStorage.getItem("token")
                        },
                        dataSrc: function(response) {
                            return response.data
                        },
                        error: function(xhr, error, thrown) {
                            component.ajaxHandler.handleResponseErrorsIsValid(xhr)
                        },
                        cache: false
                    },
                    initComplete: function(settings) {
                        let stateColumns = JSON.parse(localStorage.getItem('DataTables_GPSModelsGrid')).columns
                        var sets = settings
                        var index = 0
                        this.api().columns().every(function() {
                            var column = this
                            if (sets.aoColumns[index].filterType) {
                                if (sets.aoColumns[index].filterType === 'select') {
                                    var select = $('<select class="btn-dt-filter" required><option value="" selected>Buscar...</option></select>')
                                        .appendTo($(column.footer()).empty())
                                        .on('change', function() {
                                            var val = $.fn.dataTable.util.escapeRegex($(this).val())
                                            column
                                                .search(val ? val : '', true, false)
                                                .draw()
                                        });
                                    if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
                                        filters[sets.aoColumns[index].name].map(e => {
                                            select.append('<option value="' + e.value + '">' + e.label + '</option>')
                                            return true
                                        });
                                    } else {
                                        column.data().unique().sort().each(function(d, j) {
                                            select.append('<option value="' + d + '">' + d + '</option>')
                                        });
                                    }
                                    if (stateColumns[index].search.search) select.val(stateColumns[index].search.search)
                                }
                                if (sets.aoColumns[index].filterType === 'input') {
                                    var input = $('<input type="text" class="btn-dt-filter" placeholder="Buscar..." />')
                                    if (stateColumns[index].search.search) input.val(stateColumns[index].search.search)
                                    input.appendTo($(column.footer()).empty())
                                    input.on('keyup change', function() {
                                        if (column.search() !== this.value) {
                                            column
                                                .search(this.value)
                                                .draw()
                                                .ajax.reload(null, false)
                                        }
                                    })
                                }
                            }
                            index++
                            return ''
                        })
                        $('tfoot tr').appendTo('#dataTable > thead')
                    },
                    columns: [
                        {
                            title: 'Id',
                            name: 'id',
                            className: 'all',
                            filterType: 'input',
                        },
                        {
                            title: 'Nombre',
                            className: 'all',
                            name: 'name',
                            filterType: 'input'
                        },
                        {
                            title: 'Provee odómetro',
                            className: 'all',
                            name: 'provides_odometer',
                            render: function(data, type, row) {
                                return data ? 'Sí': 'No'
                            },
                            filterType: 'select'
                        },
                        {
                            orderable: false,
                            data: null,
                            name: 'actions',
                            className: "text-center all",
                            width: '120px',
                            searchable: false,
                            render: function(data, type, full, meta) {
                                let html =
                                    (Security.hasPermission('GPS_DEVICES_EDIT') ? `
                                <button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" data-title="Editar" data-togle="tooltip" data-placement="top">
                                    <i class="fa fa-pencil fa-xs"></i>
                                </button>` : '') +
                                    (Security.hasPermission('GPS_DEVICES_DELETE') ? `
                                <button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="Eliminar" data-togle="tooltip" data-placement="top">
                                    <i class="fa fa-trash fa-xs"></i>
                                </button>` : '');
                                return html;
                            },
                            createdCell: function(td, cellData, rowData, row, col) {
                                $(td).find('button').tooltip();
                            },
                            filterType: 'none'
                        }
                    ],
                    drawCallback: function() {
                        $(this).find('.action').on('click', function(e) {
                            e.preventDefault()
                            let data = component.table.row($(this).parents('tr')).data()
                            let redirectTo
                            if ($(this).hasClass('edit')) redirectTo = '/gps/brands/models/' + data[0] + '/edit'
                            if ($(this).hasClass('delete')) {
                                swal({
                                    title: "¿Confirma la eliminación?",
                                    text: null,
                                    icon: "warning",
                                    buttons: {
                                        confirm: {
                                            text: "Si",
                                            value: true,
                                            visible: true,
                                            className: "btn btn-primary",
                                            closeModal: false
                                        },
                                        cancel: {
                                            text: "No",
                                            value: null,
                                            visible: true,
                                            className: "btn btn-danger",
                                            closeModal: true,
                                        }
                                    }
                                }).then((isConfirm) => {
                                    if (isConfirm) {
                                        fetch(Config.get('apiUrlBase') + '/gps_devices/models/' + data[0], {
                                            method: 'DELETE',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'Authorization-Token': localStorage.getItem("token")
                                            }
                                        }).then(function(response) {
                                            component.table.ajax.reload(null, false);
                                            swal("Eliminada!", "", "success");
                                        }).catch(function(error) {
                                            swal("Error", "", "error");
                                        });
                                    }
                                });
                            }
                            component.setState({
                                redirectTo: redirectTo
                            })
                        })
                    }
                }, datatablesConfig(this.props.intl))
            )
            new $.fn.dataTable.Buttons(this.table, {
                buttons: [{
                    extend: 'colvis',
                    name: 'columns',
                    text: '<i class="la la-columns"></i>',
                    titleAttr: 'Seleccionar columnas'
                }, {
                    extend: 'collection',
                    name: 'export',
                    text: '<i class="ft-upload"></i>',
                    titleAttr: 'Exportar',
                    buttons: [{
                        extend: 'copyHtml5',
                        exportOptions: {
                            columns: ':visible'
                        },
                        text: 'Copiar',
                        titleAttr: 'Copiar'
                    }, {
                        extend: 'excelHtml5',
                        exportOptions: {
                            columns: ':visible'
                        },
                        titleAttr: 'Exportar a Excel'
                    }, {
                        extend: 'csvHtml5',
                        exportOptions: {
                            columns: ':visible'
                        },
                        titleAttr: 'Exportar en formato .csv'
                    }, {
                        extend: 'pdfHtml5',
                        exportOptions: {
                            columns: ':visible'
                        },
                        titleAttr: 'Exportar a PDF'
                    }]
                }]
            })

            this.table.buttons(0, null).container().appendTo('#buttons')

            this.table.button('print:name')
                .nodes()
                .attr('data-toggle', 'tooltip')
                .attr('data-position', 'top')
            this.table.button('columns:name')
                .nodes()
                .attr('data-toggle', 'tooltip')
                .attr('data-position', 'top')
            this.table.button('export:name')
                .nodes()
                .attr('data-toggle', 'tooltip')
                .attr('data-position', 'top')
            this.table.button('import:name')
                .nodes()
                .attr('data-toggle', 'tooltip')
                .attr('data-position', 'top')
            $('[data-toggle="tooltip"]').tooltip()

            $('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
        }
        catch(error) {
            this.ajaxHandler.handleError(error)
        }
        finally{
            this.setState({
                loading: false
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                <div className="row mt-2 w-100">
                    <div className="col-12">
                        <div className="card-content collpase show">
                            <div className="card-body card-dashboard">
                                <div className="container-fluid">
                                    <div className="row dt-icons">
                                        <div className="col-6">
                                            {Security.renderIfHasPermission('GPS_DEVICES_CREATE', (
                                                <div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title="Nuevo">
                                                    <i className="ft-plus"></i>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-6" id="buttons"></div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
                                        <tfoot>
                                            <tr style={{backgroundColor: '#fff'}}>
                                                <th className="dt-search-header"></th>
                                                <th className="dt-search-header"></th>
                                                <th className="dt-search-header"></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(GPSModelsGrid)
