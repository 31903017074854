import InstanciaText from './instancias/InstanciaText.js'
import ExtraBasic from './extras/ExtraBasic.js'

export const ID_TEXT = 1;

export const ID_ESTADO_APROBACION_VALIDO = 1;
export const ID_ESTADO_APROBACION_INVALIDO = 2;
export const ID_ESTADO_APROBACION_SIN_COMPROBACION = 3;
export const ID_ESTADO_APROBACION_PENDIENTE = 4;


export const TEMPLATE_ATRIBUTO = {
	nombre: '',
	mmWarning: '',
	mmDanger: '',
	value: '',
	required: true,
	estado: ID_ESTADO_APROBACION_SIN_COMPROBACION,
	activo: true,
}

export const ESTADOS_APROBACION = {}
export const INSTANCIAS_DICT = {}


ESTADOS_APROBACION[ID_ESTADO_APROBACION_VALIDO] = {
	estado: ID_ESTADO_APROBACION_VALIDO,
	text: 'NORMAL',
	icon: 'fa fa-2x fa-check',
}

ESTADOS_APROBACION[ID_ESTADO_APROBACION_INVALIDO] = {
	estado: ID_ESTADO_APROBACION_INVALIDO,
	text: 'CRITICO',
	icon: 'fa fa-2x fa-window-close',
}

ESTADOS_APROBACION[ID_ESTADO_APROBACION_SIN_COMPROBACION] = {
	estado: ID_ESTADO_APROBACION_SIN_COMPROBACION,
	text: 'ADVERTENCIA',
	icon: 'fa fa-2x fa-low-vision',
}

INSTANCIAS_DICT[ID_TEXT] = {
	id: ID_TEXT,
	label: 'Texto',
	icono: 'fa fa-file-text-o',
	clase: InstanciaText,
	conEstados: false,
	conAdjunto: false,
	extra: ExtraBasic,
	modulos: ['llantas']
}