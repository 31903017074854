import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import GPSBrandsGrid from './GPSBrandsGrid.js'
import GPSModelsGrid from './GPSModelsGrid.js'
import GPSBrandsABM from './GPSBrandsABM.js'
import GPSModelsABM from './GPSModelsABM.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class GPSDevices extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title"><FormattedMessage id="GPSDevices.header_administracion_dispositivos_GPS" defaultMessage="Administración de dispositivos GPS"/></h3>
                    </div>
                </div>
                <div className="content-body">
                    <Switch>
                        <Route exact path={`${this.props.match.url}`} component={GPSBrandsGrid} />
                        <Route exact path={`${this.props.match.url}/add`} render={(props) => <GPSBrandsABM {...props} action="ADD" />} />
                        <Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <GPSBrandsABM {...props} action="EDIT" />} />
                        <Route exact path={`${this.props.match.url}/:id`} render={(props) => <GPSBrandsABM {...props} action="VIEW" />} />

                        <Route exact path={`${this.props.match.url}/:id/models/add`} render={(props) => <GPSModelsABM {...props} action="ADD" />} />
                        <Route exact path={`${this.props.match.url}/models/:id/edit`} render={(props) => <GPSModelsABM {...props} action="EDIT" />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default injectIntl(GPSDevices);
