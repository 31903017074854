import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip
} from '@material-ui/core';

// VEC Fleet styled MaterialUI Table Cell
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#241b3e',
    color: theme.palette.common.white,
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    paddingBlock: 12,
    // whiteSpace: 'pre',
  },
  body: {
    fontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    borderBottom: '1px solid #e3ebf3 !important',
    paddingBlock: 12,
    color: '#6B6F82',
    whiteSpace: 'pre',
  },
}))(TableCell);

export const StyledTableCellProps = (props) => {
  return (
  <StyledTableCell
    children={props.children}
    onClick={props.onClick} 
    className={props.className} 
    align={props.align}
    colSpan={props.colSpan}
    style={{...props.style, display: (props.show === false) ? 'none' : ''}}>
      {props.children} 
  </StyledTableCell>
  )}

export const StyledTableDetailsCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#241b3e',
    color: theme.palette.common.white,
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    paddingBlock: 12,
    border: '2px solid #1a1036 !important',
  },
  body: {
    fontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    borderBottom: '1px solid #e3ebf3 !important',
    paddingBlock: 12,
    color: '#6B6F82',
    border: '2px solid #1a1036 !important',
  },
}))(TableCell);

// Tooltip utilizado en grilla moviles
export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#464855',
    maxWidth: 200,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    textAlign: 'center',
    
  },
}))(Tooltip);

// Opciones de paginado Mostrar #Cant. registros en tablas
export const rowsPerPage = [10, 25, 50, 100];