import $ from 'jquery'
import FormValidation from '../../commons/validation/FormValidation.js'
import Loading from '../ui/Loading.js'
import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import Validator from '../../commons/validation/Validator.js'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import axios from 'axios'

import GPSModelsGrid from './GPSModelsGrid.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

const ajaxConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
    }
}

class GPSBrandsABM extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTo: null,
            props: this.props,
            formData: {
                id: 0,
                name: ''
            },
            errors: [],
            loading: false
        }
        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'formData.name': (value) => Validator.notEmpty(value)
            }
        })

        this.handleInputFormChange = this.handleInputFormChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    initForm() {
        let component = this
        if (this.state.props.action !== 'ADD') {
            this.setState({ loading: true })
            axios.get(Config.get('apiUrlBase') + '/gps_devices/brands/' + this.state.props.match.params.id, ajaxConfig)
                .then((response) => {
                    let formData = response.data
                    if(formData) component.setState({
                        formData: formData
                    })
                }).catch(function(error) {
                    console.log(error)
                    component.exit()
                }).finally(() => {
                    this.setState({ loading: false })
                })
            window.scrollTo(0, 0)
            $('#name').focus()
        }
    }

    componentWillMount() {
        if(
            (this.state.props.action === 'ADD' && Security.hasPermission('GPS_DEVICES_CREATE'))
            || (this.state.props.action === 'EDIT' && Security.hasPermission('GPS_DEVICES_EDIT'))
            || (this.state.props.action === 'VIEW' && Security.hasPermission('GPS_DEVICES_VIEW'))
        ) {
            this.initForm()
        } else {
            this.setState({ redirectTo: '/error' })
        }
    }

    componentWillUnmount() { }

    handleSubmit(event) {
        this.setState({ loading: true })
        let component = this
        axios({
            method: this.props.action === 'ADD' ? 'post' : 'put',
            url: Config.get('apiUrlBase') + '/gps_devices/brands' + (this.props.action === 'ADD' ? '' : ('/' + this.state.formData.id)),
            headers: ajaxConfig.headers,
            data: JSON.stringify({
                ...this.state.formData
            })
        }).then(response => {
            if(response.status !== 400) {
                component.exit()
            } else {
                response.json().then(data => {
                    this.setState({ errors: data.detalle })
                })
            }
            window.scrollTo(0,0)
        }).catch((error) => {

        }).finally(() => {
            this.setState({ loading: false })
        })
        event.preventDefault()
    }

    handleCancel(event) {
        this.exit();
    }

    handleInputFormChange(event) {
        const target = event.target
        const name = target.name
        const value = target.type === 'checkbox' ? target.checked : target.value

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData))
        formDataCopy[name] = value
        this.setState({
            formData: formDataCopy
        })
    }

    exit() {
        this.setState({
            redirectTo: '/gps/brands'
        })
    }

    render() {
        this.formValidation.validate()
        let formData = this.state.formData
        let validationState = this.formValidation.state

        let action = (this.props.action == 'ADD')? 'NUEVA': ((this.props.action == 'EDIT')? 'EDITAR' : 'VER')

        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                {this.state.loading && <Loading />}

                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
                            {this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
                        </div>
                        <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
                            <div className="form-body">
                                <div className="card pull-up">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <h4 className="form-section">
                                                <i className="icon-home"></i>{action}: <FormattedMessage id="GPSBrandsABM.render.gps_mark.header_marca_de_GPS" defaultMessage="marca de GPS "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="GPSBrandsABM.render.gps_mark.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                                            </h4>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row"> {/* CAMBIAR DESDE CSS */} 
                                                        <div className="col-md-6">
                                                            <br></br><br></br><br></br>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* NOMBRE */}
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="name">
                                                                    <FormattedMessage id="GPSBrandsABM.render.gps_mark.label_nombre" defaultMessage="Nombre *:"/>
                                                                </label>
                                                                <div className="col-md-8">
                                                                    {this.props.action === 'VIEW' ? (
                                                                        <div className="form-control-static col-form-label form-value">{formData.name}</div>
                                                                    ) : (
                                                                        <div>
                                                                            <input type="text" className="form-control" id="name" name="name" placeholder={this.props.intl.formatMessage({ id: 'GPSBrandsABM.render.gps_mark.placeholder_nombre', defaultMessage: 'Nombre' })} value={formData.name} onChange={this.handleInputFormChange} />
                                                                            <div className="help-block text-danger field-message" hidden={validationState.formData.name.pristine || validationState.formData.name.valid}>{validationState.formData.name.message}</div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.props.action === 'VIEW' && (
                                    <div className="card pull-up">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <h4 className="form-section">
                                                    <i className="icon-list"></i><FormattedMessage id="GPSBrandsABM.render.gps_mark.header_modelos" defaultMessage="Modelos"/>
                                                </h4>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row"> {/* CAMBIAR DESDE CSS */} 
                                                            <div className="col-md-6">
                                                                <br></br><br></br><br></br>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <GPSModelsGrid {...this.props} brandId={this.state.props.match.params.id}></GPSModelsGrid>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="card pull-up">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="text-cd text-right">
                                                {this.props.action !== 'VIEW' && (
                                                    <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                                                        <i className="fa fa-check-circle"></i> Guardar
                                                    </button>
                                                )}
                                                <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                                                    <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? 'Volver' : 'Cancelar'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(GPSBrandsABM);
