import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import Security from "../../commons/security/Security.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Collapse from "@material-ui/core/Collapse";
import LlantaDetail from "./LlantaDetail";
import Paper from "@material-ui/core/Paper";
import Fab from "./FAB/Fab.js";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import Timezone from '../../commons/timezone/Timezone.js';
import * as ConstantsMedidor from '../unidadMedidor/constants.js';
import * as Utils from '../../commons/utils/Utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1a1036",
    color: theme.palette.common.white,
    fontFamily: "'Poppins', sans-serif",
  },
  body: {
    fontSize: 12,
    fontFamily: "'Poppins', sans-serif",
  },
}))(TableCell);

function Row(props) {
  const { odometro, llantaId } = props;
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [llanta, setLlanta] = useState(null);
  
  const getDesgastePromedio = (odometro) => {
    console.log(odometro.odometroPosicion);
    let odometroFinal = odometro.odometroPosicion;
    let mm_inicial = odometro.mm_inicial
      ? odometro.mm_inicial.valor_medidos
      : "";
    let mm_final = odometro.mm_final ? odometro.mm_final.valor_medidos : "";
    if (!odometro.mm_inicial) {
      let puntos = [];
      let punto = {};
      let mmInicio = odometro.llanta.llanta_modelo.mmInicio;
      odometro.llanta.llanta_modelo.puntos_medicion.forEach((item) => {
        punto.punto_dinamico = item;
        punto.value = mmInicio;
        puntos.push(punto);
      });
      mm_inicial = puntos;
    }
    if (!odometro.mm_final) {
      let puntos = [];
      let punto = {};
      let mmInicio = odometro.llanta.llanta_modelo.mmInicio;
      odometro.llanta.llanta_modelo.puntos_medicion.forEach((item) => {
        punto.punto_dinamico = item;
        punto.value = mmInicio;
        puntos.push(punto);
      });
      mm_final = puntos;
    }

    // Calculate desgaste promedio
    let puntos1 = 0;
    let puntos2 = 0;
    mm_inicial.forEach((punto) => {
      puntos1 += parseFloat(punto.value);
    });
    mm_final.forEach((punto) => {
      puntos2 += parseFloat(punto.value);
    });

    let puntosProm = (puntos1 - puntos2) / mm_inicial.length;
    let desgastePromedio = odometroFinal / puntosProm;

    if (isNaN(desgastePromedio) || !isFinite(desgastePromedio) || desgastePromedio < 0) {
      return "-";
    } else {
      return desgastePromedio.toFixed(1);
    }
  };

  const labelUnidadMedidor = (odometro) => {
    let unidad_medidor_id = odometro.llanta ? odometro.llanta.unidad_medidor_id : null;
    if (unidad_medidor_id === ConstantsMedidor.ID__UM_ODOMETRO) {
      return props.intl.formatMessage({
        id: "llantaOdometroGrid.render.label_unidad_medidor_odometro",
        defaultMessage: "kms",
      })
    } else if (unidad_medidor_id === ConstantsMedidor.ID__UM_HOROMETRO){
      return props.intl.formatMessage({
        id: "llantaOdometroGrid.render.label_unidad_medidor_horometro",
        defaultMessage: "hrs",
      })
    } else {
      return props.intl.formatMessage({
        id: "llantaOdometroGrid.render.label_sin_unidad_medidor",
        defaultMessage: "- Medidor no asignado.",
      })
    }
  };

  return (
    <React.Fragment>
      <TableRow key={odometro.id}>
        <StyledTableCell align="center">{ Timezone.getDateForClient(odometro.fecha, null, 'YYYY-MM-DD hh:mm:ss') }</StyledTableCell>
        <StyledTableCell align="center">
          {odometro.movil ? odometro.movil.dominio : ""}
        </StyledTableCell>
        <StyledTableCell align="center">{odometro.posicion}</StyledTableCell>
        <StyledTableCell align="center">{"R" + odometro.or}</StyledTableCell>
        <StyledTableCell align="center">
          {odometro.odometroInicial + " " + labelUnidadMedidor(odometro)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {odometro.odometroFinal + " " + labelUnidadMedidor(odometro)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {odometro.odometroPosicion + " " + labelUnidadMedidor(odometro)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {odometro.mm_inicial
            ? odometro.mm_inicial.valor_medidos.map((valor, index) => {
                return (
                  <div key={"mm_inicial_valor_medidos_" + index}>
                    {valor.punto_dinamico.nombre + ": " + valor.value}
                  </div>
                );
              })
            : odometro.llanta.llanta_modelo.puntos_medicion.map(
                (punto, index) => {
                  return (
                    <div key={"mm_inicial_puntos_medicion_" + index}>
                      {punto.nombre +
                        ": " +
                        odometro.llanta.llanta_modelo.mmInicio}
                    </div>
                  );
                }
              )}
        </StyledTableCell>
        <StyledTableCell align="center">
          {odometro.mm_final
            ? odometro.mm_final.valor_medidos.map((valor, index) => {
                return (
                  <div key={"mm_final_valor_medidos_" + index}>
                    {valor.punto_dinamico.nombre + ": " + valor.value}
                  </div>
                );
              })
            : odometro.llanta.modelo.puntos_medicion.map((punto, index) => {
                return (
                  <div key={"mm_final_puntos_medicion_" + index}>
                    {punto.nombre +
                      ": " +
                      odometro.llanta.llanta_modelo.mmInicio}
                  </div>
                );
              })}
        </StyledTableCell>
        <StyledTableCell align="center">
          {getDesgastePromedio(odometro)}
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
}
const LlantaOdometroGrid = (props) => {
  const [open, setOpen] = React.useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [dataService, setDataService] = useState("/llantas");
  const [odometros, setOdometros] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    count: 10,
    perPage: 5,
  });
  const [query, setQuery] = useState({
    page: 0,
    serial: "",
    marca: "",
    modelo: "",
  });
  const { llanta } = props;

  useEffect(() => {
    //componentDidMount
    if (Security.hasPermission("LLANTAS_ODOMETRO_VISUALIZAR")) {
      ajaxHandler.subscribe(this);
      initGrid();
    } else {
      setRedirectTo("/error");
    }
    //componentWillUnmount
    return () => {};
  }, []);

  const initGrid = () => {
    window.scrollTo(0, 0);

    Promise.all([
      Utils.getData("/llantaodometro" + "/" + llanta.id + dataTableQuery())
    ]).then((data) => {
      setOdometros(data[0].llantas);
      let pag = {
        page: data[0].pagination.page,
        count: data[0].pagination.count,
        perPage: 5,
      };
      setPagination(pag);
    });
  };

  const dataTableQuery = (pag) => {
    if (pag) {
      let myQuery = "?page=" + pag.page;
      myQuery += "&perPage=" + pag.perPage;
      return myQuery;
    } else {
      let myQuery = "?page=" + pagination.page;
      myQuery += "&perPage=" + pagination.perPage;
      return myQuery;
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage + 1);
    let pag = {
      page: newPage,
      count: pagination.count,
      perPage: 5,
    };
    // setPagination(pag);
    dataTableUpdate(pag);

    // Promise.all([
    //   getData("/llantaodometro" + "/" + llanta.id + dataTableQuery()),
    // ]).then((data) => {
    //   setOdometros(data[0].llantas);
    //   let pag = {
    //     page: data[0].pagination.page,
    //     count: data[0].pagination.count,
    //     perPage: 5,
    //   };
    //   setPagination(pag)
    // });
  };

  const dataTableUpdate = (pag) => {
    Promise.all([
      getData("/llantaodometro" + "/" + llanta.id + dataTableQuery(pag)),
    ]).then((data) => {
      let llantasNew = [];
      for (let item in data[0].llantas) {
        console.log(data[0].llantas[item]);
        llantasNew.push(data[0].llantas[item])
      }
      setOdometros(llantasNew);
      let pag = {
        page: data[0].pagination.page,
        count: data[0].pagination.count,
        perPage: 5,
      };
      setPagination(pag);
    });
  };

  const getData = (service) => {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response)
        ? response.json()
        : null;
    });
  };

  const tituloDeModal = (llanta) => {
    if (llanta.unidad_medidor_id === ConstantsMedidor.ID__UM_ODOMETRO) {
      return props.intl.formatMessage({
        id: "llantaOdometroGrid.render.header_odometro_de_llanta",
        defaultMessage: "Odometro de Llanta",
      })
    } else if (llanta.unidad_medidor_id === ConstantsMedidor.ID__UM_HOROMETRO){
      return props.intl.formatMessage({
      id: "llantaOdometroGrid.render.header_horometro_de_llanta",
        defaultMessage: "Horometro de Llanta",
      })
    }
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="row mt-2">
        <div className="col-12">
          <div className="card">
            <div className="card-content collpase show">
              <div className="card-body card-dashboard">
                <div className="container-fluid">
                  <div className="row dt-icons mb-4">
                    <div className="col-11">
                      <h3>
                        {tituloDeModal(llanta)}{" "}
                        {llanta.serial +
                          " - " +
                          (llanta.llanta_marca
                            ? llanta.llanta_marca.nombre
                            : "") +
                          " - " +
                          (llanta.llanta_modelo
                            ? llanta.llanta_modelo.nombre
                            : "") +
                          " - " +
                          (llanta.llanta_modelo
                            ? llanta.llanta_modelo.llanta_medida
                              ? llanta.llanta_modelo.llanta_medida.nombre
                              : ""
                            : "")}
                      </h3>
                    </div>
                    <div
                      className="col-1 d-flex justify-content-end"
                      id="buttons"
                    >
                      {/* <button onClick={() => props.handleClose()}>Close</button> */}
                      <IconButton
                        aria-label="close"
                        onClick={() => props.handleClose()}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          <div>
                            {props.intl.formatMessage({
                              id:
                                "llantaOdometroGrid.render.table_colmun_fecha",
                              defaultMessage: "Fecha",
                            })}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            {props.intl.formatMessage({
                              id:
                                "llantaOdometroGrid.render.table_colmun_Vehiculo",
                              defaultMessage: "Vehiculo",
                            })}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.intl.formatMessage({
                            id:
                              "llantaOdometroGrid.render.table_colmun_posicion",
                            defaultMessage: "Pos.",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            {props.intl.formatMessage({
                              id: "llantaOdometroGrid.render.table_colmun_or",
                              defaultMessage: "O/R",
                            })}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.intl.formatMessage({
                            id:
                              "llantaOdometroGrid.render.table_colmun_medidor_inicial",
                            defaultMessage: "Medidor Inicial",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.intl.formatMessage({
                            id:
                              "llantaOdometroGrid.render.table_colmun_medidor_final",
                            defaultMessage: "Medidor Final",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.intl.formatMessage({
                            id:
                              "llantaOdometroGrid.render.table_colmun_medidor_en_pos.",
                            defaultMessage: "Medidor en Pos.",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.intl.formatMessage({
                            id:
                              "llantaOdometroGrid.render.table_colmun_mm_pos_inicial",
                            defaultMessage: "mm (Pos. Inicial)",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.intl.formatMessage({
                            id:
                              "llantaOdometroGrid.render.table_colmun_mm_pos_final",
                            defaultMessage: " mm (Pos. Final)",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {llanta.unidad_medidor_id===1 ?
                            props.intl.formatMessage({
                              id:
                                "llantaOdometroGrid.render.table_colmun_redimiento_km",
                              defaultMessage: "Rendimiento (kms x mm)",
                            })
                            :
                            props.intl.formatMessage({
                              id:
                                "llantaOdometroGrid.render.table_colmun_redimiento_hr",
                              defaultMessage: "Rendimiento (hs x mm)",
                            })
                          }
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {odometros.map((odometro) => (
                        <Row
                          odometro={odometro}
                          llantaId={llanta.id}
                          key={odometro.id}
                          intl={props.intl}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={pagination.count}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page}
                  onChangePage={handleChangePage}
                  // onChangeRowsPerPage={event => handleChangeRowsPerPage(event)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(LlantaOdometroGrid);
