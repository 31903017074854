import React, { Component } from 'react'
import Loading from '../ui/Loading.js'
import Switch from "react-switch"
import moment from 'moment'
import 'moment/min/locales'
import AtributoDinamicoInstancia from './AtributoDinamicoInstancia.js'
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js'
import * as Constants from './constants.js'
import Select from 'react-select'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Draggable } from 'react-beautiful-dnd'
import {FormattedMessage, injectIntl} from 'react-intl';
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
const ESTADOS_ACTIVO = [{ id: 1, nombre: 'Visible'},{ id: 0, nombre: 'Invisible'}]
const ESTADOS_REQUIRED = [{ id: 1, nombre: 'Requerido'},{ id: 0, nombre: 'No Requerido'}]
const ESTADOS_GENERA_TICKET = [{ id: 1, nombre: 'Si'},{ id: 0, nombre: 'No'}]

/*const SOLICITAR_MAS_INFO = [
	{ id: Constants.ID_ESTADO_APROBACION_VALIDO, label: 'Al Aprobar'},
	{ id: Constants.ID_ESTADO_APROBACION_INVALIDO, label: 'Al Desaprobar'},
]*/

class AtributoDinamico extends Component {
	constructor(props) {
    super(props);
		this.props = props;
		this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleChangeAndUpdateFather = this.handleChangeAndUpdateFather.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.handleServicioChange = this.handleServicioChange.bind(this);
		this.handleGenerateTicketChange = this.handleGenerateTicketChange.bind(this);
		let tiposValor = Object.values(Constants.INSTANCIAS_DICT).filter(tipoValor => tipoValor.modulos.includes(this.props.modulo))

    this.state = {
			formData: Object.assign({}, Constants.TEMPLATE_ATRIBUTO),
			serviciosOptions: [],
			serviciosGestoriaOptions: [],
			tareasOptions: [],
			selectedTareas: [],
			loading: true,
      hovered: false,
			tiposValor: tiposValor,
      solicitar_mas_info: [
        { id: Constants.ID_ESTADO_APROBACION_VALIDO, label: this.props.intl.formatMessage({ id: 'atributoDinamico.solicitar_mas_info.al_aprobar', defaultMessage: 'Al Aprobar' })},
        { id: Constants.ID_ESTADO_APROBACION_INVALIDO, label: this.props.intl.formatMessage({ id: 'atributoDinamico.solicitar_mas_info.al_desaprobar', defaultMessage: 'Al Desaprobar' })},
			]
	  }
  }

  componentDidMount() {
		let formDataCopy = JSON.parse(JSON.stringify(this.props.formulario));
		let html = formDataCopy.description
		let instruccionesState = EditorState.createEmpty()
		if (html) {
	    let contentBlock = htmlToDraft(html);
	    let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	    instruccionesState = EditorState.createWithContent(contentState);
		}
		let selectedTareas = formDataCopy.tareas.map(tarea => {
			if (tarea.id) {
				return {"value": tarea.id , "label": tarea.nombre }
			} else {
				return {"value": tarea.value, "label": tarea.label }
			}
    	});

    this.handleSelectedTareasChange(selectedTareas);
    
		this.setState({
			instruccionesState: instruccionesState,
			formData: formDataCopy,
			loading: false,
      		selectedTareas: selectedTareas,
		});

    this.ajaxHandler.subscribe(this);
    this.getServicios();
  }

  componentWillUnmount() {
	this.ajaxHandler.unsubscribe();
  }

  getServicios() {
		this.ajaxHandler.getJson("/servicios/tipo-ticket/CORRECTIVO/select")
		.then((res) => {
			let serviciosOptions = res;
			this.setState({ serviciosOptions });

		if (this.state.formData.servicio_id)
			this.getTareasFromServicio(this.state.formData.servicio_id);
		})
		.catch((er) => console.log(er))

		this.ajaxHandler.getJson("/servicios/tipo-ticket/GESTORIA/select")
		.then((res) => {
			let serviciosGestoriaOptions = res;
			this.setState({ serviciosGestoriaOptions });
		})
		.catch((er) => console.log(er))
	}

  getTareasFromServicio(servicio) {
	this.ajaxHandler.getJson("/tareas/servicio/"+servicio+"/select")
	.then((res) => {
    let tareasOptions = res
      this.setState({ tareasOptions });
	})
	.catch((er) => console.log(er))
  }

  onDescriptionChange(editorState) {
		if (editorState.getCurrentContent().hasText()) {
			let rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))
			this.handleChangeAndUpdateFather("description", rawHtml)
		}
		else
			this.handleChangeAndUpdateFather("description", '')
    this.setState({
      instruccionesState: editorState,
    });
  }

  handleDelete () {
    if (this.state.formData.id)
      this.handleChangeAndUpdateFather("activo", false)
    else
      this.props.handleDelete(this.props.index);
  }

	handleChangeAndUpdateFather(name, object) {
		this.state.formData[name] = object;
		this.setState({
			formData: this.state.formData
		});
		this.props.handleChange(this.props.index, name, object);

	}

	handleInputFormChange(event) {
		// El input debe tener el atributo name para que funcione
		const target = event.target;
		const name = target.name;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		this.handleChangeAndUpdateFather(name, value)
}

  handleServicioChange(servicioId) {
    this.handleChangeAndUpdateFather("servicio_id", servicioId);
    this.getTareasFromServicio(servicioId);
  }

  handleSelectedTareasChange(selectedTareas) {
    this.setState({ selectedTareas });
    // let tareas = selectedTareas.map(tarea => (tarea.value));
    this.handleChangeAndUpdateFather("tareas", selectedTareas);
  }

	handleGenerateTicketChange(name, value){
		switch (name) {
			case "genera_ticket":
				if(value){
					this.state.formData.genera_ticket = true;
					this.state.formData.genera_ticket_gestoria = false;
				}
				else
					this.state.formData.genera_ticket = false;
				break;
			case "genera_ticket_gestoria":
				if(value){
					this.state.formData.genera_ticket_gestoria = true;
					this.state.formData.genera_ticket = false;
				}
				else
					this.state.formData.genera_ticket_gestoria = false;
				break;
			default:
				break;
		}
		this.handleChangeAndUpdateFather("genera_ticket", this.state.formData.genera_ticket);
		this.handleChangeAndUpdateFather("genera_ticket_gestoria", this.state.formData.genera_ticket_gestoria);
	}

	render() {
      let tipoValor = this.props.formulario.tipoValor ? this.props.formulario.tipoValor : 0
			let ExtraDinamico = Constants.INSTANCIAS_DICT[tipoValor].extra
			let poseeEstados = Constants.INSTANCIAS_DICT[tipoValor].conEstados

      return (
        <React.Fragment>
					{this.state.loading && <Loading />}
          { !this.state.loading &&
							<Draggable
								draggableId={this.props.index.toString()}
								key={this.props.index.toString()}
								index={this.props.index}
								isDragDisabled={!this.props.draggable}>
								{(provided, snapshot) =>
									<div
										ref={provided.innerRef}
										{...provided.dragHandleProps}
										{...provided.draggableProps}
										onMouseEnter={() => this.setState({hovered: true})}
										onMouseLeave={() => this.setState({hovered: false})}
									>
			            <div className="card pull-up">
			              <div className="card-content">
			                <div className="card-body">
			                  <h4 className="form-section">
													<i className="la la-ticket"></i> <FormattedMessage id="atributoDinamico.render.form_option.header_opcion_del_formulario" defaultMessage="Opción del formulario"/> # {this.props.index + 1}
			                    {!this.state.formData.id && (
			                      <span className="pull-right text-danger cursor-pointer" onClick={this.handleDelete}>
			  											<i className="fa fa-trash"></i>
			  										</span>
			                    )}
													{(this.props.draggable && this.state.hovered) && (

														<span className="pull-right" >
															<i className="grey-color fa fa-bars"></i>
														</span>
													)}

			                  </h4>

												<div className="row">
													<div className="col-md-6">

			                      {/* Nombre */}
														<div className="form-group row">
															<label className="col-md-3 label-control col-form-label" htmlFor="nombre">
																<FormattedMessage id="atributoDinamico.render.form_option.header_nombre" defaultMessage="Nombre"/> *:
															</label>
															<div className="col-md-9">
																	<input disabled={this.props.disabled}  name="nombre" type="text" className="form-control" value={this.state.formData.nombre} onChange={this.handleInputFormChange} />
															</div>
														</div>


			                      {/* Tipo */}
			                      <div className="form-group row">
															<label className="col-md-3 label-control col-form-label" htmlFor="tipo">
															<FormattedMessage id="atributoDinamico.render.form_option.label_tipo_valor" defaultMessage="Tipo Valor"/> *:
															</label>
															<div className="col-md-9">
																<SelectWithIcons
																	placeholder={this.props.intl.formatMessage({ id: 'atributoDinamico.render.form_option.placeholder_tipo', defaultMessage: 'Tipo' })}
															    valueKey="id"
															    labelKey="label"
															    clearable={false}
																	onChange={(value) => this.handleChangeAndUpdateFather("tipoValor", value.id)}
															    value={this.state.formData.tipoValor}
																	disabled={this.props.disabled || this.state.formData.tipoValor == Constants.ID_MEDIDOR}
																	options={this.state.tiposValor}
																/>
															</div>
			                      </div>

			                      {/* Info */}
			                      <div className="form-group row">
			                        <label className="col-md-3 label-control col-form-label" htmlFor="tipo">
																<FormattedMessage id="atributoDinamico.render.form_option.label_instrucciones" defaultMessage="Instrucciones"/>:
			                        </label>
			                        <div className="col-md-9 info-cursor-text">

															{this.state.instruccionesState &&
																<Editor
																 wrapperClassName="form-control min-height-150"
																 editorState={this.state.instruccionesState}
																 readOnly={this.props.disabled}
																 onEditorStateChange={this.onDescriptionChange}
																 toolbar={{
																	 options:[ 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji']
			  											 	}}
															 	/>
														 	}
			                       </div>
			                      </div>


			                      {this.props.modulo === 'modelo-tipo' 
			                      	? ''
			                      	: <>
			                      	{/* Requerido */}
				                      <div className="form-group row">
				                        <label className="col-md-3 label-control col-form-label" htmlFor="required">
																	<FormattedMessage id="atributoDinamico.render.form_option.label_requerido" defaultMessage="Requerido"/>:
				                        </label>
				                        <div className="col-md-1 mt-auto">
				                          <Switch
				                            onChange={(value) => this.handleChangeAndUpdateFather("required", value)}
				                            checked={this.state.formData.required}
				                            options={ESTADOS_REQUIRED}
				                            id="required"
				                            name="required"
				                            valueKey='id'
				                            labelKey='nombre'
				                            disabled={this.props.disabled}
				                            offColor="#FF4961"
				                            onColor="#28D094"
				                          />
				                        </div>

				                        {/* Visible */}
									            	<label className="col-md-2 label-control col-form-label" htmlFor="activo">
																	<FormattedMessage id="atributoDinamico.render.form_option.label_visible" defaultMessage="Visible"/>:
				                        </label>
				                        <div className="col-md-2 mt-auto">
				                          <Switch
				                            onChange={(value) => this.handleChangeAndUpdateFather("activo", value)}
				                            checked={this.props.formulario.activo}
				                            options={ESTADOS_ACTIVO}
				                            id="activo"
				                            name="activo"
				                            valueKey='id'
				                            labelKey='nombre'
				                            disabled={this.props.disabled}
				                            offColor="#FF4961"
				                            onColor="#28D094"
				                          />
				                        </div>

							{ConfigBusiness.get('rop.habilitado') === 'true' ?
								<>
									<label className="col-md-2 label-control col-form-label" htmlFor="genera_rop">
										<FormattedMessage id="atributoDinamico.render.form_option.label_genera_rop" defaultMessage="Genera ROP Correctivo"/>:
									</label>
                                    <div className="col-md-2 mt-auto">
                                      <Switch
                                        onChange={(value) => this.handleChangeAndUpdateFather("genera_rop", value)}
                                        checked={this.state.formData.genera_rop}
                                        options={ESTADOS_GENERA_TICKET}
                                        id="genera_rop"
                                        name="genera_rop"
                                        valueKey='id'
                                        labelKey='nombre'
                                        disabled={this.props.disabled ||!poseeEstados || this.props.tipoPersona}
                                        offColor="#FF4961"
                                        onColor="#28D094"
                                      />
									</div>
								</>
								: 
                                <>
									{/* Genera Ticket Correctivo */}
                                    <label className="col-md-2 label-control col-form-label" htmlFor="genera_ticket">
                                      <FormattedMessage id="atributoDinamico.render.form_option.label_genera_ticket" defaultMessage="Genera Ticket Correctivo"/>:
                                    </label>
                                    <div className="col-md-1 mt-auto">
                                      <Switch
                                        onChange={(value) => this.handleGenerateTicketChange("genera_ticket", value)}
                                        checked={this.state.formData.genera_ticket}
                                        options={ESTADOS_GENERA_TICKET}
                                        id="genera_ticket"
                                        name="genera_ticket"
                                        valueKey='id'
                                        labelKey='nombre'
                                        disabled={this.props.disabled ||!poseeEstados || this.props.tipoPersona}
                                        offColor="#FF4961"
                                        onColor="#28D094"
                                      />
                                    </div>
									{/* Genera Ticket Gestoria */}
									{ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ?
										<>
											<label className="col-md-3 label-control col-form-label" htmlFor="genera_ticket">
											<FormattedMessage id="atributoDinamico.render.form_option.label_genera_ticket_gestoria" defaultMessage="Genera Ticket Gestoría"/>:
											</label>
											<div className="col-md-2 mt-auto">
											<Switch
												onChange={(value) => this.handleGenerateTicketChange("genera_ticket_gestoria", value)}
												checked={this.state.formData.genera_ticket_gestoria}
												options={ESTADOS_GENERA_TICKET}
												id="genera_ticket"
												name="genera_ticket"
												valueKey='id'
												labelKey='nombre'
												disabled={this.props.disabled ||!poseeEstados || this.props.tipoPersona}
												offColor="#FF4961"
												onColor="#28D094"
											/>
											</div>
										</>
										: ''
									}
                                </>
                            }
				        </div>
					</>
				}
				<ExtraDinamico
					className=""
					disabled={this.props.disabled}
					handleChange={(value) => this.handleChangeAndUpdateFather("extraParams", value)}
					extraParams={this.state.formData.extraParams}
				/>
					{poseeEstados &&
						<div className="form-group row">
							<label className="col-md-3 label-control col-form-label" htmlFor="tipo">
								<FormattedMessage id="atributoDinamico.render.form_option.label_solicitar_foto_comentarios" defaultMessage="Solicitar Foto/Comentarios"/>:
							</label>
							<div className="col-md-9">
								<Select
									placeholder={<FormattedMessage id="atributoDinamico.render.form_option.label_tipo" defaultMessage="Tipo"/>}
									valueKey="id"
									labelKey="label"
									onChange={(value) => this.handleChangeAndUpdateFather("estadosInformativos", value)}
									value={this.state.formData.estadosInformativos}
									disabled={this.props.disabled}
									options={this.state.solicitar_mas_info}
									multi={true}
								/>
							</div>
						</div>
					}

					{/* Servicio del Ticket Correctivo */}
					{this.state.formData.genera_ticket ?
						<div>	
							<div className="form-group row">
								<label className="col-md-3 label-control col-form-label" htmlFor="servicio">
									<FormattedMessage id="atributoDinamico.render.form_option.label_genera_ticket_servicio" defaultMessage="Servicio"/>:
								</label>
								<div className="col-md-9">
									<Select
									id="servicio_id"
									name="servicio_id"
									placeholder={this.props.intl.formatMessage({ id: 'atributoDinamico.render.form_option.label_servicio', defaultMessage: 'Servicio' })}
									options={this.state.serviciosOptions}
									valueKey="value"
									labelKey="label"
									value={this.state.formData.servicio_id}
									onChange={(e) => this.handleServicioChange( e ? e.value : null)}
									disabled={this.props.disabled}
									multi={false}
									/>
								</div>
							</div>

							{/* Tareas del Servicio */}
							<div className="form-group row">
								<label className="col-md-3 label-control col-form-label"  htmlFor="tareas" >
									<FormattedMessage id="atributoDinamico.servicios_tareas.add.tareas" defaultMessage="Tareas" /> *:
								</label>
								<div className="col-md-9">
									{this.props.action === "VIEW" ? null : (
									<div>
										<Select
											id="tareas"
											name="tareas"
											placeholder={this.props.intl.formatMessage({ id: 'atributoDinamico.render.form_option.label_tareas', defaultMessage: 'Tareas' })}
											options={this.state.tareasOptions}
											valueKey="value"
											labelKey="label"
											multi={true}
											value={this.state.selectedTareas}
											onChange={(e) => this.handleSelectedTareasChange(e)}
											disabled={this.state.tareasOptions.length == 0 ? true : false}
											removeSelected={true}
											closeOnSelect={true}
										/>
									</div>
									)}
								</div>
							</div>
						</div>
					: ''}
					{/* Servicio del Ticket Gestoria */}
					{(this.state.formData.genera_ticket_gestoria && ConfigBusiness.get('mantenimientos.gestoria.habilitado')=='true') ?
						<div className="form-group">
							<label className="col-md-3 label-control col-form-label" htmlFor="servicio">
								<FormattedMessage id="atributoDinamico.render.form_option.label_ticket_gestoria" defaultMessage="Ticket Gestoría"/>
							</label>
							<div className="form-group row">
								<label className="col-md-3 label-control col-form-label" htmlFor="servicio">
								<FormattedMessage id="atributoDinamico.render.form_option.label_genera_ticket_servicio" defaultMessage="Servicio"/> *:
								</label>
								<div className="col-md-9">
									<Select
									id="servicio_id"
									name="servicio_id"
									placeholder={this.props.intl.formatMessage({ id: 'atributoDinamico.render.form_option.label_servicio', defaultMessage: 'Servicio' })}
									options={this.state.serviciosGestoriaOptions}
									valueKey="value"
									labelKey="label"
									value={this.state.formData.servicio_gestoria_id}
									onChange={(e) => this.handleChangeAndUpdateFather("servicio_gestoria_id", e ? e.value : null)}
									disabled={this.props.disabled}
									multi={false}
									/>
								</div>
							</div>
						</div>
					: ''}
													</div>

													<div className="col-md-6 p-1 form-control">
			                    {/* Default y previsualización */}
			                      <div className="form-group row">

			                          <label className="col-md-12 pull-left " htmlFor="default">
																	<FormattedMessage id="atributoDinamico.render.form_option.label_previsualizacion_valor_default" defaultMessage="Previsualización y Valor Default"/>
			                            <hr/>
			                          </label>
			                          <AtributoDinamicoInstancia
																	disabled={this.props.disabled}
			                            previsualizacion={this.props.previsualizacion}
			                            handleChange={(name, value) => this.handleChangeAndUpdateFather(name, value)}
			                            atributo={this.state.formData}
			                          />
			                      </div>

													</div>
												</div>
			                </div>
			              </div>
			            </div>
								</div>
							}
						</Draggable>
						}
        </React.Fragment>
      )
  }
}

export default injectIntl(AtributoDinamico);