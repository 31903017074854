
import React from 'react'
import backendStrings from '../../lang/backendStrings'
import { FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'


// TICKET ESTADOS
export const getTicketEstadosComponent = (estado) => {
    let ticketEstado = 'tickets_estado.'+estado

    if (backendStrings[ticketEstado]){
        return <FormattedMessage {...backendStrings[ticketEstado]}/>
    } else {
        return estado
    }
}

export async function setTicketEstadoStringsToLocalStorage(intl) {
    let estados = await Utils.getData('/tickets/estados').then(data => {
        let acc = {}
        data.forEach((estado) => {
            let ticketEstado = 'tickets_estado.'+estado
            acc[estado] = intl.formatMessage(backendStrings[ticketEstado] ? backendStrings[ticketEstado] :  backendStrings['tickets.sinTraduccion'])
        })
        acc['PENDIENTES'] = intl.formatMessage(backendStrings['tickets_estados.PENDIENTES'] ? backendStrings['tickets_estados.PENDIENTES'] : backendStrings['tickets.sinTraduccion'])
        return acc
    })
    // localStorage.setItem('ticketsEstadoStrings', JSON.stringify(estados))
    return estados
}


// TICKET TIPOS
export const getTicketTipoComponent = (tipo) => {
    let ticketTipo = 'tickets_tipo.'+tipo

    if (backendStrings[ticketTipo]){
        return <FormattedMessage {...backendStrings[ticketTipo]}/>
    } else {
        return tipo
    }
}

export function setTicketTipoStringsToLocalStorage(intl) {
    let tipos = {
        DEFAULT: intl.formatMessage(backendStrings['tickets_tipo.DEFAULT'] ? backendStrings['tickets_tipo.DEFAULT'] :  backendStrings['tickets.sinTraduccion']),
        PREVENTIVO: intl.formatMessage(backendStrings['tickets_tipo.PREVENTIVO'] ? backendStrings['tickets_tipo.PREVENTIVO'] :  backendStrings['tickets.sinTraduccion']),
        CORRECTIVO: intl.formatMessage(backendStrings['tickets_tipo.CORRECTIVO'] ? backendStrings['tickets_tipo.CORRECTIVO'] :  backendStrings['tickets.sinTraduccion']),
        VENCIMIENTO: intl.formatMessage(backendStrings['tickets_tipo.VENCIMIENTO'] ? backendStrings['tickets_tipo.VENCIMIENTO'] :  backendStrings['tickets.sinTraduccion']),
    }
    if (ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true') {
        tipos['GESTORIA'] = intl.formatMessage(backendStrings['tickets_tipo.GESTORIA'] ? backendStrings['tickets_tipo.GESTORIA'] :  backendStrings['tickets.sinTraduccion'])
    }
    // localStorage.setItem('ticketsTipoStrings', JSON.stringify(tipos))
    return tipos
}

export const getPermissionComponent = (permission, intl) => {
    let permissionId = 'permission.' + permission
    if (backendStrings[permissionId]){
        return intl.formatMessage(backendStrings[permissionId] ? backendStrings[permissionId] :  backendStrings['tickets.sinTraduccion'])
    } else {
        return permission
    }
}