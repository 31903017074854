import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import {FormattedMessage, injectIntl} from 'react-intl';
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;


window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CecosGrid extends Component {
	constructor(props) {
		super(props);
		this.ajaxHandler = new AjaxHandler();
		this.dataService = '/moviles/' + this.props.movilId + '/cecos/grid';
		this.columnsToPrint = [0,1,2,3,4,5];
		this.state = {
			redirectTo: null
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('MOVILES_CECOS_HISTORICO_LISTAR')) {
	      	this.ajaxHandler.subscribe(this);
			this.initGrid();
	    } else {
			this.setState({
				redirectTo: '/error'
			});
	    }
	}

	componentWillUnmount() {		
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleExportExcel() {
		let component = this;
		this.setState({loading:true});
		component.ajaxHandler.fetch('/moviles/' + this.props.movilId + '/cecos/excel', {
			method: 'POST',
			body: JSON.stringify({
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-"+this.props.movilDominio+"-historico-cecos.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			this.setState({ loading: false });
			$('#cecos_modal').modal('toggle');
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="container-fluid">
							<div className="row dt-icons">
								<div className="col-6">
								</div>
								<div className="col-6" id="buttons"></div>
							</div>
						</div>
						<div className="table-responsive">
							<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
								<tfoot>
									<tr style={{backgroundColor: '#fff'}}>
										<th className="dt-search-header"></th>
										<th className="dt-search-header"></th>
										<th className="dt-search-header"></th>										
										<th className="dt-search-header"></th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		try{
			let filters = null;
			let component = this;
			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_CecosGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_CecosGrid'));
					},
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService,
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr)
						},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_CecosGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'cecosGrid.initGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'modelosGrid.initGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('#dataTable > thead');
					},
					columns: [
						{
							title: component.props.intl.formatMessage({ id: 'cecosGrid.column_domain.label_dominio', defaultMessage: 'Dominio' }),
							className: 'all',
							data: 'dominio'
						},
						{						
							title: component.props.intl.formatMessage({ id: 'cecosGrid.column_date.label_fecha_sistema', defaultMessage: 'Fecha Sistema' }),
							className: 'all',
							data: 'fecha',
							filterType: 'input',
							render: function(data, type, full, meta) {
								return data ? Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : '';
							},
						}, 
						{
							title: component.props.intl.formatMessage({ id: 'cecosGrid.column_domain.label_fecha_efectivo', defaultMessage: 'Fecha Efectivo' }),
							className: 'all',
							data: 'fechaCambio',
							filterType: 'input',
							render: function(data, type, full, meta) {
								return data ? Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : '';
							},
						},
						{
							title: component.props.intl.formatMessage({ id: 'cecosGrid.column_responsable_one.label_ceco', defaultMessage: 'CECO' }),
							className: 'all',
							data: 'ceco',						
							filterType: 'input'					
						},{
							title: component.props.intl.formatMessage({ id: 'cecosGrid.column_user.label_usuario', defaultMessage: 'Usuario' }),
							className: 'all',
							data: 'usuario',
							render: function(data, type, row) {
								return data && data.nombreApellido ? data.nombreApellido : '';
							},
							filterType: 'input'					
						},
						{
							title: component.props.intl.formatMessage({ id: 'cecosGrid.column_domain.label_comentario', defaultMessage: 'Comentario' }),
							className: 'all',
							data: 'comentario'
						}
					],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							component.setState({
								redirectTo: redirectTo
							});
						});
					}
				}, datatablesConfig(this.props.intl),
				{"language": {
						emptyTable: this.props.intl.formatMessage({ id: 'cecosGrid.sin_ceco_asignado', defaultMessage: 'SIN CECO ASIGNADO' }),
					}
				}),
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 45px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'cecosGrid.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'cecosGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					name: 'exportExcel',
						text: '<i class="ft-upload"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'cecosGrid.tools_export.select_title_exportar_excel', defaultMessage: 'Exportar a Excel' }),
						action: () => {this.handleExportExcel()}
				}, {
					name: 'import',
					text: '<i class="ft-download"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'cecosGrid.tools_import.title_importar', defaultMessage: 'Importar' })
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('import:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}
		catch(error) {
			AjaxHandler.handleError(error);
		}
		finally{
			this.setState({
				loading: false
			});
		}
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(CecosGrid);