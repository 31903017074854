import React, { useState, useEffect, useLayoutEffect } from 'react';

import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'moment/min/locales';

import 'bootstrap4-duallistbox';
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';
import Timezone from '../../commons/timezone/Timezone.js';
import * as Utils from '../../commons/utils/Utils';
import $ from 'jquery';
import { StyledTableCell, StyledTableDetailsCell } from '../../commons/utils/TableStyles';

import {
  Table,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import cambioPosicion from '../../assets/images/llantas/change_tyre.svg';
import lugWrench from '../../assets/images/llantas/svg/lug-wrench.svg';
import deleteSVG from '../../assets/images/llantas/svg/delete.svg';
import recapadoIcon from '../../assets/images/llantas/recapadoicon.png';
import gomaPinchadaIcon from '../../assets/images/llantas/flat-tire.svg';
import desasignarSVG from '../../assets/images/llantas/svg/lug-wrench.svg';
import llantaInspeccionBlack from '../../assets/images/llantas/llanta-inspeccion-black.svg';

import LlantaCambio from './LlantaCambio';
import LlantaBaja from './LlantaBaja';
import LlantaDesasignar from './LlantaDesasignar';
import LlantaRecapado from './LlantaRecapado';
import LlantaAsignacionesDetailsModal from './LlantaAsignacionesDetailsModal.js';
import LlantaReparar from './LlantaRepararModal';
import CarLayout from './CarLayout.js';

const MySwal = withReactContent(Swal);

const LlantaAsignacionesDetails = (props) => {
  const intl = useIntl();
  const [movil, setMovil] = useState(null);
  const [llantas, setLlantas] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    actualizarDatos();
  }, []);

  useLayoutEffect(() => {
    $('.llanta-action-button').tooltip();
    return () => {
      $('.llanta-action-button').tooltip('dispose');
    };
  }, []);

  const handleInspeccion = () => {
    props.redirectToInspeccion(props.movil.id);
  };

  const showModalCambio = () => {
    MySwal.fire({
      html: (
        <LlantaCambio
          intl={intl}
          fromAsignacion={true}
          llanta={null}
          llantasDelAuto={llantas}
          handleSubmitCambioLlanta={() => {
            actualizarDatos();
            MySwal.fire(
              intl.formatMessage({
                id: 'llantaAsignacionesDetails.render.modal_cambio_llanta.rotacion_exitosa',
                defaultMessage: 'Rotación exitosa',
              }),
              '',
              'success',
            );
          }}
          handleModalCloseCambioLlanta={() => {
            MySwal.clickCancel();
          }}
        />
      ),
      showConfirmButton: false,
    });
  };

  const showModalReparar = () => {
    MySwal.fire({
      title: <p>Reparar</p>,
      html: (
        <LlantaReparar
          id={null}
          intl={intl}
          movil={movil}
          llantasAsignadas={llantas}
          fromAsignacion={true}
          redirectCorrectivo={(myId) => {
            MySwal.clickConfirm();
            props.redirectToCorrectivo(myId);
          }}
          confirmCorrectivo={() => {
            MySwal.clickConfirm();
            MySwal.fire({
              title: 'Reparacion Exitosa!',
            });
          }}
        />
      ),
      showConfirmButton: false,
    });
  };

  const showModalDesasignar = () => {
    MySwal.fire({
      html: (
        <LlantaDesasignar
          intl={intl}
          llanta={null}
          movil={movil}
          fromAsignacion={true}
          llantasDelAuto={llantas}
          closeModalDesasignar={() => {
            MySwal.clickCancel();
          }}
          handleSubmitDesasignar={() => {
            actualizarDatos();
            MySwal.fire(
              intl.formatMessage({
                id: 'llantasGrid.modal_desasignar.information_llanta_desasignada',
                defaultMessage: 'Llanta desasignada',
              }),
              '',
              'success',
            );
          }}
        />
      ),
      showConfirmButton: false,
    });
  };

  const showModalBaja = () => {
    MySwal.fire({
      html: (
        <LlantaBaja
          intl={intl}
          llanta={null}
          movil={movil}
          fromAsignacion={true}
          llantasDelAuto={llantas}
          close={() => {
            MySwal.clickCancel();
          }}
          handleSubmit={() => {
            actualizarDatos();
            MySwal.fire(
              intl.formatMessage({
                id: 'llantaAsignacionesDetails.render.modal_baja.baja_exitoso',
                defaultMessage: 'Baja exitosa',
              }),
              '',
              'success',
            );
          }}
        />
      ),
      showConfirmButton: false,
    });
  };

  const showModalRecapado = () => {
    MySwal.fire({
      html: (
        <LlantaRecapado
          intl={intl}
          llanta={null}
          fromAsignacion={true}
          llantas={llantas}
          closeModalForzarTicketRecapado={() => MySwal.clickCancel()}
          handleSubmitForzarTicketRecapado={(hasTicket) => {
            actualizarDatos();
            if (hasTicket) {
              MySwal.fire(
                intl.formatMessage({
                  id: 'llantaRecapado.modal_recapado.information_recapado_exitoso',
                  defaultMessage: 'Recapado exitoso',
                }),
                '',
                'success',
              );
            } else {
              MySwal.fire(
                intl.formatMessage({
                  id: 'llantaRecapado.modal_recapado.information_enviado_a_taller',
                  defaultMessage: 'Enviado a taller',
                }),
                '',
                'success',
              );
            }
          }}
        />
      ),
      showConfirmButton: false,
    });
  };

  const actualizarDatos = () => {
    Promise.all([
      Utils.getData(`/moviles/${props.movil.id}`),
      Utils.getData(`/llantas/llantasdemovil/${props.movil.id}`),
    ]).then((res) => {
      setMovil(res[0]);
      setLlantas(res[1]);
      setLoading(false);
    });
  };

  return (
    <>
      {(loading ? <CircularProgress />
      : 
      <div className="w-100" style={{ background: '#f4f5fa' }}>
        {movil && ( movil.tipologiaPosicion || movil.modelo.tipologiaPosicion ) ? (
          <div className="row">
            {/* Diagrama de Posicion */}
            <div className="col-md-2" style={{ background: '#f4f5fa' }}>
              {movil && llantas &&
                <CarLayout 
                  movil={movil}
                  llantas={llantas}
                  tipologia={movil.tipologiaPosicion ? movil.tipologiaPosicion: movil.modelo.tipologiaPosicion}
                />
              }
            </div>
            {/* Columna de Botones de Accion */}
            <div className="col-md-1 d-flex flex-column">
              <button
                type="button"
                className="btn llanta-action-button"
                data-toggle="tooltip"
                data-placement="top"
                title={intl.formatMessage({
                  id: 'llantaAsignacionesDetails_acciones_generarInspeccion_label',
                  defaultMessage: 'Generar Inspeccion',
                })}
                style={{ backgroundColor: 'transparent' }}
                onClick={handleInspeccion}
              >
                <img
                  src={llantaInspeccionBlack}
                  alt={'/'}
                  style={{ height: '40px', width: '40px', marginLeft: 5 }}
                />
              </button>
              <button
                type="button"
                className="btn llanta-action-button"
                data-toggle="tooltip"
                data-placement="top"
                title={intl.formatMessage({
                  id: 'llantaAsignacionesDetails_acciones_cambioPosicion_label',
                  defaultMessage: 'Rotar',
                })}
                style={{ backgroundColor: 'transparent' }}
                onClick={showModalCambio}
              >
                <img src={cambioPosicion} alt={'/'} style={{ height: '40px', width: '40px' }} />
              </button>
              <button
                type="button"
                className="btn llanta-action-button"
                data-toggle="tooltip"
                data-placement="top"
                title={intl.formatMessage({
                  id: 'llantaAsignacionesDetails_acciones_reparar_label',
                  defaultMessage: 'Reparar',
                })}
                style={{ backgroundColor: 'transparent' }}
                onClick={showModalReparar}
              >
                <img src={gomaPinchadaIcon} alt={'/'} style={{ height: '40px', width: '40px' }} />
              </button>
              <button
                type="button"
                className="btn llanta-action-button"
                data-toggle="tooltip"
                data-placement="top"
                title={intl.formatMessage({
                  id: 'llantaAsignacionesDetails_acciones_reencauche_label',
                  defaultMessage: 'Reencauche',
                })}
                style={{ backgroundColor: 'transparent' }}
                onClick={showModalRecapado}
              >
                <img src={recapadoIcon} alt={'/'} style={{ height: '40px', width: '40px' }} />
              </button>
              <button
                type="button"
                className="btn llanta-action-button"
                data-toggle="tooltip"
                data-placement="top"
                title={intl.formatMessage({
                  id: 'llantaAsignacionesDetails_acciones_desasignar_label',
                  defaultMessage: 'Desasignar del Movil',
                })}
                style={{ backgroundColor: 'transparent' }}
                onClick={showModalDesasignar}
              >
                <img src={desasignarSVG} alt={'/'} style={{ height: '40px', width: '40px' }} />
              </button>
              <button
                type="button"
                className="btn llanta-action-button"
                data-toggle="tooltip"
                data-placement="top"
                title={intl.formatMessage({
                  id: 'llantaAsignacionesDetails_acciones_baja_label',
                  defaultMessage: 'Dar de Baja',
                })}
                style={{ backgroundColor: 'transparent' }}
                onClick={showModalBaja}
              >
                <img src={deleteSVG} alt={'/'} style={{ height: '40px', width: '40px' }} />
              </button>
            </div>
            {/* Tabla de Asignaciones */}
            <div className="col-md-9 d-flex justify-content-center">
              {movil && llantas && (
                <TableAsinaciones
                  llantas={llantas}
                  movil={movil}
                  actualizarDatos={actualizarDatos}
                />
              )}
            </div>
          </div>
        ) : (
          <div>
            El modelo de vehículo no tiene un diagrama asignado
            <a href={'modelos/' + movil.modelo.id + '/edit'}> Asignar diagrama al modelo</a>
          </div>
        )}
      </div> )}
    </>
  );
};

const TableAsinaciones = (props) => {
  const intl = useIntl();
  const [posiciones, setPosiciones] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPosiciones();
  }, [props.llantas]);

  const getPosiciones = (params) => {
    let tipologia = props.movil.tipologiaPosicion
      ? props.movil.tipologiaPosicion
      : props.movil.modelo.tipologiaPosicion;

    let posiciones = tipologia.llanta_posiciones.map((llantaPosicion) => {
      let llanta = props.llantas.find((l) => l.llanta_posicion.id === llantaPosicion.id);
      return { ...llantaPosicion, llanta: llanta ? llanta : null };
    });

    setPosiciones(posiciones);
    setLoading(false);
  };

  const showModalAsignacion = (index, repuesto) => {
    MySwal.fire({
      html: (
        <LlantaAsignacionesDetailsModal
          posicion={!repuesto ? index + 1 : null}
          movil={props.movil}
          intl={intl}
          actualizarDatos={props.actualizarDatos}
          confirm={() => MySwal.clickConfirm()}
          cancel={() => MySwal.clickCancel()}
        />
      ),
      showConfirmButton: false,
    });
  };

  const getCellStatus = (medicion, indexUltimaInspeccion) => {
    let punto_dinamico = medicion.punto_dinamico;
    if (punto_dinamico) {
      let isDanger = false;
      let medicionValue = parseFloat(medicion.value);
      let mmWarning = parseFloat(punto_dinamico.mmWarning);
      let mmDanger = parseFloat(punto_dinamico.mmDanger);
      let backgroundColor = '#6BF365';

      if (medicionValue <= mmDanger) {
        backgroundColor = '#FC0000';
        isDanger = true;
      } else if (medicionValue > mmDanger && medicionValue <= mmWarning) {
        backgroundColor = '#F9F257';
      }

      return (
        <div
          key={indexUltimaInspeccion}
          className={'w-100'}
          style={{
            backgroundColor: backgroundColor,
            color: isDanger ? 'white' : 'gray',
            fontWeight: '500',
            border: `1px solid ${backgroundColor}`,
          }}
        >
          {medicionValue}
        </div>
      );
    }
  };
  return (
    <>
      <TableContainer>
        <Table cellPadding="0" cellSpacing="0">
          <TableHead className="hover-reset">
            <TableRow>
              <StyledTableDetailsCell className="all">
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_posicion',
                  defaultMessage: 'Pos',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_serial',
                  defaultMessage: 'Serial',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_marca',
                  defaultMessage: 'Marca',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_modelo',
                  defaultMessage: 'Modelo',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_medida',
                  defaultMessage: 'Medida',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_or',
                  defaultMessage: 'O/R',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_psi',
                  defaultMessage: 'Psi',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_mediciones',
                  defaultMessage: 'Mediciones',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_ult_inspeccion',
                  defaultMessage: 'Ult. Inspeccion',
                })}
              </StyledTableDetailsCell>
              <StyledTableDetailsCell>
                {intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.table_column.header_medidor',
                  defaultMessage: 'Medidor',
                })}
              </StyledTableDetailsCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <StyledTableCell colSpan={10} align="center">
                  <CircularProgress />
                </StyledTableCell>
              </TableRow>
            )}
            {!loading &&
              posiciones &&
              posiciones.map((posicion, index) =>
                posicion.llanta ? (
                  <TableRow className="" key={`${posicion.llanta.serial}_${index}`}>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {/* {index + 1} */}
                      {posicion.llanta.llanta_posicion.label}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.serial}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-impeccion-detalles-puntos-medicion"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.llanta_marca.logo ? (
                        <img
                          style={{ width: '42px', height: '42px' }}
                          src={
                            process.env.PUBLIC_URL +
                            '/images/llantas/marcas-logo/' +
                            posicion.llanta.llanta_marca.logo
                          }
                          alt=""
                        />
                      ) : (
                        <div style={{ textAlign: '-webkit-center' }}>
                          <div className="dt-user-avatar dt-user-avatar-more">
                            {posicion.llanta.llanta_marca.nombre.substr(0, 2).toUpperCase()}
                          </div>
                        </div>
                      )}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.llanta_modelo.nombre}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.llanta_modelo.llanta_medida.nombre}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.recapadas > 0
                        ? intl.formatMessage({
                            id: 'llantaAsignacionesDetails.render.table_column_or.simbolo_recapado',
                            defaultMessage: 'R',
                          }) + posicion.llanta.recapadas
                        : posicion.llanta.recapadas}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.ultima_medicion ? posicion.llanta.ultima_medicion.psi : ''}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-impeccion-detalles-puntos-medicion"
                      align="center"
                    >
                      {posicion.llanta.ultima_medicion ? (
                        <div className="d-flex flex-row">
                          {posicion.llanta.ultima_medicion.valor_medidos.map(
                            (valor, indexUltimaInspeccion) =>
                              getCellStatus(valor, indexUltimaInspeccion),
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.ultima_medicion
                        ? Timezone.getDateForClient(
                            posicion.llanta.ultima_medicion.created_at,
                            null,
                            'YYYY-MM-DD HH:mm:ss',
                          )
                        : ''}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      padding="none"
                      align="center"
                    >
                      {posicion.llanta.recapadas > 0 ? (
                        <div style={{ lineHeight: '1em' }}>
                          {Math.trunc(posicion.llanta.odometro) +
                            ' ' +
                            posicion.llanta.odometroLabel}
                          <br></br>
                          {Math.trunc(posicion.llanta.odometro_recapado) +
                            ' ' +
                            posicion.llanta.odometroLabel +
                            ' ' +
                            intl.formatMessage({
                              id: 'llantaAsignacionesDetails.render.table_column_odometro.simbolo_recapado',
                              defaultMessage: 'R',
                            })}
                        </div>
                      ) : (
                        <div>
                          {Math.trunc(posicion.llanta.odometro) +
                            ' ' +
                            posicion.llanta.odometroLabel}
                        </div>
                      )}
                    </StyledTableDetailsCell>
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      scope="row"
                      align="center"
                    >
                      {posicion.label}
                    </StyledTableDetailsCell>
                    <StyledTableDetailsCell
                      className="custom-td-llanta-asignacion-detalles"
                      colSpan={9}
                    >
                      <button
                        type="button"
                        className="btn btn-block"
                        onClick={() => showModalAsignacion(index)}
                        style={{ padding: 0, background: 'transparent' }}
                      >
                        <span style={{ fontSize: '14px', color: '#6B6F82' }}>
                          {intl.formatMessage({
                            id: 'llantaAsignacionesDetails.render.asignar_llanta.boton_asignar_llanta',
                            defaultMessage: 'ASIGNAR LLANTA',
                          })}
                        </span>
                        <img
                          src={lugWrench}
                          alt={'/'}
                          style={{ height: '28px', width: '28px', marginLeft: '5px' }}
                        />
                      </button>
                    </StyledTableDetailsCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LlantaAsignacionesDetails;
