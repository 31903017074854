import React, { Component } from 'react'
import ExtraSelectSimple from './ExtraSelectSimple.js'
import { WithContext as ReactTags } from 'react-tag-input';
import * as Constants from '../constants.js'
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ExtraSelectCompuesto extends ExtraSelectSimple {
	constructor(props) {
    super(props);
    this.handleEstadoOpcionesChange = this.handleEstadoOpcionesChange.bind(this);

  }

  handleEstadoOpcionesChange(event, opcion, index) {
    const target = event.target;
    let nuevoEstado = parseInt(target.value)
    opcion.estado = nuevoEstado
    let opciones = this.state.opciones
    opciones[index] = opcion
    this.handleChangeAndUpdateFather(this.state)
  }


	render() {

      const ESTADOS_POSIBLES = JSON.parse(JSON.stringify(Object.values(Constants.ESTADOS_APROBACION)))

      return (
        <React.Fragment>
				<div className="form-group row">
					{/* Opciones */}
					<label className="col-md-3 label-control col-form-label" htmlFor="default">
						Opciones:
					</label>
					<div className="col-md-9">
							<ReactTags
								inputFieldPosition="top"
								tags={this.state.opciones}
								classNames={{
									tagInputField:'form-control',
									tag: 'badge badge-dark mr-1 mb-1',
									selected: 'hidden mt-1',
									remove: 'ml-1',
								}}
								suggestions={[]}
								placeholder="Agregue opciones"
								delimiters={delimiters}
								readOnly={this.props.disabled}
                handleDelete={event => this.handleOptionDelete(event)}
								handleAddition={this.handleOptionAddition}
								allowDragDrop={false}
							/>
              <div className="m-1">
                <div className="row">
                  <div className="col-md-1">
                  </div>
                  <div className="col-md-5">
                    <b>Etiqueta</b>
                  </div>
                  <div className="col-md-5">
                    <b>Estado</b>
                  </div>
                </div>
              </div>
							{this.state.opciones && this.state.opciones.map((opcion, index) =>
								<div className="m-1" key={opcion.text} value={opcion.text}>
									<div className="row">
										<div className="col-md-1">
                      {! this.props.disabled &&
  											<span className="text-danger cursor-pointer" onClick={event => this.handleOptionDelete(index)}>
  												<i className="fa fa-trash"></i>
  											</span>
                      }
										</div>
										<div className="col-md-5">
											{opcion.text}
										</div>
										<div className="col-md-6">
											<select
												 onChange={(event) => this.handleEstadoOpcionesChange(event, opcion, index)}
												 placeholder="Valor"
												 disabled={this.props.disabled}
												 value={opcion.estado}
												>
												 {ESTADOS_POSIBLES.map((estado_aprobacion, i) =>
													 <option key={estado_aprobacion.estado} value={estado_aprobacion.estado}>
														{estado_aprobacion.text}
													 </option>
													)}
											</select>
										</div>
									</div>
								</div>
							)}
					</div>
				</div>
        </React.Fragment>
      )
  }
}

export default ExtraSelectCompuesto;
