import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import * as Utils from '../../commons/utils/Utils';

am4core.useTheme(am4themes_animated);

const LlantasDetailCostsChart = ({ llanta }) => {
  const chart = useState(null);
  const [costo, setCosto] = useState(null);

  useEffect(() => {
    Utils.getData(`/llantas/${llanta.id}/costos`).then((res) => setCosto(res));
  }, []);
  //   amChart INIT
  useLayoutEffect(() => {
    if (costo) {
      let x = am4core.create('chartdiv_llanta_' + llanta.id, am4charts.PieChart);
      // let data = [
      //   {
      //     label: 'ADQUISICIÓN',
      //     value: costo.precioCompra,
      //     id: 'precioCompra',
      //   },
      //   {
      //     label: 'REPARACIÓN',
      //     value: costo.gastosRecapado,
      //     id: 'gastosRecapado',
      //   },
      //   {
      //     label: 'REENCAUCHE',
      //     value: costo.gastosReencauche,
      //     id: 'gastosReencauche',
      //   },
      // ];
      let data = costo
      x.data = data;

      let paleta = {
        precioCompra: '#4dc4bc',
        gastosRecapado: '#67b7dc',
        gastosReencauche: '#DA6767',
      };
      let pieSeries = x.series.push(new am4charts.PieSeries());

      x.innerRadius = am4core.percent(50);
      x.numberFormatter.numberFormat = '#.###,##';

      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'label';

      pieSeries.colors.list = [];
      for (let item in data) {
        pieSeries.colors.list.push(am4core.color(paleta[data[item]['id']]));
      }

      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.tooltipText =
        "{category}: {value.percent.formatNumber('#.00')}% (${value.formatNumber('#.')})";

      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      let label = pieSeries.createChild(am4core.Label);
      let totalSum = 0;
      costo.forEach(el => totalSum+= el.value)
      label.text = '$' + totalSum;
      label.horizontalCenter = 'middle';
      label.verticalCenter = 'middle';
      label.fontSize = 30;

      x.legend = new am4charts.Legend();
      x.legend.contentAlign = 'center';
      x.legend.fontSize = 10;

      chart.current = x;

      return () => {
        x.dispose();
      };
    }
  }, [costo]);
  return (
    <>
      <div id={'chartdiv_llanta_' + llanta.id} style={{ width: '100%', height: '500px' }}></div>
    </>
  );
};

export default LlantasDetailCostsChart;
