import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import Security from "../../commons/security/Security.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import moment from "moment";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";

import mainButton from "../../assets/images/llantas/mainpng.png";
import firstButton from "../../assets/images/llantas/png1.png";
import secondButton from "../../assets/images/llantas/png2.png";
import thirdButton from "../../assets/images/llantas/png3.png";
import deleteSVG from "../../assets/images/llantas/svg/delete.svg";
import upgradeSVG from "../../assets/images/llantas/svg/upgrade.png";

import fourthButton from "../../assets/images/llantas/png4.png";
import recapadoIcon from "../../assets/images/llantas/recapadoicon.png";

//Configure Table and childs THEME
import { withStyles } from "@material-ui/core/styles";
import { green, yellow, red } from "@material-ui/core/colors";
import LlantaDetailsLogo from "./LlantaDetailsLogo";
import backendStrings from "../../lang/backendStrings.js";
const StyledTable = withStyles((theme) => ({
  root: {
    padding: "0 !important",
  },
}))(Table);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1a1036",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif",
  },
  body: {
    fontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    color: "#6B6F82"
  },
}))(TableCell);

const StyledTableHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1a1036",
    color: theme.palette.common.white,
    lineHeight: 0,
    fontSize: "1rem",
    fontWeight: "400",
    borderBottom: "solid 4px !important",
    fontFamily: "'Poppins', sans-serif",
  },
  body: {
    fontSize: 10,
  },
}))(TableCell);

const BlankTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F4F5FA",
    color: theme.palette.common.black,
    fontSize: 10,
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif",
    borderBottom: 0,
  },
  body: {
    fontSize: 10,
  },
}))(TableCell);

const PSITableCell = withStyles((theme) => ({
  body: {
    backgroundColor: yellow[500],
  },
}))(TableCell);

const GreenTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: green[400],
  },
}))(TableCell);

const YellowTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: yellow[500],
  },
}))(TableCell);

const RedTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: red[500],
  },
}))(TableCell);

const MySwal = withReactContent(Swal);

const LlantaInspectionDetails = (props) => {
  const [movilId, setMovilId] = useState(props.movil);
  const [inspeccionId, setInspeccionId] = useState(props.inspeccion_id);
  const [fecha, setFecha] = useState(props.fecha);
  const [mediciones, setMediciones] = useState([]);
  const [value, setValue] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [intl, setIntl] = useState(props.intl);
  useEffect(() => {
    getData("/llantainspecciones/mediciones/" + inspeccionId)
      .then((res) => {
        setMediciones(res);
      })
      .catch((error) => console.log(error))
      .finally(() => console.log("DONE"));
  }, []);

  const logSomething = (value) => {
    setValue(value);
    let indexToDelete = "";
    mediciones.map((element, index) => {
      if (element.llanta_id === value) {
        indexToDelete = value;
        mediciones.splice(index, 1);
      }
    });
  };

  const initMa = () => {};

  const dataTableUpdate = () => {
    let medicionesNuevas = [];
    Promise.all([getData("/llantainspecciones/mediciones/" + inspeccionId)])
      .then((data) => {
        medicionesNuevas = data[0];
        setMediciones([]);
      })
      .catch(function (error) {
        ajaxHandler.handleError(error);
      })
      .finally(() => {
        setMediciones(medicionesNuevas);
      });
  };

  const getData = (service) => {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response)
        ? response.json()
        : null;
    });
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      <TableContainer>
        <Table cellPadding="0" cellSpacing="0">
          <TableHead className="hover-reset">
            <TableRow>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.details_table_column.header_pos",
                  defaultMessage: "Pos"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.details_table_column.header_serial",
                  defaultMessage: "Serial"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.details_table_column.header_marca",
                  defaultMessage: "Marca"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.details_table_column.header_modelo",
                  defaultMessage: "Modelo"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.details_table_column.header_medida",
                  defaultMessage: "Medida"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.details_table_column.header_or",
                  defaultMessage: "O/R"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetailsT.details_table_column.header_psi",
                  defaultMessage: "PSI"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetails.details_table_column.header_mediciones",
                  defaultMessage: "Mediciones"
                })}
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                {props.intl.formatMessage({
                  id: "llantaInspectionDetails.details_table_column.header_medidor",
                  defaultMessage: "Medidor"
                })}
              </StyledTableHeadCell>
              <td className="blankTableCell" align="center"></td>
            </TableRow>
          </TableHead>
          <TableBody>
            {mediciones.map((medicion, index) => (
              <Row
                key={index}
                medicion={medicion}
                logSomething={(value) => logSomething(value)}
                redirectToCorrectivo={(id) => props.redirectToCorrectivo(id)}
                dataTableUpdate={dataTableUpdate}
                intl={intl}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

const Row = (props) => {
  const [medicion, setMedicion] = useState(props.medicion);
  const [llanta, setLlanta] = useState(null);
  const [puntosMedicion, setPuntosMedicion] = useState([]);
  const [valoresMedidos, setValoresMedidos] = useState([]);
  const [servicio, setServicio] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [intl, setIntl] = useState(props.intl);
  useState(() => {
    let llanta_id = medicion.llanta_id;
    getData("/llantas/" + llanta_id)
      .then((llanta) => {
        setLlanta(llanta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, []);

  const logValoresMedidos = () => {
    puntosMedicion.map((punto, index) => {});
  };

  const getCellStatus = (punto, index) => {
    if (punto) {
      let isDanger = false;
      let medicionValue = parseFloat(punto.value);
      let mmWarning = parseFloat(punto.punto_dinamico.mmWarning);
      let mmDanger = parseFloat(punto.punto_dinamico.mmDanger);
      let backgroundColor = "#6BF365";

      if (medicionValue <= mmDanger) {
        backgroundColor = "#FC0000";
        isDanger = true;
      } else if (medicionValue > mmDanger && medicionValue <= mmWarning) {
        backgroundColor = "#F9F257";
      }

      return (
        <div
          key={index}
          className={"w-100"}
          style={{
            backgroundColor: backgroundColor,
            color: isDanger ? 'white' : 'gray',
            fontWeight: "500",
            border: `1px solid ${backgroundColor}`
          }}
        >
          {medicionValue}
        </div>
      );
    }
  };

  const getServicioButton = () => {
    let estados = [];
    props.medicion.valor_medidos.forEach((medicion, index) => {
      //if (medicion.estado === 2) {
        estados.push(medicion.estado);
      //}
    });
    if (estados.includes(2) && (llanta.recapadas < llanta.modelo.recapado)) {
      return buttonActionMenu(1, upgradeSVG, 30);
    }else if((llanta.recapadas >= llanta.modelo.recapado)){
      return buttonActionMenu(2, deleteSVG, 20);
    }else{}
  };

  const buttonActionMenu = (action, icon, height) => {
    return (
      <FloatingMenu slideSpeed={500} direction="right" isOpen={isOpen}>
        <MainButton
          className=""
          background="#22C1BD"
          iconResting={
            <img style={{ height: height }} src={icon} alt="/" />
          }
          iconActive={<img style={{ height: 30 }} src={icon} alt="/" />}
          size={30}
          onClick={() => buttonAction(action)}
        />
      </FloatingMenu>
    )
  }

  const forceRecapado = (id) => {
    MySwal.fire({
      title: (
        <div>
          <img
            src={recapadoIcon}
            alt={"/"}
            style={{ height: "100px", width: "100px" }}
          />
          <p>Desea forzar el servicio preventivo de recapado?</p>
        </div>
      ),
      input: "textarea",
      inputPlaceholder: props.intl.formatMessage({
        id: "llantaInspectionDetailsTable.recapado.modal_recapado.ingrese_detalle_acerca_razon_del_forzado",
        defaultMessage: "Por favor, ingrese un detalle acerca de la razon del forzado."
      }),
      buttonsStyling: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: props.intl.formatMessage({
        id: "llantaInspectionDetailsTable.recapado.modal_recapado.confirmar_si",
        defaultMessage: "Si",
      }),
      cancelButtonText: props.intl.formatMessage({
        id: "llantaInspectionDetailsTable.recapado.modal_recapado.confirmar_no",
        defaultMessage: "No",
      }),
      confirmButtonClass: "btn btn-primary mr-1 btn-vec",
      cancelButtonClass: "btn btn-danger btn-vec",
    }).then((result) => {
      if (!result.dismiss) {
        fetch(Config.get("apiUrlBase") + "/llantas/" + id + "/recapada", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization-Token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            observacion: result.value,
          }),
        })
          .then(function (response) {
            if (response.status !== 400) {
              props.dataTableUpdate();
              MySwal.fire(
                props.intl.formatMessage({
                  id: "llantaInspectionDetailsTable.recapado.modal_recapado.recapado_exitoso",
                  defaultMessage: "Recapado exitoso"
                }),
                "",
                "success"
              ).then((result) => {});
            } else {
              response.json().then((data) => {
                let mensaje = intl.formatMessage({
                  ...(backendStrings[data.detalle]
                    ? backendStrings[data.detalle]
                    : backendStrings["errors.default"]),
                });
                MySwal.fire(mensaje, "", "error");
              });
            }
          })
          .catch(function (error) {
            MySwal.fire("Error", "", "error");
          });
      }
    });
  };

  const eventDelete = (id) => {
    MySwal.fire({
      title: (
        <div>
          <i className="far fa-trash-alt fa-7x text-black p-2"></i>
          <h2 className="p-1">
            {props.intl.formatMessage({
              id:
                "llantaInspectionDetailsTable.recapado.modal_baja.desea_dar_de_baja_llanta",
              defaultMessage: "¿Desea dar de baja la llanta?",
            })}
          </h2>
        </div>
      ),
      buttonsStyling: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: props.intl.formatMessage({
        id: "llantaInspectionDetailsTable.recapado.modal_recapado.confirmar_si",
        defaultMessage: "Si",
      }),
      cancelButtonText: props.intl.formatMessage({
        id: "llantaInspectionDetailsTable.recapado.modal_recapado.confirmar_no",
        defaultMessage: "No",
      }),
      confirmButtonClass: "btn btn-primary mr-1 btn-vec",
      cancelButtonClass: "btn btn-danger btn-vec",
    }).then((result) => {
      if (!result.dismiss) {
        fetch(Config.get("apiUrlBase") + "/llantas/" + id, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization-Token": localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            props.dataTableUpdate();
            MySwal.fire(
              props.intl.formatMessage({
                id: "llantaInspectionDetailsTable.modal_baja.baja_exitosa",
                defaultMessage: "Eliminado!",
              }),
              "",
              "success"
            ).then(() => {});
          })
          .catch(function (error) {
            MySwal.fire("Error", "", "errors");
          });
      }
    });
  };

  const buttonAction = (service) => {
    if (service === 1) {
      //Force recapado
      forceRecapado(llanta.id);
    }
    if (service === 2) {
      //Delete
      eventDelete(llanta.id);
    }
    if (service === 3) {
      //Force Correctivo
      props.redirectToCorrectivo(llanta.id);
    }
  };

  return llanta !== null ? (
    <TableRow key={medicion.id}>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {medicion.posicion}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {llanta.serial}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {llanta.llanta_marca.logo ?
          <img style={{ width: "42px", height: "42px" }} src={process.env.PUBLIC_URL + "/images/llantas/marcas-logo/" + llanta.llanta_marca.logo} alt=""/>
          :
          <div style={{textAlign: "-webkit-center"}}>
            <div className="dt-user-avatar dt-user-avatar-more">
              {llanta.llanta_marca.nombre.substr(0, 2).toUpperCase()}
            </div>
          </div>
        }
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {llanta.llanta_modelo ? llanta.llanta_modelo.nombre : ""}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {llanta.llanta_modelo ? llanta.llanta_modelo.llanta_medida.nombre : ""}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        R{llanta.recapadas > 0 ? llanta.recapadas : llanta.recapadas}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {medicion.psi}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles-puntos-medicion"
        padding="none"
        align="center"
      >
        <div className="d-flex flex-row">
          {medicion.valor_medidos.map((punto, index) =>
            getCellStatus(punto, index)
          )}
        </div>
      </StyledTableCell>

      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {llanta.recapadas > 0 ? (
          <div style={{ lineHeight: "1em" }}>
            {Math.trunc(medicion.odometro) + " " + llanta.odometroLabel}
            <br></br>
            {Math.trunc(medicion.odometro_recapado)} R
          </div>
        ) : (
          Math.trunc(medicion.odometro) + " " + llanta.odometroLabel
        )}
      </StyledTableCell>
      <td
        className="blankTableCell custom-td-llanta-impeccion-detalles-accion"
        padding="none"
        align="center"
      >
        {getServicioButton()}
      </td>
    </TableRow>
  ) : null;
};

const ajaxHandler = new AjaxHandler();
const getData = (service) => {
  let serviceURL = Config.get("apiUrlBase") + service;
  return fetch(serviceURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization-Token": localStorage.getItem("token"),
    },
  }).then((response) => {
    return ajaxHandler.handleResponseErrorsIsValid(response)
      ? response.json()
      : null;
  });
};

export default LlantaInspectionDetails;
