import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import '../../assets/css/vec-dropzone.css'
import TicketsGestoriaAbmAdjuntos from './TicketsGestoriaAbmAdjuntos.js';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { ID__TE_PERSONA as PERSONA, ID__TE_MOVIL as MOVIL } from '../tipoEntidad/constants.js'

class TicketsGestoriaAbm extends Component {
	constructor(props) {
		super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        ticketTipo: 'GESTORIA',
        persona: null,
        movil: null,
        servicio: null,
        gerenciador: null,
        estado: 'ABIERTO',
        kmGenerado: 0,
        detalle: null,
        autogestion: false,
        abono: false,
        tareas: [],
        esSiniestro: false,
        numeroSiniestro: null,
        adjuntos: []
      },
      moviles: [],
      gerenciadores: [],
      listaSelectServicio: [],
      errors: [],
      loading: false,
      gerenciadoresDisabled: true,
      gerenciadoresIsLoading: false,
      movilesLoading: false,
      personasLoading: false
    };

    this.handleServicioChange = this.handleServicioChange.bind(this);
    this.handlePersonaChange = this.handlePersonaChange.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.servicio': (value) => Validator.notEmpty(value),
        'formData.persona': (value) => Validator.conditionalNotEmpty(this.state.formData.servicio && this.state.formData.servicio.vencimientoAplicaA === PERSONA, value),
        'formData.movil': (value) => Validator.conditionalNotEmpty(this.state.formData.servicio && this.state.formData.servicio.vencimientoAplicaA === MOVIL, value),
			}
    });
  }

  componentDidMount() {
    if(Security.hasPermission('TICKETS_GESTORIA_CREAR')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;
    Promise.all([
      this.ajaxHandler.getJson('/servicios/gestoria-correctivos/select'),
    ]).then((data) => {
      let servicios = data[0];
      component.setState({
        servicios: servicios
      }, () => {
        window.scrollTo(0, 0);
        $('#servicio').focus();
      });
    }).catch(function(error) {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      component.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleServicioChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.persona = null;
        formDataCopy.movil = null;
        this.setState({
          formData: formDataCopy,
          movilesLoading: true,
          personasLoading: true
         });
        this.ajaxHandler.getJson('/servicios/' + object.value)
        .then((data) => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy.servicio = data;
          this.setState({
            formData: formDataCopy
          }, () => {
            if(this.state.formData.servicio && this.state.formData.servicio.vencimientoAplicaA === PERSONA){
              this.ajaxHandler.getJson('/personas/select')
              .then((data2) => {
                let personas = data2;
                this.setState({
                  personas: personas,
                  personasLoading: false
                });
              }, () => resolve());
            } else {
              this.ajaxHandler.getJson('/moviles/operativos/'+object.value+'/select')
              .then((data2) => {
                let moviles = data2;
                this.setState({
                  moviles: moviles,
                  movilesLoading: false
                });
              }, () => resolve());
            }
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.servicio = null;
        formDataCopy.persona = null;
        formDataCopy.movil = null;
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }

  handleMovilChange(object) {
    let component = this;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

    if(object) {
      this.ajaxHandler.getJson('/moviles/' + object.value)
      .then((data) => {
        formDataCopy.movil = data;
        this.setState({
          formData: formDataCopy
        });
      }).catch(function(error) {
        component.ajaxHandler.handleError(error);
      });
    } else {
      formDataCopy.movil = null;
      this.setState({
        formData: formDataCopy
      });
    }
  }

  handlePersonaChange(object) {
    let component = this;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

    if(object) {
      this.ajaxHandler.getJson('/personas/' + object.value)
      .then((data) => {
        formDataCopy.persona = data;
        this.setState({
          formData: formDataCopy
        });
      }).catch(function(error) {
        component.ajaxHandler.handleError(error);
      });
    } else {
      formDataCopy.persona = null;
      this.setState({
        formData: formDataCopy
      });
    }
  }

  handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleSubmit(event) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    this.setState({
      formData: formDataCopy,
      loading: true
    },() =>{
      let component = this;
      this.ajaxHandler.fetch('/tickets', {
        method: 'POST',
        body: JSON.stringify({
          ...this.state.formData
        }),
      }).then(response => {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        this.ajaxHandler.handleError(error);
      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  	event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/tickets'
    });
	}

  handleAdjuntosChange(adjuntos) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.adjuntos = adjuntos;
    this.setState({
      formData: formDataCopy
    });
  }

	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let state = this.state;
    let requiredSymbol = state.props.action !== 'VIEW' ? ' *' : '';

    let servicio = formData.servicio ? state.servicios.find(e => e.value === formData.servicio.id) : null;
    let persona = formData.persona ? state.personas.find(e => e.value === formData.persona.id) : null;
    let movil = formData.movil ? state.moviles.find(e => e.value === formData.movil.id) : null;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i><FormattedMessage id="ticketsGestoriaAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="ticketsGestoriaAbm.render.general_data.campos_requeridos" defaultMessage="campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* SERVICIO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="servicio">
                              <FormattedMessage id="ticketsGestoriaAbm.render.general_data.label_servicio" defaultMessage="Servicio"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.servicio && formData.servicio.nombre ? formData.servicio.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="servicio"
                                  name="servicio"
                                  placeholder={!state.serviciosLoading ? this.props.intl.formatMessage({ id: 'ticketsGestoriaAbm.render.general_data.placeholder_servicio', defaultMessage: 'Seleccione' }) : ''}
                                  options={state.servicios}
                                  valueKey='value'
                                  labelKey='label'
                                  value={servicio ? { value: servicio.id, label: servicio.label } : null}
                                  onChange={(e) => this.handleServicioChange(e)}
                                  isLoading={state.serviciosLoading}
                                  disabled={state.serviciosLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.servicio.pristine || validationState.formData.servicio.valid}>{validationState.formData.servicio.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* MOVIL */}
                        {formData.servicio && formData.servicio.vencimientoAplicaA === MOVIL ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="base">
                              <FormattedMessage id="ticketsGestoriaAbm.render.general_data.label_movil" defaultMessage="Movil:"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.movil ? formData.movil.dominio : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="movil"
                                  name="movil"
                                  placeholder={this.props.intl.formatMessage({ id: 'ticketsGestoriaAbm.render.general_data.placeholder_movil', defaultMessage: 'Móvil' })}
                                  options={this.state.moviles}
                                  valueKey='value'
                                  labelKey='label'
                                  value={formData.movil ? {value: formData.movil.id, label: formData.movil.dominio} : null}
                                  onChange={(e) => this.handleMovilChange(e)}
                                  isLoading={state.movilesLoading}
                                  disabled={state.movilesLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.movil.pristine || validationState.formData.movil.valid}>{validationState.formData.movil.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : ( null )}
                        {/* PERSONA */}
                        {formData.servicio && formData.servicio.vencimientoAplicaA === PERSONA ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="persona">
                              <FormattedMessage id="ticketsGestoriaAbm.render.general_data.label_persona" defaultMessage="Persona"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              <div>
                                <Select
                                  id="persona"
                                  name="persona"
                                  placeholder={!state.personasLoading ? this.props.intl.formatMessage({ id: 'ticketsGestoriaAbm.render.general_data.placeholder_persona', defaultMessage: 'Seleccione' }) : ''}
                                  options={state.personas}
                                  valueKey='value'
                                  labelKey='label'
                                  value={persona ? { value: persona.id, label: persona.label } : null}
                                  onChange={(e) => this.handlePersonaChange(e)}
                                  isLoading={state.personasLoading}
                                  disabled={state.personasLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.persona.pristine || validationState.formData.persona.valid}>{validationState.formData.persona.message}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        ) : ( null )}
                        {/* BASE */}
                        {formData.movil || formData.persona ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label">
                              <FormattedMessage id="ticketsGestoriaAbm.render.general_data.label_base" defaultMessage="Base:"/>
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">{formData.movil ? (formData.movil.base ? formData.movil.base.descripcion : '') : (formData.persona.base ? formData.persona.base.descripcion : '')}</div>
                            </div>
                          </div>
                        </div>
                        ): null}
                      </div>
                      <div className="row">
                        {/* KM */}
                        {formData.movil ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="kmActual">
                              <FormattedMessage id="ticketsGestoriaAbm.render.general_data.label_km_actual" defaultMessage="Km. Actual :"/>
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">{formData.movil && formData.movil.kmActual ? Math.floor(formData.movil.kmActual) : ''}</div>
                            </div>
                          </div>
                        </div>
                        ) : ( null )}
                      </div>
                      <div className="row">
                        {/* DETALLE */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-2 label-control col-form-label" htmlFor="detalle">
                              <FormattedMessage id="ticketsGestoriaAbm.render.general_data.label_detallle" defaultMessage="Detallle:"/>
                            </label>
                            <div className="col-md-10">
                              <div>
                                <textarea className="form-control" id="detalle" name="detalle" rows="3" placeholder={this.props.intl.formatMessage({ id: 'ticketsGestoriaAbm.render.general_data.placeholder_detalle', defaultMessage: 'Detalle' })} value={formData.detalle ? formData.detalle : ''} onChange={this.handleInputFormChange}></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <TicketsGestoriaAbmAdjuntos callbackUpdate={this.handleAdjuntosChange.bind(this)}></TicketsGestoriaAbmAdjuntos>

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid || this.state.loading}>
                          <i className="fa fa-check-circle"></i><FormattedMessage id="ticketsGestoriaAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'ticketsGestoriaAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'ticketsGestoriaAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketsGestoriaAbm);
