import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import {FormattedMessage, injectIntl} from 'react-intl';
import Timezone from '../../../commons/timezone/Timezone.js';

am4core.useTheme(am4themes_animated);

class RadarTimeline extends Component{   
  

  constructor(props) {
		super(props);

    this.ajaxHandler = new AjaxHandler();

 	moment.locale('es');

	this.state = {
		redirectTo: null,
        props: this.props,	
        noHayDatos: false,		
        valores: null,
        startYear: moment().year(),
        endYear: moment().year(),
        currentYear: moment().year(),
        
		};
	
    //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);
    this.chart = null;
    this.series = null;
    this.valueAxis = null;
    this.yearLabel = null;

    this.colorSet = new am4core.ColorSet();
    this.useSetColor = false;
    this.categoryAxis= null;
    
  }

  componentDidMount(){
    this.renderData(this.props);
  }

  componentWillReceiveProps(nextProps){ 
      //si los datos llegan luego de renderizar el gráfico se vuelve a crear
    this.renderData(nextProps);
  }

  renderData(params){
    
   if(params.valores != this.state.valores || this.state.valores == null){
        if(params.valores != null){

    if(this.chart){
        this.chart.dispose();
    }
    
    this.chart = am4core.create("rtchartdiv"+this.props.id, am4charts.RadarChart);
    this.chart.numberFormatter.numberFormat = "$#|#|0";
    this.chart.hiddenState.properties.opacity = 0;

    this.chart.startAngle = 270 - 180;
    this.chart.endAngle = 270 + 180;
    
    this.chart.padding(5,15,5,10)
    this.chart.radius = am4core.percent(65);
    this.chart.innerRadius = am4core.percent(40);
    
    // year label goes in the middle
    this.yearLabel = this.chart.radarContainer.createChild(am4core.Label);
    this.yearLabel.horizontalCenter = "middle";
    this.yearLabel.verticalCenter = "middle";
    this.yearLabel.fill = am4core.color("#673AB7");

    if(params.fechaDesde != params.fechaHasta){
        this.yearLabel.fontSize = 20;
        this.yearLabel.text = moment(params.fechaDesde).format('DD/MM/YY') + '\n' + moment(params.fechaHasta).format('DD/MM/YY');
    } else{
        this.yearLabel.fontSize = 25;
        this.yearLabel.text = Timezone.getDateForClient(params.fechaDesde, null, 'DD/MM/YY');
    }
    
    if(params.name == 'gasto-total'){
        //seteo los colores definidos para cada entidad
        this.colorSet = {'PREVENTIVO':'#4dc4bc', 'CORRECTIVO':'#67b7dc', 'VENCIMIENTO':'#fe9657','GESTORIA':'#f7c361','COMBUSTIBLES':'#dc6967', 'INFRACCIONES': '#616c6f'};
        this.useSetColor = true;
    }

    // zoomout button
    let zoomOutButton = this.chart.zoomOutButton;
    zoomOutButton.dx = 0;
    zoomOutButton.dy = 0;
    zoomOutButton.marginBottom = 15;
    zoomOutButton.parent = this.chart.rightAxesContainer;
    
    // scrollbar
    this.chart.scrollbarX = new am4core.Scrollbar();
    this.chart.scrollbarX.parent = this.chart.rightAxesContainer;
    this.chart.scrollbarX.orientation = "vertical";
    this.chart.scrollbarX.align = "center";
    this.chart.scrollbarX.exportable = false;
    
    // vertical orientation for zoom out button and scrollbar to be positioned properly
    this.chart.rightAxesContainer.layout = "vertical";
    this.chart.rightAxesContainer.padding(120, 20, 120, 20);
    
    // category axis
    //let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    this.categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    this.categoryAxis.renderer.grid.template.location = 0;
    this.categoryAxis.dataFields.category = "country";
    
    let categoryAxisRenderer = this.categoryAxis.renderer;
    let categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.location = 0.5;
    categoryAxisLabel.radius = 28;
    categoryAxisLabel.relativeRotation = 90;
    
    categoryAxisRenderer.fontSize = 11;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -25;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;
    
    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;
    
    categoryAxisRenderer.tooltipLocation = 0.5;
    this.categoryAxis.tooltip.defaultState.properties.opacity = 0;
    
    // value axis
    this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    this.valueAxis.min = params.valores.minimo;
    this.valueAxis.max = params.valores.maximo;
    
    this.valueAxis.strictMinMax = true;
    this.valueAxis.tooltip.defaultState.properties.opacity = 0;
    this.valueAxis.tooltip.animationDuration = 0;
    this.valueAxis.cursorTooltipEnabled = true;
    this.valueAxis.zIndex = 10;
    
    let valueAxisRenderer = this.valueAxis.renderer;
    valueAxisRenderer.axisFills.template.disabled = true;
    valueAxisRenderer.ticks.template.disabled = true;
    valueAxisRenderer.minGridDistance = 20;
    valueAxisRenderer.grid.template.strokeOpacity = 0.05;
    
    
    // series
    this.series = this.chart.series.push(new am4charts.RadarColumnSeries());
    this.series.columns.template.width = am4core.percent(90);
    this.series.columns.template.strokeOpacity = 0;
    
    this.series.dataFields.valueY = "value" + this.state.currentYear;
    //this.series.dataFields.valueX = "tooltip" + this.state.currentYear;
    this.series.dataFields.categoryX = "country";
    this.series.tooltipText = "{categoryX}:{valueY.value}";
    
    // this makes columns to be of a different color, depending on value
    this.series.heatRules.push({ target: this.series.columns.template, property: "fill", minValue: params.valores.minimo, maxValue: params.valores.maximo, min: am4core.color("#673AB7"), max: am4core.color("#F44336"), dataField: "valueY" });
    
    // cursor
    let cursor = new am4charts.RadarCursor();
    this.chart.cursor = cursor;
    cursor.behavior = "zoomX";
    
    cursor.xAxis = this.categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;
    
    cursor.lineX.fillOpacity = 0.2;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = true;
    
    // year slider
    let yearSliderContainer = this.chart.createChild(am4core.Container);
    yearSliderContainer.layout = "vertical";
    yearSliderContainer.padding(0, 38, 0, 38);
    yearSliderContainer.width = am4core.percent(100);
    
    let yearSlider = yearSliderContainer.createChild(am4core.Slider);
    yearSlider.events.on("rangechanged", function () {
        //this.updateRadarData(this.state.startYear + Math.round(yearSlider.start * (this.state.endYear - this.state.startYear)));
        let year = this.state.startYear + Math.round(yearSlider.start * (this.state.endYear - this.state.startYear));
        if (this.state.currentYear != year) {
            this.state.currentYear=year;//currentYear = year;
            this.yearLabel.text = String(year);
            this.series.dataFields.valueY = "value" + year;
            this.chart.invalidateRawData();
        }
    },this)
    
    //yearSlider.events.on("rangechanged", this.yearEvent(yearSlider.start));
    yearSlider.orientation = "horizontal";
    yearSlider.start = 1;
    yearSlider.exportable = false;
    
    this.chart.data = this.generateRadarData(params);
    
    let slider = yearSliderContainer.createChild(am4core.Slider);
    slider.start = 1;
    slider.exportable = false;
    let component = this.chart;
    let axis = this.valueAxis;
    slider.events.on("rangechanged", function () {
        let start = slider.start;
    
        component.startAngle = 270 - start * 179 - 1;
        component.endAngle = 270 + start * 179 + 1;
    
        axis.renderer.axisAngle = component.startAngle;
    });
    this.setState({valores: params.valores, noHayDatos: false});
}else{
    this.setState({valores: null, noHayDatos: true});
}
}
}    
    generateRadarData(nextProps) {
        let data = [];
        let i = 0;
        
        var interior = nextProps.valores.datos;
        for (var continent in interior) {
            let continentData = interior[continent];
            let startYear = this.state.startYear;
            continentData.forEach(function (country) {
                let rawDataItem = { "country": country[0] }
    
                for (var y = 1; y < country.length; y++) {
                    rawDataItem["value" + (startYear + y - 1)] = country[y];
                     //    rawDataItem["tooltip" + (startYear + y - 1)] = parseInt(country[y]).toLocaleString('de-DE');
                }
    
                data.push(rawDataItem);
            });
    
            this.createRange(continent, continentData, i);
            i++;
    
        }
        return data;
    }
  /*  
    yearEvent (yearSliderStart) {
        this.updateRadarData(this.state.startYear + Math.round(yearSliderStart * (this.state.endYear - this.state.startYear)));
    }

    updateRadarData(year) {console.log("updateRadarData");
       // if (this.state.currentYear != year) {
       //     this.state.set("currentYear",year);//currentYear = year;
       //     yearLabel.text = String(this.state.currentYear);
       //     series.dataFields.valueY = "value" + this.state.currentYear;
       //     chart.invalidateRawData();
       // }
    }
    */
    createRange(name, continentData, index) {
    
        let axisRange = this.categoryAxis.axisRanges.create();
        axisRange.axisFill.interactionsEnabled = true;
        axisRange.text = name;
        
        // first country
        axisRange.category = continentData[0][0];
        // last country
        axisRange.endCategory = continentData[continentData.length - 1][0];
    
        if(!this.useSetColor){
            // every 3rd color for a bigger contrast
            axisRange.axisFill.fill = this.colorSet.getIndex(index * 3);
        }
        else{
            axisRange.axisFill.fill = this.colorSet[name];
        }
        axisRange.grid.disabled = true;
        axisRange.label.interactionsEnabled = false;
    
        let axisFill = axisRange.axisFill;
        axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
        axisFill.radius = -20; // negative radius means it is calculated from max radius
        axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
        axisFill.fillOpacity = 1;
        axisFill.togglable = true;
    
        axisFill.showSystemTooltip = true;
        axisFill.readerTitle = name+":$"+continentData[continentData.length - 1][2];
        axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        
        //axisFill.events.on("hit", this.axisFillEvent,this);
        axisFill.events.on("hit",function (event) {
            let dataItem = event.target.dataItem;
            if (!event.target.isActive) {
                this.categoryAxis.zoom({ start: 0, end: 1 });
            }
            else {
                this.categoryAxis.zoomToCategories(dataItem.category, dataItem.endCategory);
            }
        },this);
    
        // hover state
        let hoverState = axisFill.states.create("hover");
        hoverState.properties.innerRadius = -10;
        hoverState.properties.radius = -25;
    
        let axisLabel = axisRange.label;
        axisLabel.location = 0.5;
        axisLabel.fill = am4core.color("#ffffff");
        axisLabel.radius = 3;
        axisLabel.relativeRotation = 0;
    }
/*
    axisFillEvent (event) {console.log(event);
        let dataItem = event.target.dataItem;
        if (!event.target.isActive) {
            this.state.categoryAxis.zoom({ start: 0, end: 1 });
        }
        else {
            this.state.categoryAxis.zoomToCategories(dataItem.category, dataItem.endCategory);
        }
    }
*/

    componentWillUnmount() {
        
        if (this.chart) {
          this.chart.dispose();
        }
      }

  render() {
    return (
        <React.Fragment>
        <div id={"rtchartdiv"+this.props.id} style={{ width: "100%", height: "500px" }} hidden={this.state.noHayDatos === true}></div>
        <div hidden={this.state.noHayDatos === false} className="text-center col-12" ><h4 style={{color:"blue"}}><FormattedMessage id="radarTimeline.filter_date.information.no_hay_datos_fecha_especificada" defaultMessage="No hay datos en la fecha especificada"/></h4></div> 
        </React.Fragment>
    );
  }
 


} export default injectIntl(RadarTimeline);
