import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Loading from "../ui/Loading.js";
import moment from "moment";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import * as Constants from "../atributoDinamico/constants.js";
import $ from "jquery";
import axios from "axios";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import { castValoresDinamicos } from "../atributoDinamico/helpers.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import AsociarTicket from "./AsociarTicket.js";
import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import LlantaInspectionDetailsTable from "./LlantaInspectionDetailsTable.js";

const configAxios = {
  headers: {
    "Authorization-Token": localStorage.getItem("token"),
  },
};

const StyledTable = {
  root: {
    padding: "0 !important",
  },
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1a1036",
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif"
  },
  body: {
    fontSize: 10,
  },
}));

const BlankTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F4F5FA",
    color: theme.palette.common.black,
    fontSize: 10,
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif",
    borderBottom: 0,
  },
  body: {
    fontSize: 10,
  },
}));

class LlantaInspectionDetails extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");

    this.openModalTicket = this.openModalTicket.bind(this);
    this.handleCloseModalTicket = this.handleCloseModalTicket.bind(this);
    this.redireccionarATickets = this.redireccionarATickets.bind(this);
    this.getTicketURL = this.getTicketURL.bind(this);

    this.state = {
      props: this.props,
      inspeccion: null,
      mediciones: [],
      loading: true,
      id_map: "map",
      id_modal_ticket: "modal_ticket",
      dialogInformarPago: {
        open: false,
        movil: null,
        valorDinamicoId: null,
      },
      latitudCentro: null,
      longitudCentro: null,
    };
  }
  initForm() {
    this.setState({ loading: true });
    let component = this;
    let id_map = "map_" + this.state.props.inspeccion_id;
    let id_modal_ticket = "modal_ticket_" + this.state.props.inspeccion_id;
    Promise.all([
      this.state.props.action !== "ADD"
        ? this.ajaxHandler.getJson("/llantainspecciones/" + this.state.props.inspeccion_id)
        : null,
      ConfigBusiness.get("direccion.latitud"),
      ConfigBusiness.get("direccion.longitud"),
      this.state.props.action !== "ADD"
        ? this.ajaxHandler.getJson("/llantainspecciones/mediciones/" + this.state.props.inspeccion_id)
        : null,
    ])
      .then((data) => {
        let inspeccion = data[0];
        //let valores_dinamicos = castValoresDinamicos(formulario.tipo_formulario.atributos_dinamicos, formulario.valores_dinamicos, false)
        let latitudesPosibles = [inspeccion.latitudMovil, inspeccion.latitudForm, data[1], -34.6035814];
        let longitudesPosibles = [inspeccion.longitudMovil, inspeccion.longitudForm, data[2], -58.38157119];
        let mediciones = data[3];
        component.setState({
          id_map: id_map,
          id_modal_ticket: id_modal_ticket,
          inspeccion: inspeccion,
          mediciones: mediciones,
          latitudCentro: parseFloat(latitudesPosibles.find((x) => !!x)),
          longitudCentro: parseFloat(longitudesPosibles.find((x) => !!x)),
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if(this.state.inspeccion)
          this.initMap();
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.ajaxHandler.subscribe(this);
    this.initForm();
  }
  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
    $(".popover2").popover("dispose");
  }

  openImage(adjunto_id) {
    let config = Object.assign({}, configAxios, { responseType: "blob" });
    axios
      .get(Config.get("apiUrlBase") + "/adjuntos/files/" + adjunto_id, config)
      .then((response) => {
        if (response.status === 200) {
          let fileUrl = URL.createObjectURL(response.data);
          window.open(fileUrl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  openModalTicket(valorDinamico) {
    let component = this;
    component.setState({
      dialogInformarPago: {
        open: true,
        movil: this.state.inspeccion.movil,
        valorDinamicoId: this.state.inspeccion.id,
      },
    });
    $("#" + component.state.id_modal_ticket).modal({ backdrop: "static" });
    $("#" + component.state.id_modal_ticket).on("hidden.bs.modal", function () {
      component.setState({
        dialogInformarPago: {
          open: false,
          movil: null,
          valorDinamicoId: null,
        },
      });
    });
  }

  handleCloseModalTicket() {
    $("#" + this.state.id_modal_ticket).modal("hide");
    this.setState({
      dialogInformarPago: {
        open: false,
        movil: null,
        valorDinamicoId: null,
      },
    });
  }

  redireccionarATickets() {
    $("#" + this.state.id_modal_ticket).modal("hide");
    $(".modal-backdrop").remove();
    let valorDinamicoId = this.state.dialogInformarPago.valorDinamicoId;
    let movil = this.state.dialogInformarPago.movil;
    this.setState(
      {
        dialogInformarPago: {
          open: false,
          movil: null,
          valorDinamicoId: null,
        },
      },
      () => {
        $(".popover2").popover("dispose");
        window.location.href =
          "/correctivos/add/movil/" + movil.id + "/" + movil.dominio + "?valorDinamico=" + valorDinamicoId;
        //this.props.setRedirect('/correctivos/add/movil/' + movil.id + '/' + movil.dominio + '?valorDinamico=' + valorDinamicoId );
      }
    );
  }

  initMap() {
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (window.google) {
          var centro = {
            lat: this.state.latitudCentro,
            lng: this.state.longitudCentro,
          };
          this.map = new window.google.maps.Map(document.getElementById(this.state.id_map), {
            zoom: 10,
            center: centro,
          });
          // Place a draggable marker on the map
          if (this.state.inspeccion.latitudMovil) {
            let markerMovil = {
              lat: parseFloat(this.state.inspeccion.latitudMovil),
              lng: parseFloat(this.state.inspeccion.longitudMovil),
            };
            let car_image = {
              url: "/images/icons/car.svg",
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(30, 30),
            };
            this.markerMovil = new window.google.maps.Marker({
              position: markerMovil,
              map: this.map,
              draggable: false,
              title: this.props.intl.formatMessage({
                id: "llantaInspectionDetails.initMap.title_posicion_movil",
                defaultMessage: "Posición del Móvil",
              }),
              icon: car_image,
            });
          }
          if (this.state.inspeccion.latitudForm) {
            let markerForm = {
              lat: parseFloat(this.state.inspeccion.latitudForm),
              lng: parseFloat(this.state.inspeccion.longitudForm),
            };
            let person_image = {
              url: "/images/icons/checklist.svg",
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(30, 30),
            };
            this.markerForm = new window.google.maps.Marker({
              position: markerForm,
              map: this.map,
              draggable: false,
              icon: person_image,
              title: this.props.intl.formatMessage({
                id: "llantaInspectionDetails.initMap.title_posicion_formulario",
                defaultMessage: "Posición del Formulario",
              }),
            });
          }
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  redirectToCorrectivo(id) {
    this.props.redirectToCorrectivo(id)
  }

  getTicketURL(ticket_id) {
    return window.location.origin + "/tickets/" + ticket_id + "/edit";
  }

  render() {
    let state = this.state;
    let inspeccion = state.inspeccion;
    $(".popover2").popover();

    return (
      <React.Fragment>
        <div className="w-100" style={{ background: "#f4f5fa" }}>
          <div className="card">
            <div className="card-content">
              <div className="card-body" style={{ "background": "#f4f5fa" }}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title card-title-costo-total">
                          <i className="grey-color font-large-2 pr-1 fa fa-map-marker"></i>
                          {this.props.intl.formatMessage({
                            id: "llantaInspectionDetails.render.maps.header_ubucacion",
                            defaultMessage: "Ubicación"
                          })}
                        </h5>
                        <div id={this.state.id_map} style={{ height: "400px", margin: "auto" }} />
                      </div>

                      {this.state.inspeccion &&
                        this.state.inspeccion.estado_gps == Constants.ID_ESTADO_APROBACION_INVALIDO && (
                          <div className="col-md-12  danger">
                            <p className="text-truncate">
                              {this.props.intl.formatMessage({
                                id: "llantaInspectionDetails.render.maps.inspeccion_realizada_a",
                                defaultMessage: "Inspección realizada a"
                              })}{" "}
                              {Math.ceil(this.state.inspeccion.metros_avl / 1000)} {" "}
                              {this.props.intl.formatMessage({
                                id: "llantaInspectionDetails.render.maps.kilometros_posicion_obtenida_del_vehiculo",
                                defaultMessage: "kilómetros de la posición obtenida del vehículo en el AVL"
                              })}
                            </p>
                          </div>
                        )}

                      {this.state.inspeccion &&
                        this.state.inspeccion.movil_id &&
                        !this.state.inspeccion.latitudMovil && (
                          <div className="col-md-12">
                            <p className="text-truncate">
                              {this.props.intl.formatMessage({
                                id: "llantaInspectionDetails.maps.information_no_se_encontraron_posicion_movil",
                                defaultMessage: "No se encontraron datos sobre la posición del móvil",
                              })}
                            </p>
                          </div>
                        )}
                      {this.state.inspeccion && !this.state.inspeccion.latitudForm && (
                        <div className="col-md-12">
                          <p className="text-truncate">
                            {this.props.intl.formatMessage({
                              id: "llantaInspectionDetails.maps.information_no_se_encontraron_posicion_donde_completo_formulario",
                              defaultMessage:
                                "No se encontraron datos sobre la posición donde se completó el formulario",
                            })}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h5 className="card-title card-title-costo-total">
                      <i className="font-large-2 pr-1 fa fa-check"></i>
                      {this.props.intl.formatMessage({
                        id: "llantaInspectionDetails.render.inspecciones_header",
                        defaultMessage: "Inspecciones"
                      })}
                    </h5>
                    {this.state.inspeccion ? (
                      <LlantaInspectionDetailsTable
                        redirectToCorrectivo={(id) => this.redirectToCorrectivo(id)}
                        intl={this.props.intl}
                        inspeccion_id={this.state.inspeccion.id}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            tabIndex="-1"
            id={this.state.id_modal_ticket}
            role="dialog"
            aria-labelledby={this.state.id_modal_ticket + "_label"}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id={"h_" + this.state.id_modal_ticket + "_label"}>
                    <i className="ft-info align-middle icon-modal-title"></i>
                      {this.props.intl.formatMessage({
                        id: "llantaInspectionDetails.render.modal_asociar_ticket.header_asociar_ticket",
                        defaultMessage: "Asociar Ticket"
                      })}
                  </h4>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden pagarInfranccion_modal_content">
                  {this.state.dialogInformarPago.valorDinamicoId && (
                    <AsociarTicket
                      data={this.state.dialogInformarPago}
                      redireccionarATickets={this.redireccionarATickets}
                      callbackClose={(event) => this.handleCloseModalTicket()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LlantaInspectionDetails;
