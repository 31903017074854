import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import LlantaMarcasGrid from "./LlantaMarcasGrid";
import LlantaMarcasAbm from "./LlantaMarcasAbm.js";
import { FormattedMessage, injectIntl } from "react-intl";

class LlantaMarcas extends Component {
  render() {
    return (
      <div className="content-wrapper llantas-modulo">
        <div className="content-header row">
          <div className="content-header-left col-md-6 col-12 mb-5">
            <h3 className="content-header-title">
              <FormattedMessage
                id="llanta_marcas.header_administracion_marcas"
                defaultMessage="Administración de Marcas Llantas"
              />
            </h3>
          </div>
        </div>
        <div className="content-body">
          <Switch>
            <Route exact path={`${this.props.match.url}`} component={LlantaMarcasGrid}/>
            <Route exact path={`${this.props.match.url}/add`} render={(props) => <LlantaMarcasAbm {...props} action="ADD" />} />
            <Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <LlantaMarcasAbm {...props} action="EDIT" />} />
            <Route path={`${this.props.match.url}/:id`} render={(props) => <LlantaMarcasAbm {...props} action="VIEW" />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default LlantaMarcas;
