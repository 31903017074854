import React, { useState } from 'react';

const TicketEditaDetalle = ({ detalle, onSave, onCancel }) => {
  const [nuevoDetalle, setNuevoDetalle] = useState(detalle);

  const handleGuardarDetalle = () => {    
    onSave(nuevoDetalle)      
  };

  return (
    <div>
      <textarea
        className="form-control"
        id="detalle"
        name="detalle"
        rows="3"
        placeholder="Detalle"
        value={nuevoDetalle}
        onChange={(e) => setNuevoDetalle(e.target.value)}
      ></textarea>
      <button type="button" className="btn btn-default btn-fleet" onClick={handleGuardarDetalle}>
        Guardar
      </button>
    </div>
  );
};

export default TicketEditaDetalle;
