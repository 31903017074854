import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import backendStrings from '../../lang/backendStrings.js';


class VencimientosImportarReporte extends Component {
	constructor(props) {
		super(props);
		this.props = props;

		this.state = {
			tipo: null,
			tiposArchivos: []
		};

	}

	componentDidMount() {

		this.ajaxHandler = new AjaxHandler();
		this.ajaxHandler.subscribe(this);

	}

	componentWillUnmount() {

		this.ajaxHandler.unsubscribe();

	}

	handleUploadFiles(file) {
		this.setState({ archivo: file });
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.props.callbackClose();
	}

	handleSave(event) {

		this.setState({ loading: true }, () => this.dropzone.processQueue());
		event.preventDefault();
	}

	render() {

		return (
			<React.Fragment>
				<div className="card pull-up">
					<div className="card-content pl-1 pr-1">
						<div className="card-body form">
							<h4 className="form-section">
								Filas que fallaron
							</h4>
							<div className="row mb-1" style={{ border: 0 }}>
								<div className="content-body" style={{ margin: "auto" }}>
									{this.props.data.filas_fallaron && Object.entries(this.props.data.filas_fallaron).map(([index, pagina]) => {
										if (Array.isArray(pagina)) {
											return (
												<div className="row mt-2" key={index}>
													<div className="table-responsive">
														<table id="dataTable" className="table dataTable nowrap server-side table-hover dataTableMovil" ref="grid" width="100%">
															<thead>
																<tr>
																	<th>Fila</th>
																	<th>Entidad</th>
																	<th>Motivo Falla</th>
																</tr>
															</thead>
															<tbody>
																{pagina.map(({ fila, dominio, motivo_falla }, index) => (
																	<tr key={index}>
																		<td>{fila}</td>
																		<td>{dominio}</td>
																		<td>
																			<FormattedMessage
																				{...(backendStrings[motivo_falla] || backendStrings['errors.default'])}
																			/>
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												</div>
											);
										} else {
											this.handleCancel();
											return (null);
										}
									})}
								</div>
							</div>

						</div>

					</div>
				</div>

			</React.Fragment>)
	}
}

export default VencimientosImportarReporte
