import React, { useState, useEffect} from "react";
import { Redirect } from 'react-router-dom'
import {
	TableRow,
} from '@material-ui/core'
import 'moment/min/locales'
import Swal from 'sweetalert2'

import * as Utils from '../../commons/utils/Utils'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'

const MotivoCambioTurnosGridRow = (props) => {
	const intl = props.intl
	const motivo = props.motivo;
	const permissions = props.permissions;
	const baseUrl = props.baseUrl;

	const [redirectTo, setRedirectTo] = useState(null)

	useEffect(()=>{
	},[]);

	const getEstado = (activo) => {
		if (activo === 1) {
		  	return "status-green";
		} else if  (activo === 0){
			return "status-red";
		} else {
		  	return "";
		}
	};

	const eliminarMotivo = () => {
		Swal.fire({
			title: intl.formatMessage({ id: '¿Confirma la eliminación?', defaultMessage: '¿Confirma la eliminación?' }),
			html: intl.formatMessage({ id: 'Se_eliminará_el_motivo', defaultMessage: 'Se eliminará el motivo' }) + ': ' + "<b>" + motivo.nombre + "</b>",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: intl.formatMessage({ id: 'Si', defaultMessage: 'Si' }),
			cancelButtonText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
		}).then((result) => {
			if (result.value) {
				Utils.deleteData('/motivocambioturnos/' + motivo.id)
				.then(() => {
					props.fetchMotivos();
					Swal(intl.formatMessage({ id: 'Eliminado!', defaultMessage: 'Eliminado!' }), "", "success");
				})
				.catch((err) => {
					Swal(
						err.response.data.detalle[0],
						'',
						'error',
					);
				});
			}
		})
	};

	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<TableRow>
				<StyledTableCell align="left">
					{motivo.id}
				</StyledTableCell>
				<StyledTooltip arrow placement="right">
					<td
						align="left"
						className={getEstado(motivo.activo) + " p-0 all custom-lineHeight br"}
						style={{ maxWidth: "3px"}}
					/>
				</StyledTooltip>
				<StyledTableCell align="left">
					{motivo.nombre}
				</StyledTableCell>

				<StyledTableCell>
					<div className="d-inline-flex">
						{permissions.motivosVisualizar &&
							<button
								className="action view btn btn-sm btn-icon btn-dt-grid text-success"
								title="Ver"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + motivo.id)}
							>
								<i className="fa fa-search fa-xs"/>
							</button>
						}
						{permissions.motivosModificar &&
							<button
								className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
								title="Editar"
								data-togle="tooltip"
								data-placement="top"
								onClick={()=> setRedirectTo(baseUrl +'/'+ motivo.id +'/edit')}
								>
									<i className="fa fa-pencil fa-xs"/>
							</button>
						}
						{permissions.motivosEliminar &&
							<button
								className="action delete btn btn-sm btn-icon btn-dt-grid text-danger"
								title="Eliminar"
								data-togle="tooltip"
								data-placement="top"
								onClick={()=> eliminarMotivo()}
								>
									<i className="fa fa-trash fa-xs"/>
							</button>
						}
					</div>
				</StyledTableCell>
			</TableRow>
		</>
	)
}

export default MotivoCambioTurnosGridRow
