import * as Yup from 'yup'

export const validateForm = (component, field, form) => {
  if(!component && !field && !form ) return false

  let dirty = component.state.validationDirty
  dirty[field] = true;
  component.state.schema.validate(form, {abortEarly: false})
  .then(valid => {
    component.setState({
      validationErrors: {},
      validationDirty: dirty
    })
  })
  .catch(e => {
    let errors={};
    e.inner.forEach((x) => {
      if (x.path !== undefined && dirty[x.path] === true) {
        errors[x.path] = x.message;
      }
    });

    component.setState({
      validationErrors: errors,
      validationDirty: dirty
    })
  })
  // validateFormAll(component, form);
}

export const validateFormAll = (component, form) => {

  component.state.schema.validate(form, {abortEarly: false})
  .then(valid => {
    console.log('Valid Form!');
  })
  .catch(e => {
    let errors={};
    e.inner.forEach((x) => {
      if (x.path !== undefined) {
        errors[x.path] = x.message;
      }
    });

    console.log('Errors: ',errors);
    console.log('# fails: ',Object.keys(errors).length);
  })
}