import { useEffect, useReducer } from "react"

export const isEmptyObject = (obj) => {
    if (typeof (obj) !== 'object') throw Error(`'isEmptyObject' expects an object, recived: ${typeof (obj)}`)

    return Object.keys(obj).length === 0
}

export const isEmptyArray = (arr) => {
    if (!Array.isArray(arr)) throw Error(`'isEmptyArray' expects an array, recived: ${typeof (arr)}`)

    return arr.length === 0
}

export const pluck = property => element => element[property]

export const preventSymbolsOnNumerInput = e => ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()


// Simple maquina de estados 
// Se tomo como ejemplo la libreria 'xstate' de react 
// Si bien no es el mismo codigo, trata de emular el funcionamiento.
// Para trubleshooting chequear docs (https://xstate.js.org/docs/)
// ya que al ser la fuente de inspiracion y puede dar ideas ¯\_(ツ)_/¯

export const createMachine = (config, context = {}) => {
    // Estado incial
    const initialState = {
        current: config.init,
        actions: Object.keys(config.states[config.init].on)
    }

    // Segun en el estado (state node) que se encuentre devulve los posibles pasos siguientes `nextStates`
    const [stateNode, dispatch] = useReducer((state, action) => {
        const currentState = config.states[state.current]
        const nextState = currentState.on[action]

        // console.log('currentState', currentState)
        // console.log('nextState', nextState)

        if (!nextState) return state

        return {
            current: nextState,
            actions: Object.keys(config.states[nextState].on)
        }
    }, initialState)

    // console.log('stateNode', stateNode)

    // Aplica acciones de transicion asociado al estado (opcional)  
    useEffect(() => {
        // console.log('stateNode current', stateNode.current)
        if (!config.states[stateNode.current]) return

        // Opcionalmente si se necesita hacer alguna validacion contextual en la entrada/salida del estado 
        // se puede usar el segundo parametro 'context' que estarà disponible tanto en `entry` como en `exit`
        config.states[stateNode.current].entry && config.states[stateNode.current].entry(context)
        return () => config.states[stateNode.current].exit && config.states[stateNode.current].exit(context)
    }, [stateNode.current])

    return [stateNode, dispatch]
}

//     // ESTRUCTURA DEL OBJETO `config` QUE ESPERA `createMachine`
// const config = {
//     id: "ejemplo", /* string */
//     init: "verde", /* string: que corresponda a uno de los nodos de estado definidos a continuacion */
//     states: { /* objeto */
//         verde: { /* objeto */
//             on: { AMARILLO: "amarillo" }, /* objeto: {ACCION1 : nodo1, ACCION2: nodo2} */
//             entry: () => {
//                 /* funcion de entrada */

//                 if (!context.isMidnight) {
//                     setTimeout(send('AMARILLO'), 5000)
//                 }
//             },
//             exit: (context) => {
//                 /* funcion de salida */

//                 context.isMidnight && setBlinkStatus()
//             }
//         },
//         amarillo: {
//             on: {
//                 ROJO: "rojo",
//                 VERDE: "verde"
//             },
//             entry: () => {
//                 console.log('Entra amarillo')
//             },
//             exit: () => {
//                 console.log('Sale amarillo')
//             }
//         },
//         rojo: {
//             on: { AMARILLO: "amarillo" },
//             entry: () => {
//                 console.log('Entra rojo')
//             },
//             exit: () => {
//                 console.log('Sale rojo')
//             }
//         },
//     }
// }


// Inicializacion 
// import * as H from '../../commons/utils/Helpers' 
// const [testMachine, send] = H.createMachine(config, contexto)

// Ej. de uso en el componente
// <span>{testMachine.current}</span>
// <button onClick={() => send("VERDE")}>VERDE</button>
// <button onClick={() => send("AMARILLO")}>AMARILLO</button>
// <button onClick={() => send("ROJO")}>ROJO</button>