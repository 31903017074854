import React, { Component } from 'react'

class ExtraBasic extends Component {
	constructor(props) {
    super(props);
    this.props = props;
		this.handleChangeAndUpdateFather = this.handleChangeAndUpdateFather.bind(this);
		this.componentDidUpdate = this.componentDidUpdate.bind(this);
		this.state = {}
  }

	componentDidUpdate(prevProps) {
		if (prevProps.extraParams !== this.props.extraParams) {
			this.setState(this.props.extraParams);
		}
	}

	handleChangeAndUpdateFather(extraParams) {
		this.setState(extraParams);
		this.props.handleChange(extraParams);
	}
	render() {
      return false
  }
}

export default ExtraBasic;
