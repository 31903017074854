import React, { Component } from 'react'
import {FormattedMessage,injectIntl} from 'react-intl';
import { Route, Switch } from 'react-router-dom'
import UsuariosMovilesGrid from './UsuariosMovilesGrid'
import UsuariosMovilesAbm from './UsuariosMovilesAbm'


class UsuariosMoviles extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title"><i className="la la-truck ml-1 mr-1 align-middle"></i><FormattedMessage id="moviles.header_administracion_de_usuriosmoviles" defaultMessage="Administración de Usuarios Móviles"/></h3>
                    </div>
                </div>
                <div className="content-body">
                    <Switch>
                        <Route exact path={`${this.props.match.url}`} component={UsuariosMovilesGrid} />
                        <Route exact path={`${this.props.match.url}/:id/edit`} component={(props) => <UsuariosMovilesAbm {...props} action="EDIT" />} />
                        <Route path={`${this.props.match.url}/:id`} component={(props) => <UsuariosMovilesAbm {...props} action="VIEW" />} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default injectIntl(UsuariosMoviles);
