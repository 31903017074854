import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import LlantaAsignacionesGrid from "./LlantaAsignacionesGrid.js";

class LlantaAsignaciones extends Component {
  render() {
    return (
      <div className="content-wrapper llantas-modulo">
        <div className="content-header row">
          <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title">
              <FormattedMessage
                id="llantasAsignaciones.header_asignacion_llantas"
                defaultMessage="Asignación de Llantas"
              />
            </h3>
          </div>
        </div>
        <div className="content-body">
          <Switch>
            <Route exact path={`${this.props.match.url}`} component={LlantaAsignacionesGrid}/>
          </Switch>
        </div>
      </div>
    );
  }
}

export default LlantaAsignaciones;
