import $ from 'jquery'
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import Dropzone from "./Dropzone.js";
import Loading from "../ui/Loading.js";
import { Redirect } from "react-router-dom";
import * as Utils from '../../commons/utils/Utils'
import Dialog from '../../commons/dialog/Dialog.js';
import backendStrings from '../../lang/backendStrings.js';
import { FormattedMessage, injectIntl } from "react-intl";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import React, { useState, useEffect, useLayoutEffect } from "react";
import TicketPresupuestoRepuesto from "./TicketPresupuestoRepuesto.js";
import TicketPresupuestoManoObra from "./TicketPresupuestoManoObra.js";

const FORM_DATA_INIT = {
	id: null,
	manoDeObra: 0,
	repuestos: 0,
	impuestos: 0,
	otros: 0,
	adjunto: null,
	adicional: false,
	presupuestoItems: [],
	presupuestoTareas: []

}

const requiredSymbol = ' *';

const TicketPresupuesto = (props) => {
  const intl = props.intl;
	const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
	const [servicioItems, setServicioItems] = useState([]);
	const [presupuestoTareas, setPresupuestoTareas] = useState([]);
	const [formData, setFormData] =  useState({ ...FORM_DATA_INIT });
	const [totalRepuestos, setTotalRepuestos] = useState(0);
	const [totalManoObra, setTotalManoObra] = useState(0);
	const [impuestos, setImpuestos] = useState(0);
	const [otros, setOtros] = useState(0);
	const [total, setTotal] = useState(0);
	const [adjunto, setAdjunto] = useState(null);
	const [errors, setErrors] = useState([]);
	
	useEffect(() => {
			setLoading(true)
      init();
  }, []);
	
	const init = () => {
		setImpuestos(0)
		setOtros(0)
		setAdjunto(null)
		setErrors([])
    Promise.all([
      	((props.ticket.ticketTipo === 'PREVENTIVO' || props.ticket.ticketTipo === 'CORRECTIVO') && props.ticket.servicio) ? Utils.getData(`/items/items-and-servicio-by-ticket/${props.ticket.id}`) : [],
		(props.ticket.ticketTipo === 'PREVENTIVO' || props.ticket.ticketTipo === 'CORRECTIVO') ? Utils.getData(`/tareas/tareas-and-servicio-by-ticket/${props.ticket.id}`) : [],
    ])
    .then((data) => {


		let items = [];
		if(data[0]){
			data[0].forEach(element => {
				items.push({
					'cantidad': element.cantidad ? element.cantidad : "1",
					'costo': element.costo ? element.costo : "0",
					'cuenta_mayor': element.cuenta_mayor,
					'cuenta_mayor_descripcion': element.cuenta_mayor_descripcion,
					'descripcion': element.descripcion,
					'external_code': element.external_code,
					'id': element.id,
					'id_item': element.id_item,
					'marca': element.marca,
					'numero_parte': element.numero_parte,
					'referencia': element.referencia,
					'servicio_id': element.servicio_id,
					'subcategoria': element.subcategoria,
					'tipo': element.tipo,
					'unidad': element.unidad,
				});
			});
		}

		setServicioItems(items);
		let tareas = [];
		if(data[1]){
			data[1].forEach(element => {
				tareas.push({
					'id_tarea': element.id,
					'external_code': element.external_code,
					'nombre': element.nombre,
					'cuenta_mayor': element.servicio_external_code,
					'cuenta_mayor_descripcion': element.servicio_nombre,
					'precio': element.precio ? element.precio : "0",
					'servicio_id': element.servicio_id
				});
			});
		}

		setPresupuestoTareas(tareas);
		calculateTotal(data[0], data[1], 0, 0);
    })
    .catch((err) => {
      setLoading(false)
    })
		.finally(() => {
			setLoading(false)
		});
  };

	const calculateTotal = (items, tareas, impuestosActual, otrosActual) => {
    let totalRepuestosNew = 0;
		items.forEach(element => {
			if(element.cantidad){
				if(element.costo){
					totalRepuestosNew += element.cantidad*element.costo
				}
			}
		});
		setTotalRepuestos(totalRepuestosNew);

		let totalManoObraNew = 0;
		tareas.forEach(element => {
			if(element.precio){
				totalManoObraNew += parseFloat(element.precio)
			}
		});
		setTotalManoObra(totalManoObraNew);

		let totalNew = 0;
		totalNew = totalRepuestosNew + totalManoObraNew + parseFloat(impuestosActual) + parseFloat(otrosActual);

		setTotal(totalNew)
  };

	const deleteServicioItem = (indexArray) => {
		let items = [];
		servicioItems.forEach((element, index) => {
			if (index!==indexArray) {
				items.push(element);
			}
		});
		calculateTotal(items, presupuestoTareas, impuestos, otros);
		setServicioItems(items);
  };

	const addPresupuestoRepuesto = (newServicioItem) => {
		let copy = JSON.parse(JSON.stringify(servicioItems));
		copy.push(newServicioItem)
		calculateTotal(copy, presupuestoTareas, impuestos, otros);
		setServicioItems(copy);
  };

	const addPresupuestoTarea = (newPresupuestoTarea) => {
		let copy = JSON.parse(JSON.stringify(presupuestoTareas));
		copy.push(newPresupuestoTarea)
		calculateTotal(servicioItems, copy, impuestos, otros);
		setPresupuestoTareas(copy);
  };

	const handleCancel = (newServicioItem) => {
		props.callbackClose();
		init();
  };

	const deletePresupuestoTareas = (indexArray) => {
		let tareas = [];
		presupuestoTareas.forEach((element, index) => {
			if (index!==indexArray) {
				tareas.push(element);
			}
		});
		calculateTotal(servicioItems, tareas, impuestos, otros);
		setPresupuestoTareas(tareas);
  };

	const handleFormChangeImpuestos = (value) =>  {
		calculateTotal(servicioItems, presupuestoTareas, value ? value : 0, otros);
		setImpuestos(value ? value : 0)
	}

	const handleFormChangeOtros = (value) =>  {
		calculateTotal(servicioItems, presupuestoTareas, impuestos, value ? value : 0);
		setOtros(value ? value : 0)
	}

	const handleSubmit = () =>  {
		setLoading(true);
		let formDataCopy = JSON.parse(JSON.stringify(formData));
		formDataCopy.id = props.ticket.id
		formDataCopy.manoDeObra = totalManoObra
		formDataCopy.repuestos = totalRepuestos
		formDataCopy.impuestos = impuestos
		formDataCopy.otros = otros
		formDataCopy.adicional = false
		formDataCopy.presupuestoItems = servicioItems
		formDataCopy.presupuestoTareas = presupuestoTareas
		formDataCopy.adjunto = adjunto
		Utils.sendData(
			`/ticket-presupuestos/ticket/${props.ticket.id}`,
			'POST',
			formDataCopy,
		)
		.then(response => {
			if(response.status === 400) {
				response.json()
				.then(data => {
					setErrors(data.detalle)
				});
			}else {
				if (response.status === 202) {
					Dialog.alert(intl, {
						title: intl.formatMessage({ id: 'Aprobado', defaultMessage: 'Aprobado' }),
						text: intl.formatMessage({ id: 'Su_presupuesto_fue_aprobado._Puede_comenzar_a_trabajar', defaultMessage: 'Su presupuesto fue aprobado. Puede comenzar a trabajar' }),
						icon: 'success'
					});
				}
				setFormData({ ...FORM_DATA_INIT })
				init()
				setLoading(false)
				props.callbackSave();
				props.callbackClose();
			}
		})
		.catch((err) => {
			setLoading(false);
		});
	}

	const updateServicioItem = (data, index) => {
		servicioItems[index].cantidad = data.cantidad
		servicioItems[index].costo = data.costo
		calculateTotal(servicioItems, presupuestoTareas, impuestos, otros);
	}

	const updatePresupuestoTarea = (data, index) => {
		presupuestoTareas[index].precio = data.costo
		calculateTotal(servicioItems, presupuestoTareas, impuestos, otros);
	}
	
  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
			{servicioItems ?
				<div>
					<div className="alert alert-danger" role="alert" hidden={errors.length===0}>
						{errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
					</div>


					<div className="card pull-up">
						<div className="card-content">
							<div className="card-body">
								<h4 className="form-section">
									<i className="la la-file-text"></i><FormattedMessage id="Repuesto" defaultMessage="Repuesto"/>
								</h4>
								<div className="row">
									<div className="col-md-12">
										<TicketPresupuestoRepuesto 
											ticket={props.ticket} 
											servicioItems={servicioItems}
											deleteItem={deleteServicioItem}
											addItemServicio={addPresupuestoRepuesto}
											totalRepuestos={totalRepuestos}
											updateServicioItem={updateServicioItem}
										>
										</TicketPresupuestoRepuesto>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card pull-up">
						<div className="card-content">
							<div className="card-body">
								<h4 className="form-section">
									<i className="la la-file-text"></i><FormattedMessage id="Mano_de_Obra" defaultMessage="Mano de Obra"/>
								</h4>
								<div className="row">
									<div className="col-md-12">
										<TicketPresupuestoManoObra 
											ticket={props.ticket}
											presupuestoTareas={presupuestoTareas}
											deleteTarea={deletePresupuestoTareas}
											addTarea={addPresupuestoTarea}
											totalManoObra={totalManoObra}
											updatePresupuestoTarea={updatePresupuestoTarea}
										>
										</TicketPresupuestoManoObra>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card pull-up">
						<div className="card-content">
							<div className="card-body">
								<h4 className="form-section">
									<i className="la la-file-text"></i>
								</h4>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-12 label-control col-form-label text-left">
												<FormattedMessage id="Adjunto" defaultMessage="Adjunto"/>
												{ ConfigBusiness.get('tickets.adjuntoPresupuesto.obligatorio') === 'true' ? requiredSymbol : ''}:
											</label>
											<div className="col-md-3"></div>
											<Dropzone actions={props.actions} setAdjunto={setAdjunto}></Dropzone>
										</div>
									</div>
									<div className="col-md-6">
										<div className="col-md-12">
											<div className="row p-1" style={{justifyContent: "right"}}>
												<label className="col-md-6 label-control col-form-label" htmlFor="impuestos">
													<FormattedMessage id="Impuestos" defaultMessage="Impuestos"/> :
												</label>
												<div className="col-md-6">
													<div>
														<input
															type="number"
															className="form-control text-right pr-1"
															id="impuestos"
															name="impuestos"
															value={impuestos}
															onChange={(e) => handleFormChangeImpuestos(e.target.value)}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="row p-1" style={{justifyContent: "right"}}>
												<label className="col-md-6 label-control col-form-label" htmlFor="otros">
													<FormattedMessage id="Otros" defaultMessage="Otros"/> :
												</label>
												<div className="col-md-6">
													<div>
														<input
															type="number"
															className="form-control text-right pr-1"
															id="otros"
															name="otros"
															value={otros}
															onChange={(e) => handleFormChangeOtros(e.target.value)}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="row p-1" style={{justifyContent: "right"}}>
												<label className="col-md-6 label-control col-form-label" htmlFor="total">
													<b><FormattedMessage id="Total" defaultMessage="Total"/></b> :
												</label>
												<div className="col-md-6">
													<div>
														<input disabled 
															type="number"
															className="form-control text-right pr-1"
															id="total"
															name="total"
															value={total}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card pull-up">
						<div className="card-content">
							<div className="card-body">
								<div className="text-cd text-right">
									<button 
										type="submit"
										className="btn btn-primary mr-1" 
										onClick={handleSubmit}
										disabled={
											(ConfigBusiness.get('tickets.adjuntoPresupuesto.obligatorio') === 'true' ? (adjunto ? false: true) : false) ||
											((servicioItems.length > 0 ? false : true) && (presupuestoTareas.length > 0 ? false : true))
										}
									>
										<i className="fa fa-check-circle"></i> <FormattedMessage id="Guardar" defaultMessage="Guardar"/>
									</button>
									<button type="button" className="btn btn-danger" onClick={handleCancel}>
										<i className="fa fa-times-circle"></i> <FormattedMessage id="Cancelar" defaultMessage="Cancelar"/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			: ''}
    </React.Fragment>
  );
};

export default injectIntl(TicketPresupuesto);
