import React, { Component } from 'react'
// import { Route, Switch } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Iframe from 'react-iframe'
import UboroAvlIframe from './UboroAvlIframe.js'
import Security from '../../commons/security/Security.js'
import { Redirect } from 'react-router-dom'

class Avl extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            redirectTo: null,
            avlService: ConfigBusiness.get('avl_service.service'),
        }
    }

    componentWillMount() {
        if (!Security.hasPermission('AVL_ACCEDER')) {
            this.setState({
                redirectTo: '/error'
            });
        }
    }

    renderAvl() {
        if (this.state.avlService == "traccar") {
            let persona = JSON.parse(localStorage.getItem('persona'));
            let hash = persona.usuario.id + ConfigBusiness.get('frontend.traccarHash');

            return (
                <Iframe url={ConfigBusiness.get('frontend.urlAvl') + hash}
                    width="100%"
                    height="800px"
                    id="myId"
                    className="embed-responsive-item"
                    display="initial"
                    position="relative"
                    allowFullScreen/>
            );
        } else if (this.state.avlService == "uboro") {
            return (
                <UboroAvlIframe />
            );
        }
        return (
            <h1>Error en la configuración del sistema</h1>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.state.redirectTo ? <Redirect push to={this.state.redirectTo} />:this.renderAvl() }
            </React.Fragment>
        )
    }
}

export default Avl;
