import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import AmCharts from "@amcharts/amcharts3-react";
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
//import { Sparklines, SparklinesBars } from 'react-sparklines';




class DashboardEconomico extends Component {

  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();
    this.state = {
      conf: [],
      taller: [],
      accordions: []
      

      
    };
  }
 
  
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
  }

  componentDidMount(nextProps, nextState) {
    if(Security.hasPermission('DASHBOARD_COMERCIAL_VISUALIZAR')) {
      this.ajaxHandler.subscribe(this);
      this.initData();
      //this.initForm();

    } else {
        this.setState({
          redirectTo: '/error'
        });
    }
  }

  initData() {
      
    let component = this;
    
    Promise.all([
      this.ajaxHandler.getJson('/CostoPorVehiculo', null),
      this.ajaxHandler.getJson('/TasaIngresoTaller', null),
      this.ajaxHandler.getJson('/Antiguedad', null),
      this.ajaxHandler.getJson('/Antiguedad/5a', null),
    ]).then((data) => {
      let conf = data[0];
      let taller = data[1];
      let antiguedad = data[2];
      let antiguedad5a = data[3];
      component.setState({
        conf: conf,
        taller: taller,
        antiguedad: antiguedad,
        antiguedad5a: antiguedad5a
      });
    }).catch(function(error) {
      // TODO: Manejar errores.
		}).finally(() => {
      this.setState({ loading: false });
      
    });
    //alert('promise out');
    
  }

  
  
  getData = (stateKey, jsonFile) => {
    fetch(jsonFile, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then(data => {
			this.setState({[stateKey]: data});
		})
		.catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
		});
  }

  render() {
    let conf = this.state.conf;
    let taller = this.state.taller;
    let antiguedad = this.state.antiguedad;
    let antiguedad5a = this.state.antiguedad5a;
    if (conf == "")  {
      return (
        <div className="App">
          <label>&emsp; &emsp; <b><FormattedMessage id="dashboardEconomico.loading_graphics.label.costo_promedio_vehiculo" defaultMessage="Costo Promedio por Vehiculo"/></b></label>
        </div>
      );
    } else {
      return (
        <div className="App">
        <div> <br></br></div>
          
          <div align="center"><label><b><FormattedMessage id="dashboardEconomico.graphics.label.costo_promedio_vehiculo_afectado" defaultMessage="Costo Promedio por Vehiculo Afectado"/></b></label></div>
          <AmCharts.React style={{ width: "95%", height: "500px" }} options={conf} />

          <div> <br></br><br></br></div>
          <div align="center"><label><b><FormattedMessage id="dashboardEconomico.graphics.label.tasa_ingreso_taller" defaultMessage="Tasa de Ingreso a Taller"/></b></label></div>
          <AmCharts.React style={{ width: "95%", height: "500px" }} options={taller} />

          <div> <br></br></div>
        
          <AmCharts.React style={{ width: "95%", height: "500px" }} options={antiguedad} />

          <div> <br></br></div>
          
          
          <AmCharts.React style={{ width: "95%", height: "500px" }} options={antiguedad5a} />
          
        </div>
      );
    }
  }
  
}

export default injectIntl(DashboardEconomico);

