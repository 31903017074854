import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import MotivoCambioTurnosGrid from './MotivoCambioTurnosGrid.jsx'
import MotivoCambioTurnosAbm from './MotivoCambioTurnosAbm'
import {FormattedMessage, injectIntl} from 'react-intl';

class MotivoCambioTurnos extends Component {
	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
	  					<h3 className="content-header-title"><FormattedMessage id="Administración_de_motivos_de_cambio_de_turno" defaultMessage="Administración de motivos de cambio de turno"/></h3>
	  				</div>
				</div>
				<div className="content-body">								
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={MotivoCambioTurnosGrid} />
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <MotivoCambioTurnosAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <MotivoCambioTurnosAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <MotivoCambioTurnosAbm {...props} action="VIEW" />} />
					</Switch>										
				</div>
			</div>
		);
  	}
}

export default MotivoCambioTurnos;