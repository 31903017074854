import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import '../palette-gradient.css';
import PopoverGeneral from '../../../commons/popover/popover'
import {FormattedMessage} from 'react-intl';
import backendStrings from '../../../lang/backendStrings.js';

am4core.useTheme(am4themes_animated);

class WidgetSparkline extends Component{

constructor(props) {
  super(props);

  this.ajaxHandler = new AjaxHandler();

 moment.locale('es');


this.state = {
  redirectTo: null,
  props: this.props,
  valores: null,
  primeraVez: true,
  unidad: ''
   };

  //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);

  this.chart = null;
  this.dateAxis = null;
  this.valueAxis = null;
  this.series = null;
  this.categoryAxis = null;
  this.valueAxisX = null;
}

componentWillReceiveProps(nextProps){

  if(nextProps.datos != this.state.valores){
    let color = '#'+nextProps.mainColorClass;
    if(this.state.primeraVez){
      this.initData(color, nextProps.id, nextProps.datos, nextProps.unidad);
      this.setState({primeraVez: false, unidad: nextProps.unidad});
    }
    else{
      if(nextProps.unidad != this.state.unidad){
        this.calcularUnidad(nextProps.unidad);
      }
      if(nextProps.datos != null){

        this.series.stroke = color;
        this.series.fillOpacity = 1;
        this.series.fill = color;
        this.chart.data=nextProps.datos;
        this.setState({valores: nextProps.datos});
      } else{
          this.setState({valores: null});
      }
    }
	}
}

initData(color, id, datos, unidad){

  // Color set
  //let colors = new am4core.ColorSet();

/*
  this.createLine("AAPL (Price)", [
    { "date": new Date(2018, 0, 1, 8, 0, 0), "value": 57 },
    { "date": new Date(2018, 0, 1, 9, 0, 0), "value": 27 },
    { "date": new Date(2018, 0, 1, 10, 0, 0), "value": 24 },
    { "date": new Date(2018, 0, 1, 11, 0, 0), "value": 59 },
    { "date": new Date(2018, 0, 1, 12, 0, 0), "value": 33 },
    { "date": new Date(2018, 0, 1, 13, 0, 0), "value": 46 },
    { "date": new Date(2018, 0, 1, 14, 0, 0), "value": 20 },
    { "date": new Date(2018, 0, 1, 15, 0, 0), "value": 42 },
    { "date": new Date(2018, 0, 1, 16, 0, 0), "value": 59, "opacity": 1}
   ], color);

   this.createLine("",this.props.datos,color);
}

// Functions that create various sparklines
createLine(title, data, color) {
*/
  this.chart = am4core.create("wschartdiv"+id, am4charts.XYChart);
  this.chart.width = am4core.percent(100);
  this.chart.height = 100;

  this.chart.data = datos;
/*
    chart.titles.template.fontSize = 10;
    chart.titles.template.textAlign = "left";
    chart.titles.template.isMeasured = false;
    chart.titles.create().text = title;


*/
 /*
  this.dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
  this.dateAxis.renderer.grid.template.disabled = true;
  this.dateAxis.renderer.labels.template.disabled = true;
  this.dateAxis.startLocation = 0.5;
  this.dateAxis.endLocation = 0.7;
  this.dateAxis.cursorTooltipEnabled = false;
  this.dateAxis.renderer.grid.template.location = 0;
  this.dateAxis.renderer.minGridDistance = 50;
 */
  this.chart.padding(0, 0, 0, 0);
  this.categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
  this.categoryAxis.dataFields.category = "date";
  this.categoryAxis.renderer.grid.template.location = 0;
  this.categoryAxis.renderer.minGridDistance = 50;
  this.categoryAxis.renderer.cellStartLocation = 0.1;
  this.categoryAxis.renderer.cellEndLocation = 0.9;
  this.categoryAxis.renderer.grid.template.disabled = true;
  this.categoryAxis.renderer.labels.template.disabled = true;
  this.categoryAxis.cursorTooltipEnabled = false;
  this.categoryAxis.startLocation = 0.5;
  this.categoryAxis.endLocation = 0.5;
  this.calcularUnidad(unidad);
  //this.categoryAxis.title.text = referenciaHor;
  this.categoryAxis.title.fill = '#aaa';

  this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
  //this.valueAxis.min = 0; quitando este parámetro grafica desde el mínimo del rango de valores
  this.valueAxis.renderer.grid.template.disabled = true;
  this.valueAxis.renderer.baseGrid.disabled = true;
  this.valueAxis.renderer.labels.template.disabled = true;
  this.valueAxis.cursorTooltipEnabled = false;

  this.chart.cursor = new am4charts.XYCursor();
  this.chart.cursor.lineY.disabled = true;
  this.chart.cursor.behavior = "none";

  this.series = this.chart.series.push(new am4charts.LineSeries());
  this.series.tooltipText = "{date}: [bold]{value}";
  this.series.dataFields.categoryX = "date";
  this.series.dataFields.valueY = "value";
  this.series.tensionX = 0.95;
  this.series.strokeWidth = 2;
  this.series.stroke = color;
  this.series.fillOpacity = 1;
  this.series.fill = color;

/*
  // render data points as bullets
  let bullet = series.bullets.push(new am4charts.CircleBullet());
  bullet.circle.opacity = 0;
  bullet.circle.fill = color;
  bullet.circle.propertyFields.opacity = "opacity";
  bullet.circle.radius = 3;
*/
  return this.chart;
}

  componentDidMount(){
    if(this.props.datos){
      let color = '#'+this.props.mainColorClass;
      this.initData(color, this.props.id, this.props.datos, this.props.unidad);
      this.setState({primeraVez: false, unidad: this.props.unidad});
    }

  }

  componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
  }

    calcularUnidad(unidad){
      if(unidad != null){

        switch (unidad) {
          case "week":
          this.categoryAxis.title.text = " por Semana";
            break;
          case "year":
          this.categoryAxis.title.text = " por Año";
            break;
          case "month":
            this.categoryAxis.title.text = " por Mes";
            break;
          case "day":
            this.categoryAxis.title.text = " por Día";
            break;
        }
        this.setState({unidad: unidad});
      }
    }

    handleFilter(link,filter){
      if (this.props.onClickWidge)
          this.props.onClickWidget(link,filter);
    }

render() {
  let props = this.props;

  return (

    <div className="card pull-up">

      <div className="card-content" style={{ cursor:"pointer" }} onClick={this.handleFilter.bind(this,props.link, props.filter)}>
        <div className="card-body" style={{paddingBottom: '0px'}}>
          <div className="media d-flex">
            <div className="media-body text-left">
            <h1 className={" font-weight-500"} style={{ fontSize:"2rem" }}>{props.value}</h1>
              <div className="dashboard-title">
                <h6 style={{fontSize: "1rem"}}><FormattedMessage {...(backendStrings[props.description] ? backendStrings[props.description] : backendStrings['errors.default'])}/></h6>
                <PopoverGeneral icon="icon-info" text={props.text} style={{color: '#'+props.mainColorClass}}></PopoverGeneral>
              </div>


            </div>
            <div>{
              props.iconClass != "icon-file-check" && props.iconClass != "icon-sin-reporte" && props.iconClass != "icon-247" &&
            props.iconClass != 'icon-kms-litros' && props.iconClass != 'icon-litros-consumidos' && props.iconClass != 'icon-gasto-total'
            && props.iconClass != 'icon-costo-litros' && props.iconClass != 'icon-inmovilizado'
            && props.iconClass != 'icon-gasto-total-infracciones' && props.iconClass != 'icon-cantidad-infracciones'
            && props.iconClass != 'icon-gasto-pendiente' && props.iconClass != 'icon-abonadas-sin-ld'
            && props.iconClass != 'icon-comodato' && props.iconClass != 'icon-fuera-de-servicio' ?
              <i className={props.iconClass + ' font-large-2 float-right'} style={{color: '#'+props.mainColorClass}}></i>
              :<img className={props.iconClass} src={"../../../images/"+props.entidad+"/"+props.iconClass+".svg"}/>
              }
            </div>
          </div>

        </div>
      </div>
      <div id={"wschartdiv"+this.props.id} style={{ width: "100%", height: "100px" }}></div>
    </div>

  );
}

} export default WidgetSparkline;
