import React, { useEffect, useState, useRef } from 'react';
import * as Utils from '../../commons/utils/Utils';
import backendStrings from '../../lang/backendStrings.js';
import Select from 'react-select';
import LlantaBasesSelect from './../llantas/LlantaBasesSelect';
import Security from '../../commons/security/Security.js';
import {FormattedMessage} from 'react-intl';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MovilesCambioTipologia = (props) => {
  const formRef = useRef('movil_cambio_tipologia_form_ref');
  const [bases, setBases] = useState(props.bases ? props.bases : []);
  const [movil, setMovil] = useState(props.movil ? props.movil : null);
  const [formData, setFormData] = useState({
    base: null,
    tipologia_posicion_id: null
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [basesLoading, setBasesLoading] = useState(false);
  const [basesSelectLoading, setBasesSelectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showModalCambioTipologia, setShowModalCambioTipologia] = useState(props.open);

  const useStylesCambioTipologia = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "15px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "0px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "15px",
    },
  }));

  const MySwal = withReactContent(Swal);

  const classesCambioTipologia = useStylesCambioTipologia();

  const handleFormChangeSelectObject = (event, name) => {
    let formDataCopy = { ...formData };
    !event && name === 'base' && (formDataCopy['base'] = null);
    formDataCopy[name] = event;
    setFormData(formDataCopy);
  };

  const handleModalCloseCambioLlanta = () => {
    let formDataCopy = { ...formData };
    formDataCopy['base'] = null;
    setFormData(formDataCopy);
    props.callbackUpdate();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    setLoading(true);
    formData.tipologia_posicion_id = props.tipologia_posicion.id;
    Utils.sendData('/moviles/'+ movil.id +'/desasignarllantas', 'PUT', formData)
      .then((response) => {
        props.update();
        MySwal.fire(
          props.intl.formatMessage({
            id: "movilesCambioTipologia.se_cambio_diagrama_posicion_y_desasigno_las_llantas.information_llanta_desasignada",
            defaultMessage: "Se cambio de diagrama y desasigno las llantas",
          }),
          "",
          "success"
        );

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setSubmitLoading(false);
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={handleModalCloseCambioLlanta}
      className={classesCambioTipologia.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classesCambioTipologia.paper}>
          <div
            style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '1rem', maxWidth: '350px' }}
          >
            <div className="alert alert-danger" role="alert" hidden={errors.length === 0}>
              {errors.map((e, i) => (
                <li key={i}>
                  {props.intl.formatMessage({
                    ...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default']),
                  })}
                </li>
              ))}
            </div>
            <form className="form form-horizontal" ref={formRef}>
              <div className="text-center mt-2">
                <h3>
                  <FormattedMessage
                    id="movilesCambioTipologia.tipologia_modal.atencion_vehiculo"
                    defaultMessage="Atención: el vehículo"
                  />
                  {" "}<b>{movil.dominio}</b>{" "}
                  <FormattedMessage 
                    id="movilesCambioTipologia.tipologia_modal.cuenta_con_llantas_asignadas"
                    defaultMessage="cuenta con llantas asignadas, para proceder se deben desasignar todas las llantas del mismo."
                  />
                </h3>
              </div>
              
              <div className="form-group row mt-2">
                <label htmlFor="milimetrosRecapado" className="text-center col-sm-4 col-form-label">
                  <FormattedMessage id="movilesCambioTipologia.render.general_data.seleccione_donde_ubicarlas" defaultMessage="Por favor seleccione donde ubicarlas"/>*:
                </label>
                <div className="col-sm-8">
                  <LlantaBasesSelect
                    intl={props.intl}
                    changeBase={(e) => handleFormChangeSelectObject(e, 'base')}
                    elementDefault={movil.base}
                  />
                </div>
              </div>
              <div className="text-center mt-1 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary mr-1"
                  onClick={(e) => handleSubmit(e)}
                  disabled={
                    loading ||
                    submitLoading ||
                    (formData.base ? false : true)
                  }
                >
                  {submitLoading ? (
                    <>
                      <i className="la la-spinner"></i>
                      {props.intl.formatMessage({
                        id: 'movilesCambioTipologia.render.button_guardando',
                        defaultMessage: ' Guardando',
                      })}
                    </>
                  ) : (
                    <>
                      <i className="fa fa-check-circle"></i>
                      {props.intl.formatMessage({
                        id: 'movilesCambioTipologia.render.button_guardar',
                        defaultMessage: ' Guardar',
                      })}
                    </>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-danger mr-1"
                  onClick={() => handleModalCloseCambioLlanta()}
                >
                  <i className="fa fa-times-circle"></i>
                  {props.intl.formatMessage({
                    id: 'movilesCambioTipologia.render.button_cancelar',
                    defaultMessage: ' Cancelar',
                  })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default MovilesCambioTipologia
