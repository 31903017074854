import moment from 'moment'
import 'moment/min/locales'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

class Validator {
	static notEmpty(value) {
		let valid = value === null ? false : /\S/.test(value);
		return {
			valid: valid,
			message: !valid ? 'Dato requerido' : ''
		};
	}

	static intNumber(value) {
		let valid = false;
		let message = "Dato inválido";

		if(this.notEmpty(value).valid){
			valid = /^\d+$/.test(value);
			if(valid){
				valid = true;
				message = "";
			} else {
				valid = false;
				message = "Dato inválido";
			}
		} else {
			valid = false;
			message = "Dato requerido";
		}
		return {
			valid: valid,
			message: message
		};
	}

	static intNumberGreaterOrEqualThan(value, operator, operatorName) {
		let valid = false;
		let message = "Dato inválido";

		if(this.notEmpty(value).valid){
			valid = /^\d+$/.test(value);
			if(valid){
				if(value >= operator) {
					valid = true;
					message = "";
				} else {
					valid = false;
					message = 'El valor debe ser mayot o igual que ' + operatorName;
					message = "";
				}

			} else {
				valid = false;
				message = "Dato inválido";
			}
		} else {
			valid = false;
			message = "Dato requerido";
		}
		return {
			valid: valid,
			message: message
		};
	}

	static floatNunmber(value) {
		let valid = !value ? false : /^[+-]?\d+(\.\d+)?$/.test(value);
		return {
			valid: valid,
			message: !valid ? 'Dato requerido' : ''
		};
	}

	static conditionalFloatNunmber(value, condition) {
		let valid = condition ? (!value ? false : /^[+-]?\d+(\.\d+)?$/.test(value)) : true;
		return {
			valid: valid,
			message: !valid ? 'Dato requerido' : ''
		};
	}

	//solo acepta números reales separados con , la parte entera de la decimal y separado de a miles por .
	//Ej: 3.456.567,23 
	static floatNumberWithSeparator(value){
		let valid = !value ? true : /^\d{1,3}(\.\d{3})*(\,\d{1,3})?$/.test(value);

		return {
			valid: valid,
			message: !valid ? 'Formato correcto: 1.234.453,22' : ''
		}
	}

	static conditionalFloatNumber(condition, value) {
		let valid = condition ? this.floatNunmber(value).valid : true;
		return {
			valid: valid,
			message: "Dato requerido"
		}
	}

	static conditionalNotEmpty(condition, value) {
		let valid = condition ? this.notEmpty(value).valid : true;
		return {
			valid: valid,
			message: "Dato requerido"
		}
	}

	static email(value) {
		const regexMail  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let valid = regexMail.test(value);
		return {
			valid: valid,
			message: "Formato inválido"
		}
	}

	static conditionalEmail(condition, value) {
		let valid = false;
		let message = "Email inválido";
		if(condition) {
			if(this.notEmpty(value).valid){
				valid = this.email(value).valid;
			} else {
				valid = false;
				message = "Campo requerido";
			}
		} else {
			if(value){
				valid = this.email(value).valid;
			} else {
				valid = true;
			}
		}
		return {
			valid: valid,
			message: message
		}
	}

	static date(value) {
		var dateFormat = 'DD/MM/YYYY';
		let valid = false;
		moment.locale('es');
		let formatedDate = moment(value).format(dateFormat);
		let date = moment(formatedDate,dateFormat,true);

		if(this.notEmpty(value).valid) {
			valid = value === '0000-00-00'? true : date.isValid();
		} else {
			valid = true;
		}

		return {
			valid: valid,
			message: "Formato inválido"
		};
	}

	static dateBeforeToday(value) {
		if(this.date(value).valid){
			let valid = false;
			let message = "La fecha debe ser anterior a hoy";
			var dateFormat = 'DD/MM/YYYY';
			moment.locale('es');
			var date = moment(value, dateFormat);
			var diffNow = date.diff(moment(),"days")

			diffNow > 0 ? valid = false :	valid = true;
			return {
				valid: valid,
				message: message
			};
		} else {
			return this.date(value);
		}
	}

	static patente(value) {
		if (ConfigBusiness.get('moviles.patente.validaPatentes') == 'true') {
			const regexPatente = new RegExp(ConfigBusiness.get('moviles.patente.patentesValidas'));  
			let valid = regexPatente.test(value);
			return {
				valid: valid,
				message: "Formato inválido"
			}
		} else {
			return { valid: true }
		}
	}

	static numeroDocumento(value) {
		const regexDocumentoNumero = new RegExp(ConfigBusiness.get('personas.nroDocFormatosValidos'));  
		let valid = regexDocumentoNumero.test(value);
		return {
			valid: valid,
			message: "Formato inválido"
		}
	}

	static fechaVencimiento(fechaAlta, value){
		if(this.date(value).valid) {
			let valid = false;
			let message = "Debe ser mayor a hoy y a la fecha de expedición";
			if(moment(value).isAfter(moment(fechaAlta)) && moment(value).isAfter(moment())){
				valid = true;
				message = '';
			}
			return {
				valid: valid,
				message: message
			};
		} else {
			return this.date(value);
		}
	}

}

export default Validator;