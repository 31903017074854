import React, { useState, useRef, useEffect } from "react";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  TablePagination,
  Collapse,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Select from 'react-select'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StyledTableCell } from "../../commons/utils/TableStyles";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import 'moment/min/locales'


const sendData = (service, data) => {
  let serviceURL = Config.get("apiUrlBase") + service;
  return fetch(serviceURL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization-Token": localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  });
};

const ajaxHandler = new AjaxHandler();

const CambioCentroCostoMovil = (props) => {
  const formRef = useRef();
  useEffect(() => {}, []);

  const [centroCosto, setCentroCosto] = useState(props.id);
  const [fecha, setFecha] = useState(moment());
  const [razon, setRazon] = useState('');
  const [comentario, setComentario] = useState('');
  const [centrosCosto, setCentrosCosto] = useState(props.CECOs);
  const dominio = useState(props.dominio);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      dominio : dominio,
      centroCosto : centroCosto,
      fecha : fecha.format(),
      razon : razon,
      comentario : comentario
    }
    sendData("/moviles/actualizar/centrocosto", data)
    .then((response) => response.json())
    .then(response => {
        props.success("Centro de Costo actualizado con Exito!")
    })
    .catch((error) => {
      console.log("Error:",error)
    });
  };

  return (<>
    <form className="form form-horizontal" ref={formRef} onSubmit={handleSubmit} id="form-centro-costo">
      <div className="col-md-12">
        <form noValidate autoComplete="off">
          <label className="col-md-5 label-control col-form-label text-left" htmlFor="fecha">
            Fecha de Cambio
          </label>
          <DatePicker
            id="fecha"
            name="fecha"
            selected={fecha}
            className="form-control date-picker-placeholder"  
            placeholderText ="DD/MM/AAAA"   
            maxDate={moment()}
            minDate={moment().subtract(ConfigBusiness.get('moviles.centroCostos.diasHistoricoMaximo'), 'days')}
            onChange={(e)=>setFecha(e)}
          />
        </form>
      </div>
      <div className="col-md-12">
        <TextField
          id="razon"
          style={{ width: '100%' }}
          label="Razón del cambio"
          name="razon"
          value={razon}
          onChange={(e) => setRazon(e.target.value)}
        />
      </div>
      <div className="col-md-12">
        <TextField
          id="comentario"
          style={{ width: '100%' }}
          label="Comentario"
          name="comentario"
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
        />
      </div>
      <div className="col-md-12 mt-2 mb-2 text-center">
        <span class="text-danger">Se actualizará automáticamente el Centro de Costo.</span>
      </div>
      <div className="col-md-12">
        <div className="d-flex justify-content-center mt-2">
          <button type="submit" className="btn btn-primary mr-1">
            <i className="fa fa-check-circle mr-1"></i>
            Actualizar
          </button>
          <button type="button" className="btn btn-danger" onClick={() => props.dismiss()}>
            <i className="fa fa-times-circle mr-1"></i>
            Cancelar
          </button>
        </div>
      </div>
    </form>
  </>
  );
};
export default CambioCentroCostoMovil;
