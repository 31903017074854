import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import '../palette-gradient.css';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
am4core.useTheme(am4themes_animated);


class ColumnsLine extends Component{   
  
  constructor(props) {
    super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');
    const {intl } = props

    this.state = {
      redirectTo: null,
    	props: this.props,
    	noHayDatos: false,	
    	valores: null	
    }
    //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);
    this.chart = null;
    this.categoryAxis = null;
    this.valueAxis = null;
    this.series = null;
    this.seriesLine = null;
  }

  componentWillReceiveProps(nextProps){
  	if(nextProps.datos != this.state.valores){
  		if(nextProps.datos != null){
        for (let i = 0; i < nextProps.datos.length; i++) {
          nextProps.datos[i].date = this.replaceStringMonthAndWeek(nextProps.datos[i].date);
        }
  			let referenciaHor;
  			switch (nextProps.unidad) {
  				case "week":
  					referenciaHor = " " + this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.interval_por_semana", defaultMessage: "por Semana"});
  					break;
  				case "year":
  					referenciaHor = " " + this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.interval_por_anio", defaultMessage: "por Año"});
  					break;
  				case "month":
  					referenciaHor = " " + this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.interval_por_mes", defaultMessage: "por Mes"});
  					break;
  				default:
  					referenciaHor = " " + this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.interval_por_dia", defaultMessage: "por Día"});
  					break;
  			}
        // Color set
        let colors = new am4core.ColorSet();

      	if(this.chart){
          this.chart.dispose();
        }

      	this.chart = am4core.create("clchartdiv", am4charts.XYChart);
      	this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        let color = colors.getIndex(8);


        // Create axes
        this.categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        this.categoryAxis.dataFields.category = "date";
        this.categoryAxis.title.text = this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.interval_title_servicios", defaultMessage: "Servicios"}) + referenciaHor;
        this.categoryAxis.renderer.grid.template.location = 0;
        this.categoryAxis.renderer.minGridDistance = 20;
        this.categoryAxis.renderer.cellStartLocation = 0.1;
        this.categoryAxis.renderer.cellEndLocation = 0.9;

        this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        this.valueAxis.min = 0;
        this.valueAxis.title.text = this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.cantidad_de_servicios", defaultMessage: "Cantidad de Servicios"});

        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.behavior = "none";
        this.chart.data = nextProps.datos;
        //this.chart.colors.list = [am4core.color('#a66a99'), am4core.color('#cb5748')];

        let charts = this.chart;
        let ser = this.series;
        // Create series
        function createSeries(field, name, stacked) {
            //seteo los colores definidos para cada entidad
            let colorSet = {
              'preventivos':'#4dc4bc',
              'correctivos':'#67b7dc',
              'vencimientos':'#fe9657'
            };

            ser = charts.series.push(new am4charts.ColumnSeries());
            ser.dataFields.valueY = field;
            ser.dataFields.categoryX = "date";
            ser.name = name;
            ser.fill = colorSet[field];
            ser.stroke = colorSet[field];
            ser.tooltipText = "{name}: [bold]{valueY}[/]";
            // ser.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
            ser.columns.template.width = am4core.percent(95);
        }



        createSeries("correctivos", this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.correctivos_label", defaultMessage: "Correctivos"}), true);
        createSeries("preventivos", this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.preventivos_label", defaultMessage: "Preventivos"}), false);
        createSeries("vencimientos", this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.vencimientos_label", defaultMessage: "Vencimientos"}), false);

        this.seriesLine = this.chart.series.push(new am4charts.LineSeries());
        this.seriesLine.tooltipText = "{date}: [bold]{total}";
        this.seriesLine.dataFields.categoryX = "date";
        this.seriesLine.dataFields.valueY = "total";
        this.seriesLine.tensionX = 0.8;
        this.seriesLine.strokeWidth = 2;
        this.seriesLine.stroke = color;
        this.seriesLine.yAxis = this.valueAxis;
        this.seriesLine.name = this.props.intl.formatMessage({id: "dashboardAnalitico.columnLine.valor_promedio", defaultMessage: "Promedio"});
        // Add legend
        this.chart.legend = new am4charts.Legend();
  				this.setState({valores: nextProps.datos, noHayDatos: false});
    	} else{
    			this.setState({valores: null, noHayDatos: true});
    	}
  	} 
  }   

  replaceStringMonthAndWeek(message){
    if(message.includes('Enero'))
      message = message.replace('Enero', this.props.intl.formatMessage({id: "months.enero", defaultMessage: "Enero"}));
    if(message.includes('Febrero'))
      message = message.replace('Febrero', this.props.intl.formatMessage({id: "months.febrero", defaultMessage: "Febrero"}));
    if(message.includes('Marzo'))
      message = message.replace('Marzo', this.props.intl.formatMessage({id: "months.marzo", defaultMessage: "Marzo"}));
    if(message.includes('Abril'))
      message = message.replace('Abril', this.props.intl.formatMessage({id: "months.abril", defaultMessage: "Abril"}));
    if(message.includes('Mayo'))
      message = message.replace('Mayo', this.props.intl.formatMessage({id: "months.mayo", defaultMessage: "Mayo"}));
    if(message.includes('Junio'))
      message = message.replace('Junio', this.props.intl.formatMessage({id: "months.junio", defaultMessage: "Junio"}));
    if(message.includes('Julio'))
      message = message.replace('Julio', this.props.intl.formatMessage({id: "months.julio", defaultMessage: "Julio"}));
    if(message.includes('Agosto'))
      message = message.replace('Agosto', this.props.intl.formatMessage({id: "months.agosto", defaultMessage: "Agosto"}));
    if(message.includes('Septiembre'))
      message = message.replace('Septiembre', this.props.intl.formatMessage({id: "months.septiembre", defaultMessage: "Septiembre"}));
    if(message.includes('Octubre'))
      message = message.replace('Octubre', this.props.intl.formatMessage({id: "months.octubre", defaultMessage: "Octubre"}));
    if(message.includes('Noviembre'))
      message = message.replace('Noviembre', this.props.intl.formatMessage({id: "months.noviembre", defaultMessage: "Noviembre"}));
    if(message.includes('Diciembre'))
      message = message.replace('Diciembre', this.props.intl.formatMessage({id: "months.diciembre", defaultMessage: "Diciembre"}));
    
    if(message.includes('Semana'))
      message = message.replace('Semana', this.props.intl.formatMessage({id: "semana", defaultMessage: "Semana"}));
    return message;
  }

  componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

  render() {
    let props = this.state.props;
    return (
  		<React.Fragment>
            <div id="clchartdiv" style={{ width: "100%", height: "400px" }} hidden={this.state.noHayDatos === true}></div>
  					<div hidden={this.state.noHayDatos === false} className="text-center col-12" ><h4 style={{color:"blue"}}><FormattedMessage id="dashboardAnalitico.columnsLine.filter_date.information.no_hay_datos_fecha_especificada" defaultMessage="No hay datos en la fecha especificada"/></h4></div>
    </React.Fragment>);
  }

} export default injectIntl(ColumnsLine);