import React, { Component } from 'react'
import * as Constants from '../constants.js'

class InstanciaBasic extends Component {
	constructor(props) {
    super(props);
    this.props = props;
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChangeAndUpdateFather = this.handleChangeAndUpdateFather.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.componentDidUpdate = this.componentDidUpdate.bind(this);
		this.recalcularEstado = this.recalcularEstado.bind(this);
		this.castValue = this.castValue.bind(this);
		this.valueKey = this.props.valueKey ? this.props.valueKey : 'value'

  }

	recalcularEstado(value) {
		return Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION
	}

	castValue(value) {
		return value
	}

	componentDidMount() {
		this.handleChangeAndUpdateFather(this.castValue(this.props.atributo[this.valueKey]))
	}

	componentDidUpdate(prevProps) {
		if (prevProps.atributo[this.valueKey] !== this.props.atributo[this.valueKey]) {
			this.handleChangeAndUpdateFather(this.castValue(this.props.atributo[this.valueKey]))
		}
	}

	handleChangeAndUpdateFather(value) {
		this.props.handleChange("estado", this.recalcularEstado(value));
		// esto debe ir abajo del estado siempre sino genera loop infinito
		this.props.handleChange(this.valueKey, value);
	}

	handleInputChange(event) {
		// El input debe tener el atributo name para que funcione
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.handleChangeAndUpdateFather(value)
	}

	render() {
      return false
  }
}

export default InstanciaBasic;
