import React, { Component } from 'react'
import Config from '../../commons/config/Config.js'
import Iframe from 'react-iframe'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import swal from 'sweetalert'
import * as Utils from "../../commons/utils/Utils";

class UboroAvlIframe extends Component {
    constructor(props) {
        super(props);
        this.ajaxHandler = new AjaxHandler();
        this.props = props;

        this.state = {
            token: null,
            error: false,
        }
    }

    componentDidMount() {
        this.ajaxHandler.subscribe(this);
        Promise.all([
            Utils.getData('/usuarios/uboro-token'),
        ]).then((data) => {
            let token = data[0].token
            if (token == null) {
                swal({
                    title: "Error interno del sistema.",
                    text: "Contacte al administrador.",
                    icon: "error",
                    buttons: {
                        confirm: {
                            text: "Aceptar",
                            value: true,
                            visible: true,
                            className: "btn btn-primary",
                            closeModal: true
                        }
                    }
                })
            }

            this.setState({
                token: token,
                error: token == null,
            })
        })
    }

    componentWillUnmount() {
        this.ajaxHandler.unsubscribe();
    }

    render() {
        let token = this.state.token
        let error = this.state.error
        return (
            (error == true)?(
                <div style={{ width: "100%", minHeight: "500px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <div className="swal-icon swal-icon--error">
                        <div className="swal-icon--error__x-mark">
                            <span className="swal-icon--error__line swal-icon--error__line--left"></span>
                            <span className="swal-icon--error__line swal-icon--error__line--right"></span>
                        </div>
                    </div>
                    <h1>Intente nuevamente más tarde</h1>
                    <p>Si el problema persiste, contacto al administrador</p>
                </div>
            ):(
                (token !== null)?(
                    <Iframe url={ "https://avl.vecfleet.io/login?hideUserInfo=true&hideLogout=true&signin=true&token=" + token + "&lang=es" }
                        width="100%"
                        height="800px"
                        id="uboroAVLIframe"
                        position="relative"
                        allowFullScreen/>
                ):(
                    <div className="row">
                        <div className="col-3 col-centered">
                            <img src={process.env.PUBLIC_URL + "/images/loading.svg"}/>
                        </div>
                    </div>
                )
            )
        );
    }
}

export default UboroAvlIframe;

