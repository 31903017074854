import React, { Component } from "react";
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import axios from "axios";
import Config from "../../commons/config/Config.js";

class LlantaDetailsLogo extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    this.dataService = '/llantas';
    this.state = {
      props: this.props,
      formulario: null,
      recapadoObservation: '',
      adjunto_id: this.props.adjunto_id,
      nombre: this.props.nombre,
      logo: null,
      loadingLogo: true,
    };

  }
  
  componentWillMount() {
    this.ajaxHandler.subscribe(this);
    this.initLogo();
  }

  componentWillUnmount() {
    //this.ajaxHandler.unsubscribe();
    //$(".popover2").popover("dispose");
  }

  initLogo() {
    let component = this;
    if (this.state.adjunto_id) {
      let photoUrl = Config.get('apiUrlBase') + '/adjuntos/files/'+ this.state.adjunto_id;
      fetch(photoUrl, {
        method: 'GET',
        headers: {
          'Authorization-Token': localStorage.getItem("token")
        }
      }).then(response => {
        return response.blob();
      }).then(imgBlob => {
        const imageUrl = window.URL.createObjectURL(imgBlob);
        this.setState({logo: imageUrl, loadingLogo: false});
      }).catch((error) => {
        console.log(error);
      });
    }else{
      this.setState({loadingLogo: false});
    }
  }

  getData(service) {
    let serviceURL = Config.get('apiUrlBase') + service;
    return fetch(serviceURL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/()json',
        'Authorization-Token': localStorage.getItem("token")
      }
    })
    .then(response => {
      return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }
  
  render() {
    let state = this.state;

    return (
      <React.Fragment>
        {!this.state.loadingLogo ?
          this.state.logo ? 
            <img style={this.state.props.style} src={this.state.logo} alt=""/>
            :
              <div style={{textAlign: "-webkit-center"}}> 
                <div className="dt-user-avatar dt-user-avatar-more">
                  {this.state.nombre.substr(0, 2).toUpperCase()}
                </div>
              </div> 
          : ''
        }
      </React.Fragment>
    );
  }
}

export default LlantaDetailsLogo;
