import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import '../../assets/css/vec-dropzone.css'
import {FormattedMessage, injectIntl} from 'react-intl';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import backendStrings from '../../lang/backendStrings.js';
import swal from 'sweetalert'

class TicketTareasCierre extends Component {
	constructor(props) {
    super(props);
    this.props = props;
    this.ajaxHandler = new AjaxHandler();

    let mdo = this.props.ticket.manoDeObra ? this.props.ticket.manoDeObra : '';
    let impuestos =  this.props.ticket.impuestos ?  this.props.ticket.impuestos : '';
    let otros = this.props.ticket.otros ? this.props.ticket.otros : '';
    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        tareas: [],
        manoDeObra: mdo,
        impuestos: impuestos,
        otros: otros
      },
			maxLimitMonto: false,
			maxLimitObra: false,
			montosRepuesto: false,
			montosManoDeObra: false,
      listaSelectServicio: [],
      listaSelectTareas: [],
      servicioSeleccionado: null,
      tareasSeleccionadas: [],
      errors: [],
      loading: false,
      tareasDisabled: true,
      tareasIsLoading: false,
      totalRepuestos :0,
      totalManoDeObra: 0,
      isFormComplete: false,
    };

    this.handleServicioChange = this.handleServicioChange.bind(this);
    this.handleTareasChange = this.handleTareasChange.bind(this);
    this.handleDeleteTarea = this.handleDeleteTarea.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckControlTotal = this.handleCheckControlTotal.bind(this);
    this.handleChangeManoDeObra = this.handleChangeManoDeObra.bind(this);
    this.handleModalCheck = this.handleModalCheck.bind(this);
  }

  componentDidMount() {
    if(Security.hasPermission('TICKETS_VER_TAREAS')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;

    Promise.all([
      this.ajaxHandler.getJson('/servicios/tipo-ticket/' + this.props.ticket.ticketTipo + '/select'),
      this.ajaxHandler.getJson('/tickets/tareas/' + this.props.ticket.id ),
			ConfigBusiness.get('tickets.tareasCierre.montosRepuesto'),
    ]).then((data) => {
      let servicios = data[0];
      let tareas = data[1];
			let montosRepuesto = data[2] == 'true';
			let montosManoDeObra = data[3] == 'true';
      let formDataCopy = this.state.formData
      formDataCopy.tareas = tareas
      this.setState({
				formData: formDataCopy,
				servicios: servicios,
				montosRepuesto: montosRepuesto,
				montosManoDeObra: montosManoDeObra,
			});
    }).catch(function(error) {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      component.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
    this.setState({
      redirectTo: null,
      props: this.props,
      formData: {
        id: 0,
        tareas: []
      },
			maxLimitMonto: false,
      maxLimitObra: false,
      maxLimitImpuestos: false,
      maxLimitOtros: false,
			montosRepuesto: false,
			montosManoDeObra: false,
      listaSelectServicio: [],
      listaSelectTareas: [],
      servicioSeleccionado: null,
      tareasSeleccionadas: [],
      errors: [],
      loading: false,
      tareasDisabled: true,
      tareasIsLoading: false
    }, ()=>{
      this.formValidation.validate();
      this.props.callbackSave();
    });
  }

  handleServicioChange(object) {
    if(object) {
      this.setState({
        servicioSeleccionado: object,
        tareasIsLoading: true
      }, () => {
        this.ajaxHandler.getJson('/tareas/servicio/' + object.value + '/select')
        .then((data) => {
          let listaSelectTareas = data;
          this.setState({
            listaSelectTareas: listaSelectTareas,
            tareasDisabled: false,
            tareasIsLoading: false
          }, () => {
            /*let tareas = [];

            this.state.listaSelectTareas.map((listaSelectTarea)=>{
              tareas.push({
                id: listaSelectTarea.value,
                nombre: listaSelectTarea.label,
                servicio: {
                  id: this.state.servicioSeleccionado.value,
                  nombre: this.state.servicioSeleccionado.label
                }
              });
              return listaSelectTarea;
            });

            this.loadTareas(tareas);*/
          });
        }).catch(function(error) {
          AjaxHandler.handleError(error);
        });
      });
    } else {
      this.setState({
        servicioSeleccionado: null,
        tareasSeleccionadas: [],
        tareasDisabled: true
      });
    }
  }

  handleTareasChange(value) {
    this.setState({
      tareasSeleccionadas: value
    });
	}

  loadTareas(tareas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    tareas.map((tarea)=>{
      if(formDataCopy.tareas.findIndex(x => x.id === tarea.id && x.servicio.id === tarea.servicio.id) === -1) {
        formDataCopy.tareas.push(tarea);
      }
      return tareas;
    });

		this.setState({
      formData: formDataCopy,
      servicioSeleccionado: null,
      tareasSeleccionadas: [],
      tareasDisabled: true
    });
    this.handleCheckControlTotal(formDataCopy.tareas)
  }

  handleAddTarea() {
    let tareas = [];

    this.state.tareasSeleccionadas.map((tareaSeleccionada)=>{
      tareas.push({
        id: tareaSeleccionada.value,
        nombre: tareaSeleccionada.label,
        servicio: {
          id: this.state.servicioSeleccionado.value,
          nombre: this.state.servicioSeleccionado.label
        }
      });
     return tareaSeleccionada;
    });
    this.loadTareas(tareas);
  }

  handleCheckControlTotal(tareas){
    let total = 0;
    tareas.forEach(element => {
      total += parseFloat(element.monto ? element.monto : 0)
    });

    let repuestos = this.state.props.ticket.repuestos;

    if(total > (repuestos)) {
      this.setState({
        maxLimitMonto: true
      });
    } else {
      this.setState({
        maxLimitMonto: false
      });
    }

    this.setState({totalRepuestos: total});
  }

  handleCheckControlTotalManoDeObra(tareas){
    let totalManoDeObra = 0;
    tareas.forEach(element => {
      totalManoDeObra += parseFloat(element.mano_de_obra ? element.mano_de_obra : 0)
    });
    let mano_de_obra = this.state.props.ticket.manoDeObra;

    if(totalManoDeObra > (mano_de_obra)) {
      this.setState({
        maxLimitObra: true
      });
    } else {
      this.setState({
        maxLimitObra: false
      });
    }

    this.setState({totalManoDeObra: totalManoDeObra})
  }

  handleDeleteTarea(index) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.tareas.splice(index, 1);
		this.setState({
			formData: formDataCopy
    });
		this.handleCheckControlTotal(formDataCopy.tareas)
  }

  handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  isFormCompleteCheck(){
    let tareas = this.state.formData.tareas
    const todosConValores = tareas.every(tarea => tarea.monto && tarea.mano_de_obra)
    this.setState({isFormComplete: todosConValores})
  }

  handleModalCheck(event) {
    let component = this

    // let tareas = this.state.formData.tareas
    let totalIngresado = this.state.totalManoDeObra + this.state.totalRepuestos
    let totalPresupuestado = this.state.props.ticket.manoDeObra + this.state.props.ticket.repuestos

    // let invalidos = ''
    // tareas.map((tarea) => {

    //   if(tarea.monto == 0 && tarea.mano_de_obra == 0) {
    //     invalidos += tarea.nombre+", "
    //   }

    // });

    // INVALIDOS -> Tareas con ambos valores en 0.
    // if(invalidos !== ''){
    //   swal({
    //     title: this.props.intl.formatMessage({
    //       id: 'ticketTareasCierre.render.swal.invalidos',
    //       defaultMessage: 'Tarea con valor de mano de obra y repuestos en cero. Por favor elimine para continuar'
    //     }),
    //     text: invalidos.slice(0, -2),
    //     icon: "warning",
    //   })

    // } else
    if (totalIngresado < totalPresupuestado && this.state.montosRepuesto) {
      swal({
        title: this.props.intl.formatMessage({
          id: 'ticketTareasCierre.render.swal.valoresMenores',
          defaultMessage: 'La suma de los valores ingresados es menores a lo presupuestado'
        }),
        text: this.props.intl.formatMessage({
          id: 'ticketTareasCierre.render.swal.deseaContinuar',
          defaultMessage: '¿Desea continuar?'
        }),
        icon: "warning",
        buttons: [
          this.props.intl.formatMessage({
            id: 'ticketTareasCierre.render.swal.cancel',
            defaultMessage: 'Cancelar'}),
            this.props.intl.formatMessage({
              id: 'ticketTareasCierre.render.swal.continue',
              defaultMessage: 'Continuar'}),
        ],
        dangerMode: true,
      }).then(function(isConfirm) {
        if (isConfirm) {
          component.handleSubmit(event);
        }
      });

    } else {
      component.handleSubmit(event);
    }

    event.preventDefault();
  }

	handleSubmit(event) {
    if(this.state.formData.tareas && this.state.formData.tareas.length > 0 ){
      let servicio = this.state.formData.tareas[0].servicio;
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.servicio = servicio;
      this.setState({
        formData: formDataCopy,
        loading: true
      },() =>{
        this.props.callbackSave(this.state.formData);
      });
    } else {
      let errors = this.state.errors;
      errors.push('ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea');
      this.setState({
        errors: errors
      });
    }
		event.preventDefault();

  }

  handleChange(evt, index){
    let formDataCopy = this.state.formData
    formDataCopy.tareas[index].monto = evt.target.value
    this.setState({ formData: formDataCopy });
    this.handleCheckControlTotal(this.state.formData.tareas)
    this.isFormCompleteCheck()
  }

  handleChangeManoDeObra(evt, index){
    let formDataCopy = this.state.formData
    formDataCopy.tareas[index].mano_de_obra = evt.target.value
    this.setState({ formData: formDataCopy });
    this.handleCheckControlTotalManoDeObra(this.state.formData.tareas)
    this.isFormCompleteCheck()
  }

  handleChangeImpuestos(evt){
    let formDataCopy = this.state.formData
    formDataCopy.impuestos = evt.target.value
		let checkImpuestos = this.props.ticket.impuestos < formDataCopy.impuestos
    this.setState({
			formData: formDataCopy,
			maxLimitImpuestos: checkImpuestos
		});
  }

  handleChangeOtros(evt){
    let formDataCopy = this.state.formData
    formDataCopy.otros = evt.target.value
		let checkOtros = this.props.ticket.otros < formDataCopy.otros
    this.setState({
			formData: formDataCopy,
			maxLimitOtros: checkOtros
		});
  }



  handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			redirectTo: null,
      props: this.props,
      formData: {
        id: 0,
        tareas: []
      },
      listaSelectServicio: [],
      listaSelectTareas: [],
      servicioSeleccionado: null,
      tareasSeleccionadas: [],
      errors: [],
      loading: false,
      tareasDisabled: true,
      tareasIsLoading: false
		 }, ()=>{
			this.props.callbackClose();
		 });
	}

	render() {
    let formData = this.state.formData;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleModalCheck}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="la la-cog"></i><FormattedMessage id="ticketTareasCierre.render.tareas.header_tareas" defaultMessage=" Tareas"/>
                      </h4>
                      <div className="row">
                        {/* SERVICIO */}
                        <div className="col-md-5">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="servicio">
                              <FormattedMessage id="ticketTareasCierre.render.tareas.label_servicio" defaultMessage="Servicio:"/>
                            </label>
                            <div className="col-md-8">
                              <div>
                                <Select
                                  id="servicio"
                                  name="servicio"
                                  placeholder={this.props.intl.formatMessage({ id: 'ticketTareasCierre.render.tareas.placeholder_servicio', defaultMessage: 'Servicio' })}
                                  options={this.state.servicios}
                                  valueKey='value'
                                  labelKey='label'
                                  value={this.state.servicioSeleccionado}
                                  onChange={(e) => this.handleServicioChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* TAREAS */}
                        <div className="col-md-5">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="tareasServicio">
                              <FormattedMessage id="ticketTareasCierre.render.tareas.label_tareas" defaultMessage="Tareas:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              null
                              ) : (
                              <div>
                                <Select
                                  id="tareasServicio"
                                  name="tareasServicio"
                                  placeholder={this.props.intl.formatMessage({ id: 'ticketTareasCierre.render.tareas.placeholder_tareas', defaultMessage: 'Tareas' })}
                                  options={this.state.listaSelectTareas}
                                  valueKey='value'
                                  labelKey='label'
                                  closeOnSelect={true}
                                  multi
                                  removeSelected={true}
                                  joinValues
                                  value={this.state.tareasSeleccionadas}
                                  disabled={this.state.tareasDisabled}
                                  isLoading={this.state.tareasIsLoading}
                                  onChange={this.handleTareasChange}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-icon-add text-center text-fleet"
                            onClick={(e) => { this.handleAddTarea(); e.preventDefault();}}
                            style={{backgroundColor: 'transparent'}}
                          >
                            <i className="ft-plus-circle"></i>
                          </button>
                        </div>
                      </div>
											{/* { this.state.montosRepuesto &&
												<>
			                      <div className="row">
			                        <div className="col-md-5">
			                          <div className="form-group row">
			                            <label className="col-md-4 label-control col-form-label" htmlFor="manoDeObra">
			                              <FormattedMessage id="ticketTareasCierre.render.tareas.label_mano_obra" defaultMessage="Mano de Obra:"/>
			                            </label>
			                            <div className="col-md-8">
			                              {this.props.action === 'VIEW' ? ( null ) : (
			                                <div>
			                                    <input
			                                      type="number"
			                                      className="form-control"
			                                      value={this.state.formData.manoDeObra}
			                                      onChange={(e) => this.handleChangeManoDeObra(e)}
			                                    />
			                                </div>
			                              )}
			                            </div>
			                          </div>
			                        </div>
			                        <div className="col-md-7"></div>
			                      </div>
												</>
											} */}


                      { this.state.montosRepuesto &&
												<>
			                      <div className="row">
			                        <div className="col-md-5">
			                          <div className="form-group row">
			                            <label className="col-md-4 label-control col-form-label" htmlFor="impuestos">
			                              <FormattedMessage id="ticketTareasCierre.render.tareas.label_impuestos" defaultMessage="Impuestos:"/>
			                            </label>
			                            <div className="col-md-8">
			                              {this.props.action === 'VIEW' ? ( null ) : (
			                                <div>
			                                    <input
			                                      type="number"
																						className="form-control text-right pr-1"
																						step="0.01"
			                                      value={this.state.formData.impuestos}
			                                      onChange={(e) => this.handleChangeImpuestos(e)}
			                                    />
			                                </div>
			                              )}
			                            </div>
			                          </div>
                                { this.state.maxLimitImpuestos &&
                                  <div className="form-group row">
                                    <div className="col-md-4"></div>
                                    <label className="col-md-8 col-form-label" htmlFor="warningImpuestos" style={{color: "red"}}>
                                      <FormattedMessage id="ticketTareasCierre.render.tareas.label_warning_impuestos" defaultMessage="El monto de impuestos supera el valor presupuestado"/>
                                    </label>
                                  </div>
                                }
			                        </div>
			                        <div className="col-md-5">
                                  <div className="form-group row">
                                    <label className="col-md-3 label-control col-form-label" htmlFor="otros">
                                      <FormattedMessage id="ticketTareasCierre.render.tareas.label_otros" defaultMessage="Otros:"/>
                                    </label>
                                    <div className="col-md-9">
                                      {this.props.action === 'VIEW' ? ( null ) : (
                                        <div>
                                            <input
                                              type="number"
																							className="form-control text-right pr-1"
	                                            step="0.01"
                                              value={this.state.formData.otros}
                                              onChange={(e) => this.handleChangeOtros(e)}
                                            />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  { this.state.maxLimitOtros &&
                                  <div className="form-group row">
                                    <div className="col-md-3"></div>
                                    <label className="col-md-9 col-form-label" htmlFor="warningOtros" style={{color: "red"}}>
                                      <FormattedMessage id="ticketTareasCierre.render.tareas.label_warning_otros" defaultMessage="El monto de otros supera el valor presupuestado"/>
                                    </label>
                                  </div>
                                  }
                              </div>
			                      </div>
												</>
											}

                      { this.state.montosRepuesto &&
													<>
			                      { this.state.maxLimitMonto &&
			                        <div className="row">
			                          <div className="col-md-5">
			                              <div className="form-group row">
			                                <div className="col-md-4"></div>
			                                <label className="col-md-8 col-form-label" htmlFor="warningManoDeObra" style={{color: "red"}}>
			                                  <FormattedMessage id="ticketTareasCierre.render.tareas.label_warning_repuestos" defaultMessage="La sumatoria de los montos de los respuestos supera el valor presupuestado"/>
			                                </label>
			                              </div>
			                          </div>
			                          <div className="col-md-7">
			                          </div>
			                        </div>
			                      }

														{ this.state.maxLimitObra &&
															<div className="row">
																<div className="col-md-5">
																		<div className="form-group row">
																			<div className="col-md-4"></div>
																			<label className="col-md-8 col-form-label" htmlFor="warningManoDeObra" style={{color: "red"}}>
																				<FormattedMessage id="ticketTareasCierre.render.tareas.label_warning_mano_de_obra" defaultMessage="La sumatoria de los montos de mano de obra supera el valor presupuestado"/>
																			</label>
																		</div>
																</div>
																<div className="col-md-7">
																</div>
															</div>
														}
													</>
											}


                      <div className="row" style={{display: formData.tareas.length > 0 ? '' : 'none' }} >
                        {/* TAREAS SELECCIONADAS */}
                        <div className="col-md-12">
                          <table className="table">
                            <thead className="thead-fleet">
                              <tr>
                                <th scope="col"><FormattedMessage id="ticketTareasCierre.render.table_column_servicio" defaultMessage="Servicio"/></th>
                                <th scope="col"><FormattedMessage id="ticketTareasCierre.render.table_column_tareas" defaultMessage="Tareas"/></th>
															  { this.state.montosRepuesto &&
																	<>
		                                <th scope="col"><FormattedMessage id="ticketTareasCierre.render.table_column_repuestos" defaultMessage="Repuestos"/></th>
		                                <th scope="col"><FormattedMessage id="ticketTareasCierre.render.table_column_mano_de_obra" defaultMessage="Mano de obra"/></th>
																	</>
																}
                                <th scope="col"><FormattedMessage id="ticketTareasCierre.render.table_column_eliminar" defaultMessage="Eliminar"/></th>
                              </tr>
                            </thead>
                            <tbody>
                            {formData.tareas.map((tarea, index)=>{
                              return(
                                <tr key={index}>
                                  <td className="align-middle">{tarea.servicio.nombre}</td>
                                  <td className="align-middle">{tarea.nombre}</td>
																	{ this.state.montosRepuesto &&
																		<>
			                                  <td className="align-middle">
			                                    <input
			                                      type="number"
			                                      className="form-control text-right pr-1"
																						min="0"
                                            step="0.01"
			                                      value={tarea.monto}
			                                      onChange={(e) => this.handleChange(e,index)}
			                                    />
			                                  </td>
                                        <td className="align-middle">
			                                    <input
			                                      type="number"
			                                      className="form-control text-right pr-1"
																						min="0"
                                            step="0.01"
			                                      value={tarea.mano_de_obra}
			                                      onChange={(e) => this.handleChangeManoDeObra(e,index)}
			                                    />
			                                  </td>
																		</>
																	}
                                  <td>
                                    <span className="btn btn-icon text-danger" onClick={(e) => this.handleDeleteTarea(index)}>
                                      <i className="fa fa-trash fa-xs"></i>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={this.state.loading || (this.state.montosRepuesto && (this.state.maxLimitMonto || this.state.maxLimitObra || this.state.maxLimitImpuestos || this.state.maxLimitOtros || !this.state.isFormComplete))}>
                          <i className="fa fa-check-circle"></i><FormattedMessage id="ticketTareasCierre.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i><FormattedMessage id="ticketTareasCierre.finish_button.cancelar" defaultMessage=" Cancelar"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketTareasCierre);
