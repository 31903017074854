import React, { Component } from 'react'
import ExtraBasic from './ExtraBasic.js'
import { WithContext as ReactTags } from 'react-tag-input';
import * as Constants from '../constants.js'
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ExtraSelectSimple extends ExtraBasic {
	constructor(props) {
    super(props);

		this.handleOptionAddition = this.handleOptionAddition.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
		this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.state = {
      opciones: []
    }
  }

	componentDidMount() {
		if (this.props.extraParams && this.props.extraParams.opciones)
      this.handleChangeAndUpdateFather(this.props.extraParams)
    else
      this.handleChangeAndUpdateFather(this.state);
	}

	handleOptionDelete(i) {
		let opciones = this.state.opciones
		opciones = opciones.filter((tag, index) => index !== i)
		this.state.opciones = opciones
		this.handleChangeAndUpdateFather(this.state)
	}

	handleOptionAddition(nuevaOpcion) {
    nuevaOpcion.estado = Constants.ID_ESTADO_APROBACION_VALIDO
		let opciones = this.state.opciones
		this.state.opciones = [...opciones, nuevaOpcion]
		this.handleChangeAndUpdateFather(this.state)
	}



	render() {
      return (
        <React.Fragment>
				<div className="form-group row">
					{/* Opciones */}
					<label className="col-md-3 label-control col-form-label" htmlFor="default">
						Opciones:
					</label>
					<div className="col-md-9">
							<ReactTags
								inputFieldPosition="top"
								tags={this.state.opciones}
								classNames={{
									tagInputField:'form-control',
									tag: 'badge badge-dark mr-1 mb-1',
									selected: 'mt-1',
									remove: 'ml-1',
								}}
								suggestions={[]}
								placeholder="Agregue opciones"
								delimiters={delimiters}
								readOnly={this.props.disabled}
								handleDelete={event => this.handleOptionDelete(event)}
								handleAddition={this.handleOptionAddition}
								allowDragDrop={false}
							/>
					</div>
				</div>
        </React.Fragment>
      )
  }
}

export default ExtraSelectSimple;
