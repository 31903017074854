import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'
import {RadioGroup, Radio} from 'react-radio-group';
import * as Constants from '../constants.js'

class InstanciaSiNo extends InstanciaBasic {
	constructor(props) {
    super(props);
		this.recalcularEstado = this.recalcularEstado.bind(this);
  }

	recalcularEstado(value) {
		let estadoActual = Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION
		let opcionCorrespondiente = null
		if (this.props.atributo.extraParams.checks)
			opcionCorrespondiente = this.props.atributo.extraParams.checks.find(opcion => opcion.text == value)
		if (opcionCorrespondiente)
			estadoActual = opcionCorrespondiente.estado
		return estadoActual
	}

	render() {
      return (
        <React.Fragment>
			{this.props.ocultarInput === false ? ( 
				<RadioGroup
					className="form-control"
					selectedValue={this.props.atributo[this.valueKey]}
					onChange={this.handleChangeAndUpdateFather}
					>
						{this.props.atributo.extraParams.checks && this.props.atributo.extraParams.checks.map((option, index) =>
						<label key={index} className="p-1">
							<Radio disabled={this.props.disabled} key={option.text} value={option.text}/>
							<p>{option.text}</p>
						</label>
						)}
				</RadioGroup>
			) : (
				<input
				disabled={this.props.disabled}
				type="text"
				className="form-control"
				value={this.props.atributo[this.valueKey]}
				onChange={this.handleInputChange}
			/>
			)}
						
        </React.Fragment>
      )
  }
}

export default InstanciaSiNo;
