import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class BasesAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        descripcion: '',
        region: null,
        subregion: null,
        tipo: null,
        activa: true,
        esOperativa: null,
        telefono: '',

        observaciones: '',
        direccion: '',
        numero: '',
        ceco: '',
				direccionForm: {},
        atributosCedis: ConfigBusiness.get('bases.atributosCedis.habilitado') === 'false' ? null : {
          local: null,
          centro_emplazamiento: null,
          grupo_autorizacion: null,
          pto_trabajo_responsable: null,
          centro_planificacion: null,
          emplazamiento: null,
          grupo_planificacion: null,
          pto_planificacion_transporte: null,
          ubicaciones_tecnicas: null,
          campo_de_clasificacion: null,
        },

        administrador: '',
        localidad: null,
        'estadoAlmacen': null,

      },
      // cecos:[{ id: 0, nombre: '124567'},{ id: 1, nombre: '9863456'}], // CAMBIAR POR WS
      cecos:[],
      regiones:[],
      jefes:[],
      personasCombo:[],
      responsables:[],
      subregiones:[],
      paises: [],
      provincias: [],
      localidades: [],
      estados: [{ id: 0, nombre: 'Inactivo'},{ id: 1, nombre: 'Activo'}],
      tipos: [{ id: 1, nombre: 'Operativa'},{ id: 0, nombre: 'No Operativa'}], // CAMBIAR POR WS
			disableAvlFields: false,
			centroCostosEnabled: false,
      atributosCedisEnabled: false,
      llantasEnabled: false,
      categorias: [],
      gerenciadores: [],
      errors: [],

			loading: false,
      estadosDeAlmacen: [],
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleProvinciaChange = this.handleProvinciaChange.bind(this);

    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsuarioFormChange = this.handleUsuarioFormChange.bind(this);
    this.handlePerfilChange = this.handlePerfilChange.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.handleGerenciadorChange = this.handleGerenciadorChange.bind(this);
    this.handleLocalidadChange = this.handleLocalidadChange.bind(this);
    this.handleBasesChange = this.handleBasesChange.bind(this);
    this.handlePersonaFormChange = this.handlePersonaFormChange.bind(this);
		this.handleChangeDireccion = this.handleChangeDireccion.bind(this);
    this.handleAtributosCedisChange = this.handleAtributosCedisChange.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.descripcion': (value) => Validator.notEmpty(value),
        'formData.region': (value) => Validator.notEmpty(value),
        'formData.subregion': (value) => Validator.notEmpty(value),
        'formData.jefe': (value) => Validator.notEmpty(value),
        'formData.responsable': (value) => Validator.notEmpty(value),
        'formData.esOperativa': (value) => {value = this.state.formData.esOperativa;return Validator.notEmpty(value);},//se setea el valor esOperativa porque el value 0 lo toma como null
        'formData.direccionValid': (value) => Validator.notEmpty(value),
			}
    });

  }
  initForm() {
    this.setState({ loading: true });
    let component = this;

		Promise.all([
      this.ajaxHandler.getJson('/regiones', null),
      //this.ajaxHandler.getJson('/personas', null),
      this.ajaxHandler.getJson('/personas/select?filterNotInternal=true'),
      this.ajaxHandler.getJson('/gerenciadores/tipo/1/select',null),
      this.ajaxHandler.getJson('/paises/select',null),
      this.ajaxHandler.getJson('/centros-costos',null),
      //this.state.props.action !== 'ADD' ? this.getData('bases', this.state.props.match.params.id) : null,
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/bases/' + this.state.props.match.params.id) : null,
      this.ajaxHandler.getJson('/permisos')

		]).then((data) => {
      let regiones = data[0];
      let gerenciadores = data[2];
      let paises = data[3];
      let cecos = data[4]
      let formData = data[5];
      //let permisosDisponibles = data[6];
      let jefes = data[1];
      let responsables = data[1];

      /*
      let jefes = data[1].map(p => ({
        id: p.id,
        // apellido: p.apellido + ', ' + p.nombre
        nombre: p.label
      }));
      */
      /*
      let responsables = data[1].map(p => ({
        id: p.id,
        // apellido: p.apellido + ', ' + p.nombre
        nombre: p.label
      }));
      */
      let estadosAlmacen = ConfigBusiness.get('llantas.estadoAlmacen');
      estadosAlmacen = estadosAlmacen
        ? estadosAlmacen.split(",").map((e) => ({ id: e, nombre: e }))
        : [];
      let estadosEnTaller = ConfigBusiness.get('llantas.estadoEnTaller');
      estadosEnTaller = estadosEnTaller
        ? estadosEnTaller.split(",").map((e) => ({ id: e, nombre: e }))
        : [];
      let estadosEnRecapado = ConfigBusiness.get('llantas.estadoEnRecapado');
      estadosEnRecapado = estadosEnRecapado
        ? estadosEnRecapado.split(",").map((e) => ({ id: e, nombre: e }))
        : [];
      let estadosBaja = ConfigBusiness.get('llantas.estadoBaja');
      estadosBaja = estadosBaja
        ? estadosBaja.split(",").map((e) => ({ id: e, nombre: e }))
        : [];
      let estados = [...estadosAlmacen,...estadosEnTaller,...estadosEnRecapado,...estadosBaja];
			let direccionForm = {}
			
      let disableAvlFields = false
      if(ConfigBusiness.get('avl_service.service') === 'pointer') {
        disableAvlFields = ConfigBusiness.get('pointer.disableFields') === 'true' ? true: false
      }
      if(ConfigBusiness.get('avl_service.service') === 'geotab') {
        disableAvlFields = ConfigBusiness.get('geotab.disableFields') === 'true' ? true: false
      }

			if(formData){
				direccionForm = {
					calle: formData.direccion,
					numero: formData.numero,
					localidad: formData.localidad,
					provincia: formData.provincia,
					pais: formData.pais,
					latitud: formData.latitud,
					longitud: formData.longitud
				};
      }
      component.setState({
        regiones: regiones,
        jefes: jefes,
        responsables: responsables,
        gerenciadores: gerenciadores,
        paises: paises,
        cecos: cecos,
				disableAvlFields: disableAvlFields,
				centroCostosEnabled: ConfigBusiness.get('bases.centroCostos.habilitado') === 'true' ? true : false,
        atributosCedisEnabled: ConfigBusiness.get('bases.atributosCedis.habilitado') === 'true' ? true : false,
        llantasEnabled: ConfigBusiness.get('llantas.habilitado') === 'true' ? true : false,
				direccionForm: direccionForm,
        estadosDeAlmacen: estados
			}, () => {
				if(formData) component.setState({
					formData: formData
				});
			});
      if(this.state.props.action === 'EDIT') {

          if(formData['localidad']) this.handlePaisChange({ value: formData['localidad'].provincia.pais.id, label: formData['localidad'].provincia.pais.nombre })
          .then(() => {

            if(formData['localidad']) this.handleProvinciaChange({ value: formData['localidad'].provincia.id, label: formData['localidad'].provincia.nombre })
            .then(() => {
              if(formData['localidad']) this.handleLocalidadChange({ value: formData['localidad'].id, label: formData['localidad'].nombre })
            });
          });
      }
      $(this.refs.duallistbox).bootstrapDualListbox({
        moveOnSelect: false,
        moveAllLabel: "Mover todos",
        removeAllLabel: "Remover todos",
        moveSelectedLabel: "Mover seleccionados",
        removeSelectedLabel: "Remover seleccionados",
        infoText: false,
        filterPlaceHolder: "Filtro",
        nonSelectedListLabel: "Bases Disponibles:",
        selectedListLabel: "Bases Asignadas:"
      });

      $(this.refs.duallistbox).change((e) => {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario.bases = $(this.refs.duallistbox).val();
        this.setState({
          formData: formDataCopy
        });
      });
    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
  }
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
    }
	componentWillMount() {
    if ((Security.hasPermission('BASES_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('BASES_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('BASES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }



	}

  getData(service, param) {
    let serviceURL ="";
    if ( service === "provincias" || service === "localidades" ) {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param+ '/select') : (Config.get('apiUrlBase') + '/' + service);
    } else {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
    }
    return fetch(serviceURL, {
      method: 'GET',
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  handleRegionChange(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['subregion'] = null;
    if(object == null){
      let subregiones = [];
			this.setState({
        subregiones: subregiones,
        formData: formDataCopy
      });
      this.handleSelectFormChange(name, object);
    }
    else{
      Promise.all([
        //this.getData('subregiones', object.id)
        this.ajaxHandler.getJson('/subregiones/' + object.id + '/select')
      ]).then((data) => {
        let subregiones = data[0];
        this.setState({
          subregiones: subregiones,
			    formData: formDataCopy
        });
        this.handleSelectFormChange(name, object);
      }).catch(function(error) {
        // TODO: Manejo de errores
        console.log(error);
      });
    }
  }



  handlePaisChange(object) {

    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        // formDataCopy.pais = { id: object.value, label: object.label };
        formDataCopy.pais = { id: object.value, label: object.label };
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => {
          this.setState({ provinciasLoading: true });
          this.ajaxHandler.getJson('/provincias/' + object.value + '/select')
          .then((data) => {
            this.setState({
              provincias: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ provinciasLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.pais = null;
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => resolve());
      }
    });
  }

  handleProvinciaChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.provincia = { id: object.value, label: object.label };
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          localidades: []
        }, () => {
          this.setState({ localidadesLoading: true });
          this.ajaxHandler.getJson('/localidades/' + object.value + '/select')
          .then((data) => {
            this.setState({
              localidades: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ localidadesLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          localidades: []
        }, () => resolve());
      }
    });
  }

  handleLocalidadChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.localidad = { id: object.value, label: object.label };
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }

  handleEstadoChange(activa) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activa'] = activa;
		this.setState({
			formData: formDataCopy
    });
  }

  handleEsUsuarioChange(esUsuario) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['esUsuario'] = esUsuario;
    this.setState({
			formData: formDataCopy
		});
  }

  handleNotificacionesActivasChange(notificacionesActivas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleAtributosCedisChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.atributosCedis = {...formDataCopy.atributosCedis};
    formDataCopy.atributosCedis[name] = value;

    this.setState({
      formData: formDataCopy
    });
  }

	handleBasesChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleUsuarioFormChange(event){
    const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

    //TODO: CHEQUEAR QUE EL USUARIO SEA ÜNICO LO MISMO CON EL NRO. DE LEGAJO
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handlePersonaFormChange(name, object) {
    const value = object === null ? null : object.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }
  handleSelectFormChange(name, object) {
    let value;
    if ( name === "localidad")
      value = object === null ? null : object.value;
    else
      value = object === null ? null : object.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handlePerfilChange(name, perfil) {
    const value = perfil === null ? null : perfil.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		}, () => {
      if(perfil){
        perfil.permisos.includes('USUARIO_POSEER_MOVIL') ? this.setState({ hasPermisoMovil : true }) : this.setState({ hasPermisoMovil : false });
        perfil.permisos.includes('USUARIO_ACTUAR_COMO_GERENCIADOR') ? this.setState({ hasPermisoGerenciador : true }) : this.setState({ hasPermisoGerenciador : false });
        perfil.permisos.includes('USUARIO_TRABAJAR_BASES') ? this.setState({ hasPermisoBases : true }) : this.setState({ hasPermisoBases : false });
      }
		});
  }

  handleMovilChange(name, movil) {
    const value = movil === null ? null : movil.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleFormChangeSelectObject(name, object) {

    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[name] = object ? { id: object.value } : null;
      this.setState({
        formData: formDataCopy
      }, () => resolve());
    });
  }
  handleGerenciadorChange(name, gerenciador) {
   const value = gerenciador === null ? null : gerenciador.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
    });

  }

	handleChangeDireccion(keyDireccion, valueDireccion, direccionValid){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		if (keyDireccion == 'calle')
			keyDireccion = 'direccion'
		formDataCopy[keyDireccion] = valueDireccion;
    formDataCopy['direccionValid'] = direccionValid;
		this.setState({
			formData: formDataCopy
		});

	}


  handleDatePickerFormChange(name, event) {
    let date = event ? event.format(): '';
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }


	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    this.ajaxHandler.fetch('/bases' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {

        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/bases'
		});
	}

	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let atributosCedis = this.state.formData.atributosCedis;
    let validationState = this.formValidation.state;
    let state = this.state;
    let gerenciador = formData.gerenciador ? state.gerenciadores.find(e => e.value === formData.gerenciador.id) : null;
    let jefe = formData.jefe ? state.jefes.find(e => e.value === formData.jefe.id) : null;
    let responsable = formData.responsable ? state.responsables.find(e => e.value === formData.responsable.id) : null;
    let pais = formData.pais ? state.paises.find(e => e.value === formData.pais.id) : null;
    let provincia = formData.provincia ? state.provincias.find(e => e.value === formData.provincia.id) : null;
    let localidad = formData.localidad ? state.localidades.find(e => e.value === formData.localidad.id) : null;
    const canEdit = (Security.hasPermission('BASES_MODIFICAR_INFORMACION_COMPLEMENTARIA') && (this.props.action === 'EDIT' || this.props.action === 'ADD')) ? '' : 'disabled';

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="basesAbm.information.datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="bases.information.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* BASE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="descripcion">
                              <FormattedMessage id="basesAbm.base" defaultMessage="Base *:"/>
                            </label>
                            <div className="col-md-9">

                              {(this.state.disableAvlFields || this.props.action === 'VIEW') ? (
                              <div className="form-control-static col-form-label form-value">{formData.descripcion}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="descripcion" name="descripcion" placeholder={this.props.intl.formatMessage({ id: 'base.base.placeholder', defaultMessage: 'Base' })} value={formData.descripcion} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.descripcion.pristine || validationState.formData.descripcion.valid}>{validationState.formData.descripcion.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* REGION */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="region">
                              <FormattedMessage id="basesAbm.region" defaultMessage="Región *:"/>
                            </label>
                            <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.region ? formData.region.nombre : ''}</div>
                              ) : (
                                <div>
                            <Select
                                  id="region"
                                  name="region"
                                  placeholder={this.props.intl.formatMessage({ id: 'basesAbm.region.placeholder', defaultMessage: 'Región' })}
                                  options={this.state.regiones}
                                  valueKey='id'
                                  labelKey='nombre'
                                  value={formData.region}
                                  onChange={(e) => this.handleRegionChange("region", e)}
                                />
                                </div>
                            )}
                                <div className="help-block text-danger field-message" hidden={validationState.formData.region.pristine || validationState.formData.region.valid}>{validationState.formData.region.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* SUB REGION */}
                        <div className="col-md-6">
                          <div style={{display: formData.region === null ? 'none' : ''}} className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="subregion">
                              <FormattedMessage id="basesAbm.sub_region" defaultMessage="Sub Región *:"/>
                            </label>
                            <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">
                                {formData.subregion ? formData.subregion.nombre : ''}
                              </div>
                              ) : (
                                <div>
                                <Select
                                  id="subregion"
                                  name="subregion"
                                  placeholder={this.props.intl.formatMessage({ id: 'basesAbm.sub_region.placeholder', defaultMessage: 'Sub Región' })}
                                  options={this.state.subregiones}
                                  valueKey='id'
                                  labelKey='nombre'
                                  value={formData.subregion}
                                  onChange={(e) => this.handleSelectFormChange("subregion", e)}
                                />
                                </div>
                              )}
                                <div className="help-block text-danger field-message" hidden={validationState.formData.subregion.pristine || validationState.formData.subregion.valid}>{validationState.formData.subregion.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                       {/* GERENCIADORES */}
                       <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="gerenciador">
                                  <FormattedMessage id="basesAbm.gerenciador" defaultMessage="Gerenciador:"/>
                                </label>
                                <div className="col-md-9">


                                {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.gerenciador ? formData.gerenciador.razon_social : ''}</div>
                              ) : (



                                  <div>
                                    <Select
                                      id="gerenciador"
                                      name="gerenciador"
                                      placeholder={this.props.intl.formatMessage({ id: 'basesAbm.gerenciador.placeholder', defaultMessage: 'Gerenciador' })}
                                      options={this.state.gerenciadores}
                                      valueKey='value'
                                      labelKey='label'
                                      value={gerenciador ? { value: gerenciador.id, label: gerenciador.label } : null}
                                      onChange={(e) => this.handleFormChangeSelectObject("gerenciador", e)}
                                    />
                                  </div>
                              )}



                                </div>
                              </div>

                          </div>
                        </div>
                        {(this.state.llantasEnabled) ?
                        <div className="row">
                       {/* CECO */}
                       <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="centroCostos">
                                  <FormattedMessage id="basesAbm.ceco" defaultMessage="Centro de Costo:"/>
                                </label>
                                <div className="col-md-9">
                                  <div>
                                    <Select
                                      id="centroCostos"
                                      name="centroCostos"
                                      placeholder={this.props.intl.formatMessage({ id: 'basesAbm.ceco.placeholder', defaultMessage: 'Centro de Costo' })}
                                      options={this.state.cecos}
                                      valueKey='id'
                                      labelKey='nombre'
                                      value={formData.centroCostos}
                                      onChange={(e) => this.handleSelectFormChange("centroCostos", e)}
                                      disabled={this.props.action === 'VIEW' ? true : false}
                                    />
                                  </div>
                                </div>
                              </div>
                          </div>
                          </div>: ""}

                        <div className="row">
                          {/* TIPOS */}
                          <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="esOperativa">
                                  <FormattedMessage id="basesAbm.tipo" defaultMessage="Tipo *:"/>
                                </label>
                                <div className="col-md-9">
                                  <div>
                                    <Select
                                      id="esOperativa"
                                      name="esOperativa"
                                      placeholder={this.props.intl.formatMessage({ id: 'basesAbm.tipo.placeholder', defaultMessage: 'Tipo' })}
                                      options={this.state.tipos}
                                      valueKey='id'
                                      labelKey='nombre'
                                      value={formData.esOperativa}
                                      onChange={(e) => this.handleSelectFormChange("esOperativa", e)}
                                      disabled={this.props.action === 'VIEW' ? true : false}
                                    />
                                  </div>
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.esOperativa.pristine || validationState.formData.esOperativa.valid}>
                                    {validationState.formData.esOperativa.message}
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>

										{(this.state.llantasEnabled) ?

                      <div className="row">
                        {/* ESTADO ALMACEN */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="region">
                              <FormattedMessage id="basesAbm.estado_almacen_label" defaultMessage="Estado de Almacén:"/>
                            </label>
                            <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.estadoAlmacen ? formData.estadoAlmacen : ''}</div>
                              ) : (
                                <div>
                                  <Select
                                    id="estadoAlmacen"
                                    name="estadoAlmacen"
                                    placeholder={this.props.intl.formatMessage({ id: 'basesAbm.estado_almacen_placeholder', defaultMessage: 'Estado' })}
                                    options={this.state.estadosDeAlmacen}
                                    valueKey='id'
                                    labelKey='nombre'
                                    value={formData.estadoAlmacen}
                                    onChange={(e) => this.handleSelectFormChange("estadoAlmacen", e)}
                                  />
                                </div>
                            )}
                            </div>
                          </div>
                        </div>
                      </div>
										: '' }

                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activa">
                              <FormattedMessage id="basesAbm.estado" defaultMessage="Activa:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activa}
                                options={this.state.estados}
                                id="activa"
                                name="activa"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-phone"></i><FormattedMessage id="basesAbm.datos_contacto" defaultMessage=" Datos de Contacto"/>
                      </h4>

                      <div className="row">
                        {/* JEFE */}
                        <div className="col-md-6">
                          <div  className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="jefe">
                              <FormattedMessage id="basesAbm.jefe" defaultMessage="Jefe *:"/>
                            </label>
                            <div className="col-md-9">                                
                                  <Select
                                    id="jefe"
                                    name="jefe"
                                    placeholder={this.props.intl.formatMessage({ id: 'basesAbm.jefe.placeholder', defaultMessage: 'Jefe' })}
                                    options={this.state.jefes}
                                    valueKey='value'
                                    labelKey='label'
                                    value={jefe ? { value: jefe.id, label: jefe.label } : null}
                                    disabled={this.props.action === 'VIEW' ? true : false}
                                    onChange={(e) => this.handleFormChangeSelectObject("jefe", e)}
                                  />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.jefe.pristine || validationState.formData.jefe.valid}>{validationState.formData.jefe.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* RESPONSABLE */}
                        <div className="col-md-6">

                          <div  className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="responsable">
                              <FormattedMessage id="basesAbm.responsable" defaultMessage="Responsable *:"/>
                            </label>
                            <div className="col-md-9">
                            <Select
                                  id="responsable"
                                  name="responsable"
                                  placeholder={this.props.intl.formatMessage({ id: 'basesAbm.responsable.placeholder', defaultMessage: 'Responsable' })}
                                  options={this.state.responsables}
                                  valueKey='value'
                                  labelKey={'label'}
                                  value={responsable ? { value: responsable.id, label: responsable.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("responsable", e)}
                                  disabled={this.props.action === 'VIEW' ? true : false}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.responsable.pristine || validationState.formData.responsable.valid}>{validationState.formData.responsable.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Administrador */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="administrador">
                              <FormattedMessage id="basesAbm.administrador" defaultMessage="Administrador:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.administrador}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="administrador" name="administrador" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.administrador.placeholder', defaultMessage: 'Administrador' })} value={formData.administrador || ''} onChange={this.handleInputFormChange} />

                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* TELEFONO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="telefono">
                              <FormattedMessage id="basesAbm.telefono" defaultMessage="Teléfono :"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.telefono}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="telefono" name="telefono" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.telefono.placeholder', defaultMessage: 'Teléfono' })} value={formData.telefono || ''} onChange={this.handleInputFormChange} />

                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body" id="container-direccion">
											<h4 className="form-section mt-2">
												<i className="ft-map-pin"></i><FormattedMessage id="basesAbm.datos_ubicacion" defaultMessage=" Datos de Ubicación"/>
											</h4>
											<DireccionAutocompletar
												action={this.props.action}
												camposHidden={["codigoPostal"]}
												cambioDireccion={this.handleChangeDireccion}
												formulario={this.state.direccionForm}
												crearProvincias={true}
												crearLocalidades={true}
												camposObligatorios={[]}
											/>
											<div className="row">
												{/* OBSERVACIONES */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="observaciones">
															<FormattedMessage id="basesAbm.observaciones" defaultMessage="Observaciones:"/>
														</label>
														<div className="col-md-9">
															{this.props.action === 'VIEW' ? (
															<div className="form-control-static col-form-label">{formData.observaciones}</div>
															) : (
															<div>
																 <textarea className="form-control" id="observaciones" name="observaciones" rows="3" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.observaciones.placeholder', defaultMessage: 'observaciones' })} value={formData.observaciones || ''} onChange={this.handleInputFormChange}></textarea>
															</div>
															)}
														</div>
													</div>
													</div>
											</div>
										</div>
									</div>
								</div>

                 {(this.state.atributosCedisEnabled) ? (

                <div className="card pull-up">
									<div className="card-content">
										<div className="card-body" id="container-direccion">
											<h4 className="form-section mt-2">
												<i className="ft-map-pin"></i><FormattedMessage id="basesAbm.informacion_complementaria" defaultMessage="Informacion complementaria"/>
											</h4>
											<div className="row">
                        {/* Local */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="local">
                              <FormattedMessage id="basesAbm.local" defaultMessage="Local :"/>
                            </label>
                            <div className="col-md-9">
                              <input 
                                type="text" 
                                className="form-control" 
                                id="local" 
                                name="local" 
                                placeholder={this.props.intl.formatMessage({ id: 'basesAbm.local.placeholder', defaultMessage: 'Local' })}
                                value={atributosCedis ? atributosCedis.local : ''}
                                onChange={this.handleAtributosCedisChange}
                                disabled={canEdit}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Centro emplazamiento */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="centro_emplazamiento">
                              <FormattedMessage id="basesAbm.centro_emplazamiento" defaultMessage="Centro emplazamiento :"/>
                            </label>
                            <div className="col-md-9">
                              <input 
                              type="text" className="form-control" id="centro_emplazamiento" name="centro_emplazamiento" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.centro_emplazamiento.placeholder', defaultMessage: 'Centro de emplazamiento' })} value={atributosCedis ? atributosCedis.centro_emplazamiento : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Grupo de autorización */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="grupo_autorizacion">
                              <FormattedMessage id="basesAbm.grupo_autorizacion" defaultMessage="Grupo de autorización :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="grupo_autorizacion" name="grupo_autorizacion" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.grupo_autorizacion.placeholder', defaultMessage: 'Grupo de autorización' })} value={atributosCedis ? atributosCedis.grupo_autorizacion : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Pto de trabajo responsable */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="pto_trabajo_responsable">
                              <FormattedMessage id="basesAbm.pto_trabajo_responsable" defaultMessage="Pto de trabajo responsable :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="pto_trabajo_responsable" name="pto_trabajo_responsable" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.pto_trabajo_responsable.placeholder', defaultMessage: 'Pto de trabajo responsable' })} value={atributosCedis ? atributosCedis.pto_trabajo_responsable : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Centro de planificación */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="centro_planificacion">
                              <FormattedMessage id="basesAbm.centro_planificacion" defaultMessage="Centro de planificación :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="centro_planificacion" name="centro_planificacion" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.centro_planificacion.placeholder', defaultMessage: 'Centro de planificación' })} value={atributosCedis ? atributosCedis.centro_planificacion : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Emplazamiento */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="emplazamiento">
                              <FormattedMessage id="basesAbm.emplazamiento" defaultMessage="Emplazamiento :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="emplazamiento" name="emplazamiento" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.emplazamiento.placeholder', defaultMessage: 'Emplazamiento' })} value={atributosCedis ? atributosCedis.emplazamiento : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Grupo de planificación */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="grupo_planificacion">
                              <FormattedMessage id="basesAbm.grupo_planificacion" defaultMessage="Grupo de planificación :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="grupo_planificacion" name="grupo_planificacion" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.grupo_planificacion.placeholder', defaultMessage: 'Grupo de planificación' })} value={atributosCedis ? atributosCedis.grupo_planificacion : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Pto de planificación transporte */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="pto_planificacion_transporte">
                              <FormattedMessage id="basesAbm.pto_planificacion_transporte" defaultMessage="Pto de planificación transporte :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="pto_planificacion_transporte" name="pto_planificacion_transporte" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.pto_planificacion_transporte.placeholder', defaultMessage: 'Pto de planificación transporte' })} value={atributosCedis ? atributosCedis.pto_planificacion_transporte : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Ubicaciones técnicas */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="ubicaciones_tecnicas">
                              <FormattedMessage id="basesAbm.ubicaciones_tecnicas" defaultMessage="Ubicaciones técnicas :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="ubicaciones_tecnicas" name="ubicaciones_tecnicas" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.ubicaciones_tecnicas.placeholder', defaultMessage: 'Ubicaciones técnicas' })} value={atributosCedis ? atributosCedis.ubicaciones_tecnicas : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Campo de Clasificación */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="campo_de_clasificacion">
                              <FormattedMessage id="basesAbm.campo_de_clasificacion" defaultMessage="Campo de Clasificación :"/>
                            </label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" id="campo_de_clasificacion" name="campo_de_clasificacion" placeholder={this.props.intl.formatMessage({ id: 'basesAbm.campo_de_clasificacion.placeholder', defaultMessage: 'Campo de Clasificación' })} value={atributosCedis ? atributosCedis.campo_de_clasificacion : ''} onChange={this.handleAtributosCedisChange} disabled={canEdit} />
                            </div>
                          </div>
                        </div>
                      </div>
										</div>
									</div>
								</div>
                ) : (
                  ''
                )}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="basesAbm.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'basesAbm.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'basesAbm.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(BasesAbm);
