import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import { withRouter } from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
am4core.useTheme(am4themes_animated);

class BasicCardInfo extends Component{   
  
    constructor(props) {
        super(props);

        this.ajaxHandler = new AjaxHandler();

        moment.locale('es');


        this.state = {
            redirectTo: null,
            props: this.props,
            backColor: 'white',
            clicked: false
        };

        this.clickedColor = '#e2e2e2';
    }

    componentDidMount(){
      //this.setState({backColor: this.props.values.backColor});
    }

    handleFilter(filter){
      //this.setState({backColor: this.state.clicked ? this.props.values.backColor : this.clickedColor, clicked:!this.state.clicked});
      this.props.onClickAlert(filter);
    }

    render() {
      let values = this.props.values;
      //let props = {value: 100, description: "alguna descripción", entity: "moviles", image: CargasVede.svg, backColor:"#d7dcf3", iconSize:"60px"}
      
      return (
        <div className="card pull-up">
        
          <div className="card-content" style={{ backgroundColor: this.props.backColor, borderRadius: ".45rem",cursor:values.cursor }} onClick={values.cursor == 'pointer' ? this.handleFilter.bind(this,values.filter): null} >
            <div className="card-body" style={{ padding: "15px" }}>
                <div className="text-right">
                    <h5 className="text-muted text-bold-500"><FormattedMessage {...(backendStrings[values.description] ? backendStrings[values.description] : backendStrings['errors.default'])}/></h5>
                </div>
            <div className="media d-flex">
                
                <div className="align-self-center">
                    <img width={ values.iconSize } height={ values.iconSize } src={require('../../assets/images/'+ values.entity +'/'+ values.image)}/>
                </div>
                
                <div className="media-body text-right">
                    
                    <h3 className="text-bold-600">{!values.value ? 0 : values.value }</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

} export default BasicCardInfo;
