export const ID__TRACCION_SIMPLE = 1;
export const ID__TRACCION_4X4 = 2;
export const TIPOS_TRACCION = {}


TIPOS_TRACCION[ID__TRACCION_SIMPLE] = {
	id: ID__TRACCION_SIMPLE,
	label: 'SIMPLE',
}

TIPOS_TRACCION[ID__TRACCION_4X4] = {
	id: ID__TRACCION_4X4,
	label: '4X4',
}