import React, { Component } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import CanvasDraw from "react-canvas-draw";

class LlantaInspeccionFirma extends Component {
	constructor(props) {
		super(props);
		this.state = {
			props: this.props,
			firmaOriginal: {},
			adjunto_url: null,
			editandoFirma: true,
		}
		this.clearFirma = this.clearFirma.bind(this);
		this.onChangeCanvasDraw = this.onChangeCanvasDraw.bind(this);
  }

  componentDidMount() {
    if (this.props.action === "VIEW" || this.props.action === "EDIT"){
    	this.state.firmaOriginal.fromData(JSON.parse(this.state.props.firma));
    }
  }

	clearFirma(event){
		event.preventDefault();
		this.state.firmaOriginal.clear();
		this.props.setFirma(null);
	}

	onChangeCanvasDraw(event){
		this.props.setFirma(this.state.firmaOriginal.toData());
	}

	render() {
      return (
        <React.Fragment>
        	<div className="form-control">
						<button
							className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid pull-right"
							onClick={(e) => this.clearFirma(e)}
							title={this.props.intl.formatMessage({
                id: "llantaInspeccionFirma.render.title_eliminar_firma",
                defaultMessage: "Eliminar",
              })}
						>
							<i className="fa fa-trash fa-xs"></i>
						</button>
						<br/>
						<br/>
						<SignatureCanvas penColor='black'
	    				canvasProps={{className: 'w-100 p-0'}}
	    				onEnd={event => this.onChangeCanvasDraw(event)}
	    				ref={(ref) => { this.state.firmaOriginal = ref }}
	    			/>
					</div>
        </React.Fragment>
      )
  }
}

export default LlantaInspeccionFirma;
