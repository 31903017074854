import React, { Component } from 'react'
import Loading from '../ui/Loading.js'
import Switch from "react-switch"
import moment from 'moment'
import 'moment/min/locales'
import PuntoDinamicoInstancia from './PuntoDinamicoInstancia.js.sample'
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js'
import * as Constants from './constants.js'
import Select from 'react-select'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Draggable } from 'react-beautiful-dnd'
import {FormattedMessage, injectIntl} from 'react-intl';

const ESTADOS_ACTIVO = [{ id: 1, nombre: 'Visible'},{ id: 0, nombre: 'Invisible'}]
const ESTADOS_REQUIRED = [{ id: 1, nombre: 'Requerido'},{ id: 0, nombre: 'No Requerido'}]


class PuntoDinamico extends Component {
	constructor(props) {
		super(props);
		this.props = props;

		moment.locale('es');

		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleChangeAndUpdateFather = this.handleChangeAndUpdateFather.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		let tiposValor = Object.values(Constants.INSTANCIAS_DICT).filter(tipoValor => tipoValor.modulos.includes(this.props.modulo))

		this.state = {
			formData: Object.assign({}, Constants.TEMPLATE_ATRIBUTO),
			loading: true,
			hovered: false,
			tiposValor: tiposValor,
			solicitar_mas_info: [
				{ id: Constants.ID_ESTADO_APROBACION_VALIDO, label: this.props.intl.formatMessage({ id: 'atributoDinamico.solicitar_mas_info.al_aprobar', defaultMessage: 'Al Aprobar' })},
				{ id: Constants.ID_ESTADO_APROBACION_INVALIDO, label: this.props.intl.formatMessage({ id: 'atributoDinamico.solicitar_mas_info.al_desaprobar', defaultMessage: 'Al Desaprobar' })},
			]
		}
	}

	componentDidMount() {
		let formDataCopy = JSON.parse(JSON.stringify(this.props.formulario));
		let html = formDataCopy.description
		let instruccionesState = EditorState.createEmpty()
		if (html) {
			let contentBlock = htmlToDraft(html);
			let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			instruccionesState = EditorState.createWithContent(contentState);
		}
		this.setState({
			instruccionesState: instruccionesState,
			formData: formDataCopy,
			loading: false,
		});
	}

	componentWillUnmount() {
	}


	onDescriptionChange(editorState) {
		if (editorState.getCurrentContent().hasText()) {
			let rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))
			this.handleChangeAndUpdateFather("description", rawHtml)
		}
		else
			this.handleChangeAndUpdateFather("description", '')
		this.setState({
			instruccionesState: editorState,
		});
	}

	handleDelete () {
		if (this.state.formData.id)
			this.handleChangeAndUpdateFather("activo", false)
		else
			this.props.handleDelete(this.props.index);
	}

	handleChangeAndUpdateFather(name, object) {
		this.state.formData[name] = object;
		this.setState({
			formData: this.state.formData
		});
		this.props.handleChange(this.props.index, name, object);

	}

	handleInputFormChange(event) {
		// El input debe tener el atributo name para que funcione
		const target = event.target;
		const name = target.name;
		let value = target.value;
		this.handleChangeAndUpdateFather(name, value)
	}

	render() {
		let tipoValor = this.props.formulario.tipoValor ? this.props.formulario.tipoValor : 0
		let ExtraDinamico = Constants.INSTANCIAS_DICT[1].extra
		let poseeEstados = Constants.INSTANCIAS_DICT[1].conEstados

		return (
			<React.Fragment>
				{this.state.loading && <Loading />}
				{ !this.state.loading &&
				<Draggable
					draggableId={this.props.index.toString()}
					key={this.props.index.toString()}
					index={this.props.index}
					isDragDisabled={!this.props.draggable}>
					{(provided, snapshot) =>
						<div
							ref={provided.innerRef}
							{...provided.dragHandleProps}
							{...provided.draggableProps}
							onMouseEnter={() => this.setState({hovered: true})}
							onMouseLeave={() => this.setState({hovered: false})}
						>
							<div className="card pull-up">
								<div className="card-content">
									<div className="card-body">
										<h4 className="form-section">
											<i className="la la-ticket"></i> <FormattedMessage id="puntoDinamico.render.form_option.header_opcion_del_formulario" defaultMessage="Punto de Medición"/> # {this.props.index + 1}
											{!this.state.formData.id && (
												<span className="pull-right text-danger cursor-pointer" onClick={this.handleDelete}>
			  											<i className="fa fa-trash"></i>
			  										</span>
											)}
											{(this.props.draggable && this.state.hovered) && (

												<span className="pull-right" >
															<i className="grey-color fa fa-bars"></i>
														</span>
											)}

										</h4>
										<div className="row ">
											<div className="col-md-6">
												{/* Nombre */}
												<div className="form-group row">
													<label className="col-md-3 label-control col-form-label" htmlFor="nombre">
														<FormattedMessage id="puntoDinamico.render.form_option.header_descripcion" defaultMessage="Descripcion"/> *:
													</label>
													<div className="col-md-9">
														<input placeholder="Descripcion" disabled={this.props.disabled}  name="nombre" type="text" className="form-control" value={this.state.formData.nombre} onChange={this.handleInputFormChange} />
													</div>
												</div>
												{/* Recapado */}
												<div className="form-group row mt-3 mb-0">
													<label className="col-md-3 label-control col-form-label" htmlFor="mmWarning">
														<FormattedMessage id="puntoDinamico.render.form_option.header_mmAlerta" defaultMessage="Espesor para Alerta"/> *:
													</label>
													<div className="col-md-9">
														<input type="number" placeholder="Espesor en mm" disabled={this.props.disabled}  name="mmWarning" className="form-control" value={this.state.formData.mmWarning} onChange={this.handleInputFormChange} />
														<div className="help-block text-danger field-message" hidden={Number(this.state.formData.mmWarning) < Number(this.props.maxValue) || !this.props.maxValue}>El valor debe ser menor que {this.props.maxValue}</div>
													</div>
												</div>
												{/* Baja */}
												<div className="form-group row">
													<label className="col-md-3 label-control col-form-label" htmlFor="mmDanger">
														<FormattedMessage id="puntoDinamico.render.form_option.header_mmReencauche" defaultMessage="Espesor para Reencauche"/> *:
													</label>
													<div className="col-md-9">
														<input type="number" placeholder="Espesor en mm" disabled={this.props.disabled}  name="mmDanger" className="form-control" value={this.state.formData.mmDanger} onChange={this.handleInputFormChange} />
														<div className="help-block text-danger field-message" hidden={Number(this.state.formData.mmDanger) < Number(this.state.formData.mmWarning) || !this.state.formData.mmWarning}>El valor debe ser menor que {this.state.formData.mmWarning}</div>
														<div className="help-block text-danger field-message" hidden={Number(this.state.formData.mmDanger) < Number(this.props.maxValue) || !this.props.maxValue}>El valor debe ser menor que {this.props.maxValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="bs-callout-info callout-border-left mb-1 p-1">
													<strong><FormattedMessage id="puntoDinamico.recuerde_que" defaultMessage="Recuerde que..."/></strong>
													<p><FormattedMessage id="puntoDinamico.primer_servicio_seleccionado_mayor_importancia" defaultMessage="Las medidas deben estar expresadas en milimetros."/></p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</Draggable>
				}
			</React.Fragment>
		)
	}
}

export default injectIntl(PuntoDinamico);
