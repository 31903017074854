import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import gomaPinchadaIcon from '../../assets/images/llantas/flat-tire.svg';
import LlantaRepuestosSelect from './LlantaRepuestosSelect.jsx';
import LlantaBasesSelect from './LlantaBasesSelect.jsx';
import * as Utils from '../../commons/utils/Utils';

const LlantaReparar = (props) => {
  const [llanta, setLlanta] = useState(props.llanta ? props.llanta : null);
  const [llantaId, setLlantaId] = useState(props.id);
  const [llantas, setLlantas] = useState(null);
  const [formData, setFormData] = useState({});
  const [gerenciadores, setGerenciadores] = useState([]);
  const [llantaReemplazoSwitch, setlLlantaReemplazoSwitch] = useState(false);

  const [llantaSelectLoading, setLlantaSelectLoading] = useState(false);
  const [proveedorSelectLoading, setProveedorSelectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [unidadMedidorChange, setUnidadMedidorChange] = useState(false);

  useEffect(() => {
    getMutualData();
    props.id ? initForm() : initBlank();
  }, []);
  useEffect(() => {
    props.llantasAsignadas && setLlantasAsignadas();
  }, [props.llantasAsignadas]);

  const getMutualData = () => {
    setProveedorSelectLoading(true);
    Promise.all([null, Utils.getData('/gerenciadores')])
      .then((response) => {
        setGerenciadores(response[1]);
        setProveedorSelectLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const initBlank = () => {
    props.fromAsignacion && setLlantasAsignadas();
  };

  const setLlantasAsignadas = () => {
    setLlantas(
      props.llantasAsignadas.map((el) => ({
        ...el,
        label: el.serial + ' - ' + el.llanta_marca.nombre + ' - ' + el.modelo.llanta_medida.nombre,
      })),
    );
  };

  const initForm = () => {
    setLlantaSelectLoading(true);
    Promise.all([null, Utils.getData('/llantas/' + llantaId)])
      .then((data) => {
        let formDataCopy = { ...formData };
        formDataCopy.llanta = data[1];
        setLlanta(data[1]);
        setFormData(formDataCopy);
        setLlantaSelectLoading(false);
        handleFormChangeSelectObject(data[1], 'llanta');
        if(data[1].movil)
          data[1].llanta_posicion.necesita_reemplazo && setlLlantaReemplazoSwitch(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleLlantaReemplazoSwitch = (event) => {
    setlLlantaReemplazoSwitch(event);
    let formDataCopy = {};
    formDataCopy.llanta = formData.llanta;
    setUnidadMedidorChange(false);
    setFormData(formDataCopy);
  };

  const handleFormChangeSelectObject = (event, name) => {
    let formDataCopy = { ...formData };
    formDataCopy[name] = event;
    setFormData(formDataCopy);
    if (name === 'llanta' && event !== null) {
      setLlantaId(event.id);
      Promise.all([null, Utils.getData('/llantas/' + event.id)])
        .then((data) => {
          setLlanta(data[1]);
          if(data[1].movil)
            data[1].llanta_posicion.necesita_reemplazo && setlLlantaReemplazoSwitch(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const replaceOrContinue = () => {
    return new Promise((resolve, reject) => {
      if (llantaReemplazoSwitch) {
        Utils.sendData('/llantas/llantadesasignar', 'PUT', formData)
          .then(() => resolve(1))
          .catch((e) => reject(e));
      } else {
        Utils.sendData(`/llantas/`+ llantaId +`/generarcorrectivo`, 'PUT', formData)
          .then(() => resolve(2))
          .catch((err) => reject(err));
      }
    });
  };

  const handleContinuar = () => {
    replaceOrContinue()
      .then((response) => {
        if (response === 1) {
          props.redirectCorrectivo(formData.llanta.id);
        } else {
          props.confirmCorrectivo();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLlantaRepuestoChange = (e) => {
    let unidad = e ? (e.unidad_medidor_id ? (e.unidad_medidor_id !== formData.llanta.unidad_medidor_id) : false) : false
    setUnidadMedidorChange(unidad);
    handleFormChangeSelectObject(e, 'llanta_reemplazo');
  };

  return (
    <>
      <div
        className="llanta-modulo"
        style={{ fontSize: '1rem', fontFamily: 'Poppins', fontWeight: '400' }}
      >
        <div className="d-flex justify-content-center w-full mb-3">
          <img
            src={gomaPinchadaIcon}
            alt="gomaPinchadaIcon"
            style={{ height: '80px', width: '80px' }}
          />
        </div>
        {formData.llanta ?
            formData.llanta.movil ? 
            <div className="d-flex justify-content-center">
              <label className="d-flex align-items-center">
                <span>
                  {props.intl.formatMessage({
                    id: 'llantaRepararModal.render.llanta_reemplazo',
                    defaultMessage: 'Llanta Reemplazo',
                  })}
                </span>
                <Switch
                  className="ml-2"
                  onChange={(event) => handleLlantaReemplazoSwitch(event)}
                  checked={llantaReemplazoSwitch}
                  value={llantaReemplazoSwitch}
                  id="llantaReemplazoSwitch"
                  name="llantaReemplazoSwitch"
                  offColor="#FF4961"
                  onColor="#28D094"
                  height={20}
                  width={40}
                  disabled={false}
                />
              </label>
            </div>
            : ''
          : ''
        }
        {props.fromAsignacion ? (
          <div className="d-flex row align-items-center mt-1">
            <p className="col-4 mb-0">
              {props.intl.formatMessage({
                id: 'llantaRepararModal.render.llanta_a_reparar',
                defaultMessage: 'Llanta a Reparar',
              })}
              : *
            </p>
            <div className="col-8">
              <Select
                name="llanta"
                className="select-personal-llantas z-15"
                maxMenuHeight={10}
                placeholder={'Cautin / S'}
                options={llantas}
                valueKey="id"
                labelKey="label"
                value={formData.llanta ? formData.llanta : ''}
                onChange={(e) => handleFormChangeSelectObject(e, 'llanta')}
                isLoading={llantaSelectLoading}
                isDisabled={llantaSelectLoading}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {llantaReemplazoSwitch ? (
          <div>
            <div className="d-flex row align-items-center mt-1">
              <p className="col-4 mb-0">
                {props.intl.formatMessage({
                  id: 'llantaRepararModal.render.llanta_de_reemplazo',
                  defaultMessage: 'Llanta de Reemplazo',
                })}
                : *
              </p>
              <div className="col-8">
                <LlantaRepuestosSelect
                  llantaId={props.id ? props.id : formData.llanta ? formData.llanta.id : null}
                  movil={props.movil}
                  intl={props.intl}
                  changeLlantaRepuesto={(e) => handleLlantaRepuestoChange(e)}
                />
              </div>
            </div>
            {unidadMedidorChange && (
              <p className="my-1 reemplazo-label-danger">
                La llanta seleccionada posee diferente medidor. Se reiniciaran los datos de
                odometro.
              </p>
            )}
            <div className="form-group row mt-2">
              <p htmlFor="milimetrosRecapado" className="col-4 mb-0">
                {props.intl.formatMessage({
                  id: 'llantaRepararModal.render.seleccione_base',
                  defaultMessage: 'Seleccione una Base',
                })}
                : *
              </p>
              <div className="col-8">
                <LlantaBasesSelect
                  intl={props.intl}
                  changeBase={(e) => handleFormChangeSelectObject(e, 'base')}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: '10pt' }}
                htmlFor="milimetrosRecapado"
                className="col-sm-4 col-form-label"
              >
                {props.intl.formatMessage({
                  id: 'llantaRepararModal.render.costo_mano_de_obra',
                  defaultMessage: 'Costo Mano de Obra',
                })}
                :
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  id="costoReparacion"
                  name="costoReparacion"
                  placeholder="Costo"
                  value={formData.costoReparacion ? formData.costoReparacion : ''}
                  onChange={(e) =>
                    handleFormChangeSelectObject(
                      e.target.value !== '' ? e.target.value : null,
                      'costoReparacion',
                    )
                  }
                />
              </div>
            </div>
            <div className="d-flex row align-items-center mt-1">
              <label className="col-4" style={{ fontSize: '10pt' }}>
                {props.intl.formatMessage({
                  id: 'llantaRepararModal.render.proveedor',
                  defaultMessage: 'Proveedor',
                })}
                :{' '}
              </label>
              <div className="col-8" style={{ fontSize: '10pt' }}>
                <Select
                  name="proveedor"
                  style={{ fontSize: '10pt' }}
                  className="select-personal-llantas z-15"
                  maxMenuHeight={10}
                  placeholder={'Proveedor'}
                  options={gerenciadores}
                  valueKey="id"
                  labelKey="razonSocial"
                  value={formData.proveedor ? formData.proveedor : ''}
                  onChange={(e) => handleFormChangeSelectObject(e, 'proveedor')}
                  isLoading={proveedorSelectLoading}
                  isDisabled={proveedorSelectLoading}
                />
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end mt-1">
          <button
            className="btn btn-primary mr-1"
            onClick={handleContinuar}
            disabled={submitLoading || (llantaReemplazoSwitch ? (formData.llanta_reemplazo && formData.base ? false : true) : (formData.costoReparacion && formData.proveedor ? false : true))}
          >
            {submitLoading ? (
              <>
                <i className="la la-spinner"></i>
                {props.intl.formatMessage({
                  id: 'llantaRepararModal.render.button_guardando',
                  defaultMessage: ' Guardando',
                })}
              </>
            ) : (
              <>
                <i className="fa fa-check-circle"></i>
                {props.intl.formatMessage({
                  id: 'llantaRepararModal.render.button_reparar',
                  defaultMessage: ' Reparar',
                })}
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default LlantaReparar;
