import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import '../palette-gradient.css';

am4core.useTheme(am4themes_animated);

class Bar extends Component{   
  
constructor(props) {
  super(props);

  this.ajaxHandler = new AjaxHandler();

 moment.locale('es');


this.state = {
  redirectTo: null,
  props: this.props
}
  //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);

}

componentDidMount() {
  
  let props = this.state.props;
  
  // Color set
  let colors = new am4core.ColorSet();

  let chart = am4core.create("bchartdiv"+this.props.id, am4charts.XYChart);
  chart.width = am4core.percent(100);
  chart.height = 100;
/* 

chart.data = [
  { "date": new Date(2018, 0, 1, 9, 0, 0), "value": 27, "color":"#800000" },
  { "date": new Date(2018, 0, 1, 8, 0, 0), "value": -57, "color":"blue" }, 
  { "date": new Date(2018, 0, 1, 9, 0, 0), "value": 27, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 10, 0, 0), "value": 24, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 11, 0, 0), "value": -59, "color":"#f00000" }, 
  { "date": new Date(2018, 0, 1, 12, 0, 0), "value": 33, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 13, 0, 0), "value": 46, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 14, 0, 0), "value": -20, "color":"#f00000" }, 
  { "date": new Date(2018, 0, 1, 15, 0, 0), "value": 42, "color":"#800000" },
  { "date": new Date(2018, 0, 1, 12, 0, 0), "value": 33, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 10, 0, 0), "value": 24, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 11, 0, 0), "value": -59, "color":"#f00000" },
  { "date": new Date(2018, 0, 1, 13, 0, 0), "value": 46, "color":"#800000" }, 
  { "date": new Date(2018, 0, 1, 14, 0, 0), "value": -20, "color":"#f00000" },  
  { "date": new Date(2018, 0, 1, 16, 0, 0), "value": 59, "color":"#800000" , "opacity": 1 }
 ];
*/
chart.data=this.props.datos;
 
let color = colors.getIndex(3);

let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.renderer.grid.template.disabled = true;
dateAxis.renderer.labels.template.disabled = true;
dateAxis.cursorTooltipEnabled = false;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.grid.template.disabled = true;
valueAxis.renderer.baseGrid.disabled = true;
valueAxis.renderer.labels.template.disabled = true;
valueAxis.cursorTooltipEnabled = false;

chart.cursor = new am4charts.XYCursor();
chart.cursor.lineY.disabled = true;

let series = chart.series.push(new am4charts.ColumnSeries());
series.tooltipText = "{date}: [bold]{value}";
series.dataFields.dateX = "date";
series.dataFields.valueY = "value";
series.strokeWidth = 0;
series.fillOpacity = 1;
series.columns.template.propertyFields.fillOpacity = "opacity";
series.columns.template.fill = colors.getIndex(3);

series.columns.template.adapter.add("fill", (fill, target) => {
  return am4core.color(target.dataItem.dataContext.color);
});

}

componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

render() {
  let props = this.state.props;
  return (
    
          <div id={"bchartdiv"+this.props.id} style={{ width: "200px", height: "100px" , marginLeft:0}}></div>
        
  );
}

} export default Bar;
