import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Security from '../../commons/security/Security.js'
import Loading from '../ui/Loading.js'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				claveActual: '',
				clave: '',
				claveReingresada: ''
			},
			persona: JSON.parse(localStorage.getItem("persona")),
			errors: [],
			loading: false
		};

		this.handleFormChange = this.handleFormChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.claveActual': (value) => Validator.notEmpty(value),
				'formData.clave': (value) => Validator.notEmpty(value),
				'formData.claveReingresada': (value) => Validator.notEmpty(value)
			}
		});
	}

	componentDidMount() {
		this.ajaxHandler.subscribe(this);
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}

	handleFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
	}

	handleSubmit(event) {
		let component = this

		if(this.state.formData.clave !== this.state.formData.claveReingresada) {
			this.setState({	errors: ['changePassword.errors.contrasenias_ingresadas_no_coinciden'] });
		} else {
			this.setState({ loading: true });
			this.setState({	errors: [] });

			this.ajaxHandler.fetch('/personas/change-password', {
				method: 'POST',
				body: JSON.stringify({
					...this.state.formData
				}),
			}).then(response => {
				if(response.status === 200) {
					this.passwordChanged();
				} else if(response.status === 400) {
					response.json()
					.then(data => {
						this.setState({
							errors: data.detalle
						});
					});
				}
				window.scrollTo(0,0);
			}).catch((error) => {
				component.ajaxHandler.handleError(error);
			}).finally(() => {
				component.setState({ loading: false });
			});
		}

		event.preventDefault();
	}

	passwordChanged() {
		swal({
			title: this.props.intl.formatMessage({ id: 'changePassword.complete.contrasenia_fue_modificada', defaultMessage: 'La contraseña fue modificada' }),
			text: this.props.intl.formatMessage({ id: 'changePassword.complete.vuelva_a_ingresar', defaultMessage: 'Por favor, vuelva a ingresar.' }),
			icon: "info",
			buttons: {
				confirm: {
					text: this.props.intl.formatMessage({ id: 'changePassword.complete.button.aceptar', defaultMessage: 'Aceptar' }),
					value: true,
					visible: true,
					className: "btn btn-primary",
					closeModal: false
				}
			}
		})
		.then((isConfirm) => {
			if (isConfirm) {
				Security.logout();
			}
		});
	}

	render() {
		this.formValidation.validate();
		let formData = this.state.formData;
		let validationState = this.formValidation.state;
		let requiredSymbol = ' *';

		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				<div className="content-wrapper">
					<div className="content-header row">
						<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><i className="la la-key ml-1 mr-1 align-middle"></i><FormattedMessage id="changePassword.cambio_contrasenia" defaultMessage="Cambio de Contraseña"/></h3>
      				</div>
					</div>
					<div className="content-body">
						<div className="row">
							<div className="col-md-12">
								<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
									{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
								</div>
								<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
									<input type="text" id="username" name="username" value={this.state.persona.usuario.usuario} hidden="true" />
									<div className="form-body">
										<div className="card pull-up">
											<div className="card-content">
												<div className="card-body">
													<h4 className="form-section">
														<i className="ft-lock"></i><FormattedMessage id="changePassword.modifique_contrasenia" defaultMessage=" Modifique su Contraseña"/>
														<div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="changePassword.campos_requeridos" defaultMessage="* campos requeridos"/></div>
													</h4>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row">
																<label className="col-md-4 label-control col-form-label" htmlFor="claveActual">
																	<FormattedMessage id="changePassword.contrasenia_actual" defaultMessage="Contraseña actual"/>{requiredSymbol}:
																</label>
																<div className="col-md-8">
																	<div>
																		<input type="password" className="form-control" id="claveActual" name="claveActual" placeholder={this.props.intl.formatMessage({ id: 'changePassword.contrasenia_actual.placeholder', defaultMessage: '(Requerido)' })} value={formData.claveActual} onChange={this.handleFormChange} maxlength="16" />
																		<div className="help-block text-danger" hidden={validationState.formData.claveActual.pristine || validationState.formData.claveActual.valid}>{validationState.formData.claveActual.message}</div>
																	</div>
																</div>
															</div>
															<div className="form-group row">
																<label className="col-md-4 label-control col-form-label" htmlFor="clave">
																	<FormattedMessage id="changePassword.contrasenia_nueva" defaultMessage="Contraseña nueva"/>{requiredSymbol}:
																</label>
																<div className="col-md-8">
																	<div>
																		<input type="password" className="form-control" id="clave" name="clave" placeholder={this.props.intl.formatMessage({ id: 'changePassword.contrasenia_nueva.placeholder', defaultMessage: '(Requerido)' })} value={formData.clave} onChange={this.handleFormChange} maxlength="16" />
																		<div className="help-block text-danger" hidden={validationState.formData.clave.pristine || validationState.formData.clave.valid}>{validationState.formData.clave.message}</div>
																	</div>
																</div>
															</div>
															<div className="form-group row">
																<label className="col-md-4 label-control col-form-label" htmlFor="claveReingresada">
																	<FormattedMessage id="changePassword.reingrese_contrasenia" defaultMessage="Reingrese la contraseña"/>{requiredSymbol}:
																</label>
																<div className="col-md-8">
																	<div>
																		<input type="password" className="form-control" id="claveReingresada" name="claveReingresada" placeholder={this.props.intl.formatMessage({ id: 'changePassword.reingrese_contrasenia.placeholder', defaultMessage: '(Requerido)' })} value={formData.claveReingresada} onChange={this.handleFormChange} maxlength="16" />
																		<div className="help-block text-danger" hidden={validationState.formData.claveReingresada.pristine || validationState.formData.claveReingresada.valid}>{validationState.formData.claveReingresada.message}</div>
																	</div>
																	<div className="alert alert-info small" role="alert" style={{marginTop: '8px'}}>
																		<FormattedMessage id="changePassword.information.longitud_contrasenia" defaultMessage="La contraseña debe tener una longitud entre 8 y 16 caracteres."/>
																		<FormattedMessage id="changePassword.information.contener_numero_letra" defaultMessage="Debe contener al menos un número y una letra."/>
																		<FormattedMessage id="changePassword.information.permiten_siguientes_simbolos" defaultMessage="Sólo se permiten los siguientes símbolos "/><strong>. ! ¿ ? @ $ & # % * + - _ =</strong>
																	</div>
																</div>																
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card pull-up">
										<div className="card-content">
											<div className="card-body">
												<div className="text-cd text-right">
													<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
													<i className="fa fa-check-circle"></i><FormattedMessage id="changePassword.final.guardar" defaultMessage=" Guardar"/>
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(ChangePassword);