import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import 'moment/min/locales'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class MotivoCambioTurnosAbm extends Component {
	constructor(props) {
		super(props);
		this.ajaxHandler = new AjaxHandler();
		
		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				id: 0,
				nombre: ''
			},
			errors: [],
			loading: false
		};
		
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		
		this.handleSubmit = this.handleSubmit.bind(this);

		let validators = {
			'formData.nombre': (value) => Validator.notEmpty(value)
		}

		if(ConfigBusiness.get('tareas.montoAutoAprobacion.habilitado') === 'true'){
			validators['formData.nombre'] = (value) => Validator.notEmpty(value)
		}

		this.formValidation = new FormValidation({
			component: this,			
			validators: validators
		});
	
  	}


  	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}

	componentWillMount() {
		if ((Security.hasPermission('MOTIVO_CAMBIO_TURNO_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('MOTIVO_CAMBIO_TURNO_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('MOTIVO_CAMBIO_TURNO_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
  	}
  	initForm() {
		this.setState({ loading: true });
		let component = this;
		
		Promise.all([
			this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/motivocambioturnos/' + this.state.props.match.params.id) : null,
		]).then((data) => {
			let formData = data[0];
			if(formData) component.setState({
				formData: formData
			});

		}).catch(function(error) {
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
		});
	
	}
 
	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  	}

	handleSubmit(event) {
		this.setState({ loading: true });
		let component = this
		this.ajaxHandler.fetch('/motivocambioturnos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
			method: this.props.action === 'ADD' ? 'POST' : 'PUT',
			body: JSON.stringify({
				...this.state.formData
			}),
		}).then(response => {
			if(response.status !== 400) {
				
				component.exit();
			} else {
				response.json()
				.then(data => {
				this.setState({
					errors: data.detalle
				});
				});
			}
			window.scrollTo(0,0);
		}).catch((error) => {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			this.setState({ loading: false });
		});
		event.preventDefault();
	}


	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/motivocambioturnos'
		});
	}

	render() {
		this.formValidation.validate();
		let formData = this.state.formData;
		let validationState = this.formValidation.state;
		let state = this.state;
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}				
				<div className="row">
					<div className="col-md-12">
						<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
							<div className="form-body">
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">      
											<h4 className="form-section">
												<i className="icon-home"></i><FormattedMessage id="tareasAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="tareasAbm.render.general_data.campos_requeridos" defaultMessage="campos requeridos"/></div>
											</h4>
											<div className="row">
												{/* Motivo */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="nombre">
															<FormattedMessage id="Motivo" defaultMessage="Motivo"/>*:
														</label>
														<div className="col-md-9">
															{this.props.action === 'VIEW' ? (
																<div className="form-control-static col-form-label form-value">{formData.nombre}</div>
															) : (
																<div>
																	<input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'Motivo', defaultMessage: 'Motivo' })} value={formData.nombre} onChange={this.handleInputFormChange} />   
																	<div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
																</div>
															)}															
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">     
											<div className="text-cd text-right">
												{this.props.action !== 'VIEW' && (
													<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
														<i className="fa fa-check-circle"></i><FormattedMessage id="tareasAbm.finish_button.guardar" defaultMessage=" Guardar"/>
													</button>
												)}
												<button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
													<i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'tareasAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'tareasAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(MotivoCambioTurnosAbm);