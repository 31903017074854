import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import TicketsGestoriaAbm from './TicketsGestoriaAbm'
import {FormattedMessage, injectIntl} from 'react-intl';

class TicketsGestoria extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
						<h3 className="content-header-title"><i className="icon-briefcase ml-1 mr-1 align-middle"></i><FormattedMessage id="ticketsGestoria.header_generacion_de_tickets_de_gestoria" defaultMessage="Generación de Tickets de Gestoría"/></h3>
					</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}/add`} component={(props) => <TicketsGestoriaAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/add/movil/:movilId/:movilDominio`} component={(props) => <TicketsGestoriaAbm {...props} action="ADD" />} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default TicketsGestoria;