import React, { Component } from 'react'
import '../../assets/css/styles.css'

class Bienvenido extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirectTo: null
		}
	}

	render() {
	    return (
	    	<React.Fragment>
					<img src={process.env.PUBLIC_URL + "/images/bienvenido.png"} style={{width: '100%',height: '100%',position: 'absolute',objectFit: 'contain'}}/>
			</React.Fragment>
	    );
  	}
}

export default Bienvenido;