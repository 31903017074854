import React, {Component} from 'react';
import Switch from "react-switch"
import PropTypes from 'prop-types';

/*const stringOrNode = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.node,
]);
*/
class GravatarOption extends Component{
	/*propTypes: {
		
  },*/
  constructor(props) {
	super(props);
	
	this.handleMouseDown = this.handleMouseDown.bind(this);
	this.handleMouseEnter = this.handleMouseEnter.bind(this);
	this.handleMouseMove = this.handleMouseMove.bind(this);
	this.handleSinFacturaChange = this.handleSinFacturaChange.bind(this);
  }
	handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		if(this.props.option.id !== 3 )
			this.props.onSelect(this.props.option, event);
	}
	handleMouseEnter (event) {
		if(this.props.option.id !== 3 )
			this.props.onFocus(this.props.option, event);
	}
	handleMouseMove (event) {
		if (this.props.isFocused) 
			return;
		if(this.props.option.id !== 3 )
			this.props.onFocus(this.props.option, event);
	}

	handleSinFacturaChange(event){
		//this.props.onSelect(this.props.option, event);
		this.props.option.sinFacturaChange(event);
	}

	componentDidMount(){
		//console.log(this.props);
	}

	render () {
		
		let gravatarStyle = {
			borderRadius: 3,
			display: 'inline-block',
			marginRight: 10,
			position: 'relative',
			top: -2,
			verticalAlign: 'middle',
		};
		return (
		<React.Fragment>
			
			<div className={this.props.className}
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				
				> 
				{this.props.option.id === 3 ? //solo con factura 
				<Switch
					onChange={this.handleSinFacturaChange}
					checked={this.props.option.soloFactura === "false" ? false : true}
					id="factura"
					name="factura"
					offColor="#FF4961"
					onColor="#28D094"
					height={15}
					width={30}
					className="switch-factura"
				/>
				:
				""}
				{this.props.children}
			</div>
		</React.Fragment>
		);
	}
}export default GravatarOption


GravatarOption.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	isFocused: PropTypes.bool,
	isSelected: PropTypes.bool,
	onFocus: PropTypes.func,
	onSelect: PropTypes.func,
	option: PropTypes.object.isRequired,
  };