import { getThemeProps } from '@material-ui/styles';
import React from 'react'
import { FormattedMessage } from 'react-intl';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

let viewCount = '';
ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? (
    viewCount = "col-xl-2 col-lg-6 col-12 cards-5"
) : (
    viewCount = "col-xl-3 col-lg-6 col-12 cards-4"
)

const TicketCards = ({estadisticas, handleFilter, unclickable}) => {

  return (
    <section id="minimal-gradient-statistics-bg" className={unclickable ? "ticketWidget unclickable" : "ticketWidget"}>
            <div className="row justify-content-around">
                <div className={viewCount} style={{cursor: 'pointer'}} onClick={() => handleFilter('PREVENTIVO')}>
                    <div className="card bg-gradient-directional-preventivo">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="media d-flex">
                                    <div className="align-self-center">
                                        <i className="ft-check-circle text-white font-large-2 float-left"></i>
                                    </div>
                                    <div className="media-body text-white text-right">
                                        <h3 className="text-white">{estadisticas && estadisticas.cantidadPreventivos ? estadisticas.cantidadPreventivos : '0' }</h3>
                                        <span><FormattedMessage id="ticketsGrid.render.button_filter_preventivos" defaultMessage="Preventivos"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={viewCount} style={{cursor: 'pointer'}} onClick={() => handleFilter('CORRECTIVO')}>
                    <div className="card bg-gradient-directional-correctivo">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="media d-flex">
                                    <div className="align-self-center">
                                        <i className="icon-wrench text-white font-large-2 float-left"></i>
                                    </div>
                                    <div className="media-body text-white text-right">
                                        <h3 className="text-white">{estadisticas && estadisticas.cantidadCorrectivos ? estadisticas.cantidadCorrectivos : '0' }</h3>
                                        <span><FormattedMessage id="ticketsGrid.render.button_filter_correctivos" defaultMessage="Correctivos"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={viewCount} style={{cursor: 'pointer'}} onClick={() => handleFilter('VENCIMIENTO')}>
                    <div className="card bg-gradient-directional-vencimiento">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="media d-flex">
                                    <div className="align-self-center">
                                        <i className="icon-credit-card font-size-3-5rem text-white float-left"></i>
                                    </div>
                                    <div className="media-body text-white text-right">
                                        <h3 className="text-white">{estadisticas && estadisticas.cantidadVencimientos ? estadisticas.cantidadVencimientos : '0' }</h3>
                                        <span><FormattedMessage id="ticketsGrid.render.button_filter_vencimientos" defaultMessage="Vencimientos"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? (
                    <div className={viewCount} style={{cursor: 'pointer'}} onClick={() => handleFilter('GESTORIA')}>
                    <div className="card bg-gradient-directional-gestoria">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="media d-flex">
                                    <div className="align-self-center">
                                        <i className="icon-briefcase font-size-3-5rem text-white float-left"></i>
                                    </div>
                                    <div className="media-body text-white text-right">
                                        <h3 className="text-white">{estadisticas && estadisticas.cantidadGestoria ? estadisticas.cantidadGestoria : '0' }</h3>
                                        <span><FormattedMessage id="ticketsGrid.render.button_filter_gestorias" defaultMessage="Gestorias"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    ) : (
                        null
                    )}
                    <div className={viewCount} style={{cursor: 'pointer'}} onClick={() => handleFilter('EN_TALLER')}>
                    <div className="card bg-gradient-directional-en-taller">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="media d-flex">
                                    <div className="align-self-center">
                                        <i className="fas fa-warehouse text-white font-large-2 float-left"></i>
                                    </div>
                                    <div className="media-body text-white text-right">
                                    <h3 className="text-white">{estadisticas && estadisticas.cantidadEnTaller ? estadisticas.cantidadEnTaller : '0' }</h3>
                                        <span><FormattedMessage id="ticketsGrid.render.button_filter_en_taller" defaultMessage="En Taller"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default TicketCards