import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import '../palette-gradient.css';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';


am4core.useTheme(am4themes_animated);

class Pie extends Component{   
  
constructor(props) {
  super(props);

  this.ajaxHandler = new AjaxHandler();

 moment.locale('es');
 const {intl } = props


this.state = {
  redirectTo: null,
  props: this.props,
  noHayDatos: false,	
	valores: null	
}
  //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);
  this.chart = null;
  this.pieSeries = null;
}

componentWillReceiveProps(nextProps){
  if(nextProps.datos != this.state.valores){
		if(nextProps.datos != null){
			this.chart.data=nextProps.datos;
			this.setState({valores: nextProps.datos, noHayDatos: false});
		} else{
				this.setState({valores: null, noHayDatos: true});
		}
	}
}

componentDidMount(){  

  let paleta = [am4core.color('#fe9657'),am4core.color('#4dc4bc'),am4core.color('#67b7dc')];
  // Color set
  let colors = new am4core.ColorSet(paleta);

  if(this.chart){
    this.chart.dispose();
  }
   
  this.chart = am4core.create("pchartdiv", am4charts.PieChart);
  this.chart.width = am4core.percent(100);
  this.chart.height = 100;
  
  //this.chart.data=this.props.datos;
let color = colors.getIndex(3);

this.pieSeries = this.chart.series.push(new am4charts.PieSeries());
this.pieSeries.dataFields.value = "value";
this.pieSeries.dataFields.category = this.props.intl.formatMessage({id: "labelId", defaultMessage: "tipo"});
this.pieSeries.slices.template.stroke = am4core.color('#fff');
this.pieSeries.slices.template.strokeWidth = 2;
this.pieSeries.slices.template.strokeOpacity = 1;

this.pieSeries.colors.list = paleta;
    /*for(let dato in datos.datos){
        this.pieSeries.colors.list.push(am4core.color(paleta[datos.datos[dato]['gasto']]));
    }*/

// This creates initial animation
this.pieSeries.hiddenState.properties.opacity = 1;
this.pieSeries.hiddenState.properties.endAngle = -90;
this.pieSeries.hiddenState.properties.startAngle = -90;
  
}

componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

render() {
  let props = this.state.props;
  return (
      <div id="pchartdiv" style={{ width: "40%", height: "150px" , marginLeft:0}} hidden={this.state.noHayDatos === true}></div>        
  );
}

} export default Pie;
