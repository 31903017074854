import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import LlantaInspeccionesAbm from "./LlantaInspeccionesAbm";
import LlantaInspeccionesGrid from "./LlantaInspeccionesGrid.js";

class LlantaInspecciones extends Component {
  render() {
    return (
      <div className="content-wrapper llantas-modulo">
        <div className="content-header row">
          <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title">
              <FormattedMessage
                id="llantasInspecciones.header_listado_inspecciones"
                defaultMessage="Listado de Inspecciones"
              />
            </h3>
          </div>
        </div>
        <div className="content-body">
          <Switch>
            <Route exact path={`${this.props.match.url}`} component={LlantaInspeccionesGrid}/>
              <Route exact path={`${this.props.match.url}/add`} render={(props) => <LlantaInspeccionesAbm {...props} action="ADD" />} />
              <Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <LlantaInspeccionesAbm {...props} action="EDIT" />} />
              <Route path={`${this.props.match.url}/:id`} render={(props) => <LlantaInspeccionesAbm {...props} action="VIEW" />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default LlantaInspecciones;