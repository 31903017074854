import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import PopoverGeneral from '../../../commons/popover/popover'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../../lang/backendStrings.js';
am4core.useTheme(am4themes_animated);

class SolidGauge extends Component{   
  
constructor(props) {
  super(props);

  this.ajaxHandler = new AjaxHandler();

 moment.locale('es');


this.state = {
  redirectTo: null,
  props: this.props,
  primeraVez: true,
  valores: null
  
  };

  //this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);
this.chart = null;
this.categoryAxis = null;
this.valueAxis = null;
this.series1 = null;
this.series2 = null;
}

componentWillReceiveProps(nextProps){ 
  if(nextProps.datos != this.state.valores){ 
    
    if(this.state.primeraVez){
      this.initData(nextProps.id, nextProps.datos);
      this.setState({primeraVez: false});
    } 
    else{
      if(nextProps.datos != null){
        this.chart.data=nextProps.datos;
        this.setState({valores: nextProps.datos});
      } else{
          this.setState({valores: null});
      }
    }
	}

}

componentDidMount(){
    //Si los datos llegan junto con la creación del elemento no pasan por willReceiveProps entonces no se dibuarían si no se hace esto
    if(this.state.primeraVez){
        this.initData(this.state.props.id, this.state.props.datos);
        this.setState({primeraVez: false,valores:this.state.props.datos});
    }
}

initData(id, datos){
  // Create chart instance
  if(this.chart){
    this.chart.dispose();
  }
this.chart = am4core.create("sgchartdiv"+id, am4charts.RadarChart);
/*
// Add data
chart.data = [{
  "category": "Research",
  "value": 80,
  "full": 100
}, {
  "category": "Marketing",
  "value": 35,
  "full": 100
}, {
  "category": "Distribution",
  "value": 92,
  "full": 100
}, {
  "category": "Human Resources",
  "value": 68,
  "full": 100
}];
*/
this.chart.data = datos;

// Make chart not full circle
this.chart.startAngle = -90;
this.chart.endAngle = 180;
this.chart.innerRadius = am4core.percent(20);

// Set number format
this.chart.numberFormatter.numberFormat = "#.#'%'";

// Create axes
this.categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
this.categoryAxis.dataFields.category = "estado";
this.categoryAxis.renderer.grid.template.location = 0;
this.categoryAxis.renderer.grid.template.strokeOpacity = 0;
this.categoryAxis.renderer.labels.template.horizontalCenter = "right";
this.categoryAxis.renderer.labels.template.fontWeight = 500;
this.categoryAxis.renderer.labels.template.fontSize = 12;
let charts = this.chart;
this.categoryAxis.renderer.labels.template.adapter.add("fill", function(fill, target) {
  return (target.dataItem.index >= 0) ? charts.colors.getIndex(target.dataItem.index) : fill;
});
this.categoryAxis.renderer.minGridDistance = 10;

this.valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
this.valueAxis.renderer.grid.template.strokeOpacity = 0;
this.valueAxis.min = 0;
this.valueAxis.max = 100;
this.valueAxis.strictMinMax = true;

// Create series
this.series1 = this.chart.series.push(new am4charts.RadarColumnSeries());
this.series1.dataFields.valueX = 100;
this.series1.dataFields.categoryY = "estado";
this.series1.clustered = false;
this.series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
this.series1.columns.template.fillOpacity = 0.08;
this.series1.columns.template.cornerRadiusTopLeft = 20;
this.series1.columns.template.strokeWidth = 0;
this.series1.columns.template.radarColumn.cornerRadius = 20;

if(this.props.datos){
  var label = this.series1.createChild(am4core.Label);
  label.text = "[bold]"+this.props.datos[0].total;
  label.align = "middle";
  label.verticalCenter = "middle"
  label.horizontalCenter = "middle"
}

this.series2 = this.chart.series.push(new am4charts.RadarColumnSeries());
this.series2.dataFields.valueX = "porc";
this.series2.dataFields.categoryY = "estado";
this.series2.clustered = false;
this.series2.columns.template.strokeWidth = 0;
this.series2.columns.template.tooltipText = "{cantidad} - {estado}: [bold]{porc}[/]";
this.series2.columns.template.radarColumn.cornerRadius = 20;

this.series2.columns.template.adapter.add("fill", function(fill, target) {
  return charts.colors.getIndex(target.dataItem.index);
});

// Add cursor
this.chart.cursor = new am4charts.RadarCursor();
  
}   
  
  componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

render() {
  let props = this.state.props;
  return (
    <div className="card pull-up">    
      <div className="card-content">
        <div className="card-header dashboard-title">
          <h4 className="card-title"><FormattedMessage {...(backendStrings[this.props.cardTitle] ? backendStrings[this.props.cardTitle] : backendStrings['errors.default'])}/> </h4>
          <PopoverGeneral icon="icon-info" text={this.props.text}></PopoverGeneral> 
        </div>
        <div className="card-body">
          <div id={"sgchartdiv"+this.props.id} style={{ width: "100%", height: "250px" }}></div>
        </div>
      </div>      
    </div>
  );
}

} export default SolidGauge;

