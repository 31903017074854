import Popover from 'react-simple-popover';
import React, { Component } from 'react';
 
class PopoverGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
 
  handleClick(e) {
    this.setState({open: !this.state.open});
  }
 
  handleClose(e) {
    this.setState({open: false});
  }
 
  render() {
    return (
      
        <div className="button-popover" onClick={this.handleClick.bind(this)}>
        <i ref="target" className={this.props.icon + " success info-popover"}></i>
        <Popover
          placement='right'
          //style={{backgroundColor:'black', position:'absolute'}}
          //containerStyle={{position:'relative'}}//, left: '0', top: '-100px', boxShadow:'none'}}
          container={this}
          target={this.refs.target}
          show={this.state.open}
          onHide={this.handleClose.bind(this)} >
          <p>{this.props.text}</p>
        </Popover>
        </div>
    );
  }
}export default PopoverGeneral;