import React, { Component } from 'react'
import InstanciaBasicConAdjunto from './InstanciaBasicConAdjunto.js'
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';

const ImageThumbnail = styled.img.attrs(props => ({
		src: props.src,
	}))`
	width: 120px;
	height: 120px;
	object-fit: cover;
	margin: 2px;
  `;

class InstanciaImagen extends InstanciaBasicConAdjunto {
	constructor(props) {
		super(props);
  }

	render() {
      return (
        <React.Fragment>
					<div className="form-control">
							{
								this.props.ocultarInput ? (Array.isArray(this.state.adjuntoUrlArray) && this.state.adjuntoUrlArray.length === 0) && 'Sin imagen' : 
								<input
									disabled={this.props.disabled || this.props.previsualizacion}
									type="file"
									accept="image/*"
									multiple
									onChange={(e) => {this.handleInputChange(e, this.props.intl)}}
								/>
							}

							{this.state.adjuntoUrlArray.map((url, i) => <a key={i} href={url} target="_blank"><ImageThumbnail src={url} /></a>)}
							{this.state.adjunto && (
								<>
									<hr/>
									{this.state.adjunto_url &&  <img className="img-fluid" src={this.state.adjunto_url}/>}
									<label className="btn-sm">{this.state.adjunto.archivoNombre}</label>
									{! this.props.disabled &&

										<button
											className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid pull-right"
											onClick={(e) => this.handleChangeAndUpdateFather(null)}
											title="Eliminar" >
												<i className="fa fa-trash fa-xs"></i>
										</button>
									}
								</>
							)}
					</div>
					{Array.isArray(this.state.adjuntoArray) && this.state.adjuntoArray.length == 0 && ( 
					<small><FormattedMessage id='instanciaAdjunto.tamano_maximo_de_archivo' defaultMessage='Tamaño máximo de archivo 20MB.'/></small>
					)}
        </React.Fragment>
      )
  }
}

export default InstanciaImagen;
