import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import Security from '../../commons/security/Security.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'

import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PerfilesGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.columnsToPrint = [0];

		this.state = {
			redirectTo: null
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('PERFILES_LISTAR')) {
	      	this.ajaxHandler.subscribe(this);
			this.initGrid();
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	initGrid() {
		window.scrollTo(0, 0);

		let component = this;
		this.table = $(this.refs.grid).DataTable(
			Object.assign({
				dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
				stateSave: true,
				stateSaveCallback: function(settings, data) {
					localStorage.setItem('DataTables_PerfilesGrid', JSON.stringify(data));
				},
				stateLoadCallback: function(settings) {
					return JSON.parse(localStorage.getItem('DataTables_PerfilesGrid'));
				},
				ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + '/perfiles',
					headers: {
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function(response) {
						return response;
					},
					error: function(xhr, error, thrown) {
						component.ajaxHandler.handleResponseErrorsIsValid(xhr);
					},
					cache: false
				},
				initComplete: function(settings) {
					let stateColumns = JSON.parse(localStorage.getItem('DataTables_PerfilesGrid')).columns;

					var sets = settings;
					var index = 0;

					this.api().columns().every(function() {
						var column = this;

						if (sets.aoColumns[index].filterType) {
							if (sets.aoColumns[index].filterType === 'select') {
								var select = $('<select class="btn-dt-filter" required><option value="" disabled hidden selected>'+component.props.intl.formatMessage({ id: 'perfilesGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
									.appendTo($(column.footer()).empty())
									.on('change', function() {
										var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
											.search(val ? '^' + val + '$' : '', true, false)
											.draw();
									});
								column.data().unique().sort().each(function(d, j) {
									select.append('<option value="' + d + '">' + d + '</option>')
								});
							}
							if (sets.aoColumns[index].filterType === 'input') {
								var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'perfilesGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
								if (stateColumns[index].search.search) input.val(stateColumns[index].search.search);
								input.appendTo($(column.footer()).empty());
								input.on('keyup change', function() {
									if (column.search() !== this.value) {
										column
											.search(this.value)
											.draw()
											.ajax.reload(null, false);
									}
								});
							}
						}
						index++;
						return '';
					});

					$('tfoot tr').appendTo('thead');
				},
				columns: [{
					title: component.props.intl.formatMessage({ id: 'perfilesGrid.column_profile.label_perfil', defaultMessage: 'Perfil' }),
					data: 'nombre',
					className: 'all',
					filterType: 'input'
				}, {
					orderable: false,
					data: null,
					className: "text-center all",
					width: '120px',
					render: function(data, type, full, meta) {
						let html =  
							(Security.hasPermission('PERFILES_VISUALIZAR') ? `
							<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'perfilesGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
								<i class="fa fa-search fa-xs"></i>
							</button>` : '') + 
							(Security.hasPermission('PERFILES_MODIFICAR') ? `
							<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'perfilesGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" data-togle="tooltip" data-placement="top">
								<i class="fa fa-pencil fa-xs"></i>
							</button>` : '') +
							(Security.hasPermission('PERFILES_DUPLICAR') ? `
							<button class="action duplicate btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'perfilesGrid.column_actions.button_title_duplicar', defaultMessage: 'Duplicar' })+`" data-togle="tooltip" data-placement="top">
								<i class="fas fa-copy"></i>
							</button>` : '') +
							(Security.hasPermission('PERFILES_ELIMINAR') ? `
							<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'perfilesGrid.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`" data-togle="tooltip" data-placement="top">
								<i class="fa fa-trash fa-xs"></i>
							</button>` : '');
						return html;
					},
					createdCell: function(td, cellData, rowData, row, col) {
						$(td).find('button').tooltip();
					},
					filterType: 'none'
				}],
				drawCallback: function() {
					$(this).find('.action').on('click', function() {
						let data = component.table.row($(this).parents('tr')).data();
						let redirectTo;
						if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
						if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
						if ($(this).hasClass('duplicate')) redirectTo = component.props.match.url + '/' + data.id + '/duplicate';
						component.setState({
							redirectTo: redirectTo
						});

						if ($(this).hasClass('delete')) {
							swal({
									title: component.props.intl.formatMessage({ id: 'perfilesGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'perfilesGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'perfilesGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/perfiles/' + data.id, {
												method: 'DELETE',
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											})
											.then(function(response) {
												component.table.ajax.reload(null, false);
												swal(component.props.intl.formatMessage({ id: 'perfilesGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
											})
											.catch(function(error) {
												swal(component.props.intl.formatMessage({ id: 'perfilesGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
											});
									}
								});
						}
					});
				},
				processing: true
			}, datatablesConfig(this.props.intl))
		);

		new $.fn.dataTable.Buttons(this.table, {
			buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 0, 'asc' ]);
						dt.ajax.reload();
					}
				}, {
				extend: 'print',
				name: 'print',
				text: '<i class="ft-printer"></i>',
				title: '',
				customize: function(win) {
					var css = '@page { size: landscape; }',
						head = win.document.head || win.document.getElementsByTagName('head')[0],
						style = win.document.createElement('style');

					style.type = 'text/css';
					style.media = 'print';

					if (style.styleSheet) {
						style.styleSheet.cssText = css;
					} else {
						style.appendChild(win.document.createTextNode(css));
					}

					head.appendChild(style);

					$(win.document.body)
						.css('font-size', '10pt')
						.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="` + LogoCompany + `" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="` + LogoVecFleet + `" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'perfilesGrid.print_content.header_listado_de_perfiles', defaultMessage: 'Listado de Perfiles' })+`</h2>
														</div>
													</div>
												</div>`);

					$(win.document.body).find('table')
						.addClass('compact')
						.css('font-size', 'inherit');
				},
				exportOptions: {
					columns: component.columnsToPrint
				},
				titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
			}, {
				extend: 'colvis',
				name: 'columns',
				text: '<i class="la la-columns"></i>',
				titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
			}, {
				extend: 'collection',
				name: 'export',
				text: '<i class="ft-upload"></i>',
				titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.select_title_exportar', defaultMessage: 'Exportar' }),
				buttons: [{
					extend: 'copyHtml5',
					exportOptions: {
						columns: ':visible'
					},
					text: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.select_copiar', defaultMessage: 'Copiar' })
				}, {
					extend: 'excelHtml5',
					exportOptions: {
						columns: ':visible'
					},
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.select_exportar_a_excel', defaultMessage: 'Exportar a Excel' })
				}, {
					extend: 'csvHtml5',
					exportOptions: {
						columns: ':visible'
					},
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.select_exportar_en_formato_csv', defaultMessage: 'Exportar en formato .csv' })
				}, {
					extend: 'pdfHtml5',
					exportOptions: {
						columns: ':visible'
					},
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
				}]
			}, {
				name: 'import',
				text: '<i class="ft-download"></i>',
				titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_import.select_importar', defaultMessage: 'Importar' })
			}, ]
		});

		this.table.buttons(0, null).container().appendTo('#buttons');

		this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
		this.table.button('print:name')
			.nodes()
			.attr('data-toggle', 'tooltip')
			.attr('data-position', 'top');
		this.table.button('columns:name')
			.nodes()
			.attr('data-toggle', 'tooltip')
			.attr('data-position', 'top');
		this.table.button('export:name')
			.nodes()
			.attr('data-toggle', 'tooltip')
			.attr('data-position', 'top');
		this.table.button('import:name')
			.nodes()
			.attr('data-toggle', 'tooltip')
			.attr('data-position', 'top');
		$('[data-toggle="tooltip"]').tooltip();

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	render() {
	    return (
			<React.Fragment>
			{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
			<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
											{Security.renderIfHasPermission('PERFILES_CREAR', (
											<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'perfilesGrid.render.button_add.title_nuevo', defaultMessage: 'Nuevo' })}>
												<i className="ft-plus"></i>
											</div>
											))}
										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
								<div className="table-responsive">
									<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
										<tfoot>
										<tr style={{backgroundColor: '#fff'}}>
											<th className="dt-search-header"></th>
											<th className=""></th>
										</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</React.Fragment>
	    );
  	}
}

export default injectIntl(PerfilesGrid);