import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import '../../assets/css/styles.css'
import Config from '../../commons/config/Config.js';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class AzureCallback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            errors: [],
            loading: true,
        }

        this.loginRandom = null;
    }

    componentWillMount () { }

    handleGoHome(e) {
        this.setState({
            redirectTo: '/'
        });
        e.preventDefault();
    }

    componentDidMount () {
        document.body.className = 'vertical-layout vertical-menu 1-column menu-expanded blank-page blank-page';
        document.body.setAttribute('data-col', '1-column');

        const query = new URLSearchParams(this.props.location.search)
        const az_code = query.get('code')
        const az_state = query.get('state')

        if (!az_code || !az_state) {
            this.setState({
                loading: false,
                errors: [ 'azureCallback.errors.faltan_parametros_validar' ]
            })
        } else {
            fetch(Config.get('apiUrlBase') + '/public/auth/azure/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": az_code,
                    "state": az_state,
                }),
            }).then(response => {
                if(response.status === 200) {
                    response.json()
                        .then(data => {
                            if(data && data.usuario && data.usuario.token) {
                                localStorage.setItem('persona', JSON.stringify(data));
                                localStorage.setItem('token', data.usuario.token);
                                // window.location.reload();
                                window.location.href = '/'
                            } else {
                                this.setState({
                                    loading: false,
                                    errors: [ 'azureCallback.errors.token_no_encontrado_servidor' ]
                                })
                            }
                        });
                } else if(response.status === 401) {
                    response.json()
                        .then(data => {
                            this.setState({ errors: data.detalle });
                        });
                } else {
                    this.setState({
                        loading: false,
                        errors: [ 'azureCallback.errors.pedir_token_usuario' ]
                    })
                }
            }).catch((error) => {
                this.setState({
                    loading: false,
                    errors: [ 'azureCallback.errors.lado_del_servidor' ]
                })
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    render() {
        if(!this.loginRandom){
            this.loginRandom = 1 + Math.floor((4 - 1) * Math.random());
        }
        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row">
                        </div>
                        <div className="content-body">
                            <video className="video-login-bg" autoPlay muted loop type="video/mp4" poster={process.env.PUBLIC_URL + "/images/images-login/image"+ this.loginRandom +".png"}>
								<source src={process.env.PUBLIC_URL + "/images/videos-login/video"+ this.loginRandom +".mp4"} type="video/mp4"/>
								<img src={process.env.PUBLIC_URL + "/images/images-login/image"+ this.loginRandom +".png"} alt="Video no soportado"/>
                            </video>
                            <section className="flexbox-container">
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <div className="col-md-4 col-10 box-shadow-2 p-0">
                                        <div className="card border-grey border-lighten-3 px-2 py-2 m-0">

                                            <div id="loginLogo" className="card-header border-0 text-center">
                                                <img src="/images/company/logo-login-app.png" alt="" className="rounded-circle img-fluid center-block" />
                                            </div>
                                            <p id="loginSubtitle" className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
                                            </p>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    { this.state.loading ?
                                                        (<div className="row">
                                                            <div className="col-12 text-center">
                                                                <p style={{fontSize: "1rem"}}><FormattedMessage id="azureCallback.information.finalizando_proceso_autenticacion" defaultMessage="Finalizando el proceso de autenticación"/><br/><FormattedMessage id="azureCallback.information.aguarde_instantes" defaultMessage=" Aguarde unos instantes"/></p>
                                                                <img height="100px" src="/images/loading.svg"/>
                                                            </div>
                                                        </div>) :
                                                        (<div className="row">
                                                            <div className="col-12 text-center">
                                                                { this.state.errors.length > 0 ?
                                                                        (
                                                                            <div>
                                                                                <p style={{fontSize: "1rem"}}><FormattedMessage id="azureCallback.errors.information.proceso_autenticación" defaultMessage="Ocurrió un error en el proceso de autenticación. "/><br/><br/><FormattedMessage id="azureCallback.errors.information.contactese_con_administrador" defaultMessage=" Si el problema persiste, póngase en contacto con un administrador"/></p>
                                                                                <p id="loginSubtitle" className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-3"></p>
                                                                                <div>
                                                                                    <button className="buttonAsLink card-link primary" onClick={this.handleGoHome.bind(this)}><i className="la la-chevron-circle-left"></i> <span className="align-top"><FormattedMessage id="azureCallback.information.volver_inicio" defaultMessage="Volver al inicio"/></span></button>
                                                                                </div>
                                                                            </div>
                                                                        ):
                                                                        (<p style={{fontSize: "1rem"}}><FormattedMessage id="azureCallback.information.autenticacion_exitosa" defaultMessage="Autenticación exitosa. Pronto será redirigido..."/></p>)
                                                                }
                                                            </div>
                                                        </div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(AzureCallback);
