import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import LlantasGrid from "./LlantasGrid.js";
import LlantasAbm from "./LlantasAbm";
import { FormattedMessage, injectIntl } from "react-intl";
import "./llantas.css";
class Llantas extends Component {
  render() {
    return (
      <div className="content-wrapper llantas-modulo">
        <div className="content-header row">
          <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title">
              <FormattedMessage
                id="llantas.header_administracion_marcas"
                defaultMessage="Administración de Llantas"
              />
            </h3>
          </div>
        </div>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              component={LlantasGrid}
            />
            <Route
              exact
              path={`${this.props.match.url}/add`}
              render={(props) => <LlantasAbm {...props} action="ADD" />}
            />
            <Route exact path={`${this.props.match.url}/:id/select`} component={LlantasGrid} />
            <Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <LlantasAbm {...props} action="EDIT" />} />
            <Route
              path={`${this.props.match.url}/:id`}
              render={(props) => <LlantasAbm {...props} action="VIEW" />}
            />

          </Switch>
        </div>
      </div>
    );
  }
}

export default Llantas;
