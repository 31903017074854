import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	TablePagination,
	CircularProgress,
	Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import Security from '../../commons/security/Security.js'
import 'xlsx/dist/xlsx.full.min.js'
import MotivoCambioTurnosGridRow from './MotivoCambioTurnosGridRow'
import MotivoCambioTurnosGridFiltros from './MotivoCambioTurnosGridFiltros'
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";

var pdfMake = require('pdfmake/build/pdfmake.js')
var pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

const PAGINATION_INIT ={
	page: 0,
	count: 0,
	perPage: 10,
}

const FILTER_QUERY_INIT = {
	nombre:"",
	showInactivos: '',
}

const SORT_QUERY_INIT = {
	nombre:"",
	id: ""
}

const MotivoCambioTurnosGrid = (props) => {
	const intl = useIntl()

	const [loading, setLoading] = useState(false)
	const [redirectTo, setRedirectTo] = useState(null)
	
	const [searchActive, setSearchActive] = useState(false)
	const [pagination, setPagination] = useState({ ...PAGINATION_INIT })
	const [paginationCount, setPaginationCount] = useState(0);

	const [filterResetInputs,setFilterResetInputs] = useState(false)
	const [filterQuery, setFilterQuery] = useState(null)
	
	const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT })

	const [dropdownActive, setDropdownActive] = useState(false);
	
	const [motivos, setMotivos] = useState([])
	//Security Permissions
	const [permissions,setPermissions] = useState({
		motivosCrear:false,
		motivosVisualizar:false,
		motivosModificar:false,
		motivosEliminar:false,
	})

	useEffect(() => {
		setLoading(true)
		if (Security.hasPermission("MOTIVO_CAMBIO_TURNO_LISTAR")) {
			securityPermission()
			filterQuery && fetchMotivos()

		} else {
			setRedirectTo("/error")
		}
		//componentWillUnmount
		return () => { }
	}, [pagination,filterQuery,sortQuery])

	const securityPermission = () => {
		let aux = {...permissions}
			aux.motivosCrear = Security.hasPermission('MOTIVO_CAMBIO_TURNO_CREAR')
			aux.motivosVisualizar = Security.hasPermission('MOTIVO_CAMBIO_TURNO_VISUALIZAR')
			aux.motivosModificar = Security.hasPermission('MOTIVO_CAMBIO_TURNO_MODIFICAR')
			aux.motivosEliminar = Security.hasPermission('MOTIVO_CAMBIO_TURNO_ELIMINAR')
		setPermissions(aux)   
	}

	const fetchMotivos = () => {
		Promise.all([
			Utils.getData(`/motivocambioturnos${getQuery()}`),
		])
		.then((data) => {
			setMotivos(data[0].motivos)
			setLoading(false)
			setPaginationCount(data[0].pagination.count)
		})
		.catch((err) => {
			setLoading(false)
		})
	};

	const getQuery = () => {
		//pagination
		let myQuery = "?page=" + pagination.page
		myQuery += "&perPage=" + pagination.perPage

		//filter
		myQuery += '&nombre=' + filterQuery.nombre;
		myQuery += "&showInactivos=" + filterQuery.showInactivos;

		//sort
		myQuery += '&nombreSort=' + sortQuery.nombre;
		myQuery += '&idSort=' + sortQuery.id;

		return myQuery;
	}

	const handleChangePage = (event, newPage) => {
		setPagination({ ...pagination, page: newPage });
	}

	const handleChangeRowsPerPage = (event) => {
		setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
	}

	const requestSort = (key) => {
		let query = { ...sortQuery }
		let aux = sortQuery[key];
		
		query["nombre"] = "";
		
		pagination.page = 0;
		
		if (!aux) query[key] = "asc";
		else if (aux === "desc") query[key] = "asc";
		else query[key] = "desc";
		setSortQuery(query);
	};

	const handleFilterReset = () => {
		setPagination(PAGINATION_INIT);
		setFilterQuery(FILTER_QUERY_INIT);
		setDropdownActive(false);
		setSortQuery(SORT_QUERY_INIT);
		setSearchActive(false);
		setFilterResetInputs(true)
	}

	const handleFilterChange = () => {
		setPagination(prevState => ({ ...prevState, page: 0 }));
	}

	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<div className="card">
				<div className="card-content collpase show">
					<div className="card-body card-dashboard">
						<div className="container-fluid mb-1">
							<div className="row dt-icons">
								<div className="col">
									{permissions.motivosCrear && 
										<StyledTooltip
											title={intl.formatMessage({
												id: 'Nuevo',
												defaultMessage: 'Nuevo',
											})}
											arrow
											placement="right"
										>
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												style={{ height: 55 }}
												onClick={() => setRedirectTo(props.match.url + '/add')}
											>
												<i className="ft-plus"></i>
											</div>
										</StyledTooltip>
									}
									<div
										className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
										style={{ height: 52 }}
										onClick={() => setDropdownActive(!dropdownActive)}
									>
										{dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
									</div>
								</div>
								<div className="col">
									{searchActive && (
										<div className="btn-dt-main round-icon">
											<div
												className="text-center"
												style={{
													height: '25px',
													borderRadius: '3px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingLeft: '8px',
													backgroundColor: '#5AAC43',
													color: 'white',
												}}
											>
												<div
													className="text-center"
													style={{
														width: '100%',
														fontSize: '12px',
														fontWeight: '500',
													}}
												>
													{motivos.length} de {paginationCount} resultados de busqueda
												</div>
												<div className="resultados-busqueda-notif">
													<i
														className="las la-times la-xs cursor-pointer"
														onClick={()=>handleFilterReset()}
													></i>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col"></div>
							</div>
						</div>
					
						<MotivoCambioTurnosGridFiltros
							dropdownActive={dropdownActive}
							setDropdownActive={setDropdownActive}
							filterResetInputs={filterResetInputs}
							setFilterResetInputs={setFilterResetInputs}
							filterQuery={filterQuery}
							setFilterQuery={setFilterQuery}
							searchActive={searchActive}
							setSearchActive={setSearchActive}
							pagination={pagination}
							handleFilterChange={handleFilterChange}
						/>
						<TableContainer>
							<Table aria-label="clases table" className="table-hover">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left" onClick={()=>requestSort("id")} style={{ width: "50px"}}>
											<FormattedMessage id="Id" defaultMessage="Id" />
											<img className="ml-1"
												src={sortQuery.id === "" ? Sort : sortQuery.id === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>
										<StyledTableCell>
										</StyledTableCell>
										<StyledTableCell align="left" onClick={()=>requestSort("nombre")} style={{ cursor: "pointer" }}>
											<FormattedMessage id="Motivo" defaultMessage="Motivo" />
											<img className="ml-1"
												src={sortQuery.nombre === "" ? Sort : sortQuery.nombre === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>
										<StyledTableCell align="center" style={{ width: "150px" }}>
											<FormattedMessage id="movilesGrid.rennder.column_acciones.label" defaultMessage="Acciones" />
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading && (
										<TableRow>
											<StyledTableCell colSpan={14} align="center">
												<CircularProgress />
											</StyledTableCell>
										</TableRow>
									)}
									{!loading &&
										(motivos.length > 0 ?
											motivos.map((motivo) => (
											<MotivoCambioTurnosGridRow
												intl={intl}
												permissions={permissions}
												key={'motivosGridRow_' + motivo.id}
												baseUrl = {props.match.url} 
												motivo={motivo}
												fetchMotivos={fetchMotivos}
											/>
										))
										:
										<TableRow>
											<StyledTableCell colSpan={14} align="center">
												<FormattedMessage id="movilesGrid.rennder.no_data_table" defaultMessage="No se encontraron resultados..." />
											</StyledTableCell>
										</TableRow>
										)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50, 100]}
							component="div"
							count={paginationCount}
							rowsPerPage={pagination.perPage}
							page={pagination.page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage="Mostrar"
							labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default MotivoCambioTurnosGrid
													
