import React, { Component } from 'react'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import 'react-select/dist/react-select.css'
import Select from 'react-select'
import Switch from "react-switch"
import {FormattedMessage, injectIntl} from 'react-intl';
import Security from '../../commons/security/Security.js'

class MovilesAbmGPS extends Component {
    constructor(props) {
        super(props)
        this.props = props
        this.ajaxHandler = new AjaxHandler()

        this.state = {
            modelOptions: [],
            modelsLoading: false,
            formData: {
                brand: null,
                model: null,
                forceAvlOdometer: false,
                sync_avl: true,
            },
        }

        this.getBackendBrands = this.getBackendBrands.bind(this)
        this.getBackendModels = this.getBackendModels.bind(this)
        this.handleBrandChange = this.handleBrandChange.bind(this)
        this.handleModelChange = this.handleModelChange.bind(this)
        this.handleForceAvlOdometerChange = this.handleForceAvlOdometerChange.bind(this);
        this.handleSyncAvlChange = this.handleSyncAvlChange.bind(this);
    }

    componentWillMount() {
        this.ajaxHandler.subscribe(this)
        this.initFormData()
    }

    componentWillUnmount() {
        this.ajaxHandler.unsubscribe()
    }

    initFormData() {
        let component = this
        let formDataCopy = JSON.parse(JSON.stringify(component.state.formData))
        if (this.props.movil.gps_model) {
            formDataCopy['model'] = component.props.movil.gps_model
            formDataCopy['brand'] = component.props.movil.gps_model.brand
        }
        formDataCopy['forceAvlOdometer'] = component.props.movil.force_avl_odometer
        formDataCopy['sync_avl'] = component.props.movil.sync_avl

        this.setState({
            formData: formDataCopy
        }, () => {
            if (this.state.formData.brand) {
                return this.ajaxHandler.getJson('/gps_devices/models/simple-search?name=&brand=' + this.state.formData.brand.id).then(res => {
                    this.setState({
                        modelsLoading: false,
                        modelOptions: res
                    })
                })
            } else {
                this.setState({
                    modelsLoading: false,
                    modelOptions: []
                })
            }
        })
    }

    handleBrandChange(brand) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData))
        formDataCopy['brand'] = brand
        formDataCopy['model'] = null
        this.setState({
            modelsLoading: true,
            formData: formDataCopy
        }, () => {
            this.props.callbackUpdate(this.state.formData.model, this.state.formData.forceAvlOdometer, this.state.formData.sync_avl)
            if (this.state.formData.brand) {
                return this.ajaxHandler.getJson('/gps_devices/models/simple-search?name=&brand=' + this.state.formData.brand.id).then(res => {
                    this.setState({
                        modelsLoading: false,
                        modelOptions: res
                    })
                })
            } else {
                this.setState({
                    modelsLoading: false,
                    modelOptions: []
                })
            }
        })
    }

    handleModelChange(model) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData))
        formDataCopy['model'] = model
        this.setState({
            formData: formDataCopy
        }, () => {
            this.props.callbackUpdate(this.state.formData.model, this.state.formData.forceAvlOdometer, this.state.formData.sync_avl)
        })
    }

    getBackendBrands(searchTerm) {
        return this.ajaxHandler.getJson('/gps_devices/brands/simple-search?search=' + searchTerm).then(res => {
            return {
                options: res,
                complete: true
            }
        })
    }

    getBackendModels(searchTerm) {
        if (this.state.formData.brand) {
            return this.ajaxHandler.getJson('/gps_devices/models/simple-search?name=' + searchTerm + '&brand=' + this.state.formData.brand.id).then(res => {
                return {
                    options: res,
                    complete: true
                }
            })
        }
    }

    handleForceAvlOdometerChange(forceAvlOdometer) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData))
        formDataCopy['forceAvlOdometer'] = forceAvlOdometer
        this.setState({
            formData: formDataCopy
        }, () => {
            this.props.callbackUpdate(this.state.formData.model, this.state.formData.forceAvlOdometer, this.state.formData.sync_avl)
        })
    }

    handleSyncAvlChange(sync_avl) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData))
        formDataCopy['sync_avl'] = sync_avl
        this.setState({
            formData: formDataCopy
        }, () => {
            this.props.callbackUpdate(this.state.formData.model, this.state.formData.forceAvlOdometer, this.state.formData.sync_avl)
        })
    }

    render() {
        let state = this.state;

        return (
            <React.Fragment>
                <div className="card pull-up">
                    <div className="card-content pl-1 pr-1">
                        <div className="card-body">
                            <h4 className="form-section">
                                <i className="la la-wifi"></i> <FormattedMessage id="movilesAbmGPS.render.gps_device.header_dispositivo_de_GPS" defaultMessage="Dispositivo de GPS"/>
                            </h4>
                            <div className="row chat-application ml-0 mr-0 mb-1" style={{border: 0}}>
                                <div className="content-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="gps_brand">
                                                    <FormattedMessage id="movilesAbmGPS.render.gps_device.label_marca" defaultMessage="Marca"/>:
                                                </label>
                                                <div className="col-md-9">
                                                    <Select.Async
                                                        id="gps_brand"
                                                        name="gps_brand"
                                                        placeholder={this.props.intl.formatMessage({ id: 'movilesAbmGPS.render.gps_device.placeholder_marca', defaultMessage: 'Buscar marcas' })+"..."}
                                                        value={this.state.formData.brand}
                                                        valueKey="id"
                                                        labelKey="name"
                                                        clearable={true}
                                                        loadOptions={this.getBackendBrands}
                                                        onChange={this.handleBrandChange}
                                                        disabled={this.props.action === 'VIEW'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="gps_model">
                                                    <FormattedMessage id="movilesAbmGPS.render.gps_device.label_modelo" defaultMessage="Modelo"/>:
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        id="gps_model"
                                                        name="gps_model"
                                                        placeholder={this.props.intl.formatMessage({ id: 'movilesAbmGPS.render.gps_device.placeholder_modelo', defaultMessage: 'Buscar modelos' })+"..."}
                                                        value={this.state.formData.model}
                                                        isLoading={this.state.modelsLoading}
                                                        valueKey="id"
                                                        labelKey="name"
                                                        clearable={true}
                                                        options={this.state.modelOptions}
                                                        onChange={this.handleModelChange}
                                                        disabled={this.props.action === 'VIEW' || ! this.state.formData.brand}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label className="col-md-3 label-control col-form-label text-break">
                                                    <FormattedMessage id="movilesAbmGPS.render.gps_device.label_provee_odometro" defaultMessage="Provee odómetro"/>:
                                                </label>
                                                <div className="col-md-9">
                                                    <Switch
                                                        onChange={() => {}}
                                                        checked={(this.state.formData.model) ? this.state.formData.model.provides_odometer : false}
                                                        disabled={true}
                                                        offColor="#FF4961"
                                                        onColor="#28D094"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                      <div className="col-md-6">
                                          <div className="form-group row align-items-center">
                                              <label className="col-md-5 label-control col-form-label text-break" htmlFor="forceAvlOdometer">
                                                  <FormattedMessage id="movilesAbmGPS.render.gps_device.label_medir_km_con_avl" defaultMessage="Medir Kilometraje con AVL"/>:
                                              </label>
                                              <div className="col-md-7">
                                                  <Switch
                                                      onChange={this.handleSyncAvlChange}
                                                      checked={this.state.formData.sync_avl}
                                                      id="sync_avl"
                                                      name="sync_avl"
                                                      disabled={this.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR_KM')}
                                                      offColor="#FF4961"
                                                      onColor="#28D094"
                                                  />
                                              </div>
                                           </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row align-items-center">
                                                <label className="col-md-5 label-control col-form-label text-break" htmlFor="forceAvlOdometer">
                                                    <FormattedMessage id="movilesAbmGPS.render.gps_device.label_forzar_calculo_odometro_con_AVL" defaultMessage="Forzar cálculo odómetro con AVL"/>:
                                                </label>
                                                <div className="col-md-7">
                                                    <Switch
                                                        onChange={this.handleForceAvlOdometerChange}
                                                        checked={this.state.formData.forceAvlOdometer}
                                                        id="forceAvlOdometer"
                                                        name="forceAvlOdometer"
                                                        disabled={this.props.action === 'VIEW'}
                                                        offColor="#FF4961"
                                                        onColor="#28D094"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(MovilesAbmGPS);
