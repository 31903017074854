import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import Security from '../../../commons/security/Security.js'
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import RadarTimeline from './RadarTimeline.js'
import DrillDownTreemap from './DrillDownTreemap.js';
import Pie from './Pie.js';
import ColumnsLine from './ColumnsLine.js';
import Tabla from './Tabla.js';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Loading from '../../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import $ from 'jquery'
import PopoverGeneral from '../../../commons/popover/popover'
import popoverTexts from '../../../commons/popover/popoverText.js'
import GravatarOption from './GravatarOption'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../../lang/backendStrings.js';
import Timezone from '../../../commons/timezone/Timezone.js';

class DashboardAnalitico extends Component {

  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    this.state = {
      drillDownTreemap: [],
      accordions: [],
      radialTimeline: [],
      gastoModelo: [],
      tabla: [],
      gauges: [],
      columnLinePie: [],
      filtroPeriodoInicio: moment().startOf('year').format('YYYY-MM-DD'),
      filtroPeriodoFin: moment().format('YYYY-MM-DD'),
      periodoSelect:  {id:365, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.este_anio', defaultMessage: 'Este año' })},
      tipoTicketSelect: {id:1,label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_type_ticket.select.correctivo', defaultMessage: 'CORRECTIVO' })},
      periodos: [
        {id:12, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.personalizado', defaultMessage: 'Personalizado' })},
        {id:1, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.hoy', defaultMessage: 'Hoy' })},
        {id:2, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.ayer', defaultMessage: 'Ayer' })},
        {id:7, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.ultimos_siete_dias', defaultMessage: 'Últimos 7 días' })},
        {id:14, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.ultimos_catorce_dias', defaultMessage: 'Últimos 14 días' })},
        {id:30, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.este_mes', defaultMessage: 'Este mes' })},
        {id:60, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.mes_pasado', defaultMessage: 'Mes Pasado' })},
        {id:365, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.este_anio', defaultMessage: 'Este año' })},
        {id:400, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.anio_pasado', defaultMessage: 'Año Pasado' })},
        {id:3, label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.select.solo_con_factura', defaultMessage: 'Solo con Factura' }), soloFactura:"false", sinFacturaChange:this.handleSoloFactura.bind(this)}

      ],
      tipoTicket:[
        {id:1,label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_type_ticket.select.correctivo', defaultMessage: 'CORRECTIVO' })},
        {id:2,label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_type_ticket.select.preventivos', defaultMessage: 'PREVENTIVO' })},
        {id:3,label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_type_ticket.select.vencimiento', defaultMessage: 'VENCIMIENTO' })}
      ],
      unidad: '',
      gerenciadorSelect: {value:"null",label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_type_ticket.select.todos', defaultMessage: '--TODOS--' })},
      gerenciadores: null,
      gerenciadorLogged: null,
      soloFactura: false,
      mostrarColumnasGerenciador: true
    };

    this.textos = popoverTexts(this.props.intl);

    this.handleDatePickerPeriodoChange = this.handleDatePickerPeriodoChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
  }


  componentDidMount() {
    $('[data-tooltip="tooltip"]').tooltip({ trigger: "hover" });

    if(Security.hasPermission('DASHBOARD_ANALITICO_VISUALIZAR')) {
      this.ajaxHandler.subscribe(this);
      this.setState({unidad: this.calcularUnidadTiempo()}, ()=>{
          this.initData();
      });


      //this.initForm();

    } else {
        this.setState({
          redirectTo: '/error'
        });
    }

  }

  initData() {
    let component = this;
    this.setState({loading: true});

    Promise.all([
      this.ajaxHandler.getJson('/radial-timeline/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin),
      this.ajaxHandler.getJson('/drill-down-treemap/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin),
      this.ajaxHandler.getJson('/tabla/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.tipoTicketSelect.label+'/null'),
      this.ajaxHandler.getJson('/columnlinepie/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.unidad),
      this.ajaxHandler.getJson('/gerenciadores/select'),

    ]).then((data) => {
      let radialTimeline = data[0];
      let drillDownTreemap = data[1];
      let terminoDrillDown = false;
      let promesasDrillDown = drillDownTreemap.datos.filter(marca => marca.foto_id).map(marca => {
          return this.ajaxHandler.fetch('/adjuntos/files/' + marca.foto_id, {})
          .then(response => {
            if (response.status == 200)
              response.blob().then(blobFile => {
                marca.foto = URL.createObjectURL(blobFile)
              })
          })
          .catch(error => {
            console.log(error);
          })
      })
      Promise.all(promesasDrillDown).then(() => {
        component.setState({
          drillDownTreemap: drillDownTreemap
        })
      })
      let tabla = data[2];
      let columnLinePie = data[3];
      let gerenciadores = data[4];

      if(Security.hasPermission('USUARIO_ACTUAR_COMO_GERENCIADOR')){
        let persona = JSON.parse(localStorage.getItem("persona"));
        let gerenciador = persona.usuario.gerenciador;
        component.setState({
          gerenciadorLogged: gerenciador,
          gerenciadorSelect: {value: gerenciador.id, label:gerenciador.razonSocial}
        });
        gerenciadores.forEach(function(ger) {
          if(ger.value !== gerenciador.id){
            ger.label = 'XXXXXX';
          }
        });
      } else{
        gerenciadores.push({value:'null', label: this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_type_ticket.select_init.todos', defaultMessage: '--TODOS--' })});
      }

      component.setState({
        radialTimeline: radialTimeline,
        tabla: tabla,
        columnLinePie: columnLinePie,
        gerenciadores: gerenciadores
      });
    }).catch(function(error) {
      // TODO: Manejar errores.
      console.log(error);
		}).finally(() => {
			this.setState({ loading: false });
    });
  }


  initDataByPeriod() {

    let component = this;
    this.setState({loading: true, unidad: this.calcularUnidadTiempo()}, ()=>{
    Promise.all([

      this.ajaxHandler.getJson('/radial-timeline/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.soloFactura),
      this.ajaxHandler.getJson('/tabla/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.tipoTicketSelect.label+'/'+this.state.gerenciadorSelect.value+'/'+this.state.soloFactura),
      this.ajaxHandler.getJson('/columnlinepie/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.unidad+'/'+this.state.soloFactura),
      this.ajaxHandler.getJson('/drill-down-treemap/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin),

    ]).then((data) => {

      let radialTimeline = data[0];
      let tabla = data[1];
      let columnLinePie = data[2];
      let drillDownTreemap = data[3];

      component.setState({
        radialTimeline: radialTimeline,
        tabla: tabla,
        columnLinePie: columnLinePie,
        drillDownTreemap: drillDownTreemap,
      });
    }).catch(function(error) {

		}).finally(() => {
			this.setState({ loading: false });
    });
    });
  }

  getData = (stateKey, jsonFile) => {
    fetch(jsonFile, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then(data => {
			this.setState({[stateKey]: data});
		})
		.catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
		});
  }

  handleSelectFormChange(object) {

    // const value = object === null ? null : object.id;
     this.setState({
           periodoSelect: object,
         }, ()=>{

    if(object && object.id !== 12){
      let inicio = "";
      let fin = "";
      switch (object.id) {
        case 1:
           inicio = moment();
           fin = moment();
          break;
       case 2:
         inicio = moment().subtract(1, 'days');
         fin = inicio;
         break;
       case 7:
         inicio = moment().subtract(7, 'days');
         fin = moment();
         break;
       case 14:
        inicio = moment().subtract(14, 'days');
        fin = moment();
         break;
       case 30:
         inicio = moment().startOf('month');
         fin = moment();
         break;
       case 60:
         inicio = moment().subtract(1, 'months').startOf('month');
         fin = moment().subtract(1, 'months').endOf('month');
         break;
       case 400:
         inicio = moment().subtract(1, 'year').startOf('year');
         fin = moment().subtract(1, 'year').endOf('year');
         break;
         default:
         inicio = moment().startOf('year');
         fin = moment();
      }

      inicio = moment(inicio).format('YYYY-MM-DD');
      fin = moment(fin).format('YYYY-MM-DD');
      this.setState({filtroPeriodoInicio: inicio, filtroPeriodoFin: fin}, ()=>this.initDataByPeriod());
    }

   });

   }

   handleSoloFactura(event){

    //en el elemento de periodos que tiene el switch de solo factura, actualizo su valor
    let copiaPeriodos = this.state.periodos.map(x=>{
      if(x.id === 3){
        x.soloFactura = ""+event;
      }
      return x;
    });
    this.setState({periodos: copiaPeriodos, soloFactura: event}, ()=>{this.initDataByPeriod(); });

    return ;
  }

  handleSelectChange(name, object){

    this.setState({loading: true});

    if(name === "gerenciadorSelect" && this.state.gerenciadorLogged !== null) {
      if(object.value !== this.state.gerenciadorLogged.id){
        this.setState({ mostrarColumnasGerenciador: false });
      } else{
        this.setState({ mostrarColumnasGerenciador: true });
      }
    }

    this.setState({[name]: object},()=>{
      let component = this;

      Promise.all([
        this.ajaxHandler.getJson('/tabla/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.tipoTicketSelect.label+'/'+this.state.gerenciadorSelect.value)

      ]).then((data) => {

        let tabla = data[0];

        component.setState({
          tabla: tabla,
        });
      }).catch(function(error) {

      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  }


  handleDatePickerPeriodoChange(name, event){

    let date = event ? event.format('YYYY-MM-DD'): '';

    this.setState({[name]: date});

  }

  calcularUnidadTiempo(){

  let diferencia = moment(this.state.filtroPeriodoFin).diff(moment(this.state.filtroPeriodoInicio),"days");
  let unidad = "";
    if(diferencia <=14){
      unidad = null;
    } else if(diferencia > 14 && diferencia <= 63){
      unidad = "week";
    } else if(diferencia > 63 && diferencia <= 365 ){
      unidad = "month";
    } else{
      unidad = "year";
    }

    return unidad;
  }

  render(){
    let gastoModelo = this.state.gastoModelo;
    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}

        <div className="content-wrapper" style={{ padding:"1.2rem" }}>

        <div className="card card-x-periodo">

    <div className="card-content">
        <div className="card-header card-header-x-periodo">
          <div className="row">
            <div className="col-12">
              <div className="pull-right from-group row">
                  <label className="col-auto" style={{padding:"5px", margin:"5px"}}><FormattedMessage id="dashboardAnalitico.filter_period.label.filtrar_por_período" defaultMessage="Filtrar por período: "/></label>
                  <Select
                    id="periodo"
                    name="periodo"
                    clearable={false}
                    options={this.state.periodos}
                    optionComponent={GravatarOption}
                    valueKey='id'
                    labelKey='label'
                    className="col-sm-6 select-periodo"
                    value={this.state.periodoSelect ? this.state.periodoSelect : null}
                    onChange={(e) => this.handleSelectFormChange(e)}
                   // onSelect={(e)=>this.handleSelect(e)}
                  />
              </div>
              </div>
            </div>
          {this.state.periodoSelect && this.state.periodoSelect.id === 12 ?
          <div className="row" style={{paddingTop:"5px"}}>
            <div className="offset-8 col-4">
              <div className="form-group row">

                <div className="col-4">
                  <DatePicker
                      selected={this.state.filtroPeriodoInicio ? moment(this.state.filtroPeriodoInicio) : null}
                      placeholderText={this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.selected_personalized.start_placeholder', defaultMessage: 'DD/MM/YYYY' })}
                      className="form-control date-picker-placeholder react-datepicker-ignore-onclickoutside"
                      selectsStart
                      startDate={moment(this.state.filtroPeriodoInicio)}
                      openToDate={this.state.filtroPeriodoInicio ? moment(this.state.filtroPeriodoInicio) : moment()}
                      endDate={moment(this.state.filtroPeriodoFin)}
                      maxDate={moment()}
                      onChange={(event)=>this.handleDatePickerPeriodoChange('filtroPeriodoInicio', event)}
                  />
                </div>
                <div className="col-4">
                  <DatePicker
                        selected={this.state.filtroPeriodoFin ? moment(this.state.filtroPeriodoFin) : moment()}
                        placeholderText={this.props.intl.formatMessage({ id: 'dashboardAnalitico.filter_period.selected_personalized.end_placeholder', defaultMessage: 'DD/MM/YYYY' })}
                        className="form-control date-picker-placeholder react-datepicker-ignore-onclickoutside"
                        selectsEnd
                        startDate={moment(this.state.filtroPeriodoInicio)}
                        endDate={this.state.filtroPeriodoFin ? moment(this.state.filtroPeriodoFin) : moment()}
                        openToDate={this.state.filtroPeriodoFin ? moment(this.state.filtroPeriodoFin) : moment()}
                        onChange={(event)=>this.handleDatePickerPeriodoChange('filtroPeriodoFin', event)}
                        minDate={moment(this.state.filtroPeriodoInicio)}
                        maxDate={moment()}
                    />
                </div>
                <div className="col-2 ">
                  <button type="button" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title="Filtrar" onClick={this.initDataByPeriod.bind(this)}><i className="ft-filter"></i></button>
                </div>
              </div>
            </div>
          </div>

            : ""

            }
        </div>
 <div className="card-body">
  <div className="row">
  <div className="card pull-up col-xl-6 col-lg-12 col-12">

    <div className="card-content">
      <div className="card-body">

      <div className="box box-info">
        <div className="box-header with-border dashboard-title col-12" style={{margin: '10px'}}>
          <h3 className="box-title"><FormattedMessage id="dashboardAnalitico.filter_type_ticket.label.tiempos_respuesta" defaultMessage="Tiempos de Respuesta "/></h3>
          <PopoverGeneral icon="icon-info" text={this.textos.tiempo_respuesta}></PopoverGeneral>

        <Select
            id="tipo"
            name="tipo"
            clearable={false}
            options={this.state.tipoTicket}
            valueKey='id'
            labelKey='label'
            className="col-3 select-tipo"
            value={this.state.tipoTicketSelect ? this.state.tipoTicketSelect : null}
            onChange={(e) => this.handleSelectChange('tipoTicketSelect',e)}
          />
          <Select
            id="gerenciador"
            name="gerenciador"
            clearable={false}
            options={this.state.gerenciadores}
            valueKey='value'
            labelKey='label'
            className="col-3 select-tipo"
            value={this.state.gerenciadorSelect ? this.state.gerenciadorSelect : null}
            onChange={(e) => this.handleSelectChange('gerenciadorSelect',e)}
          />
          </div>
        <div className="media d-flex">

          <Tabla tabla={this.state.tabla} soloFactura={this.state.soloFactura} fechaDesde={this.state.filtroPeriodoInicio} fechaHasta={this.state.filtroPeriodoFin} tipo={this.state.tipoTicketSelect} gerenciador={this.state.gerenciadorSelect} mostrarColumnasGerenciador={this.state.mostrarColumnasGerenciador}></Tabla>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div className="card drilldown pull-up col-xl-6 col-lg-12 col-12">
  <div className="card-header dashboard-title" style={{paddingBottom: '0px'}}>
    <h3 className="box-title"><FormattedMessage id="dashboardAnalitico.correctivos_promedio_marca_modelo.label.correctivos_promedio_marca_modelo" defaultMessage="Correctivos: Promedio por Marca/Modelo "/></h3>
    <PopoverGeneral icon="icon-info" text={this.textos.correctivos_marca}></PopoverGeneral>
  </div>
    <div className="card-content">
      <div className="card-body" style={{paddingTop:'0px'}}>
        <div className="media d-flex">
        <DrillDownTreemap cardTitle={this.state.drillDownTreemap ? this.state.drillDownTreemap.cardTitle : null} datos={this.state.drillDownTreemap ? this.state.drillDownTreemap.datos : null}></DrillDownTreemap>
        </div>
      </div>
    </div>
  </div>

    </div>
    <div className="row">
    {Security.renderIfHasAnyPermission('DASHBOARD_GASTOS', (
        <div className="col-xl-12 col-lg-3 col-12">
          <div className="card pull-up">
            <div className="card-header dashboard-title">
              <h3 className="box-title"><FormattedMessage id="dashboardAnalitico.tickets_cerrados.label" defaultMessage="Tickets Cerrados"/>{this.state.soloFactura ? this.props.intl.formatMessage({ id: 'dashboardAnalitico.tickets_cerrados.label.solo_con_factura', defaultMessage: ' (solo con factura)' }) : " "}</h3>
              <PopoverGeneral icon="icon-info" text={this.textos.column_line}></PopoverGeneral>
            </div>
            <div className="card-content">
              <div className="card-body">
                  <Pie datos={this.state.columnLinePie.pie} intl={this.props.intl}></Pie>
                  <ColumnsLine datos={this.state.columnLinePie.columnLine} unidad={this.state.unidad}></ColumnsLine>
              </div>
            </div>
          </div>
        </div>
  ))}

  </div>
  <div className="row">
  <div className="card pull-up col-xl-6 col-lg-12 col-12 radar-card">
  <div className="card-header dashboard-title">
    <h3 className="box-title"><FormattedMessage id="dashboardAnalitico.label.gasto_total" defaultMessage="Gasto total: $"/>{ this.state.radialTimeline[0] ? this.state.radialTimeline[0].sumaTotal : null } {this.state.soloFactura ? this.props.intl.formatMessage({ id: 'dashboardAnalitico.label.gasto_total.informatioadditional.solo_factura', defaultMessage: ' (solo con factura)' }) : " "} </h3>
    <PopoverGeneral icon="icon-info" text={this.textos.radar_gasto_servicio}></PopoverGeneral>
    </div>
    <div className="card-content">
      <div className="card-body">
        <div className="media d-flex">
          <RadarTimeline valores={this.state.radialTimeline[0]} id="1" fechaDesde={this.state.filtroPeriodoInicio} fechaHasta={this.state.filtroPeriodoFin} name='gasto-total'></RadarTimeline>
        </div>
      </div>
    </div>
  </div>

  <div className="card pull-up col-xl-6 col-lg-12 col-12 radar-card">
    <div className="card-header dashboard-title">
    <h3 className="box-title"><FormattedMessage id="dashboardAnalitico.label.gasto_por_antiguedad" defaultMessage="Gasto por Antigüedad de Vehículo"/>{this.state.soloFactura ? this.props.intl.formatMessage({ id: 'dashboardAnalitico.label.gasto_por_antiguedad.informatioadditional.solo_factura', defaultMessage: ' (solo con factura)' }) : " "} </h3>
    <PopoverGeneral icon="icon-info" text={this.textos.radar_gasto_anio} ></PopoverGeneral>
    </div>
    <div className="card-content">
      <div className="card-body">
        <div className="media d-flex">
          <RadarTimeline valores={this.state.radialTimeline[1]} id="2" fechaDesde={this.state.filtroPeriodoInicio} fechaHasta={this.state.filtroPeriodoFin} name='gasto-marca'></RadarTimeline>
        </div>
      </div>
    </div>
  </div>

  </div>

  </div>
  </div>
  </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(DashboardAnalitico);
