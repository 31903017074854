import React, { Component, useState } from "react";
import { Redirect } from "react-router-dom";
import Security from "../../commons/security/Security.js";
import "bootstrap4-duallistbox";
import "bootstrap4-duallistbox/dist/bootstrap-duallistbox.css";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Loading from "../ui/Loading.js";
import "react-select/dist/react-select.css";
import moment from "moment";
import "moment/min/locales";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";
import "../../assets/css/vec-dropzone.css";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import Timezone from '../../commons/timezone/Timezone.js';

class EamDetalle extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
      },
      errors: [],
      loading: false,
    };
  }

  componentWillMount() {
    if (Security.hasPermission("ROPS_VISUALIZAR")) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: "/error",
      });
    }
    
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;
    Promise.all([
      this.ajaxHandler.getJson('/rops/eam/' + this.state.props.ticketId)
    ])
      .then((data) => {
        let eam = data[0];
        let actividades = [];
				let repuestos = [];
        let formData = '';
				if (!eam) {
          formData =  null
        } else {
          if (eam.actividades) {
            eam.actividades.map(function (itemActividad) {
              let actividad = {
                codigo: itemActividad.idActividad,                          
                actividad: itemActividad.actividad,
                precio: itemActividad.precio
              }
              if((eam.estadoOrdenReparacion === 'CERRADA' && itemActividad.tipo == 'O') || (eam.estadoOrdenReparacion != 'CERRADA' && itemActividad.tipo == 'C')) actividades.push(actividad);
    
              if (itemActividad.parts) {
                itemActividad.parts.map(function (part) {
                    let repuesto = {
                      sku: part.idRepuesto,
                      repuesto: part.repuesto,
                      cantidad: part.cantidad,
                      precio: part.precio
                    }
                    if((eam.estadoOrdenReparacion === 'CERRADA' && part.tipo == 'O') || (eam.estadoOrdenReparacion != 'CERRADA' && part.tipo == 'C')) repuestos.push(repuesto);
                }.bind(this));
              }
            }.bind(this));
				  }        
          formData = {
            equipo: eam.movil.dominio,
            cliente: eam.movil.companiaOrigen,
            taller: eam.taller,
            fechaPrometida: eam.fechaPrometida,
            cotizacion: eam.numeroCotizacion,
            version_cot: eam.ultimaCotizacion,
            fechaCotizacion: eam.fechaCotizacion,
            estadoCotizacion: eam.estadoCotizacion,
            razonReparacion: eam.razonReparacionCotizacion ? eam.razonReparacionCotizacion.descripcion : '',
            or: eam.numeroOrdenReparacion,
            fechaOrdenReparacion: eam.fechaOrdenReparacion,
            estadoOrdenReparacion: eam.estadoOrdenReparacion,
            actividades: actividades, 
            repuestos: repuestos
          }
        }
      component.setState({formData: formData});
    })
      .catch(function (error) {
        component.ajaxHandler.handleError(error);
      })
      .finally(() => {
        component.setState({ loading: false });
      });
  }

  clickViewEvent(ticketId){
    const miState = {...this.state};
    miState.redirectTo = "/tickets/"+ticketId+"/edit";
    this.setState(miState);
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleCancel(event) {
    window.history.back();
  }

  exit() {
    this.setState({
      redirectTo: "/rop",
    });
  }

  render() {
    let formData = this.state.formData;
    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        {!formData ? (
        <div className="row">
          <div className="col-md-12 text-center">
            <h4>
              <FormattedMessage
                id="eamDetalle.datos_generales.no_result"
                defaultMessage="No se encontraron detalles para el ticket"
              />
            </h4>
          </div>  
        </div>  
        ) : (
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage
                    {...(backendStrings[e]
                      ? backendStrings[e]
                      : backendStrings["errors.default"])}
                  />
                </li>
              ))}
            </div>
            <form
              className="form form-horizontal"
              ref="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i>
                        <FormattedMessage
                          id="eamDetalle.datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="equipo"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_equipo"
                                defaultMessage="Equipo:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.equipo ? formData.equipo : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="cliente"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_cliente"
                                defaultMessage="Cliente:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.cliente ? formData.cliente : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="taller"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_taller"
                                defaultMessage="Taller:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.taller ? formData.taller : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="fechaPrometida"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_fecha_prometida"
                                defaultMessage="Fecha Prometida:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.fechaPrometida ? Timezone.getDateForClient(formData.fechaPrometida, null, 'DD/MM/YYYY HH:mm') : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="razonReparacion"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_razonReparacion"
                                defaultMessage="Razón de Reparación:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.razonReparacion? formData.razonReparacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                      </div>
											<h4 className="form-section">
                        <i className="la la-file-text"></i>
                        <FormattedMessage
                          id="eamDetalle.cotizacion"
                          defaultMessage=" Cotización"
                        />
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="cotizacion"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_numero_cotizacion"
                                defaultMessage="Número:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.cotizacion ? formData.cotizacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="version"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_version_cotizacion"
                                defaultMessage="Versión:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.version_cot ? formData.version_cot : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="fecha_cotizacion"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_fecha_cotizacion"
                                defaultMessage="Fecha:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.fechaCotizacion ? formData.fechaCotizacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="estado_cotizacion"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_estado_cotizacion"
                                defaultMessage="Estado:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.estadoCotizacion ? formData.estadoCotizacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
											<h4 className="form-section">
                        <i className="la la-wrench"></i>
                        <FormattedMessage
                          id="eamDetalle.orden_reparacion"
                          defaultMessage=" Orden de Reparación"
                        />
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="numero_or"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_numero_or"
                                defaultMessage="Número:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.or ? formData.or : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="fecha_or"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_fecha_or"
                                defaultMessage="Fecha:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.fechaOrdenReparacion ? formData.fechaOrdenReparacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="estado_or"
                            >
                              <FormattedMessage
                                id="eamDetalle.datos_generales.label_estado_or"
                                defaultMessage="Estado:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.estadoOrdenReparacion ? formData.estadoOrdenReparacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.loading && <Loading />}
                {this.state.formData.actividades ? (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="la la-tasks"></i><FormattedMessage id="eamDetalle.actividades.header_actividades" defaultMessage=" Actividades" />
                        </h4>
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table">
                              <thead className="thead-fleet">
                                <tr>
                                  <th scope="col"><FormattedMessage id="eamDetalle.actividades.table_column_codigo" defaultMessage="Código" /></th>
                                  <th scope="col"><FormattedMessage id="eamDetalle.actividades.table_column_actividad" defaultMessage="Actividad" /></th>
                                  <th scope="col"><FormattedMessage id="eamDetalle.actividades.table_column_precio" defaultMessage="Precio" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.formData.actividades.map((actividad, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="align-middle">{actividad.codigo}</td>
                                      <td className="align-middle">{actividad.actividad}</td>
                                      <td className="align-middle">{actividad.precio}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (null)}
								{this.state.formData.repuestos ? (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="la la-cog"></i><FormattedMessage id="eamDetalle.actividades.header_repuestos" defaultMessage=" Repuestos" />
                        </h4>
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table">
                              <thead className="thead-fleet">
                                <tr>
                                  <th scope="col"><FormattedMessage id="eamDetalle.repuestos.table_column_sku" defaultMessage="Código" /></th>
                                  <th scope="col"><FormattedMessage id="eamDetalle.repuestos.table_column_repuesto" defaultMessage="Repuesto" /></th>
																	<th scope="col"><FormattedMessage id="eamDetalle.repuestos.table_column_cantidad" defaultMessage="Cantidad" /></th>
                                  <th scope="col"><FormattedMessage id="eamDetalle.repuestos.table_column_precio_unitario" defaultMessage="Precio Unitario" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.formData.repuestos.map((repuesto, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="align-middle">{repuesto.sku}</td>
                                      <td className="align-middle">{repuesto.repuesto}</td>
																			<td className="align-middle">{repuesto.cantidad}</td>
                                      <td className="align-middle">{repuesto.precio}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (null)}
                {/*JSON.stringify(this.state)*/}
              </div>
            </form>
          </div>
        </div>
        )}
      </React.Fragment>
    );
  }
}

export default injectIntl(EamDetalle);
