import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import * as Utils from '../../commons/utils/Utils';

const LlantaBasesSelect = (props) => {
  const [bases, setBases] = useState(null);
  const [basesLoading, setBasesLoading] = useState(false);
  const [base, setBase] = useState(null);

  useEffect(() => {
    if (props.value) {
      setBase(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    setBasesLoading(true);
    Utils.getData('/bases/simple-search?search=').then((res) => {
      if(props.elementDefault){
        setBase(props.elementDefault);
        props.changeBase(props.elementDefault);
      }
      setBases(res);
      setBasesLoading(false);
    });
  }, []);

  useEffect(() => {
    props.inheritBase && setBase(props.inheritBase);
  }, [props.inheritBase]);

  const handleFormChangeSelectObject = (e) => {
    setBase(e);
    props.changeBase(e);
  };

  const handleInputBaseChange = (e) => {
    setBasesLoading(true);
    Utils.getData('/bases/simple-search?search=' + e).then((res) => {
      setBases(res);
      setBasesLoading(false);
    });
  };
  return (
    <>
      <Select
        name="base-llantas"
        id="base-llantas"
        className="select-personal-llantas z-15"
        maxMenuHeight={10}
        placeholder={props.intl.formatMessage({
          id: 'llantaDesasignar.select_placeholder_base_label',
          defaultMessage: 'Base',
        })}
        options={bases}
        labelKey="descripcion"
        valueKey="id"
        value={base}
        onChange={handleFormChangeSelectObject}
        onInputChange={handleInputBaseChange}
        isLoading={basesLoading}
        isDisabled={basesLoading || props.disabled}
        disabled={props.disabled}
      />
    </>
  );
};

export default LlantaBasesSelect;
