import moment from 'moment';
import 'moment-timezone';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

class Timezone {
	static getDateForClient(data, format) {
		if(!data)
			return data;
		var dataMoment = moment.tz(data, "America/Argentina/Buenos_Aires");
		let timezoneDefault = ConfigBusiness.get('frontend.timezoneDefault');
		if(format)
			return dataMoment.tz(timezoneDefault).format(format);
		else 
			return dataMoment.tz(timezoneDefault);
	}

	static getDateForServer(data) {
		if(!data)
			return data;
		var dataMoment = moment(data).tz('America/Argentina/Buenos_Aires');
		return dataMoment;
	}

	static getDateForClient(data, formatIn, formatOut) {
		if(!data)
			return data;
		if(formatIn)
			data = moment(data, formatIn).format('YYYY-MM-DD HH:mm:ss');
		var dataMoment = moment.tz(data, "America/Argentina/Buenos_Aires");
		let timezoneDefault = ConfigBusiness.get('frontend.timezoneDefault');
		if(formatOut)
			return dataMoment.tz(timezoneDefault).format(formatOut);
		else 
			return dataMoment.tz(timezoneDefault);
	}
}

export default Timezone;