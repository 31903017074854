import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import { CircularProgress } from '@material-ui/core'
import ConfigBusiness from "../../commons/config/ConfigBusiness.js"
import * as Utils from '../../commons/utils/Utils'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import swal from 'sweetalert2'
import Timezone from "../../commons/timezone/Timezone.js"
import * as H from '../../commons/utils/Helpers'

// PASOS CIERRE = 'SELECT_ACCION' -> 'INFORMAR_REALIZADO'* -> 'RENOVACION_VTO'* ->'INFORMAR_TALLER'* -> 'ENCUESTA'* -> 'SUBMIT'
// PASOS CANCEL = 'SELECT_ACCION' -> 'COMENTARIO_CANCELACION' -> 'SUBMIT'

const PENDIENTES_INIT = { 'correctivos': [], 'preventivos': [], 'vencimientos': [], 'gestoria': [] }
const FECHAS_INIT = { realizado: null, inicio: null, taller: null }
const ENCUESTA_INIT = { value: null, comment: '' }

const popupStyle = {
	position: "absolute",
	top: "60px",
	backgroundColor: "lightgray",
	borderRadius: "10px",
	padding: "1rem",
	overflow: 'visible'
}

const popupContainerStyle = {
	width: "400px",
	maxHeight: "450",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	overflow: 'visible'
}

const CB = {
	informaFechaInicioReparacion: ConfigBusiness.get('tickets.informaFechaInicioReparacion.habilitado') === 'true',
	informaFechaFinReparacion: ConfigBusiness.get('tickets.informaFechaFinReparacion.habilitado') === 'true',
	informaFechaSalidaTaller: ConfigBusiness.get("tickets.informaFechaSalidaTaller.habilitado") === 'true',
	encuestaCorrectivo: ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion.correctivo") === 'true',
	encuestaPreventivo: ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion.preventivo") === 'true',
	encuestaVencimiento: ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion.vencimiento") === 'true',
}
const showNavButtons = ['cancelar', 'informaFechaRealizado', 'renovacionVto', 'informaFechaTaller', 'encuesta']

const dateIsValid = (date, time = false) => {

	// TO-DO: Check validacion de fechas en backsapce

	let format = time ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"
	return moment(date, format, true).isValid()
}

const AccionesMasivas = (props) => {
	const intl = useIntl()
	const tickets = props.tickets
	const CONFIG_INIT = {
		id: "accionesMasivas",
		init: 'sinAcciones',
		states: {
			sinAcciones: {
				on: { UPDATE_ACCIONES: "seleccionarAccion" }
			},
			seleccionarAccion: {
				on: {
					LISTO_PARA_RETIRAR: 'submit',
					A_RECOTIZAR: 'submit',
					A_CONFIRMAR: 'submit',
					APROBAR: "aprobar",
					CANCELAR: "cancelar",
					CERRAR: "cerrar",
					// SUBMIT: "submit",
					BACK: "sinAcciones"
				},
				entry: () => {
					handleClearData()
				}
			},
			// LISTO_PARA_RETIRAR: {
			// 	on: { NEXT: "submit", BACK: "seleccionarAccion", SUBMIT: "submit" },
			// 	// entry: () => {
			// 	// 	send('SUBMIT')
			// 	// }
			// },
			// A_RECOTIZAR: {
			// 	on: { BACK: "seleccionarAccion", SUBMIT: "submit" },
			// 	entry: () => {
			// 		send('SUBMIT')
			// 	}
			// },
			// A_CONFIRMAR: {
			// 	on: { BACK: "seleccionarAccion", SUBMIT: "submit" },
			// 	entry: () => {
			// 		send('SUBMIT')
			// 	}
			// },
			informaFechaRealizado: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", SUBMIT: "submit" }
		},
			aprobar: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", CLEAR: "seleccionarAccion" },
				entry: () => {
					send('NEXT')
				}
			},
			cancelar: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", CLEAR: "seleccionarAccion" }
			},
			renovacionVto: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", CLEAR: "seleccionarAccion" }
			},
			informaFechaTaller: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", CLEAR: "seleccionarAccion" }
			},
			encuesta: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", CLEAR: "seleccionarAccion" }
			},
			cerrar: {
				on: { NEXT: "submit", BACK: "seleccionarAccion", CLEAR: "seleccionarAccion" },
				entry: () => {send('NEXT')}
			},
			submit: {
				on: { CLEAR: "sinAcciones" },
				entry: (context) => {
					props.setAMLoader(true)
					console.log(`Submit for: ${context.actionSelected}`)

					if (context.actionSelected === 'LISTO_PARA_RETIRAR') {
						handleListoParaRetirar(context.tickets['LISTO_PARA_RETIRAR'])
					}

					if (context.actionSelected === 'A_RECOTIZAR') {
						handleARecotizar(context.tickets['A_RECOTIZAR'])
					}

					if (context.actionSelected === 'A_CONFIRMAR') {
						handleAConfirmar(context.tickets['A_CONFIRMAR'])
					}

					if (context.actionSelected === 'APROBAR') {
						handleAprobar(context.tickets['APROBAR'])
					}

					if (context.actionSelected === 'CANCELAR') {
						handleCancelar({ tickets: context.tickets['CANCELAR'], comentario: context.comentarioCancelacion })
					}
					if (context.actionSelected === 'CERRAR') {
						handleCerrar(context)
					}

				}
			}
		}
	}

	const [actionSelected, setActionSelected] = useState('')
	const [comentarioCancelacion, setComentarioCancelacion] = useState('')
	const [config, setConfig] = useState(CONFIG_INIT)
	const [encuesta, setEncuesta] = useState(ENCUESTA_INIT)
	const [fechas, setFechas] = useState(FECHAS_INIT)
	const [listadoCorrectivos, setListadoCorrectivos] = useState([])
	const [listadoGestoria, setListadoGestoria] = useState([])
	const [listadoPreventivos, setListadoPreventivos] = useState([])
	const [listadoVencimientos, setListadoVencimientos] = useState([])
	const [pendientesFechaRealizado, setPendientesFechaRealizado] = useState(PENDIENTES_INIT)
	const [vtoRenovacionByServicio, setVtoRenovacionByServicio] = useState({})

	let context = {
		actionSelected: actionSelected,
		comentarioCancelacion: comentarioCancelacion,
		encuesta: encuesta,
		fechas: fechas,
		listadoCierre: [...listadoCorrectivos, ...listadoPreventivos, ...listadoVencimientos],
		listadoGestoria: listadoGestoria,
		tickets: tickets,
		vtoRenovacionByServicio: vtoRenovacionByServicio,
	}

	const [states, send] = H.createMachine(config, context)

	useEffect(() => {
		let t = props.tickets

		if ((t && (t.APROBAR && H.isEmptyArray(t['APROBAR'])) &&
			(t.CERRAR && H.isEmptyArray(t['CERRAR'])) &&
			(t.CANCELAR && H.isEmptyArray(t['CANCELAR'])))) {
			send('BACK')
		} else {
			send('UPDATE_ACCIONES')
		}

		if (t['cierreVencimientosByServicio']) handleRenovacionDetails(t['cierreVencimientosByServicio'])

	}, [props.tickets])


	useEffect(() => {
		if (actionSelected && tickets[actionSelected]) {
			setListadoCorrectivos(tickets[actionSelected].filter(tkt => tickets.byType['CORRECTIVO'].includes(tkt)))
			setListadoPreventivos(tickets[actionSelected].filter(tkt => tickets.byType['PREVENTIVO'].includes(tkt)))
			setListadoVencimientos(tickets[actionSelected].filter(tkt => tickets.byType['VENCIMIENTO'].includes(tkt)))
			setListadoGestoria(tickets[actionSelected].filter(tkt => tickets.byType['GESTORIA'].includes(tkt)))
		} else {
			setListadoCorrectivos([])
			setListadoPreventivos([])
			setListadoVencimientos([])
			setListadoGestoria([])
		}
	}, [actionSelected])

	useEffect(() => {
		if (actionSelected === 'CERRAR') {
			let showInformaFechaRealizado = false, showRenovacionVto = false, showInformaFechaTaller = false, showEncuesta = false

			if (!H.isEmptyArray(tickets['addFechaHoraRealizadoCierre'])) {
				let corr = [], prev = [], vto = []

				tickets['addFechaHoraRealizadoCierre'].map(ticket => {
					listadoCorrectivos.includes(ticket) && corr.push(ticket)
					listadoPreventivos.includes(ticket) && prev.push(ticket)
					listadoVencimientos.includes(ticket) && vto.push(ticket)
				})

				if (corr.length || prev.length || vto.length) {
					setPendientesFechaRealizado({ ...pendientesFechaRealizado, correctivos: corr , preventivos: prev, vencimientos: vto })
				}

				showInformaFechaRealizado = (corr.length + prev.length + vto.length) > 0
			}

			if (!H.isEmptyObject(tickets['cierreVencimientosByServicio'])) {
				handleRenovacionDetails(tickets['cierreVencimientosByServicio'])
				showRenovacionVto = true
			}

			if (CB.informaFechaSalidaTaller &&
				!(H.isEmptyArray(listadoCorrectivos) &&
					H.isEmptyArray(listadoPreventivos) &&
					(listadoGestoria.length > 0 ||
						listadoVencimientos.length > 0))) { showInformaFechaTaller = true }

			if (!H.isEmptyArray(listadoCorrectivos) && CB.encuestaCorrectivo ||
				!H.isEmptyArray(listadoPreventivos) && CB.encuestaPreventivo ||
				!H.isEmptyArray(listadoVencimientos) && CB.encuestaVencimiento) {
				showEncuesta = true
			}

			updateWorkflowCierre(showInformaFechaRealizado, showRenovacionVto, showInformaFechaTaller, showEncuesta)

		}

	}, [listadoCorrectivos, listadoPreventivos, listadoVencimientos])

	const updateWorkflowCierre = (showInformaFechaRealizado, showRenovacionVto, showInformaFechaTaller, showEncuesta) => {
		if (!(showInformaFechaRealizado || showRenovacionVto || showInformaFechaTaller || showEncuesta)) {
			return;
		}
		
		let configStates = { ...config.states };
	
		let firstState = showInformaFechaRealizado ? "informaFechaRealizado" : showRenovacionVto ? "renovacionVto" : showInformaFechaTaller ? "informaFechaTaller" : "encuesta";

		configStates['seleccionarAccion'].on = { ...configStates['seleccionarAccion'].on, CERRAR: firstState };
	
		const setTransitions = (state, nextState, backState) => {
			configStates[state].on = { NEXT: nextState, BACK: backState, CLEAR: "seleccionarAccion" };
		};

		const informaFechaTallerToSubmit = showInformaFechaTaller ? "informaFechaTaller" : (showEncuesta ? "encuesta" : "submit")
		const renovacionVtoToSeleccionarAccion = showRenovacionVto ? "renovacionVto" : (showInformaFechaRealizado ? "informaFechaRealizado" : "seleccionarAccion")

		const estados = {
			informaFechaRealizado: {
			  next: showRenovacionVto ? "renovacionVto" : informaFechaTallerToSubmit,
			  back: "seleccionarAccion"
			},
			renovacionVto: {
			  next: informaFechaTallerToSubmit,
			  back: showInformaFechaRealizado ? "informaFechaRealizado" : "seleccionarAccion"
			},
			informaFechaTaller: {
			  next: showEncuesta ? "encuesta" : "submit",
			  back: renovacionVtoToSeleccionarAccion
			},
			encuesta: {
			  next: "submit",
			  back: showInformaFechaTaller ? "informaFechaTaller" : renovacionVtoToSeleccionarAccion
			}
		};
	
		setTransitions("informaFechaRealizado", estados.informaFechaRealizado.next, estados.informaFechaRealizado.back);
		setTransitions("renovacionVto", estados.renovacionVto.next, estados.renovacionVto.back);
		setTransitions("informaFechaTaller", estados.informaFechaTaller.next, estados.informaFechaTaller.back);
		setTransitions("encuesta", estados.encuesta.next, estados.encuesta.back);

		setConfig({ ...config, states: configStates })

	};


	const handleClear = () => {
		console.log('cleaning...')
		props.setShow(false)
		send('CLEAR')
		handleClearData()
		props.setAMLoader(false)
	}

	const handleClearData = () => {
		setActionSelected('')
		setPendientesFechaRealizado(PENDIENTES_INIT)
		setFechas(FECHAS_INIT)
		setEncuesta(ENCUESTA_INIT)
		setComentarioCancelacion('')
		setVtoRenovacionByServicio({})
	}

	const postSubmitActions = () => {
		props.fetchTickets()
		handleClear()
		props.setSelectedTickets([])

	}

	function handleRenovacionDetails(ticketsCierreVencimiento) {
		if (H.isEmptyObject(ticketsCierreVencimiento)) return

		let formDetailsByServicio = {}
		Object.entries(ticketsCierreVencimiento).map((el) => {
			const separatorIdx = el[0].indexOf(' - ')
			const sNombre = el[0].slice(separatorIdx + 3)
			const sId = el[0].slice(0, separatorIdx)
			formDetailsByServicio[sId] = { nombre: sNombre, tickets: el[1], fechaExpedicion: null, fechaVencimiento: null }

		})
		setVtoRenovacionByServicio(formDetailsByServicio)
	}

	const checkDisableFor = (action) => {
		switch (action) {
			case 'cancelar':
				return !comentarioCancelacion
			case 'informaFechaRealizado':
				return !fechas.realizado
			case 'renovacionVto':
				return Object.values(vtoRenovacionByServicio).some((fecha) => fecha.fechaExpedicion == null || fecha.fechaVencimiento == null)
			case 'informaFechaTaller':
				return !fechas.taller
			case 'encuesta':
				return !encuesta.value
			default:
				return false
		}
	}

	const handleCancelar = (data) => {
		props.setAMLoader(true)
		Utils.sendData('/tickets/cancelacionMasiva', 'POST', data)
			.then((res) => {
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.tickets_cancelados', defaultMessage: 'Tickets Cancelados satisfactoriamente' }), '', 'success')
					.then(postSubmitActions)
			}).catch((err) => {
				console.log(err)
			})
	}

	const handleCerrar = ({ fechas, listadoCierre, listadoGestoria, encuesta, vtoRenovacionByServicio , tickets}) => {
		let fechaTaller = fechas.taller ? Timezone.getDateForClient(fechas.taller, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : null
		let fechaInicio = fechas.inicio ? Timezone.getDateForClient(fechas.inicio, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : null
		let fechaRealizado = null

		if (fechas.realizado) {
			if (CB.informaFechaFinReparacion) {
				fechaRealizado = Timezone.getDateForClient(fechas.realizado, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
			} else {
				fechaRealizado = Timezone.getDateForClient(fechas.realizado, 'YYYY-MM-DD', 'YYYY-MM-DD')
			}
		}

		let fechasVencimentoByTicket = []
		let ticketsConRenovacionVencimiento = []
		if (vtoRenovacionByServicio) {
			ticketsConRenovacionVencimiento = Object.values(vtoRenovacionByServicio).flatMap(servicio => servicio.tickets)
			Object.values(vtoRenovacionByServicio).forEach(servicio => {
				servicio.tickets.forEach(ticket => {
					fechasVencimentoByTicket[ticket] = {
						fechaAlta: servicio.fechaExpedicion ? Timezone.getDateForClient(servicio.fechaExpedicion, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : null,
						fechaVencimiento: servicio.fechaVencimiento ? Timezone.getDateForClient(servicio.fechaVencimiento, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : null
					}
				})
			})
		}
		let data = {
			tickets: listadoCierre,
			ticketsGestoria: listadoGestoria,
			addFechaHoraRealizadoCierre: tickets['addFechaHoraRealizadoCierre'],
			fechaRealizado: fechaRealizado,
			fechaInicio: fechaInicio,
			fechaHoraSalidaTaller: fechaTaller,
			encuestaNivelSatisfaccion: encuesta.value,
			encuestaComentariosSatisfaccion: encuesta.comment,
			ticketsConRenovacionVencimiento: ticketsConRenovacionVencimiento,
			fechasVencimentoByTicket: fechasVencimentoByTicket
		}
		console.log(data)
		Utils.sendData('/tickets/cierreMasivo', 'POST', data)
			.then((res) => {
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.tickets_cerrados', defaultMessage: 'Tickets Cerrados satisfactoriamente' }), '', 'success')
					.then(postSubmitActions)
			}).catch((err) => {
				console.log(err)
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.error', defaultMessage: 'Error' }), err.response.data.message, 'error')
			})
	}

	const handleAprobar = (tickets) => {
		Utils.sendData('/tickets/aprobacionMasiva', 'POST', { tickets: tickets })
			.then((res) => {
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.tickets_aprobados', defaultMessage: 'Tickets Aprobados satisfactoriamente' }), '', 'success')
					.then(postSubmitActions)
			}).catch((err) => {
				console.log(err)
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.error', defaultMessage: 'Error' }), err.response.data, 'error')
			})
	}

	const handleListoParaRetirar = (tickets) => {
		Utils.sendData('/tickets/listoParaRetirarMasiva', 'POST', { tickets: tickets })
			.then((res) => {
				swal.fire(intl.formatMessage({ id: 'Tickets_cambiados_a_Listo_para_Retirar_satisfactoriamente', defaultMessage: 'Tickets cambiados a Listo para Retirar satisfactoriamente' }), '', 'success')
					.then(postSubmitActions)
			}).catch((err) => {
				console.log(err)
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.error', defaultMessage: 'Error' }), err.response.data, 'error')
			})
	}

	const handleARecotizar = (tickets) => {
		Utils.sendData('/tickets/aRecotizarMasiva', 'POST', { tickets: tickets })
			.then((res) => {
				swal.fire(intl.formatMessage({ id: 'Tickets_Recotizados_satisfactoriamente', defaultMessage: 'Tickets Recotizados satisfactoriamente' }), '', 'success')
					.then(postSubmitActions)
			}).catch((err) => {
				console.log(err)
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.error', defaultMessage: 'Error' }), err.response.data, 'error')
			})
	}

	const handleAConfirmar  = (tickets) => {
		Utils.sendData('/tickets/aConfirmarMasiva', 'POST', { tickets: tickets })
			.then((res) => {
				swal.fire(intl.formatMessage({ id: 'Tickets_fueron_cambiados_su_estado_a_Confirmar_Reparación', defaultMessage: 'Tickets fueron cambiados su estado a Confirmar Reparación' }), '', 'success')
					.then(postSubmitActions)
			}).catch((err) => {
				console.log(err)
				swal.fire(intl.formatMessage({ id: 'ticketsGrid.accionesMasivas.error', defaultMessage: 'Error' }), err.response.data, 'error')
			})
	}

	return (
		<>
			{props.amLoader &&
				<div style={popupStyle}>
					<div style={{ ...popupContainerStyle, height: '150px', justifyContent: 'center', alignItems: 'center' }}>
						<CircularProgress />
					</div>
				</div>
			}

			<div style={popupStyle} hidden={!props.show} className="text-muted clickable">
				<button type="button" className="close" onClick={(handleClear)}><span>×</span></button>
				{states.current == 'sinAcciones' && (<SinAcciones handleClear={handleClear} />)}

				{states.current == 'seleccionarAccion' && (
					<SeleccionarAccion
						actionSelected={actionSelected}
						setActionSelected={setActionSelected}
						tickets={tickets}
						listado={{ correctivos: listadoCorrectivos, preventivos: listadoPreventivos, vencimientos: listadoVencimientos, gestoria: listadoGestoria }}
						handleClear={handleClear}
						send={send}
					/>
				)}

				{states.current == 'cancelar' && (
					<ComentarioCancelacion comentarioCancelacion={comentarioCancelacion} setComentarioCancelacion={setComentarioCancelacion} />
				)}

				{/* TO-CHECK validaciones fecha inicio y fin, minDate, etc. */}
				{states.current == 'informaFechaRealizado' && (
					< InformaFechaRealizado pendientes={pendientesFechaRealizado} fechas={fechas} setFechas={setFechas} />
				)}

				{states.current == 'renovacionVto' && (
					<RenovacionVencimiento vtoRenovacionByServicio={vtoRenovacionByServicio} setVtoRenovacionByServicio={setVtoRenovacionByServicio} />
				)}
				{states.current == 'informaFechaTaller' && (
					<InformaFechaTaller fechas={fechas} setFechas={setFechas} />
				)}

				{states.current == 'encuesta' && (
					<Encuesta encuesta={encuesta} setEncuesta={setEncuesta} />
				)}
				{showNavButtons.includes(states.current) && (
					<NavButtons send={send} disabled={checkDisableFor(states.current)} />
				)}

			</div>
		</>
	)
}

const Listado = ({ listado, msgCambios }) => {
	const tipoTicketStrings = localStorage.getItem('ticketsTipoStrings') ? JSON.parse(localStorage.getItem('ticketsTipoStrings')) : []
	const style = { display: "flex", flexDirection: "row", maxHeight: "60px" }
	const styleAvatar = { flexShrink: 0 }
	const styleLsit = { display: "flex", alignItems: "center", flexWrap: "wrap" }

	let showMsgCambios = (listado.correctivos && listado.correctivos.length > 0
		|| listado.preventivos && listado.preventivos.length > 0
		|| listado.vencimiento && listado.vencimiento.length > 0
		|| listado.gestoria && listado.gestoria.length > 0
	) && msgCambios

	return (
		<>
			{showMsgCambios &&
				<div className="m1">
					<FormattedMessage id="ticketsGrid.accionesMasivas.cambio_afectara_tickets" defaultMessage="El cambio afectará a los siguentes tickets:" />
				</div>
			}

			{/* CORRECTIVOS */}
			{listado.correctivos && listado.correctivos.length > 0 &&
				<div style={style}>
					<div className="paleta-correctivo avatar dt-user-avatar mt-1 mr-1" style={styleAvatar}>
						{tipoTicketStrings['CORRECTIVO'] ? tipoTicketStrings['CORRECTIVO'].substr(0, 1) : 'C'}
					</div>
					<div className="customScroll mt-1" style={styleLsit}>{listado.correctivos.join(', ')}</div>
				</div>
			}

			{/* PREVENTIVOS */}
			{listado.preventivos && listado.preventivos.length > 0 &&
				<div style={style}>
					<div className="paleta-preventivo avatar dt-user-avatar mt-1 mr-1" style={styleAvatar}>
						{tipoTicketStrings['PREVENTIVO'] ? tipoTicketStrings['PREVENTIVO'].substr(0, 1) : 'P'}
					</div>
					<div className="customScroll mt-1" style={styleLsit}>{listado.preventivos.join(', ')}</div>
				</div>
			}

			{/* VENCIMIENTOS */}
			{listado.vencimientos && listado.vencimientos.length > 0 &&
				<div style={style}>
					<div className="paleta-vencimiento avatar dt-user-avatar mt-1 mr-1" style={styleAvatar}>
						{tipoTicketStrings['VENCIMIENTO'] ? tipoTicketStrings['VENCIMIENTO'].substr(0, 1) : 'V'}
					</div>
					<div className="customScroll mt-1" style={styleLsit}>{listado.vencimientos.join(', ')}</div>
				</div>
			}

			{/* GESTORIA */}
			{listado.gestoria && listado.gestoria.length > 0 &&
				<div style={style}>
					<div className="paleta-gestoria avatar dt-user-avatar mt-1 mr-1" style={styleAvatar}>
						{tipoTicketStrings['GESTORIA'] ? tipoTicketStrings['GESTORIA'].substr(0, 1) : 'G'}
					</div>
					<div className="customScroll mt-1" style={styleLsit}>{listado.gestoria.join(', ')}</div>
				</div>
			}

		</>
	)
}

const NavButtons = ({ send, disabled }) => {
	return (
		<div className="text-cd text-right mt-1 ">
			<button type="button" className="btn btn-secondary mr-1" onClick={() => send('BACK')}>
				<i className="fa fa-chevron-circle-left"></i>
				<FormattedMessage
					id="button.atras"
					defaultMessage=" Atras"
				/>
			</button>
			<button type="button" className="btn btn-primary" disabled={disabled} onClick={() => send('NEXT')}>
				<i className="fa fa-chevron-circle-right"></i>
				<FormattedMessage
					id="button.continuar"
					defaultMessage=" Continuar"
				/>

			</button>
		</div>
	)
}

const SinAcciones = ({ handleClear }) => {
	return (
		<>
			<div style={popupContainerStyle}>
				<h4><FormattedMessage id="ticketsGrid.accionesMasivas.no_actions" defaultMessage="No hay acciones disponibles para la seleccion" /></h4>

				<div className="text-cd text-right mt-1">
					<button type="button" className="btn btn-danger mr-1" onClick={(handleClear)}>
						<i className="fa fa-times-circle"></i>
						<FormattedMessage
							id="button.cancelar"
							defaultMessage=" Cancelar"
						/>
					</button>
				</div>
			</div>
		</>
	)
}

const SeleccionarAccion = ({ actionSelected, setActionSelected, tickets, listado, handleClear, send }) => {
	return (
		<>
			<div style={popupContainerStyle}>
				<h4><FormattedMessage id="ticketsGrid.accionesMasivas.cambiar_estado_a" defaultMessage="Cambiar estado a:" /></h4>
				{(tickets['LISTO_PARA_RETIRAR'] && tickets['LISTO_PARA_RETIRAR'].length > 0) &&
					<div className="text-right popup-option" onClick={() => setActionSelected('LISTO_PARA_RETIRAR')} style={actionSelected == 'LISTO_PARA_RETIRAR' ? { backgroundColor: 'lightblue' } : {}}>
						<i className="pr-1 fa fa-arrow-right"></i>
						<FormattedMessage id="ticketsGrid.accionesMasivas.listo_para_retirar" defaultMessage="Listo para Retirar" />
						<span className="ml-1 badge badge-pill badge-dark">{tickets['LISTO_PARA_RETIRAR'].length}</span>
					</div>
				}
				
				{(tickets['A_RECOTIZAR'] && tickets['A_RECOTIZAR'].length > 0) &&
					<div className="text-right popup-option" onClick={() => setActionSelected('A_RECOTIZAR')} style={actionSelected == 'A_RECOTIZAR' ? { backgroundColor: 'lightblue' } : {}}>
						<i className="pr-1 fa fa-arrow-right"></i>
						<FormattedMessage id="ticketsGrid.accionesMasivas.recotizar" defaultMessage="Recotizar" />
						<span className="ml-1 badge badge-pill badge-dark">{tickets['A_RECOTIZAR'].length}</span>
					</div>
				}
				
				{(tickets['A_CONFIRMAR'] && tickets['A_CONFIRMAR'].length > 0) &&
					<div className="text-right popup-option" onClick={() => setActionSelected('A_CONFIRMAR')} style={actionSelected == 'A_CONFIRMAR' ? { backgroundColor: 'lightblue' } : {}}>
						<i className="pr-1 fa fa-arrow-right"></i>
						<FormattedMessage id="ticketsGrid.accionesMasivas.confirmar_reparación" defaultMessage="Confirmar Reparación" />
						<span className="ml-1 badge badge-pill badge-dark">{tickets['A_CONFIRMAR'].length}</span>
					</div>
				}
				
				{(tickets['APROBAR'] && tickets['APROBAR'].length > 0) &&
					<div className="text-right popup-option" onClick={() => setActionSelected('APROBAR')} style={actionSelected == 'APROBAR' ? { backgroundColor: 'lightblue' } : {}}>
						<i className="pr-1 fa fa-arrow-right"></i>
						<FormattedMessage id="ticketsGrid.accionesMasivas.aprobar" defaultMessage="Aprobar" />
						<span className="ml-1 badge badge-pill badge-dark">{tickets['APROBAR'].length}</span>
					</div>
				}

				{(tickets['CERRAR'] && tickets['CERRAR'].length > 0) &&
					<div className="text-right popup-option" onClick={() => { setActionSelected('CERRAR') }} style={actionSelected == 'CERRAR' ? { backgroundColor: 'lightblue' } : {}}>
						<i className="pr-1 fa fa-arrow-right"></i>
						<FormattedMessage id="ticketsGrid.accionesMasivas.cerrar" defaultMessage="Cerrar" />
						<span className="ml-1 badge badge-pill badge-dark">{tickets['CERRAR'].length}</span>
					</div>
				}

				{(tickets['CANCELAR'] && tickets['CANCELAR'].length > 0) &&
					<div className="text-right popup-option" onClick={() => { setActionSelected('CANCELAR') }} style={actionSelected == 'CANCELAR' ? { backgroundColor: 'lightblue' } : {}}>
						<i className="pr-1 fa fa-arrow-right"></i>
						<span className="text-danger"><FormattedMessage id="ticketsGrid.accionesMasivas.cancelar" defaultMessage="Cancelar" /></span>
						<span className="ml-1 badge badge-pill badge-dark">{tickets['CANCELAR'].length}</span>
					</div>
				}

				<Listado
					msgCambios={true}
					listado={listado}
				/>

				<div className="text-cd text-right mt-1">
					<button type="button" className="btn btn-danger mr-1" onClick={() => handleClear()}>
						<i className="fa fa-times-circle"></i>
						<FormattedMessage
							id="button.cancelar"
							defaultMessage=" Cancelar"
						/>
					</button>
					<button type="button" className="btn btn-primary" disabled={!actionSelected} onClick={() => send(actionSelected)}>
						<i className="fa fa-check-circle"></i>
						<FormattedMessage
							id="button.continuar"
							defaultMessage=" Continuar"
						/>
					</button>
				</div>
			</div>
		</>
	)
}

const ComentarioCancelacion = ({ comentarioCancelacion, setComentarioCancelacion }) => {
	return (
		<>
			<div style={popupContainerStyle}>
				<h4 style={{ whiteSpace: 'normal' }}>
					<FormattedMessage id="ticketHeader.modal_cancel.label_ingrese_un_comentario" defaultMessage="Por favor, ingrese un comentario acerca de la cancelación" />
				</h4>
				<div className="mt-1 mb-1 mx-auto w-100 pl-1 pr-1">
					<textarea onChange={(e) => { setComentarioCancelacion(e.target.value) }} value={comentarioCancelacion} className="form-control" id="comentariosEncuesta" rows="3" />
				</div>
			</div>
		</>
	)
}

const InformaFechaRealizado = ({ pendientes, fechas, setFechas }) => {
	const intl = useIntl()
	return (
		<>
			<div style={popupContainerStyle}>
				<h4>
					<FormattedMessage id="ticketsGrid.accionesMasivas.informar_fecha_realizado" defaultMessage="Informar Fecha de Realizado" />
				</h4>

				<Listado
					msgCambios={true}
					listado={pendientes}
				/>

				<div className="d-flex gap-3 mt-2 mb-1">

					{CB.informaFechaInicioReparacion &&
						<div>
							<label htmlFor="fechaHoraInicioReparacion">
								<FormattedMessage
									id="ticketInformarFechaRealizado.render.report_done_date.label_fecha_hora_inicio_reparacion"
									defaultMessage="Fecha Inicio Reparación"
								/>
							</label>
							<div>
								<DatePicker
									id="fechaHoraInicioReparacion"
									name="fechaHoraInicioReparacion"
									className="form-control date-picker-placeholder"
									placeholderText={intl.formatMessage({ id: 'ticketInformarFechaRealizado.render.report_done_date.placeholder_fecha_realizado', defaultMessage: 'DD/MM/AAAA' })}
									selected={fechas.inicio}
									onChange={e => setFechas({ ...fechas, inicio: e })}
									onChangeRaw={(event) => { dateIsValid(fechas.inicio, true) && setFechas({ ...fechas, inicio: event.target.value }) }}
									dateFormat="DD/MM/yyyy HH:mm"
									showTimeSelect
									timeIntervals={10}
									maxDate={fechas.realizado ? moment(fechas.realizado) : moment()}
									autoComplete="off"
								/>
							</div>
						</div>
					}

					<div>
						<label htmlFor="fechaHoraRealizado">
							{CB.informaFechaFinReparacion ? (
								<FormattedMessage
									id="ticketInformarFechaRealizado.render.report_done_date.label_fecha_hora_fin_reparacion"
									defaultMessage="Fecha Fin Reparación"
								/>
							) : (
								<FormattedMessage
									id="ticketInformarFechaRealizado.render.report_done_date.label_fecha_hora_realizado"
									defaultMessage="Fecha Realizado"
								/>

							)}
						</label>
						<div>
							<DatePicker
								id="fechaHoraRealizado"
								name="fechaHoraRealizado"
								className="form-control date-picker-placeholder"
								placeholderText={intl.formatMessage({
									id: 'ticketInformarFechaRealizado.render.report_done_date.placeholder_fecha_realizado',
									defaultMessage: 'DD/MM/AAAA',
								})}
								selected={fechas.realizado}
								onChange={e => setFechas({ ...fechas, realizado: e })}
								onChangeRaw={(e) => { e.preventDefault() }}
								// onChangeRaw={(event) => { (CB.informaFechaFinReparacion ? dateIsValid(fechas.realizado, true) : dateIsValid(fechas.realizado)) && setFechas({ ...fechas, realizado: event.target.value }) }}
								dateFormat={CB.informaFechaFinReparacion ? "DD/MM/yyyy HH:mm" : "DD/MM/yyyy"}
								openToDate={moment()}
								showTimeSelect={CB.informaFechaFinReparacion ? true : false}
								timeIntervals={10}
								showMonthDropdown={CB.informaFechaFinReparacion ? false : true}
								showYearDropdown={CB.informaFechaFinReparacion ? false : true}
								dropdownMode="select"
								maxDate={moment()}
								minDate={fechas.inicio ? moment(fechas.inicio) : undefined}
								autoComplete="off"

							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const RenovacionVencimiento = ({ vtoRenovacionByServicio, setVtoRenovacionByServicio }) => {
	const intl = useIntl()
	return (
		<>
			<div style={popupContainerStyle}>
				<h4>
					<FormattedMessage id="ticketsGrid.accionesMasivas.renovacion_vencimientos" defaultMessage="Datos de los próximos Vencimientos" />
				</h4>

				{Object.entries(vtoRenovacionByServicio).map((item) => {
					let id = item[0]
					let servicioNombre = item[1].nombre;
					let tickets = item[1].tickets || [];
					return (
						<div className="d-flex flex-column mx-auto mt-2 mb-1" key={id}>
							<h5>
								<FormattedMessage id="ticketsGrid.accionesMasivas.cierre_vencimiento.tickets_correspondientes_servicio" defaultMessage="Tickets correspondientes al servicio " />
								<strong>{servicioNombre}</strong>:
							</h5>
							<span className="pl-2">{tickets.join(', ')}</span>
							<div className="d-flex flex-row gap-3 ">
								<div >
									<label htmlFor="fechaExpedicion">
										<FormattedMessage id="ticketDatosCierreVencimiento.render.next_maturity_data.label_fecha_de_expedicion" defaultMessage="Fecha de Expedición" />:
									</label>
									<div>
										<DatePicker
											id="fechaExpedicion"
											name="fechaExpedicion"
											className="form-control date-picker-placeholder"
											placeholderText={intl.formatMessage({
												id: 'ticketDatosCierreVencimiento.render.next_maturity_data.placeholder_fecha_de_expedicion',
												defaultMessage: 'DD/MM/AAAA',
											})}
											selected={vtoRenovacionByServicio[id]['fechaExpedicion']}
											// onChange={(e) => handleDateChange(servicioId, 'fechaExpedicion', e)}
											onChange={e => setVtoRenovacionByServicio({ ...vtoRenovacionByServicio, [id]: { ...vtoRenovacionByServicio[id], fechaExpedicion: e } })}
											onChangeRaw={(e) => { e.preventDefault() }}
											// onChangeRaw={(e) => { 
											// 	dateIsValid(vtoRenovacionByServicio[id]['fechaExpedicion']) && setVtoRenovacionByServicio({ ...vtoRenovacionByServicio, [id]: { ...vtoRenovacionByServicio[id], fechaExpedicion: e.target.value } }) 
											// }}
											dateFormat="DD/MM/yyyy"
											openToDate={moment()}
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											autoComplete="off"
										/>
									</div>
								</div>
								<div>
									<label htmlFor="fechaVencimiento">
										<FormattedMessage id="ticketDatosCierreVencimiento.render.next_maturity_data.label_fecha_de_vencimiento" defaultMessage="Fecha de Vencimiento" />:
									</label>
									<div>
										<DatePicker
											id="fechaVencimiento"
											name="fechaVencimiento"
											className="form-control date-picker-placeholder"
											placeholderText={intl.formatMessage({
												id: 'ticketDatosCierreVencimiento.render.next_maturity_data.placeholder_fecha_de_vencimiento',
												defaultMessage: 'DD/MM/AAAA',
											})}
											selected={vtoRenovacionByServicio[id]['fechaVencimiento']}
											// onChange={(e) => handleDateChange(servicioId, 'fechaVencimiento', e)}
											onChange={e => setVtoRenovacionByServicio({ ...vtoRenovacionByServicio, [id]: { ...vtoRenovacionByServicio[id], fechaVencimiento: e } })}
											// onChangeRaw={(e) => { dateIsValid(vtoRenovacionByServicio[servicioId]['fechaVencimiento']) && handleDateChange(servicioId, 'fechaVencimiento', e.target.value) }}
											onChangeRaw={(e) => { e.preventDefault() }}
											dateFormat="DD/MM/yyyy"
											minDate={(vtoRenovacionByServicio[id]['fechaExpedicion'] && vtoRenovacionByServicio[id]['fechaExpedicion'].isValid()) ? moment.max([moment(), vtoRenovacionByServicio[id]['fechaExpedicion']]) : moment()}
											openToDate={moment()}
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>
						</div>
					)
				}
				)}
				<small style={{ whiteSpace: 'normal', color: 'red' }}>
					<FormattedMessage id="ticketsGrid.accionesMasivas.renovacion_vencimientos_warning_fechas" defaultMessage="Tenga en cuenta que las fechas seran aplicadas a TODOS los tickets del servicio" />
				</small>
			</div>
		</>
	)
}

const InformaFechaTaller = ({ fechas, setFechas }) => {
	const intl = useIntl()
	return (
		<>
			<div style={popupContainerStyle}>
				<h4 style={{ whiteSpace: 'normal' }}>
					<FormattedMessage id="ticketHeader.modal_cierre.informa_fecha_salida_taller" defaultMessage=" Por favor Indicar la fecha y hora de salida de taller" />
				</h4>

				<div className="d-flex mt-1 mb-1 mx-auto w-50" >
					<DatePicker
						id="fechaHoraSalidaTaller"
						name="fechaHoraSalidaTaller"
						className="form-control date-picker-placeholder"
						placeholderText={intl.formatMessage({ id: 'correctivosAbm.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}
						selected={fechas.taller}
						onChange={(event) => setFechas({ ...fechas, taller: event })}
						onChangeRaw={(event) => { dateIsValid(fechas.taller, true) && setFechas({ ...fechas, taller: event.target.value }) }}
						dateFormat="DD/MM/yyyy HH:mm"
						showTimeSelect
						timeIntervals={10}
						maxDate={moment()}
					/>
				</div>
			</div>
		</>
	)
}

const Encuesta = ({ encuesta, setEncuesta }) => {
	return (
		<>
			<div style={popupContainerStyle}>
				<h4 style={{ whiteSpace: 'normal' }}>
					<FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.nivel_satisfaccion" defaultMessage=" Por favor Indicar el nivel de satisfacción por el servicio recibido" />
				</h4>

				<div className="row mt-1 mx-auto">
					<div className="btn-group btn-group-toggle radioButtons" data-toggle="buttons" >
						<label className="btn radio-red active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 1 })} name="options" autoComplete="off" /> 1
						</label>
						<label className="btn radio-red active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 2 })} name="options" autoComplete="off" />2
						</label>
						<label className="btn radio-red active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 3 })} name="options" autoComplete="off" />3
						</label>
						<label className="btn radio-red active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 4 })} name="options" autoComplete="off" />4
						</label>
						<label className="btn radio-red active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 5 })} name="options" autoComplete="off" />5
						</label>
						<label className="btn radio-red active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 6 })} name="options" autoComplete="off" />6
						</label>
						<label className="btn radio-yellow active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 7 })} name="options" autoComplete="off" />7
						</label>
						<label className="btn radio-yellow active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 8 })} name="options" autoComplete="off" />8
						</label>
						<label className="btn radio-green active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 9 })} name="options" autoComplete="off" />9
						</label>
						<label className="btn radio-green active form-check-label">
							<input className="form-check-input" type="radio" onClick={() => setEncuesta({ ...encuesta, value: 10 })} name="options" autoComplete="off" />10
						</label>
					</div>
					<div className='row mt-1 mx-auto d-flex justify-content-between'>
						<div><FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.pesimo_trabajo" defaultMessage="Pésimo Trabajo" /></div>
						<div className="ml-4 mr-4" style={{ minWidth: '20px' }}>
							<span>{encuesta.value}</span>
						</div>
						<div><FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.excelente_trabajo" defaultMessage="Excelente Trabajo" /></div>
					</div>
					<div className="row mt-2 mx-auto">
						<label htmlFor="comentariosEncuesta">
							<FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.comentarios" defaultMessage="Comentarios (Opcional)" />
						</label>
						<textarea onChange={(e) => { setEncuesta({ ...encuesta, comment: e.target.value }) }} value={encuesta.comment} className="form-control" id="comentariosEncuesta" rows="3" />
					</div>
				</div>
			</div>
		</>
	)
}



export default AccionesMasivas