import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import LlantaModelosGrid from "./LlantaModelosGrid";
import LlantasModelosAbm from "./LlantasModelosAbm.js";
import { FormattedMessage, injectIntl } from "react-intl";

class Modelos extends Component {
  render() {
    return (
      <div className="content-wrapper llantas-modulo">
        <div className="content-header row">
          <div className="content-header-left col-md-6 col-12 mb-5">
            <h3 className="content-header-title">
              <FormattedMessage
                id="Gerenciadores.header_administracion_de_modelos"
                defaultMessage="Administración de Diseños/Modelos"
              />
            </h3>
          </div>
        </div>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              component={LlantaModelosGrid}
            />
            <Route
              exact
              path={`${this.props.match.url}/add`}
              render={(props) => (
                <LlantasModelosAbm {...props} action="ADD" />
              )}
            />
            <Route
              exact
              path={`${this.props.match.url}/:id/edit`}
              render={(props) => (
                <LlantasModelosAbm {...props} action="EDIT" />
              )}
            />
            <Route
              path={`${this.props.match.url}/:id`}
              render={(props) => (
                <LlantasModelosAbm {...props} action="VIEW" />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default injectIntl(Modelos);
