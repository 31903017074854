export const ID__TE_PERSONA = 1;
export const ID__TE_MOVIL = 2;
export const TIPOS_ENTIDAD = {}


TIPOS_ENTIDAD[ID__TE_PERSONA] = {
	id: ID__TE_PERSONA,
	defaultMessage: 'PERSONA',
}

TIPOS_ENTIDAD[ID__TE_MOVIL] = {
	id: ID__TE_MOVIL,
	defaultMessage: 'MOVIL',
}