import Autopista from '../../assets/images/tareas/autopista.svg'
import SandClock from '../../assets/images/tareas/sand_clock.svg'
export const ID__UM_ODOMETRO = 1;
export const ID__UM_HOROMETRO = 2;
export const TIPOS_UNIDAD_MEDIDOR = {}


TIPOS_UNIDAD_MEDIDOR[ID__UM_ODOMETRO] = {
	id: ID__UM_ODOMETRO,
	label: 'ODOMETRO',
	icono: 'fa fa-dashboard',
	unidad: 'Km',
	unidades: 'Kms',
	icono_preventivos: Autopista,
}

TIPOS_UNIDAD_MEDIDOR[ID__UM_HOROMETRO] = {
	id: ID__UM_HOROMETRO,
	label: 'HOROMETRO',
	icono: 'fa fa-clock',
	unidad: 'Hora',
	unidades: 'Horas',
	icono_preventivos: SandClock,
}
