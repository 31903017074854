import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'
import * as Constants from '../constants.js'

class InstanciaSelect extends InstanciaBasic {
	constructor(props) {
    super(props);
		this.recalcularEstado = this.recalcularEstado.bind(this);
		this.castValue = this.castValue.bind(this);
  }

	recalcularEstado(value) {
		let estadoActual = Constants.ID_ESTADO_APROBACION_VALIDO
		let opcionCorrespondiente = null
		if (this.props.atributo.extraParams.opciones)
			opcionCorrespondiente = this.props.atributo.extraParams.opciones.find(opcion => opcion.text == value)
		if (opcionCorrespondiente)
			estadoActual = opcionCorrespondiente.estado
		return estadoActual
	}

	castValue(value) {
		let atributoDinamico = this.props.atributo
		if (
			atributoDinamico.extraParams.opciones &&
			atributoDinamico.extraParams.opciones[0] &&
			!atributoDinamico.extraParams.opciones.map(opcion => opcion.text).includes(value)
		) {
			value = atributoDinamico.extraParams.opciones[0].text;
		}
		return value
	}


	render() {
      return (
        <React.Fragment>
					<>
						{(this.props.disabled ?
							<input
								disabled={this.props.disabled}
								type="text"
								className="form-control"
								value={this.props.atributo[this.valueKey]}

							/>
							:
							<select
								 className="form-control"
								 onChange={(value) => this.handleInputChange(value)}
								 placeholder="Valor"
								 disabled={this.props.disabled}
								 value={this.props.atributo[this.valueKey]}
								>
								 {this.props.atributo.extraParams.opciones && this.props.atributo.extraParams.opciones.map((option, index) =>
									 <option key={option.text} value={option.text}>
										{option.text}
									 </option>
									)}
							</select>
						)}
					</>
        </React.Fragment>
      )
  }
}

export default InstanciaSelect;
