import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Security from "../../commons/security/Security.js";
import Loading from "../ui/Loading.js";
import "moment/min/locales";
import { FormattedMessage, injectIntl } from "react-intl";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import moment from "moment";
import {Collapse, TablePagination, TableCell, TableRow, CircularProgress} from "@material-ui/core";
import Switch from "react-switch";
import $ from 'jquery';
import VencimientoTicketRojo from '../../assets/images/tareas/vencimientostickets-07-rojo.svg';
import VencimientoVerde from '../../assets/images/tareas/vencimiento_verde.svg';
import VencimientoAmarillo from '../../assets/images/tareas/vencimiento_amarillo.svg';
import VencimientoRojo from '../../assets/images/tareas/vencimiento_rojo.svg';
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Sort from '../../assets/images/llantas/table/sort.svg';
import SortAsc from '../../assets/images/llantas/table/sort-asc.svg';
import SortDesc from '../../assets/images/llantas/table/sort-desc.svg';
import Input from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import * as Constants from "../atributoDinamico/constants.js";
import LlantaInspectionDetails from "./LlantaInspectionDetails";
import Timezone from '../../commons/timezone/Timezone.js';
import { rowsPerPage } from '../../commons/utils/TableStyles'
import * as Utils from '../../commons/utils/Utils';
import swal from 'sweetalert';

const useStylesPersonal = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
}));

function Row(props) {
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const { inspeccion } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isHidden, setIsHidden] = useState("hidden-personal");
  useLayoutEffect(() => {
    $(".action-btn").tooltip();
    return () => {
      $(".action-btn").tooltip('dispose');
    };
  }, []);


  const openCollapse = () => {
    setOpen(!open);
    if (isHidden === "hidden-personal") {
      setIsHidden("block");
    } else {
      setIsHidden("hidden-personal");
    }
  };

  const getLlantaEstado = () => {
    if (inspeccion.estadoInspeccion == Constants.ID_ESTADO_APROBACION_VALIDO) 
      return "status-green";
    else if (inspeccion.estadoInspeccion == Constants.ID_ESTADO_APROBACION_INVALIDO)
      return "status-red";
    else if (inspeccion.estadoInspeccion == Constants.ID_ESTADO_APROBACION_PENDIENTE)
      return "status-yellow";
    else
      return "status-grey";
  };

  const handleView = () => {
    props.redirectToView(inspeccion.id, inspeccion.movil_id);
  };

  const handleEdit = () => {
    if(inspeccion.movil_cambio_diagrama)
      swal(
        props.intl.formatMessage({
          id: 'llantaInspeccionesGrid.no_editar_cambio_diagrama',
          defaultMessage: 'No se puede editar por que el movil cambio de diagrama.',
        }),
        '',
        'error',
      );
    else
      props.redirectToEdit(inspeccion.id, inspeccion.movil_id);
  };

  $('#preventivoDias_' + inspeccion.id).tooltip();
  $('#preventivoTicket_' + inspeccion.id).tooltip();
  $('#preventivoServicio_' + inspeccion.id).tooltip();
  $('#vencimientoServicio_' + inspeccion.id).tooltip();
  $('#vencimientoTicket_' + inspeccion.id).tooltip();
  return (
    <React.Fragment>
      <tr key={inspeccion.id}>
        <td
          component="th"
          scope="row"
          align="center"
          size="small"
          className="custom-lineHeight"
          style={{width: "60px"}}
        >
          <div className="d-flex items-center align-items-center">
            <div
              className="personal-no-focus avatar-plus-detail d-flex items-center"
              aria-label="expand row"
              size="small"
              onClick={() => openCollapse()}
            >
              {open ? (
                <i className="fa fa-2x fa-angle-double-down personal-no-focus avatar-plus-detail"></i>
              ) : (
                <i className="fa fa-angle-double-right fa-2x personal-no-focus avatar-plus-detail"></i>
              )}
            </div>
            <span>
              {inspeccion.id}
            </span>
          </div>
        </td>
        <td
          className={getLlantaEstado() + " all custom-lineHeight"}
          data-toggle="tooltip"
          data-placement="top"
          title={inspeccion.estado}
          style={{ maxWidth: "3px" }}
        >
        </td>
        <td className="all custom-lineHeight">
          { Timezone.getDateForClient(inspeccion.created_at, null, 'YYYY-MM-DD HH:mm:ss') }
        </td>
        <td className="all custom-lineHeight">
          {inspeccion.persona ? inspeccion.persona.apellido + ", " + inspeccion.persona.nombre : ""}
        </td>
        <td className="all custom-lineHeight">
          {inspeccion.movil ? inspeccion.movil.dominio : ""}
        </td>
        <td className="all custom-lineHeight">
          {inspeccion.cantidadFallas}
        </td>
        <td align="center" className="custom-lineHeight">
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {Security.renderIfHasPermission("LLANTAS_INSPECCIONES_VISUALIZAR",
            <button
              className="action-btn btn btn-sm btn-icon btn-dt-grid text-success"
              data-togle="tooltip"
              data-placement="top"
              title="Ver"
              onClick={() => handleView()}
            >
              <i className="fa fa-search fa-xs"></i>
            </button>)}
            {Security.renderIfHasPermission("LLANTAS_INSPECCIONES_MODIFICAR",
            <button
              className="action-btn btn btn-sm btn-icon btn-dt-grid text-success"
              data-togle="tooltip"
              data-placement="top"
              title="Editar"
              onClick={() => handleEdit(inspeccion.id)}
            >
              <i className="fa fa-pencil fa-xs"></i>
            </button>)}
          </div>
        </td>
      </tr>
      <TableRow className={isHidden}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <LlantaInspectionDetails
              intl={props.intl}
              inspeccion_id={inspeccion.id}
              movil={inspeccion.movil}
              fecha={inspeccion.created_at}
              redirectToCorrectivo={props.redirectToCorrectivo}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const LlantaInspeccionesGrid = (props) => {
  const classes = useStylesPersonal();
  const intl = props.intl;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [dataService, setDataService] = useState("/llantainspecciones");
  const [inspecciones, setInspecciones] = useState([]);
  const [id, setId] = useState("");
  const [persona, setPersona] = useState("");
  const [movil, setMovil] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    count: 2,
    perPage: 10,
  });
  const [query, setQuery] = useState({
    page: 0,
    id: "",
    idSort: "desc",
    persona: "",
    personaSort: "",
    movil: "",
    movilSort: "",
    movil_id: "",
    fechaSort: ""
  });
  const [filtersActive, setFiltersActive] = useState(false);
  const [filterLoading, setFilterLoading] = useState({
    marcas: false,
    modelos: false,
  });
  const [checked, setChecked] = React.useState(false);
  const [showPersistirFiltros, setShowPersistirFiltros] = useState(false);

  useEffect(() => {
    if (Security.hasPermission("LLANTAS_INSPECCIONES_LISTAR")) {
      ajaxHandler.subscribe(this);
      let movil_id = new URLSearchParams(props.location.search).get("movil_id");
      if(movil_id){
        query['movil_id'] = movil_id;
        setQuery(query);
        setFiltersActive(true);
      }
      initGrid();
    } else {
      setRedirectTo("/error");
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (showPersistirFiltros) {
      let filtersStorage = window.localStorage.getItem('llantaInspeccionesGrid_filter') ? JSON.parse(window.localStorage.getItem('llantaInspeccionesGrid_filter')) : {};
      filtersStorage.activo = true
      window.localStorage.setItem('llantaInspeccionesGrid_filter', JSON.stringify(filtersStorage))
    }
  }, [showPersistirFiltros])

  const initGrid = () => {
    setLoading(true);
    window.scrollTo(0, 0);

    Promise.all([
        Utils.getData(dataService + dataTableQuery())
      ]).then((data) => {
        setLoading(false);
        setInspecciones(data[0].inspecciones);
        setPagination(data[0].pagination);
        let filtersStorage = JSON.parse(window.localStorage.getItem('llantaInspeccionesGrid_filter'));
        if (filtersStorage && filtersStorage.activo) {
          setChecked(filtersStorage.activo);
          setShowPersistirFiltros(filtersStorage.activo)
          for (let filterName in filtersStorage) {
            handleMaterialChange(filterName, filtersStorage[filterName]);
          }
        }
    });
  };

  const setUserFilter = (name, element, value) => {
    let filters = window.localStorage.getItem('llantaInspeccionesGrid_filter') ? JSON.parse(window.localStorage.getItem('llantaInspeccionesGrid_filter')) : {};
    filters[name] = element
    window.localStorage.setItem('llantaInspeccionesGrid_filter', JSON.stringify(filters))
  }

  const handlePersistirFiltros = (value) => {
    setShowPersistirFiltros(value)
    if (!value) {
      let filtersStorage = {}
      filtersStorage.activo = false
      window.localStorage.setItem('llantaInspeccionesGrid_filter', JSON.stringify(filtersStorage))
      handleFilterReset()
    }
  }

  const dataTableQuery = () => {
    let myQuery = "?page=" + pagination.page;
    myQuery += "&perPage=" + pagination.perPage;
    myQuery += "&id=" + query.id;
    myQuery += "&idSort=" + query.idSort;
    myQuery += "&persona=" + query.persona;
    myQuery += "&personaSort=" + query.personaSort;
    myQuery += "&movil=" + query.movil;
    myQuery += "&movilSort=" + query.movilSort;
    myQuery += "&movil_id=" + query.movil_id;
    myQuery += "&fechaSort=" + query.fechaSort;
    return myQuery;
  };

  const dataTableUpdate = (setFilters) => {
    setFiltersActive(setFilters);
    Promise.all([
        Utils.getData(dataService + dataTableQuery())
      ]).then((data) => {
        setInspecciones(data[0].inspecciones);
        setPagination(data[0].pagination);
        
    });
  };

  const handleChangePage = (event, newPage) => {
    pagination.page = newPage;
    dataTableUpdate(false);
  };

  const handleChangeRowsPerPage = (event) => {
    pagination.perPage = parseInt(event.target.value, 10);
    pagination.page = 0;
    dataTableUpdate(false);
  };

  const getData = (service) => {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response)
        ? response.json()
        : null;
    });
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleFilterReset = () => {
    query.page = 0;
    query.id = "";
    query.idSort = "desc";
    query.fechaSort = "";
    query.persona = "";
    query.personaSort = "";
    query.movil = "";
    query.movilSort = "";
    query.movil_id = "";
    setId("")
    setPersona("")
    setMovil("")
    setQuery(query);
    dataTableUpdate(true);
    setFiltersActive(false);
  };


  const handleMaterialChange = (name, values) => {
    if (name === "id"){
      let event = values;
      setId(event.target.value);
      query[name] = event.target.value;
      setUserFilter(name, { target: { value: event.target.value } }, { target: { value: event.target.value } })
    }
    if (name === "persona"){
      let event = values;
      setPersona(event.target.value);
      query[name] = event.target.value;
      setUserFilter(name, { target: { value: event.target.value } }, { target: { value: event.target.value } })
    }
    if (name === "movil"){
      let event = values;
      query['movil_id'] = "";
      setId(event.target.value);
      query[name] = event.target.value;
      setUserFilter(name, { target: { value: event.target.value } }, { target: { value: event.target.value } })
    }
    pagination.page = 0;
    setQuery(query);
    dataTableUpdate(true);
    if (values === null) {
      setFiltersActive(false);
    }
  };

  const handleOnEnterSerialChange = (name, e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleMaterialChange(name, e);
    }
  };

  const handleAlertClick = (filter) => {
    query.filtroKpi = filter;
    dataTableUpdate(true);
  };

  const setLoadingAndUpdate = (state) => {
    setLoading(state);
    dataTableUpdate(true);
  };

  const redirectToCorrectivo = (id) => {
    setRedirectTo("/correctivos/add/llanta" + "/" + id);
  };

  const requestSort = (key) => {
    pagination['page'] = 0;
    if (key === "idSort"){
      if (!query[key])
        query[key] = "asc";
      else if(query[key] === "desc")
        query[key] = "asc";
      else
        query[key] = "desc";
      query['personaSort'] = "";
      query['movilSort'] = "";
      query['fechaSort'] = "";
    }else if(key === "personaSort"){
      if (!query[key])
        query[key] = "asc";
      else if(query[key] === "desc")
        query[key] = "asc";
      else
        query[key] = "desc";
      query['movilSort'] = "";
      query['fechaSort'] = "";
      query['idSort'] = "";
    }
    else if(key === "movilSort"){
      if (!query[key])
        query[key] = "asc";
      else if(query[key] === "desc")
        query[key] = "asc";
      else
        query[key] = "desc";
      query['personaSort'] = "";
      query['fechaSort'] = "";
      query['idSort'] = "";
    }else if(key === "fechaSort"){
      if (!query[key])
        query[key] = "asc";
      else if(query[key] === "desc")
        query[key] = "asc";
      else
        query[key] = "desc";
      query['personaSort'] = "";
      query['movilSort'] = "";
      query['idSort'] = "";
    }
    setQuery(query);
    setPagination(pagination);
    dataTableUpdate(false);
  }

  const redirectToView = (id) => {
    setRedirectTo("/llantainspecciones/" + id);
  };

  const redirectToEdit = (id) => {
    setRedirectTo("/llantainspecciones/" + id + "/edit");
  };

  const handleNew = (event) => {
    setRedirectTo("/llantainspecciones/add");
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
      <div className="row mt-2">
        <div className="col-12">
          <div className="card">
            <div className="card-content collpase show">
              <div className="card-body card-dashboard">
                <div className="container-fluid">
                  <div className="row dt-icons">
                    <div className="col-10">
                      <div className="row">
                        {Security.renderIfHasPermission(
                          "LLANTAS_INSPECCIONES_CREAR",
                          <div>
                            <div
                              className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                              style={{ height: "55px" }}
                              onClick={handleNew}
                              data-toggle="tooltip"
                              data-placement="right"
                              title={props.intl.formatMessage({
                                id: "llantaInspeccionesGrid.render.button_add.title_nuevo",
                                defaultMessage: "Nuevo",
                              })}
                            >
                              <i className="ft-plus"></i>
                            </div>
                          </div>
                        )}
                        {/*TODO: SOLVE STYLE PROBLEMS AND TRY TO RESIZE FROM ICON TO ACCORDION*/}
                        <div
                          style={{ maxHeight: "52px" }}
                          className="btn btn-grey-blue btn-round box-shadow-2 btn-dt-main round-icon"
                          onClick={handleChange}
                          data-toggle="tooltip"
                          data-placement="right"
                          title={props.intl.formatMessage({
                            id: "llantaInspeccionesGrid.render.button_add.title_filtros",
                            defaultMessage: "Filtros",
                          })}
                        >
                          {checked ? <UpIcon /> : <ExpandMoreIcon />}
                        </div>
                        {filtersActive ? (
                          <div className="btn-dt-main round-icon">
                            <div
                              className="text-center"
                              style={{
                                height: "25px",
                                borderRadius: "3px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingLeft: "8px",
                                backgroundColor: "#5AAC43",
                                color: "white",
                              }}
                            >
                              <div
                                className="text-center"
                                style={{
                                  width: "100%",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                {inspecciones.length} de {pagination.count}{" "}
                                resultados de busqueda
                              </div>
                              <div className="resultados-busqueda-notif">
                                <i
                                  className="las la-times la-xs cursor-pointer"
                                  onClick={handleFilterReset}
                                ></i>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse in={checked}>
                  <div className="row mb-1">
                    <div className="col-md-9">
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-4">
                          <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                          >
                            <Input
                              label={props.intl.formatMessage({
                                id: "llantaInspeccionesGrid.render.filtros.label_id",
                                defaultMessage: "ID",
                              })}
                              name="id"
                              value={id}
                              onChange={(e) => setId(e.target.value)}
                              onBlur={(e) =>
                                handleMaterialChange("id", e)
                              }
                              onKeyDown={(e) => handleOnEnterSerialChange('id', e)}
                            />
                          </form>
                        </div>
                        <div className="col-md-4">
                          <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                          >
                            <Input
                              label={props.intl.formatMessage({
                                id: "llantaInspeccionesGrid.render.filtros.label_persona",
                                defaultMessage: "Persona",
                              })}
                              name="persona"
                              value={persona}
                              onChange={(e) => setPersona(e.target.value)}
                              onBlur={(e) =>
                                handleMaterialChange("persona", e)
                              }
                              onKeyDown={(e) => handleOnEnterSerialChange('persona', e)}
                            />
                          </form>
                        </div>
                        <div className="col-md-4">
                          <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                          >
                            <Input
                              label={props.intl.formatMessage({
                                id: "llantaInspeccionesGrid.render.filtros.label_movil",
                                defaultMessage: "Móvil",
                              })}
                              name="movil"
                              value={movil}
                              onChange={(e) => setMovil(e.target.value)}
                              onBlur={(e) =>
                                handleMaterialChange("movil", e)
                              }
                              onKeyDown={(e) => handleOnEnterSerialChange('movil', e)}
                            />
                          </form>
                        </div>
                      </div>
                      {/*NEW FILTER*/}
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <div className="mr-2">
                          <FormattedMessage
                            id="llantaInspeccionesGrid.rennder.filtros.persistir_filtros"
                            defaultMessage="Persistir Filtros"
                          />
                        </div>
                        <Switch
                          onChange={(value) => handlePersistirFiltros(value)}
                          checked={showPersistirFiltros}
                          disabled={loading}
                          offColor="#FF4961"
                          onColor="#28D094"
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>

                <div className="table-responsive">
                  <table
                    id="dataTable"
                    className="dataTable table nowrap server-side table-hover dataTableMovil"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        {/* <th scope="col"></th> */}
                        <th 
                          scope="col" 
                          style={{cursor: 'pointer'}}
                          onClick={() => requestSort('idSort')}
                        >
                          <div
                            className="bg-transparent text-white border-0"
                          >
                            <FormattedMessage
                              id="llantaInspeccionesGrid.rennder.column_id.label"
                              defaultMessage="Id"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.idSort === "" ? Sort
                                :(
                                  query.idSort === "asc" ? SortAsc : SortDesc
                                )
                              }
                              alt={"/"}
                              style={{ height: "16px", width: "26px" }}
                            />
                          </div>
                        </th>
                        <th scope="col" style={{ maxWidth: "3px" }}></th>
                        <th 
                          scope="col"
                          style={{cursor: 'pointer'}}
                          onClick={() => requestSort('fechaSort')}
                        >
                          <div
                            className="bg-transparent text-white border-0"
                          >
                            <FormattedMessage
                              id="llantaInspeccionesGrid.rennder.column_fecha.label"
                              defaultMessage="Fecha"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.fechaSort === "" ? Sort
                                :(
                                  query.fechaSort === "asc" ? SortAsc : SortDesc
                                )
                              }
                              alt={"/"}
                              style={{ height: "16px", width: "26px" }}
                            />
                          </div>
                        </th>
                        <th 
                          scope="col"
                          style={{cursor: 'pointer'}}
                          onClick={() => requestSort('personaSort')}
                        >
                          <div
                            className="bg-transparent text-white border-0"
                          >
                            <FormattedMessage
                              id="llantaInspeccionesGrid.rennder.column_usuariolabel"
                              defaultMessage="Usuario"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.personaSort === "" ? Sort
                                :(
                                  query.personaSort === "asc" ? SortAsc : SortDesc
                                )
                              }
                              alt={"/"}
                              style={{ height: "16px", width: "26px" }}
                            />
                          </div>
                        </th>
                        <th 
                          scope="col"
                          style={{cursor: 'pointer'}}
                          onClick={() => requestSort('movilSort')}
                        >
                          <div
                            className="bg-transparent text-white border-0"
                          >
                            <FormattedMessage
                              id="llantaInspeccionesGrid.rennder.column_movil.label"
                              defaultMessage="Móvil"
                            />
                            <img
                              className="ml-1"
                              src={
                                query.movilSort === "" ? Sort
                                :(
                                  query.movilSort === "asc" ? SortAsc : SortDesc
                                )
                              }
                              alt={"/"}
                              style={{ height: "16px", width: "26px" }}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <FormattedMessage
                            id="llantaInspeccionesGrid.rennder.column_alertas.label"
                            defaultMessage="Alertas"
                          />
                        </th>
                        <th scope="col" align="center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {inspecciones.length > 0 ? (
                        inspecciones.map((inspeccion) => (
                          <Row
                            intl={props.intl}
                            inspeccion={inspeccion}
                            loading={(state) => setLoadingAndUpdate(state)}
                            key={inspeccion.id}
                            dataTableUpdate={dataTableUpdate}
                            redirectToView={redirectToView}
                            redirectToEdit={redirectToEdit}
                          />
                        ))
                      ) : (
                        <tr>
                          <td align={"center"} colSpan={14}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              <FormattedMessage
                                id="llantaInspeccionesGrid.rennder.sin_datos_en_tabla"
                                defaultMessage="No hay datos disponibles."
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  rowsPerPageOptions={rowsPerPage}
                  component="div"
                  count={pagination.count}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="Mostrar"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} a ${to} de ${count}`
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(LlantaInspeccionesGrid);