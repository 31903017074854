import React, { Component } from 'react'
import InstanciaBasicConAdjunto from './InstanciaBasicConAdjunto.js'
import CanvasDraw from "react-canvas-draw";
import axios from 'axios'
import Config from '../../../commons/config/Config.js'
import InstanciaBasic from './InstanciaBasic.js'

const configAxios = {
	headers: {
		'Authorization-Token': localStorage.getItem("token")
	}
};


class InstanciaFirma extends InstanciaBasicConAdjunto {
	constructor(props) {
		super(props);
		this.props = props;
		this.handleInputChange = this.handleInputChange.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);

		this.state = {
			adjunto: null,
			adjunto_url: null,
			editandoFirma: true,
			adjuntoArray: [],
			adjuntoUrlArray: [],
		}
	}

	castValue(value) {
		if (this.props.previsualizacion)
			return ''
		if (isNaN(value))
			return ''
		else
			return Number(value)
	}

	componentDidMount() {
		let valorCasteado = this.castValue(this.props.atributo[this.valueKey])
		this.handleChangeAndUpdateFather(valorCasteado)
		if (valorCasteado) {
			this.setState({
				editandoFirma: false
			})
		}
	}

	handleInputChange(file) {
		let valorCasteado = this.castValue(this.props.atributo[this.valueKey])
		if (valorCasteado) {
			axios.delete(Config.get('apiUrlBase') + '/adjuntos/' + valorCasteado, configAxios).then(response => {
				console.log(response);
			})
		}
		if (file) {
			let fd = new FormData();
			var hoy = new Date();
			fd.append("file", file, 'Firma ' + hoy.toDateString() + ".png");
			axios.post(Config.get('apiUrlBase') + '/adjuntos', fd, configAxios).then(response => {
				this.handleChangeAndUpdateFather(response.data.id)
			})
		} else {
			this.handleChangeAndUpdateFather('')
			this.setState({
				editandoFirma: true
			})
		}

	}

	handleChangeAndUpdateFather(adjunto_id) {
		if(this.props.atributo && this.props.atributo.adjuntoUrlArray){
			this.setState({
				adjuntoUrlArray: [...this.state.adjuntoUrlArray, this.props.atributo.adjuntoUrlArray]
			});
		}else{
			if (adjunto_id) {
				axios.get(Config.get('apiUrlBase') + '/adjuntos/' + adjunto_id, configAxios).then(response => {
					this.setState({
						adjuntoArray: [...this.state.adjuntoArray, response.data],
					}, () => {
						this.props.handleChange(this.valueKey, adjunto_id);
					});
				})
				let config = Object.assign({}, configAxios, { responseType: 'blob' });
				axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + adjunto_id, config).then(response => {
					if (response.status === 200) {
						let fileUrl = URL.createObjectURL(response.data);
						this.setState({
							adjuntoUrlArray: [...this.state.adjuntoUrlArray, fileUrl]
						});
					}
				}).catch((e) => { console.log(e) });
			} else {
				this.setState({
					adjuntoArray: [],
					adjuntoUrlArray: []
				}, () => {
					this.props.handleChange(this.valueKey, '');
				});
			}
		}

	}

	render() {
		let saveableCanvas = null
		return (
			<React.Fragment>
				<div className="form-control">
					{this.state.editandoFirma && (
						<>
							<button
								className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid pull-right"
								onClick={(e) => {
									e.preventDefault()
									saveableCanvas.clear();
									this.handleInputChange(null);
								}}
								title="Eliminar"
							>
								<i className="fa fa-trash fa-xs"></i>
							</button>
							<br />
							<br />
							<CanvasDraw
								className="img-fluid"
								ref={canvasDraw => (saveableCanvas = canvasDraw)}
								disabled={this.props.disabled || this.props.previsualizacion}
								lazyRadius={10}
								brushRadius={2}
								onChange={event => saveableCanvas.canvas.drawing.toBlob(this.handleInputChange, "image/png")}
								canvasWidth={400}
								canvasHeight={150}
							/>
						</>
					)}
					{!this.state.editandoFirma && (
						<>
							{!this.props.disabled &&
								<button
									className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid pull-right"
									onClick={(e) => {
										e.preventDefault()
										this.handleInputChange(null)
									}}
									title="Eliminar" >
									<i className="fa fa-trash fa-xs"></i>
								</button>
							}
							{this.state.adjunto_url && <img className="img-fluid" src={this.state.adjunto_url} />}
							{this.state.adjuntoUrlArray.map(url => <img className="img-fluid" src={url} />)}
						</>
					)}
				</div>
			</React.Fragment>
		)
	}
}

export default InstanciaFirma;
