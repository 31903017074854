import React, { Component } from "react";
import Loading from "../ui/Loading.js";
import moment from "moment";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import * as Constants from "../atributoDinamico/constants.js";
import $ from "jquery";
import axios from "axios";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import { castValoresDinamicos } from "../atributoDinamico/helpers.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import AsociarTicket from "./AsociarTicket.js";
import { PieChart } from "react-minimal-pie-chart";


const configAxios = {
  headers: {
    "Authorization-Token": localStorage.getItem("token"),
  },
};

class FormularioDetail extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");

    // this.openModalTicket = this.openModalTicket.bind(this);
    // this.handleCloseModalTicket = this.handleCloseModalTicket.bind(this);
    // this.redireccionarATickets = this.redireccionarATickets.bind(this);
    // this.getTicketURL = this.getTicketURL.bind(this);

    this.state = {
      props: this.props,
      formulario: null,
      valores_dinamicos: [],
      loading: true,
      id_map: "map",
      id_modal_ticket: "modal_ticket",
      dialogInformarPago: {
        open: false,
        movil: null,
        valorDinamicoId: null,
      },
    };
  }
  
  componentWillMount() {
    //this.ajaxHandler.subscribe(this);
    //this.initForm();
  }

  componentWillUnmount() {
    //this.ajaxHandler.unsubscribe();
    //$(".popover2").popover("dispose");
  }

  render() {
    let state = this.state;
    let formulario = this.state.formulario;
    $(".popover2").popover();

    return (
      <React.Fragment>
        <td colSpan="13" style={{ "background-color": "#f4f5fa" }}>
          
        </td>
      </React.Fragment>
    );
  }
}

export default FormularioDetail;
