import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Config from '../../commons/config/Config.js'
import moment from 'moment'
import 'moment/min/locales'
import $ from 'jquery'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import {FormattedMessage, injectIntl} from 'react-intl';

$.DataTable = DataTable;

class UsuariosMovilesGrid extends Component {

    constructor(props) {
        super(props);
        this.ajaxHandler = new AjaxHandler();
        this.dataService = '/personas/movilesgrid';
        moment.locale('es');

        this.state = {
            redirectTo: null,
        }        
    }

    componentDidMount(nextProps, nextState) {
        if(Security.hasPermission('USUARIOSMOVILES_LISTAR')) {
            this.ajaxHandler.subscribe(this);           
            this.initGrid();
        } else {
            this.setState({
                redirectTo: '/error'
            });
        }
    }

    componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

    render() {
        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                {this.state.loading && <Loading />}

                <div className="row mt-2">
					<div className="col-12">
						<div className="card">
                            <div className="card-content collpase show">
                                <div className="card-body card-dashboard">
                                    <div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
                                    <div className="table-responsive">
                                        <table id="dataTable" className="table nowrap server-side table-hover dataTableMovil" ref="grid" width="100%">
                                            <tfoot>
												<tr style={{backgroundColor: '#fff'}}>
                                                    <th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>													
												</tr>
											</tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    initGrid() {
        window.scrollTo(0, 0);
        let component = this;
        let data = [];
        this.table = $(this.refs.grid).DataTable(
            Object.assign({
                dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
                stateSave: true,
                stateSaveCallback: function(settings, data) {
                    localStorage.setItem('DataTables_UsuariosMovilesGrid', JSON.stringify(data));
                },
                stateLoadCallback: function(settings) {
                    return JSON.parse(localStorage.getItem('DataTables_UsuariosMovilesGrid'));
                },
                processing: true,
                serverSide: true,
                ajax: {
                    type: 'GET',
                    url: Config.get('apiUrlBase') + this.dataService,
                    headers: {
                        'Authorization-Token': localStorage.getItem("token")
                    },
                    dataSrc: function(response) {
                        return response.data;
                    },
                    error: function(xhr, error, thrown) {
                        component.ajaxHandler.handleResponseErrorsIsValid(xhr);
                    },
                    cache: false
                },
                initComplete: function(settings) {
                    let stateColumns = JSON.parse(localStorage.getItem('DataTables_UsuariosMovilesGrid')).columns;
                    var sets = settings;
                    var index = 0;

                    this.api().columns().every(function() {
                        var column = this;

						if (sets.aoColumns[index].filterType) {
                            if (sets.aoColumns[index].filterType === 'input') {
                                var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'personasGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
                                if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
                                input.appendTo($(column.footer()).empty());
                                input.on('keyup change', function() {
                                    if (column.search() !== this.value) {
                                        column
                                            .search(this.value)
                                            .draw()
                                            .ajax.reload(null, false);
                                    }
                                });
                            }
                        }
                    });
                        
                    $('tfoot tr').appendTo('thead');
                },
                columns: [
                    {
						title: component.props.intl.formatMessage({ id: 'usuariosMovilesGrid.column_lastName.label_apellido', defaultMessage: 'Apellido' }),
						className: 'all',
						data: 'apellido',
						filterType: 'input'
					}, {
						title: component.props.intl.formatMessage({ id: 'usuariosMovilesGrid.column_name.label_nombre', defaultMessage: 'Nombre' }),
						className: 'all',
						data: 'nombre',
						filterType: 'input'
					}, {
						title: component.props.intl.formatMessage({ id: 'usuariosMovilesGrid.column_legajo.label_legajo', defaultMessage: 'Legajo' }),
						className: 'all',
						data: 'legajoNumero',
						filterType: 'input'
					}, {
						orderable: false,
						data: null,
						className: "text-left all",
						width: '120px',
						render: function(data, type, full, meta) {
							let html =
								(Security.hasPermission('USUARIOSMOVILES_VISUALIZAR') ? `
								<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'UsuariosMovilesGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('USUARIOSMOVILES_MODIFICAR') ? `
								<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'UsuariosMovilesGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-pencil fa-xs"></i>
								</button>` : '');
							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'
					}
                ],
                drawCallback: function() {
                    $(this).find('.action').on('click', function() {
                        let data = component.table.row($(this).parents('tr')).data();
                        let redirectTo;
                        if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
                        if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
                        component.setState({
                            redirectTo: redirectTo
                        });
                    });
                },
                rowCallback: function(row, data) {
                }
            }, datatablesConfig(this.props.intl))
        );
        
        
        let as = new $.fn.dataTable.Buttons(this.table, {
            buttons: [{
                name: 'filterReset',
                text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
                titleAttr: component.props.intl.formatMessage({ id: 'personasGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
                action: function (e, dt, node, config) {
                    var index = 0;
                    component.table.columns().every(function() {
                        var column = this;

                        if (dt.settings()[0].aoColumns[index].filterType) {
                            column.search('', true, false);
                        }
                        index++;
                        return null;
                    });
                    $(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
                        $(element).val('');
                    });
                    dt.table().order([ 0, 'asc' ]);
                    dt.ajax.reload();
                }
            }]
        });        

        this.table.buttons(0, null).container().appendTo('#buttons');
        this.table.button('filterReset:name')
            .nodes()
            .attr('data-toggle', 'tooltip')
            .attr('data-position', 'top');
        this.table.button('print:name')
            .nodes()
            .attr('data-toggle', 'tooltip')
            .attr('data-position', 'top');

        $('[data-toggle="tooltip"]').tooltip();

        $('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
            if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
                $('.dt-button-collection>.dropdown-menu').css('display','block');
        });
    }

    getData(service) {
        let serviceURL = Config.get('apiUrlBase') + service;
        console.log(serviceURL);
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(UsuariosMovilesGrid);
