import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import '../../assets/css/vec-dropzone.css'
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class LlantaMarcasAbm extends Component {
  constructor(props) {
    super(props);
    let component = this;
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        id: 0,
        nombre: '',
        descripcion: '',
        adjunto_id: '',
        codigo: ''
      },
      estados: [{ id: 1, nombre: this.props.intl.formatMessage({ id: 'marcasAbm.state_activo', defaultMessage: 'Activo' })},{ id: 0, nombre: this.props.intl.formatMessage({ id: 'marcasAbm.state_inactivo', defaultMessage: 'Inactivo' })}],
      errors: [],

      loading: false
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);

    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);


    this.formValidation = new FormValidation({
      component: this,
      validators: {
        'formData.nombre': (value) => Validator.notEmpty(value)

      }
    });

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: this.props.action === 'ADD' ? true : false,
      postUrl: Config.get('apiUrlBase') + '/adjuntos',
    };

    this.djsConfig = {
      addRemoveLinks: false,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      thumbnailWidth: 240,
      thumbnailHeight: 240,
      dictDefaultMessage: '',
      uploadMultiple: false,
      maxFiles: 1,
      headers: {
        "Authorization-Token": localStorage.getItem("token")
      },
      init: function () {
        let dropzone = this;
          this.on('addedfile', function(file) {
            $(this.element).find('.dz-details').hide();
          });
          dropzone.disable();
      }
    };

    this.eventHandlers = {
      init: dz => {
        this.dropzone = dz;
      },
      success: this.handleFotoSuccess.bind(this)
    };

    this.componentConfig2 = {
      iconFiletypes: ['.jpg', '.png', '.gif', '.pdf', '.doc', '.xls'],
      showFiletypeIcon: true,
      postUrl: '/uploadHandler'
    };

    this.djsConfig2 = {
      dictDefaultMessage: component.props.intl.formatMessage({ id: 'marcasAbm.add_file_djsConfig2.haga_click_aqui_y_seleccione_uno_o_mas_archivos', defaultMessage: 'Haga click aquí o ' })+"<br>"+component.props.intl.formatMessage({ id: 'marcasAbm.add_file_djsConfig2.o_arrastre_sus_archivos_a_este_area', defaultMessage: ' o arrastre sus archivos a este área.' })
    };

    this.eventHandlers2 = {

    };
  }
  initForm() {
    this.setState({ loading: true });
    let component = this;
    Promise.all([

      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/llantamarcas/' + this.state.props.match.params.id) : this.ajaxHandler.getJson('/llantamarcas'),
    ]).then((data) => {
      if(this.state.props.action !== 'ADD'){
        let formData = data[0];
        if(formData) component.setState({
          formData: formData
        });
      }
    }).catch(function(error) {
      console.log(error);
    }).finally(() => {
      this.setState({ loading: false });
    });

    window.scrollTo(0, 0);
      $('#nombre').focus();
  }
  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }
  componentWillMount() {

    if ((Security.hasPermission('LLANTAS_MARCAS_CREAR') && this.state.props.action === 'ADD') ||
      (Security.hasPermission('LLANTAS_MARCAS_MODIFICAR') && this.state.props.action === 'EDIT') ||
      (Security.hasPermission('LLANTAS_MARCAS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }




  }

  getData(service, param) {
    let serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
    return fetch(serviceURL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
      }
    })
    .then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }


  handleFotoSuccess(file, response) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.adjunto_id = response.id;
    this.setState({
      formData: formDataCopy
    });
  }

  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['activo'] = activo;
    this.setState({
      formData: formDataCopy
    });
  }



  handleNotificacionesActivasChange(notificacionesActivas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
    this.setState({
      formData: formDataCopy
    });
  }

  handleInputFormChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    this.setState({
      formData: formDataCopy
    });
  }





  handleSelectFormChange(name, object) {
    const value = object === null ? null : object.id;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    this.setState({
      formData: formDataCopy
    });
  }





  handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    /*
    fetch(Config.get('apiUrlBase') + '/marcas' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
      },
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(AjaxHandler.handleResponseErrorsIsValid(response)) {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json().then(data => {
            this.setState({
              errors: data.detalle
            });
            window.scrollTo(0,0);
          });
        }
      }
    }).catch((error) => {
      AjaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
  */
  this.ajaxHandler.fetch('/llantamarcas' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
    method: this.props.action === 'ADD' ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...this.state.formData
    }),
  }).then(response => {
    if(response.status !== 400) {

      component.exit();
    } else {
      response.json()
      .then(data => {
        this.setState({
          errors: data.detalle
        });
      });
    }
    window.scrollTo(0,0);
  }).catch((error) => {
    component.ajaxHandler.handleResponseErrorsIsValid(error);
  }).finally(() => {
    this.setState({ loading: false });
  });
    event.preventDefault();
  }

  handleCancel(event) {
    this.exit();
  }

  exit() {
    this.setState({
      redirectTo: '/llantamarcas'
    });
  }

  render() {
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
              {this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
            </div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>


              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="llantaMarcasAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="marcasAbm.render.general_data.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                      </h4>

                      <div className="row">
                        <div className="col-md-9">

                          <div className="row"> {/* CAMBIAR DESDE CSS */}
                            <div className="col-md-6">
                            <br></br><br></br><br></br>
                            </div>
                          </div>

                          <div className="row">
                            {/* NOMBRE */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="nombre">
                                  <FormattedMessage id="llantaMarcasAbm.render.general_data.label_marca" defaultMessage="Marca *:"/>
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                                  ) : (
                                  <div>
                                    <input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'llantaMarcasAbm.render.general_data.placeholder_nombre', defaultMessage: 'Nombre' })} value={formData.nombre} onChange={this.handleInputFormChange} />
                                    <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="row">
                            {/* Observaciones */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="observaciones">
                                  <FormattedMessage id="marcasAbm.render.general_data.label_descripcion :" defaultMessage="Descripción :"/>
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.descripcion}</div>
                                  ) : (
                                  <div>
                                    <input type="text" className="form-control" id="descripcion" name="descripcion" placeholder={this.props.intl.formatMessage({ id: 'marcasAbm.render.general_data.placeholder_descripcion', defaultMessage: 'Descripción' })} value={formData.descripcion} onChange={this.handleInputFormChange} />
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          { Security.isInternal() ?
                            <div className="row">
                              {/* Código */}
                              <div className="col-md-6">
                                <div className="form-group row">
                                  <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="codigo">
                                    <FormattedMessage id="marcasAbm.render.general_data.label_codigo :" defaultMessage="Código :"/>
                                  </label>
                                  <div className="col-md-8">
                                    {this.props.action === 'VIEW' ? (
                                    <div className="form-control-static col-form-label form-value">{formData.codigo}</div>
                                    ) : (
                                    <div>
                                      <input type="text" className="form-control" id="codigo" name="codigo" placeholder={this.props.intl.formatMessage({ id: 'marcasAbm.render.general_data.placeholder_codigo', defaultMessage: 'Código' })} value={formData.codigo} onChange={this.handleInputFormChange} />
                                    </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
                          <i className="fa fa-check-circle"></i><FormattedMessage id="llantaMarcasAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'llantaMarcasAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'llantaMarcasAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(LlantaMarcasAbm);
